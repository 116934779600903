import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  CardContent,
  Card,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { getData } from "../../api/api";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { green } from "@material-ui/core/colors";
import {
  HashRouter as Router,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { BillingContext } from "./billingContext";
import { data } from "jquery";
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};
const columns = [
  { id: "req_reference_no", label: "Reference No." },
  {
    id: "client_name",
    label: "Client",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "req_company_name",
    label: "Assigned Company",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "req_date_added",
    label: "Date",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "completename",
    label: "Prepared by",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "req_date_start",
    label: "Period Start",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "req_date_end",
    label: "Period End",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "status",
    label: "Status",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "net_amount_due",
    label: "Amount",
    format: (value) => formatNumber(value),
  },
];

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 300,
  },
});
const options = ["View"];
const ITEM_HEIGHT = 48;
const MultiButton = (props) => {
  const branches = useSelector((state) => state.HomeReducer.branches);
  const dispatch = useDispatch();
  const { data } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const history = useHistory();
  const handleClose = (option) => {
    if (option === "Edit") {
      let data2 = {
        client_id: data.client_id,
      };
      let data3 = {
        service_id: data.service_id,
        client_id: data.client_id,
      };
      Promise.all([
        getData("aam/getServices", data2),
        getData("aam/getServiceBranch", data3),
      ]).then((res) => {
        const branchesFilter = branches.filter(
          (val) => val.company_id == data.company_id
        );
        const branchesFilterAddress = branches.filter(
          (val) => val.branch_id == data.branch_id
        );

        dispatch({
          type: "onChangeBilling",
          data: {
            branchList: branchesFilter,
            selectedBillingInvoice: data,
            actionType: "Edit",
            data: moment(data.req_date_added).format("YYYY-MM-DD"),
            invoice_due: data.invoice_due,
            company_id: data.company_id,
            branch_id: data.branch_id,
            selectedClient: {
              client_id: data.client_id,
              client_name: data.client_name,
              client_address: data.client_address,
            },
            tin: data.tin,
            address: data.client_address,
            radioValue: data.invoice_type,
            selectedService: {
              service_id: data.service_id,
              service_name: data.service_name,
              contract_no: data.service_contract_no,
            },
            serviceList: res[0].res,
            clientBranchList: res[1].res,
            periodStart: moment(data.req_date_start).format("YYYY-MM-DD"),
            periodEnd: moment(data.req_date_end).format("YYYY-MM-DD"),
            branchServedList: JSON.parse(data.req_details),
            vatPercent: data.vat_percent,
            withHoldingTaxPercent: data.with_holding_tax_percent,
            clientBranchAddress:
              branchesFilterAddress[0].branch_loc_description,
            invoice_no: data.req_reference_no,
            req_id: data.req_id,
            bill_reference_no: data.docs_reference_no,
          },
        });
        history.push("/billingForm");
      });
    } else if (option === "View") {
      let data_val = {
        req_id: data.req_id,
      };
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/getJournalEntriesById", data_val).then((res) => {
        dispatch({
          type: "onChangeBilling",
          data: {
            selectedBillingInvoice: data,
            selectedJournalEntry: res.res,
            activities: res.logs,
          },
        });
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
        history.push("/billingDetails");
      });
    }

    setAnchorEl(null);
  };
  return (
    <div>
      <div
        type="button"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <MoreVertIcon />
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => handleClose(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const StickyHeadTable = React.memo(() => {
  const branches = useSelector((state) => state.HomeReducer.branches);
  const billingList = useSelector((state) => state.BillingReducer.billingList);
  const page = useSelector((state) => state.BillingReducer.page);
  const rowsPerPage = useSelector((state) => state.BillingReducer.rowsPerPage);
  const selectedType = useSelector(
    (state) => state.BillingReducer.selectedType
  );
  const search = useSelector((state) => state.BillingReducer.search);
  const listCount = useSelector((state) => state.BillingReducer.listCount);
  const onCheckedBilling = useSelector(
    (state) => state.BillingReducer.onCheckedBilling
  );
  const { getBillingData } = React.useContext(BillingContext);
  const classes = useStyles();
  const forceUpdate = React.useReducer((bool) => !bool)[1];

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    getBillingData(selectedType, newPage, rowsPerPage, search);

    dispatch({
      type: "onChangeBilling",
      data: {
        page: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    getBillingData(
      selectedType,
      page,
      rowsPerPage + event.target.value,
      search
    );
    dispatch({
      type: "onChangeBilling",
      data: {
        page: 0,
        rowsPerPage: rowsPerPage + event.target.value,
      },
    });
  };
  React.useEffect(() => {
    getBillingRequest();
  }, []);

  const getBillingRequest = () => {
    getBillingData(selectedType, page, rowsPerPage, search);
  };

  const onCheck = (details) => {
    let match = onCheckedBilling.findIndex(
      (val) => val.req_id === details.req_id
    );
    if (match === -1) {
      let data = {
        req_id: details.req_id,
        reference_number: details.req_reference_no,
        branch_id: details.branch_id,
        company_id: details.company_id,
      };
      let newCheck = onCheckedBilling.map((val) => val);
      newCheck.push(data);
      dispatch({
        type: "onChangeBilling",
        data: {
          onCheckedBilling: newCheck,
        },
      });
    } else {
      let newCheck = onCheckedBilling.map((val) => val);
      newCheck.splice(match, 1);
      dispatch({
        type: "onChangeBilling",
        data: {
          onCheckedBilling: newCheck,
        },
      });
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Paper className={classes.root} variant="outlined">
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      backgroundColor: "#2c6164",
                      color: "#ffff",
                      whiteSpace: "nowrap",
                      width: 20,
                      whiteSpace: "nowrap",
                    }}
                  ></TableCell>
                  {/* <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap', width: 20 }}
                                    >
                                    </TableCell> */}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#2c6164",
                        color: "#ffff",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {billingList.map((row, index) => {
                  let match = onCheckedBilling.filter(
                    (val) => val.req_id == row.req_id
                  );
                  let check = false;
                  if (match.length > 0) {
                    check = true;
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <TableCell>
                        <MultiButton data={row} />
                      </TableCell>
                      {/* <TableCell >
                                                {row.status == 'Received'?undefined:
                                                    <FormControlLabel 
                                                    control={<GreenCheckbox checked={check} onChange={() => onCheck(row)} name="checkedG" />}
                                                    />
                                                }
                                               
                                            </TableCell> */}
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </CardContent>
    </Card>
  );
});
export default StickyHeadTable;
