const initialState = {
    service_name:'',
    contract_no:'',
    amount:'',
    unit:'',
    serviceList:[],
    actionType:'Add',
    selectedServices:[]
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'onChangeServiceReducer':
            return {
                ...state,
                ...action.data,
            }
        default:
            return state;
    }
}
export default dataReducer; 