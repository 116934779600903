import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button, Card, CardContent, Typography, Dialog, Slide, AppBar, Tooltip, TextField, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import RequestForm from './requestForm'
import RequestDetails from './requestDetails'
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from '@material-ui/icons/Refresh';

import { getData } from "../../api/api"
import { useSelector, useDispatch } from "react-redux";
import CallMadeIcon from '@material-ui/icons/CallMade';
import moment from 'moment'
import PositionedSnackbar from '../../alert'
let width = window.innerWidth;
const columns = [
    { id: 'req_reference_no', label: 'Reference No.' },
    {
        id: 'req_date_added',
        label: 'Date Request',
        format: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
        id: 'fullname',
        label: 'Requestor',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    // {
    //     id: 'req_payee',
    //     label: 'Payee',
    //     format: (value) => String(value).toLocaleUpperCase(),
    // },
    // {
    //     id: 'company_name',
    //     label: 'Company',
    //     format: (value) => String(value).toLocaleUpperCase(),
    // },
    // {
    //     id: 'department',
    //     label: 'Department',
    //     format: (value) => String(value).toLocaleUpperCase(),
    // },
    // {
    //     id: 'req_approval_status',
    //     label: 'Status',
    //     format: (value) => String(value).toLocaleUpperCase(),
    // },

];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const homeReducer = useSelector(state => state.HomeReducer)
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        requestList: [],
        requestListCount: 0,
        actionButton: '',
        selectedRequest: [],
        reqDetailsModal: false,
        refreshReqList: false,
        countStatus: [],
        status: 'Total',
        comment_modal: false,
        comment_value: '',
        searchDriver: ''

    })
    const handleChangePage = (event, newPage) => {
        setPage(newPage);

        getRequest(newPage, rowsPerPage, state.status, '', true)
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        getRequest(page, rowsPerPage, state.status, '', false)

    }, [homeReducer.onRefresh])

    React.useEffect(() => {
        getRequest(page, rowsPerPage, state.status, '', true, '', true)

    }, [state.refreshReqList])
    const getRequest = (page, row, status, searchDriver = "", loadingStatus) => {
        console.log(true)
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: loadingStatus,
            }
        })
        let new_page = 1 + page

        // if (typeof homeReducer.loginData.complete_name !== 'undefined') {
        let branches = homeReducer.branches.map((val) => (val.branch_id))
        let data = {
            user_id: localStorage.getItem('u'),
            branches: branches,
            page: new_page,
            limit: row,
            status: status,
            company: homeReducer.loginData.company_id,
            app_id: "7",
            searchDriver: searchDriver,
            req_type: 'Request New Supplier'
        }
        getData('aam/getRequestCRFToBeApproved', data).then((res) => {
            setState(prev => ({ ...prev, requestList: res.data, requestListCount: res.count, countStatus: res.countStatus, status: status }))
            setPage(page);
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }

    const onChangeText = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const handleNotification = (firstApprover) => {
        homeReducer.socket.emit("sendNotification", {
            senderName: homeReducer.loginData.user_id,
            receiverName: firstApprover,
            type: ''
        })
        let pendingReqForPayment = parseInt(homeReducer.pendingReqForPayment) - 1
        dispatch({
            type: 'onChangeHomeReducer',
            data: { onRefresh: !homeReducer.onRefresh, pendingReqForPayment: pendingReqForPayment }
        })
    }
    const onSubmitApproval = (e, request_status) => {
        e.preventDefault()

        let text = "Are you sure you want to " + request_status + " this request?";
        if (window.confirm(text) == true) {
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: true,
                }
            })
            let select_req = state.selectedRequest
            let match_apv_cv_access = homeReducer.branches.filter(val => (parseInt(state.selectedRequest.branch_id) === parseInt(val.branch_id)))
            let apv_cv_access = ''

            if (match_apv_cv_access.length > 0) {
                apv_cv_access = match_apv_cv_access[0].account_voucher_access
            }
            let request = {
                req_id: select_req.req_id,
                req_approval_id: select_req.req_approval_id,
                status: request_status,
                req_hierarchy_level: select_req.req_hierarchy_level,
                req_details: JSON.parse(select_req.req_details),
                approver: '',
                comment: state.comment_value,
                app_id: '7',
                company_id: homeReducer.loginData.company_id,
                branch_id: homeReducer.loginData.branch_id,
                approver_id: homeReducer.loginData.user_id,
                apv_cv_access: apv_cv_access
            }

            getData('HumanResource/changeRequestStatus', request).then((res) => {
                if (String(res).includes('Success')) {
                    getRequest(page, rowsPerPage, state.status, '', true)
                    state.selectedRequest.req_approval_status = request_status

                    if (state.selectedRequest.req_hierarchy_structure !== "") {
                        let firstApprover = JSON.parse(state.selectedRequest.req_hierarchy_structure)
                        for (let index = 0; index < firstApprover.length; index++) {
                            const element = firstApprover[index];
                            let match = element.approver.filter((val) => (val.id === homeReducer.loginData.user_id))
                            if (match.length > 0) {
                                let Nextapprover = ''
                                if (firstApprover.length - 1 !== index) {
                                    Nextapprover = JSON.parse(state.selectedRequest.req_hierarchy_structure)[index + 1].approver[0].id

                                } else {
                                    Nextapprover = apv_cv_access
                                }
                                handleNotification(Nextapprover)
                            }
                        }
                    }
                    setState(prev => ({
                        ...prev,
                        alert: true,
                        alertMessage: "Success, Request has been " + request_status,
                        alertColor: '#2ecc71',
                        comment_value: "",
                        comment_modal: false
                    }))
                } else {
                    setState(prev => ({
                        ...prev,
                        alert: true,
                        alertMessage: "Something went wrong",
                        alertColor: '#2ecc71',
                    }))
                }
            })

        } else {

        }
    }
    const AddComment = (e) => {
        e.preventDefault();
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let request_comments = {
            app_id: '7',
            user_id: homeReducer.loginData.user_id,
            comment_type: 'Text',
            comment: state.comment_value,
            req_id: state.selectedRequest.req_id,
            req_reference_no: state.selectedRequest.req_reference_no,
            req_user_id: state.selectedRequest.user_id,

        }
        getData('HumanResource/LogRequestComment', request_comments).then((res) => {
            if (String(res).includes("Success")) {
                state.selectedRequest.comments.push({
                    user_lname: state.selectedRequest.user_lname,
                    user_fname: state.selectedRequest.user_fname,
                    comment: state.comment_value
                })
                setState(prev => ({
                    ...prev, comment_modal: false,
                    comment_value: "",
                    // refreshReqList:!state.refreshReqList
                }))
                homeReducer.socket.emit("sendNotification", {
                    senderName: homeReducer.loginData.user_id,
                    receiverName: state.selectedRequest.user_id,
                    type: ''
                })

                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: false,
                    }
                })
            }
        })
    }
    return (
        <Grid container spacing={1}>
            <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container spacing={1}>
                            {state.countStatus.map((val, index) => {
                                return <Grid key={index} item xs={12} md={3}>
                                    <Card style={{ background: val.color }} variant='outlined'>
                                        <CardContent>
                                            <div style={{ display: 'flex' }}>
                                                <Typography className={classes.cardFont}>{val.type}</Typography>
                                            </div>
                                            <Typography className={classes.cardFontCount}>{val.count}</Typography>
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', cursor: 'pointer' }}>
                                                <Tooltip title='View' onClick={() => getRequest(0, rowsPerPage, val.type, "", true)}>
                                                    <CallMadeIcon className={classes.iconSize} style={{ color: '#fff', fontWeight: 'bold', width: 20, height: 20 }} />

                                                </Tooltip>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            })}

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid container spacing={1}>
                    <Grid container item xs={12} md={3} justify='flex-start'>
                        <Card variant='outlined' style={{ width: 230, padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <input value={state.searchDriver} placeholder="Search Req ID / Requestor" style={{ borderStyle: 'none', outline: 'none' }} name='searchDriver' onChange={onChangeText} />
                            <SearchIcon style={{ cursor: 'pointer', color: '#787f80' }} onClick={() => getRequest(0, rowsPerPage, state.status, state.searchDriver, true)} />
                            <RefreshIcon style={{ cursor: 'pointer', color: '#787f80' }} onClick={() => {
                                setState(prev => ({
                                    ...prev, searchDriver: ""
                                })); setTimeout(() => { getRequest(0, rowsPerPage, state.status, "", true) }, 200)
                            }} />
                        </Card>

                    </Grid>
                    <Grid container item xs={12} md={9} justify='flex-end'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'cnter' }}>
                            <Typography variant='p' style={{ fontWeight: 'bold' }}>Filter : {state.status}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.root, classes.outlined} variant='outlined'>
                            <TableContainer className={classes.container, classes.tableHeight}>
                                <Table stickyHeader aria-label="sticky table" >
                                    <TableHead>
                                        <TableRow>

                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                            <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}>Supplier Name</TableCell>
                                            <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}>Supplier Address</TableCell>

                                            <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}>Status</TableCell>

                                            <TableCell style={{ backgroundColor: '#2c6164' }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.requestList.map((row) => {
                                            let bgColor = '#3498db'
                                            if (row.req_approval_status === 'Approved') {
                                                bgColor = '#2ecc71'
                                            }
                                            if (row.req_approval_status === 'Pending') {
                                                bgColor = '#faab2d'
                                            }
                                            if (row.req_approval_status === 'Denied') {
                                                bgColor = '#bf7bdb'
                                            }

                                            let details = JSON.parse(row.req_details)
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>

                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.id === 'req_approval_status' ?
                                                                    <span style={{ paddingTop: 3, paddingBottom: 3, paddingRight: 5, paddingLeft: 5, background: bgColor, color: '#fff', borderRadius: 5 }}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </span>
                                                                    : column.format && typeof value === 'number' ? column.format(value) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    <TableCell>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>


                                                            {details.map((valDetails, index2) => {
                                                                return <Typography variant='p' key={index2}>{String(valDetails.supplier_name).toLocaleUpperCase()}</Typography>
                                                            })
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>


                                                            {details.map((valDetails, index2) => {
                                                                return <Typography variant='p' key={index2}>{String(valDetails.supplier_address).toLocaleUpperCase()}</Typography>
                                                            })
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span style={{ paddingTop: 3, paddingBottom: 3, paddingRight: 5, paddingLeft: 5, background: bgColor, color: '#fff', borderRadius: 5 }}>
                                                            {row.req_approval_status}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Tooltip onClick={() => {
                                                            setState(prev => ({ ...prev, actionButton: 'Details', selectedRequest: row, reqDetailsModal: true }));

                                                        }} title='View'>
                                                            <CallMadeIcon className={classes.iconSize} />
                                                        </Tooltip></TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={state.requestListCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Dialog fullScreen open={open} onClose={handleClose} >
                <div className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {state.actionButton}
                        </Typography>
                    </Toolbar>
                    <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} />
                </div>
                <RequestForm refresh={()=>{setState(prev=>({...prev,refreshReqList:!state.refreshReqList}))}} handleClose={() => handleClose()} action={state.actionButton} selectedRequest={state.selectedRequest} />

            </Dialog> */}
            <Dialog fullScreen open={state.reqDetailsModal} onClose={() => {
                setState(prev => ({ ...prev, reqDetailsModal: false }))
            }} >
                <AppBar className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            setState(prev => ({ ...prev, reqDetailsModal: false }))
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {state.actionButton}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ marginTop: 60 }} />
                <div style={{ display: 'flex', flex: 1, background: '#f5f7f7' }}>
                    <RequestDetails handleClose={() => handleClose()} action={state.actionButton} selectedRequest={state.selectedRequest} />
                </div>
                <div style={{ marginTop: 50 }} />
                <Card elevation={4} style={{ position: "fixed", bottom: 1, display: 'flex', alignItems: 'center', background: '#fff', width: '100%', justifyContent: 'flex-end ', height: 50, paddingRight: 20 }}>
                    {state.selectedRequest.req_approval_status === 'Pending' ?
                        <>
                            <Button onClick={() => {
                                setState(prev => ({ ...prev, comment_modal: true }))
                                //    onSubmitApproval('Denied')

                            }} style={{ backgroundColor: '#e74c3c', color: '#fff', width: width < 600 ? '100%' : undefined, marginRight: 10 }} variant="contained" >Add Comment</Button>
                            <Button onClick={(e) => {
                                onSubmitApproval(e, 'Approved')

                            }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Approve</Button>
                        </>
                        : undefined

                    }

                </Card >
            </Dialog>
            <Dialog fullWidth maxWidth="xs" open={state.comment_modal} onClose={() => {
                setState(prev => ({ ...prev, comment_modal: false }))
            }} >
                <DialogTitle>Add Comment</DialogTitle>
                <DialogContent>
                    <form onSubmit={AddComment}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <TextField required style={{ width: '100%' }} onChange={onChangeText} name='comment_value' variant='outlined' size='small' />
                            </Grid>
                            <Grid container justify='flex-end' item xs={12} md={12}>
                                <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>

            </Dialog>
        </Grid>

    );
}