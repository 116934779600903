import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {Button,IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


export default function PositionedSnackbar({ open, vertical, horizontal, alertMessage, alertColor, handleClose }) {




    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
                autoHideDuration={4000}
            >
                <SnackbarContent style={{
                    backgroundColor:alertColor,
                }}
                    message={<span id="client-snackbar">{alertMessage}</span>}
                />
            </Snackbar>
        </div>
    );
}