import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const SelectInput = (props) => {
  const { ...param } = props;
  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", display: "flex" }}>
        <Typography
          style={{ fontWeight: "bold", fontSize: 12, textAlign: "left" }}
          variant="p"
        >
          {param.label}
        </Typography>
      </div>

      <FormControl
        variant={"outlined"}
        style={{ width: "100%", background: "#fff" }}
        size="small"
      >
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          required
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={param.value}
          onChange={param.onChange}
          style={{ textAlign: "left", width: "100%" }}
        >
          <MenuItem value={"All"}>{"All"}</MenuItem>
          {param.dataList.map((val, index) => {
            return (
              <MenuItem key={index} value={param.valueData(val)}>
                {param.valueDisplay(val)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
SelectInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  dataList: PropTypes.array,
  valueData: PropTypes.func,
  valueDisplay: PropTypes.func,
};

export default React.memo(SelectInput, renderEqualProps);
