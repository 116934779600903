// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { Breadcrumbs, Backdrop, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../api/api"
import CloseIcon from '@material-ui/icons/Close';
import ClientTable from './clientTable'
import AddIcon from '@material-ui/icons/Add';
let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

const FilterClient = React.memo(()=>{
    const classes = useStyles();
    const dispatch = useDispatch()
    const onOpenAddModal = () =>{
        dispatch({
            type:'onChangeClientReducer',
            data:{
                addModal:true
            }
        })
    }
    return (
        <Grid container spacing={1}>
            <Grid container justify={'flex-start'} item xs={12} md={12}>
                <Button startIcon={<AddIcon/>} onClick={() => {onOpenAddModal()}} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Client</Button>
            </Grid>
        </Grid>
    );
})
export default FilterClient;
