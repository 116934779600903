// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
import {
  HashRouter as Router,
  BrowserRouter,
  Route,
  useParams,
  Redirect,
  Link as NewLink,
  useHistory,
} from "react-router-dom";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

function IndexReport() {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Reports</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Financial Statements
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      hover
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      component={NewLink}
                      to="/profitAndLossStatement/"
                    >
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Profit and Loss Statement
                      </TableCell>
                    </TableRow>
                    <TableRow hover style={{ cursor: "pointer" }}>
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Profit and Loss Statement(Actual vs Budget)
                      </TableCell>
                    </TableRow>
                    <TableRow hover style={{ cursor: "pointer" }}>
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                        component={NewLink}
                        to="/balanceSheet/"
                      >
                        Balance Sheet
                      </TableCell>
                    </TableRow>
                    <TableRow hover style={{ cursor: "pointer" }}>
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Statement of Changesin Equity
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        General Ledger
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover>
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Trial Balance
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        General Ledger Summary
                      </TableCell>
                    </TableRow>
                    <TableRow hover>
                      <TableCell
                        style={{
                          color: "#2c6164",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        General Ledger Transaction
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
export default React.memo(IndexReport, renderPropsAreEqual);
