// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import { Breadcrumbs, Backdrop, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PositionedSnackbar from "../../../alert";
let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    },
    textFieldStyle: {
        width: '100%',
        backgroundColor: '#f7fbfc'
    }
});

const AddingItem = React.memo((props) => {
    const { getInitialData } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const item_name = useSelector(state => state.BillingSettingReducer.item_name)
    const item_code = useSelector(state => state.BillingSettingReducer.item_code)
    const price = useSelector(state => state.BillingSettingReducer.price)
    const unit = useSelector(state => state.BillingSettingReducer.unit)

    const alertMessage = useSelector(state => state.BillingSettingReducer.alertMessage)
    const alertColor = useSelector(state => state.BillingSettingReducer.alertColor)
    const alert = useSelector(state => state.BillingSettingReducer.alert)

    const selectedClientData = useSelector(state => state.BillingSettingReducer.selectedClientData)
    const onOpenAddModal = () => {
        dispatch({
            type: 'onChangeClientReducer',
            data: {
                addModal: true
            }
        })
    }
    const onChangeText = (e) => {
        let name = e.target.name
        let value = e.target.value
        dispatch({
            type: 'onChangeBillingSetting',
            data: {
                [name]: value
            }
        })
    }
    console.log(selectedClientData)
    const onSubmit = (e) => {
        e.preventDefault()
        let data = {
            billing_item_name: item_name,
            billing_item_code: item_code,
            billing_item_price: price,
            billing_item_unit: unit,
            user_id: localStorage.getItem('u'),
            billing_client_id: selectedClientData.client_id,
            billing_client_branch_id: selectedClientData.branch_id,
        }
        getData('aam/insertBillingItem', data).then((res) => {
            console.log(res)
            if (res.res == true) {
                dispatch({
                    type: 'onChangeBillingSetting',
                    data: {
                        item_name: '',
                        item_code: '',
                        price: '',
                        unit: ''
                    }
                })
            }else if (res.res == 'Exist') {
                dispatch({
                    type:'onChangeBillingSetting',
                    data:{
                        alert: true,
                        alertMessage: "Warning , Please select a payee!",
                        alertColor: '#f39c12'
                    }
                })
            }
        })
    }

    return (
        <Card variant='outlined'>
            <CardContent>
            <PositionedSnackbar open={alert} vertical={'top'} horizontal={'center'} alertMessage={alertMessage} alertColor={alertColor} handleClose={() => {
               dispatch({
                type:'onChangeBillingSetting',
                data:{
                    alert: false,
                }
            })
            }} />
                <form onSubmit={onSubmit}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} md={12} justify='flex-start'>
                            <Typography variant="h5" style={{ color: '#2c7f84' }}>Add Item</Typography>
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={8}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Item Name</Typography>
                            <TextField required onChange={onChangeText} value={item_name} name='item_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={4}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Item Code</Typography>
                            <TextField placeholder="Optional" onChange={onChangeText} value={item_code} name='item_code' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={4}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Price</Typography>
                            <TextField required onChange={onChangeText} value={price} name='price' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={8}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Unit</Typography>
                            <FormControl variant={'outlined'} style={{ width: '100%', background: '#f7fbfc' }} size='small'>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={unit}
                                    name='unit'
                                    style={{ textAlign: 'left' }}
                                    // onChange={handleChange}
                                    onChange={onChangeText}
                                >
                                    {['Piece', 'Monthly', 'Semi-Monthly'].map((val, index) => {
                                        return <MenuItem key={index} value={val}>{val}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container justify={'flex-end'} item xs={12} md={12}>
                            <Button onClick={() => { onOpenAddModal() }} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Add</Button>
                        </Grid>
                    </Grid>
                </form>

            </CardContent>
        </Card>

    );
})
export default AddingItem;
