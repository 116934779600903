import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { TextField, Grid } from "@material-ui/core";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ReceivedChangeStatus(props) {
  const {
    handleClickOpen,
    handleClose,
    open,
    onChangeText,
    receivedBy,
    receivedDate,
    onSubmitReceived,
  } = props;
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={onSubmitReceived}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Received
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <Grid container spacing={1}>
                <Grid container alignItems="center" xs={12} md={4}>
                  <Typography style={{ fontWeight: "bold", fontSize: 12 }}>
                    Date Received
                  </Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <TextField
                    onChange={onChangeText}
                    name="received_date"
                    type="date"
                    style={{ width: "100%" }}
                    value={moment(receivedDate).format("YYYY-MM-DD")}
                  />
                </Grid>
                <Grid container alignItems="center" xs={12} md={4}>
                  <Typography style={{ fontWeight: "bold", fontSize: 12 }}>
                    Received by
                  </Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <TextField
                    onChange={onChangeText}
                    name="receivedBy"
                    style={{ width: "100%" }}
                    value={receivedBy}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <div style={{ display: "flex", padding: 10, paddingBottom: 10 }}>
            <button
              type="submit"
              style={{
                backgroundColor: "#3a7b81",
                color: "#fff",
                textDecoration: "none",
                border: "none",
                padding: 8,
                cursor: "pointer",
                marginRight: 5,
              }}
            >
              SUBMIT
            </button>
            <button
              type="button"
              onClick={handleClose}
              style={{
                color: "#3a7b81",
                textDecoration: "none",
                border: "none",
                padding: 8,
                cursor: "pointer",
                marginRight: 5,
              }}
            >
              CANCEL
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
}
