// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
import TableBankTransaction from "./bankTransaction";
import Loading from "../../assets/loading2.gif";
import { useSelector, useDispatch } from "react-redux";
import BankForm from "./bankForm";
import { makeStyles } from "@material-ui/core/styles";
import EventNoteIcon from "@material-ui/icons/EventNote";
// import { DateRange,DateRangePicker } from 'react-date-range';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import moment from "moment";
let width = window.innerWidth;

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}
const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
  },
  container: {
    maxHeight: 440,
  },
  textFieldStyle: {
    width: "100%",
    backgroundColor: "#e9f2f5",
  },
});

function IndexBankDetails() {
  const bankReducer = useSelector((state) => state.BankReducer);
  const [state, setState] = React.useState({
    date_range_modal: false,
    selection: {
      startDate: new Date(moment(new Date()).format("YYYY-MM-01")),
      endDate: new Date(),
      key: "selection",
    },
    refresh: false,
  });
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const classes = useStyles();
  const openDateRange = () => {
    setState((prev) => ({
      ...prev,
      date_range_modal: !state.date_range_modal,
    }));
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="#/bankAccounts">
              Back
            </Link>
            {/* <Link color="inherit" href="/">
                            Home
                        </Link> */}
            <Link color="inherit" href="#/bankAccounts">
              Bank Accounts
            </Link>
            <Typography color="textPrimary">
              {bankReducer.selectedBank.bank_name}
            </Typography>
            <Typography color="textPrimary">Bank Details</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={12} justify="flex-start">
                  <Typography variant="h4" style={{ color: "#2c7f84" }}>
                    Bank Details
                  </Typography>
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-start">
                  <Typography style={{ fontWeight: "bold" }} variant="p">
                    Company
                  </Typography>
                  <TextField
                    readOnly
                    value={bankReducer.selectedBank.company_name}
                    name="bank_name"
                    variant="outlined"
                    size="small"
                    className={classes.textFieldStyle}
                  />
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-start">
                  <Typography style={{ fontWeight: "bold" }} variant="p">
                    Bank{" "}
                  </Typography>
                  <TextField
                    readOnly
                    value={bankReducer.selectedBank.bank_name}
                    name="bank_name"
                    variant="outlined"
                    size="small"
                    className={classes.textFieldStyle}
                  />
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-start">
                  <Typography style={{ fontWeight: "bold" }} variant="p">
                    Account No.
                  </Typography>
                  <TextField
                    readOnly
                    value={bankReducer.selectedBank.bank_account_number}
                    name="bank_name"
                    variant="outlined"
                    size="small"
                    className={classes.textFieldStyle}
                  />
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-start">
                  <Typography style={{ fontWeight: "bold" }} variant="p">
                    Current Balance
                  </Typography>
                  <TextField
                    readOnly
                    value={
                      "Php " +
                      formatNumber(bankReducer.selectedBank.bank_actual_balance)
                    }
                    name="bank_name"
                    variant="outlined"
                    size="small"
                    className={classes.textFieldStyle}
                  />
                </Grid>
                <Grid container item xs={12} md={6} justify="flex-start">
                  <Typography style={{ fontWeight: "bold" }} variant="p">
                    Available Balance
                  </Typography>
                  <TextField
                    readOnly
                    value={
                      "Php " +
                      formatNumber(bankReducer.selectedBank.bank_balance)
                    }
                    name="bank_name"
                    variant="outlined"
                    size="small"
                    className={classes.textFieldStyle}
                  />
                </Grid>
                {/* <Grid container item xs={12} md={6} justify="flex-start">
                  <Typography style={{ fontWeight: "bold" }} variant="p">
                    System Barrowed Amount
                  </Typography>
                  <TextField
                    readOnly
                    value={
                      "Php " +
                      formatNumber(bankReducer.selectedBank.borrowed_amount)
                    }
                    name="bank_name"
                    variant="outlined"
                    size="small"
                    className={classes.textFieldStyle}
                  />
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={12} justify="flex-start">
                  <Typography variant="h6" style={{ color: "#2c7f84" }}>
                    Filter Date
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                          <Card
                            variant="outlined"
                            style={{
                              width: "100%",
                              padding: 6,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              readOnly
                              value={moment(state.selection.startDate).format(
                                "YYYY-MM-DD"
                              )}
                              placeholder="Date start"
                              style={{
                                width: "100%",
                                borderStyle: "none",
                                outline: "none",
                              }}
                              name="searchDriver"
                            />
                            <EventNoteIcon
                              onClick={() => openDateRange()}
                              style={{ cursor: "pointer", color: "#2c6164" }}
                            />
                          </Card>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Card
                            variant="outlined"
                            style={{
                              width: "100%",
                              padding: 6,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              readOnly
                              value={moment(state.selection.endDate).format(
                                "YYYY-MM-DD"
                              )}
                              placeholder="Date end"
                              style={{
                                width: "100%",
                                borderStyle: "none",
                                outline: "none",
                              }}
                              name="searchDriver"
                            />
                            <EventNoteIcon
                              onClick={() => openDateRange()}
                              style={{ cursor: "pointer", color: "#2c6164" }}
                            />
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <Button
                            type="buttom"
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                refresh: !state.refresh,
                              }));
                            }}
                            style={{
                              backgroundColor: "#2c6164",
                              color: "#fff",
                              width: width < 600 ? "100%" : undefined,
                            }}
                            variant="contained"
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid container item xs={12} md={12} justify="flex-start">
                  <Typography variant="h6" style={{ color: "#2c7f84" }}>
                    Transactions
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableBankTransaction
                    selection={state.selection}
                    refreshDetails={state.refresh}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={state.date_range_modal}
        onClose={() => openDateRange()}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Date Range</DialogTitle>
        <DialogContent>
          <Grid item xs={12} md={5}>
            {/* <DateRange
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={true}
                            onChange={item => setState({ ...state, ...item })}
                            ranges={[state.selection]}
                        /> */}
            <DateRangePicker
              onChange={(item) => setState({ ...state, ...item })}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={[state.selection]}
              direction="horizontal"
            />
            ;
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => openDateRange()}
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : undefined,
            }}
            variant="contained"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default React.memo(IndexBankDetails, renderPropsAreEqual);
