// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import { Breadcrumbs, Backdrop, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../api/api"
import CloseIcon from '@material-ui/icons/Close';
import ClientTable from './clientTable'
import AddIcon from '@material-ui/icons/Add';
let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    },
    textFieldStyle: {
        width: '100%',
    }
});

const AddingClient = React.memo((props) => {
    const { getInitialData } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const client_name = useSelector(state => state.ClientReducer.client_name)
    const contact_person = useSelector(state => state.ClientReducer.contact_person)
    const contact_number = useSelector(state => state.ClientReducer.contact_number)
    const refreshClient = useSelector(state => state.ClientReducer.refreshClient)
    const client_branch_name = useSelector(state => state.ClientReducer.client_branch_name)
    const address = useSelector(state => state.ClientReducer.address)
    const tin = useSelector(state => state.ClientReducer.tin)
    const actionType = useSelector(state => state.ClientReducer.actionType)
    const client_id = useSelector(state => state.ClientReducer.client_id)
    const company_id = useSelector(state => state.ClientReducer.company_id)
    const companies = useSelector((state) => state.HomeReducer.companies);

    const onOpenAddModal = () => {
        dispatch({
            type: 'onChangeClientReducer',
            data: {
                addModal: true
            }
        })
    }
    const onChangeText = (e) => {
        let name = e.target.name
        let value = e.target.value
        dispatch({
            type: 'onChangeClientReducer',
            data: {
                [name]: value
            }
        })
    }
    const onSubmit = (e) => {
        e.preventDefault()

        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let data = {
            client_id: client_id,
            client_name: client_name,
            client_contact_person: contact_person,
            contact_no: contact_number,
            client_branch_name: client_branch_name,
            client_address: address,
            user_id: localStorage.getItem('u'),
            tin: tin,
            actionType: actionType,
            company_id: company_id
        }
        if(actionType == 'Add'){
            data = {
                client_name: client_name,
                client_contact_person: contact_person,
                contact_no: contact_number,
                client_branch_name: client_branch_name,
                client_address: address,
                user_id: localStorage.getItem('u'),
                tin: tin,
                actionType: actionType,
                company_id: company_id
            }
        }
        getData('aam/addClient', data).then((res) => {
            if (res.res == 'Exist') {
                alert('Client is already Exist')
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: false,
                    }
                })
            } else {
                getInitialData()
                if(actionType != 'Edit'){
                    dispatch({
                        type: 'onChangeClientReducer',
                        data: {
                            client_name: '',
                            contact_person: '',
                            client_branch_name: '',
                            contact_number: '',
                            address: '',
                            tin: '',
                            company_id: '',
                        }
                    })
                }
            }
        })
    }
    const onChangeCompany = (e) => {
        let company_id = e.target.value
        dispatch({
            type: 'onChangeClientReducer',
            data: {
                company_id: company_id,
            }
        })
    }
    return (
        <Card elevation={3}>
            <CardContent>
                <form onSubmit={onSubmit}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} md={12} justify='flex-start'>
                            <Typography variant="h4" style={{ color: '#2c7f84' }}>{actionType} Client</Typography>
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={12}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Client Name</Typography>
                            <TextField required onChange={onChangeText} value={client_name} name='client_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={8}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Client Branch</Typography>
                            <TextField required onChange={onChangeText} value={client_branch_name} name='client_branch_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={4}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Contact Person</Typography>
                            <TextField required onChange={onChangeText} value={contact_person} name='contact_person' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={4}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Contact Number</Typography>
                            <TextField required onChange={onChangeText} value={contact_number} name='contact_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={8}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Address</Typography>
                            <TextField required onChange={onChangeText} value={address} name='address' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={4}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">TIN</Typography>
                            <TextField required onChange={onChangeText} value={tin} name='tin' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify='flex-start' item xs={12} md={8}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Assigned Company</Typography>
                            <FormControl variant={'outlined'} style={{ width: '100%' }} size='small'>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: 'left' }}
                                    value={company_id ?? company_id}
                                    onChange={onChangeCompany}
                                >
                                    {companies.map((val, index) => {
                                        return <MenuItem key={index} value={val.company_id}>{val.company_name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container justify={'flex-end'} item xs={12} md={12}>
                            <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? 5 : 17, paddingRight: 10, paddingLeft: 10 }} variant="contained" >{actionType == 'Add' ? 'Create' : actionType}</Button>
                        </Grid>
                    </Grid>
                </form>

            </CardContent>
        </Card>

    );
})
export default AddingClient;
