// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,

} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
// import TableBankAccounts from './tableBankAccounts'
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import TableOfAccounts from './tableOfAccounts'
import AddIcon from '@material-ui/icons/Add';
import { getData } from "../../api/api";
// import BankForm from "./bankForm";
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
let width = window.innerWidth;


function IndexChartOfAccounts(props) {
    const [state, setState] = React.useState({

    })
    const chartoFAccountsReducer = useSelector((state) => state.ChartoFAccountsReducer);
    const homeReducer = useSelector((state) => state.HomeReducer);
    const dispatch = useDispatch()
    const onChangeCompany = (e) => {
        let company_id = e.target.value
        console.log(company_id)
        dispatch({
            type:'onChangeChartOFAccountsReducer',
            data:{selected_company_id : company_id}
        })
    }
    
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <TableOfAccounts type={'Balance Sheet'} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <TableOfAccounts type={'Profit and Loss Statement'}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(IndexChartOfAccounts, renderPropsAreEqual);
