import React, { useEffect } from 'react';
import './App.css';
// import MainNav from './components/mainNavigation'
// import PendingForm from './components/pendingForm'
// import NewApplicationForm from './components/newApplicationForm'
// import LoadingScreen from './components/splash/loadingscreen'
import RouterIndex from '../src/components/router'
import {
  HashRouter,
  BrowserRouter,
  Route,
  useParams,
  Router,
  Redirect
} from "react-router-dom";
function App() {

  const getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
  const [state, setState] = React.useState({
    loginData: {
      app_id: 0,
      user_id: localStorage.getItem("u"),

    }
  });
  // const [socket,setSocket] = React.useState(null)
  // const [user,setUser] = React.useState("")


  React.useEffect(async () => {

    //     OneSignal.isPushNotificationsEnabled((event)=>{
    // console.log(event)
    //     })



    //   OneSignal.on()
    //   OneSignal.setSubscription(true)
    // OneSignal.isPushNotificationsEnabled((isEnabled)=>{
    //     console.log(isEnabled)
    //   })
    // console.log(value)
    // OneSignal.on('subscriptionChange', function (isSubscribed) {
    //   console.log("The user's subscription state is now:",isSubscribed);
    //     // OneSignal.push(function() {
    //     //   OneSignal.getUserId(function(userId) {
    //     //     console.log("OneSignal User ID:", userId);
    //     //   });
    //     // });
    //   });
    // OneSignal.subscriptionChange(true)

    // OneSignal.on('subscriptionChange', function(isSubscribed) {

    //     // OneSignal.getUserId(function(userId) {
    //     //     if (isSubscribed) {

    //     //     } else {
    //     //     }
    //     // });

    // });
    // OneSignal.setSubscription((isEnabled)=>{
    //   console.log(isEnabled)

    // })
    // OneSignal.isPushNotificationsEnabled((isEnabled)=>{
    //   console.log(isEnabled)
    // })
    // setTimeout(()=>{
    //   OneSignal.isPushNotificationsEnabled(function(isEnabled) {
    //     console.log(isEnabled)
    //     if (isEnabled) {
    //         // user has subscribed
    //         OneSignal.getUserId( function(userId) {
    //             console.log('player_id of the subscribed user is : ' + userId);
    //             // Make a POST call to your server with the user ID        
    //         });
    //     }
    //   });
    // },5000)

    //   OneSignal.addListenerForNotificationOpened((state) =>{
    //     console.log(state);
    //     // resolve(state);
    // });
    // OneSignal.push(function() {
    //   OneSignal.setExternalUserId(455);
    // });
    // OneSignal.addListenerForNotificationOpened
    // onHandleTag()
    localStorage.setItem('login_state', 0)
    if (typeof localStorage.getItem("u") === "object") {
      if (typeof getUrlVars()["u"] !== "undefined") {
        localStorage.setItem("u", getUrlVars()['u'])
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        localStorage.setItem("cookies", 1);
        window.location.replace("https://accounts.workflow.gzonetechph.com/?goto=" + window.location + "&app=" + state.loginData.app_id);
      }
    }
  }, [])





  return (
    <BrowserRouter>
      {/* <Route exact path="/:user_id" component={LoadingScreen} /> */}
      <Route path="/" component={RouterIndex} />
      {/* <Route path="/pendingform" component={MainNav} />
      <Route path="/NewApplicationForm" component={NewApplicationForm} /> */}
    </BrowserRouter>
  );
}

export default App; 