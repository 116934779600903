// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  MenuItem,
  Menu,
  Tooltip,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getData } from "../../api/api";
import {
  HashRouter as Router,
  BrowserRouter,
  Route,
  useParams,
  Redirect,
  Link as NewLink,
} from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import BillingList from "./billingList";
import AddBilling from "./addBilling";
import PositionedSnackbar from "../../alert";
import CardStatus from "./cardStatus";
import { BillingProvider } from "./billingContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import Received from "../../../assets/received.png";
import AttachmentIcon from "@material-ui/icons/Attachment";
import FilesDIsplay from "../../expenses/requestList/filesDIsplay";

let width = window.innerWidth;
const useStyles = makeStyles({
  root: {
    width: "100%",
    // overflowX: "auto",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});

function BillingDetails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.ClientReducer.alert);
  const alertMessage = useSelector((state) => state.ClientReducer.alertMessage);
  const alertColor = useSelector((state) => state.ClientReducer.alertColor);
  const selectedBillingInvoice = useSelector(
    (state) => state.BillingReducer.selectedBillingInvoice
  );

  const branches = useSelector((state) => state.HomeReducer.branches);
  const selectedService = useSelector(
    (state) => state.BillingReducer.selectedService
  );
  const company_match = branches.filter(
    (val) => val.company_id == selectedBillingInvoice.company_id
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    openFilePDF: false,
    indexPdf: 0,
    pdfDisplay: [],
  });
  let company_logo = "";
  if (company_match.length > 0) {
    let split = String(company_match[0].company_logo).split("/");
    company_logo = split[split.length - 1];
  }
  let bru = "";
  let business_center = false;
  let details = JSON.parse(selectedBillingInvoice.req_details);
  const journal = useSelector((state) => state.BillingReducer.journal);
  for (let index = 0; index < details.length; index++) {
    const element = details[index];
    let new_bru = element.client_branch_name;
    if (element.business_center != "") {
      new_bru = element.business_center;
      business_center = true;
    }
    bru += new_bru;
    if (index < details.length - 1) {
      bru += ", ";
    }
  }

  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };

  const onCancelBilling = () => {
    if (
      window.confirm("Are you sure you want to cancelled this Billing Invoice")
    ) {
      let data = {
        user_id: localStorage.getItem("u"),
        req_id: selectedBillingInvoice.req_id,
        status: "Cancelled",
      };
      getData("aam/onCancelBilling", data).then((res) => {});
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let attachments = JSON.parse(selectedBillingInvoice.req_attachments);

  const onDownload = (val) => {
    let new_attachment = [];

    attachments.forEach((val) => {
      new_attachment.push({
        url: "http://192.168.2.110/api/assets/attachments/" + val,
      });
    });
    setState((prev) => ({
      ...prev,
      openFilePDF: true,
      pdfDisplay: new_attachment,
      indexPdf: 0,
    }));
    // const link = document.createElement("a");
    // link.download = val;
    // link.href = "/http://192.168.2.85/api/assets/" + val;
    // link.click();
  };

  return (
    <div style={{ marginTop: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Table
            style={{ width: "100%", overflowX: "auto", whiteSpace: "nowrap" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#ffff",
                    textAlign: "left",
                  }}
                >
                  Product
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#ffff",
                    textAlign: "left",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#ffff",
                    textAlign: "left",
                  }}
                >
                  Account
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#ffff",
                    textAlign: "left",
                  }}
                >
                  Amount
                </TableCell>
                {attachments.length > 0 ? (
                  <TableCell
                    style={{
                      backgroundColor: "#2c6164",
                      color: "#ffff",
                      textAlign: "center",
                    }}
                  >
                    Attachment
                  </TableCell>
                ) : undefined}
              </TableRow>
            </TableHead>
            <TableBody>
              {JSON.parse(selectedBillingInvoice?.req_details).map(
                (val, index) => {
                  let product =
                    selectedBillingInvoice.invoice_type == "Others"
                      ? val.description
                      : selectedBillingInvoice.invoice_type;
                  return (
                    <TableRow key={index}>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {String(product).toLocaleUpperCase()}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {String(val.description).toLocaleUpperCase()}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {val.accountTitleName}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "left", whiteSpace: "nowrap" }}
                      >
                        {parseFloat(val.total_amount).toFixed(2)}
                      </TableCell>
                      {attachments.length > 0 ? (
                        <TableCell
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip title={attachments[index]}>
                            <AttachmentIcon
                              onClick={() => onDownload(attachments[index])}
                            />
                          </Tooltip>
                        </TableCell>
                      ) : undefined}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <table style={{ width: "100%", marginTop: 40, marginBottom: 20 }}>
            <tr>
              <td style={{ width: "30%" }}></td>
              <td style={{ fontWeight: 600, textAlign: "right" }}>
                Total Amount Before tax :
              </td>
              <td style={{ textAlign: "right" }}>
                {formatNumber(selectedBillingInvoice.req_quantity)}
              </td>
            </tr>
            <tr>
              <td style={{ width: "30%" }}></td>
              <td style={{ fontWeight: 600, textAlign: "right" }}>
                {"Add : " + selectedBillingInvoice.vat_percent + "% Vat"}
              </td>
              <td style={{ textAlign: "right" }}>
                {formatNumber(selectedBillingInvoice.vat_amount)}
              </td>
            </tr>
            <tr>
              <td style={{ width: "30%" }}></td>
              <td style={{ fontWeight: 600, textAlign: "right" }}>
                Gross Amount :
              </td>
              <td style={{ textAlign: "right" }}>
                {formatNumber(selectedBillingInvoice.gross_amount)}
              </td>
            </tr>
            <tr>
              <td style={{ width: "30%" }}></td>
              <td style={{ fontWeight: 600, textAlign: "right" }}>
                {"Less : " +
                  selectedBillingInvoice.with_holding_tax_percent +
                  "% Withholding Tax"}{" "}
                :{" "}
              </td>
              <td style={{ textAlign: "right" }}>
                {formatNumber(selectedBillingInvoice.with_holding_tax_amount)}
              </td>
            </tr>
            <tr>
              <td style={{ width: "30%" }}></td>
              <td style={{ fontWeight: 600, textAlign: "right", height: 30 }}>
                {" "}
              </td>
              <td></td>
            </tr>
            <tr>
              <td style={{ width: "30%" }}></td>
              <td
                style={{
                  width: "38%",
                  fontWeight: 600,
                  textAlign: "right",
                  backgroundColor: "#000",
                  color: "#fff",
                }}
              >
                Net Amount Due :
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  textAlign: "right",
                  backgroundColor: "#000",
                  color: "#fff",
                }}
              >
                Php {formatNumber(selectedBillingInvoice.net_amount_due)}
              </td>
            </tr>
          </table>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFilePDF: false,
          }));
        }}
        open={state.openFilePDF}
      >
        <FilesDIsplay
          state={state}
          onClosePDF={() => {
            setState((prev) => ({
              ...prev,
              openFilePDF: false,
            }));
          }}
          setState={setState}
        />
      </Dialog>
    </div>
  );
}
export default BillingDetails;
