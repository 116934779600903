// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import PositionedSnackbar from "../../alert";
import CardStatus from "./cardStatus"
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function JournalEntry() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    const selectedJournalEntry = useSelector(state => state.BillingReducer.selectedJournalEntry)
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };
    return (
        <div style={{marginTop:2}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', textAlign: 'left', width: '40%' }}>Account</TableCell>
                        <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', textAlign: 'left' }}>Label</TableCell>
                        <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', textAlign: 'center' }}>Debit</TableCell>
                        <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', textAlign: 'center' }}>Credit</TableCell>
                        <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', textAlign: 'center',width:'2%' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedJournalEntry.map((val, index) => {
                        return <TableRow key={index}>
                            <TableCell>{String(val.account_name).toLocaleUpperCase()}</TableCell>
                            <TableCell>{String(val.description).toLocaleUpperCase()}</TableCell>
                            <TableCell style={{textAlign:'right',width:'10%'}}>{val.debit != ''&&formatNumber(val.debit)}</TableCell>
                            <TableCell style={{textAlign:'right',width:'10%'}}>{val.credit != ''&&formatNumber(val.credit)}</TableCell>
                            <TableCell style={{width:'2%' }}></TableCell>
                        </TableRow>
                    })
                    }
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell style={{fontWeight:'bold',textAlign:'right',fontSize:17}}>{formatNumber(selectedJournalEntry.reduce((count, val) => {
                            if (val.type == 'Debit') {
                                count += parseFloat(val.debit)

                            }
                            return count
                        }, 0))}</TableCell>
                        <TableCell style={{fontWeight:'bold',textAlign:'right',fontSize:17}}>{formatNumber(selectedJournalEntry.reduce((count, val) => {
                            if (val.type == 'Credit') {
                                count += parseFloat(val.credit)
                            }
                            return count
                        }, 0))}</TableCell>
                        <TableCell style={{width:'2%' }}></TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </div>
    );
}
export default JournalEntry;
