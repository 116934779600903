import React, { useEffect } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Test from "./test";
import CreateReaquest from "./createRequests/index";
import Requests from "./expenses/requestList/index";
import DescriptionIcon from "@material-ui/icons/Description";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckVoucher from "./expenses/checkVoucher";
import AccountPayableVoucher from "./expenses/accountPayableVoucher/index";
import CreateCheckVoucher from "./expenses/createCheckVoucher";
import BankAccounts from "./bankAccounts/index";
import BankDetails from "./bankAccounts/bankDetails";
import Supplier from "./supplier/supplier_list/index";
import SupplierRequests from "./supplier/supplier_req/index";
import SupplierDetails from "./supplier/supplier_list/supplierDetails";
import ChartOfAccounrs from "./settings/chartOfAccounrs";
import SettingsIcon from "@material-ui/icons/Settings";
import AddChartofAccounts from "./settings/chartOfAccounrs/chartAccountsForm";
import AddBalanceSheetGroup from "./settings/chartOfAccounrs/addBalanceSheetGroup";
import FinancialReport from "./reports/index";
import ProfitandLossStatement from "./reports/Profit and Loss Statement/index";
import CreateProfitAndLossStatement from "./reports/Profit and Loss Statement/createProfitAndLossStatement";
import TransactionIndex from "./transactions/index";
import BillingSettings from "./settings/billingAmount/index";
import AddBillingAmountSettings from "./settings/billingAmount/joAmount/index";
import Client from "./client/index";
import Services from "./client/services/index";
import AddServices from "./client/services/addServices";
import Billing from "./billingAndCollection/billing/index";
import AddBillingForm from "./billingAndCollection/billing/addBillingForm";
import ClientDetails from "./client/indexTab";
import ClientBranch from "./client/branches/addBranch";
import BranchContracts from "./client/contracts/index";
import BillingDetails from "./billingAndCollection/billing/tabs";
import Collection from "./billingAndCollection/collection/index";
import JournalEntry from "./journalEntry/index";
import CreateReport from "./reports/Create Form/createReport";
import BalanceSheet from "./reports/Balance Sheet/index";
import PettyCash from "./pettyCash/indexPettyCash";
import AllRequestForPayment from "./allRequestForPayment";
export const routesDrawer = [
  {
    path: "/",
    exact: true,
    subPage: [],
    component: CreateReaquest,
    page_name: "My Request",
    icon: "add",
  },
  {
    path: "/allRequest/",
    exact: true,
    subPage: [],
    component: AllRequestForPayment,
    page_name: "All Request",
    icon: "format_list_bulleted",
  },
  {
    path: "/pettyCash",
    exact: true,
    subPage: [],
    component: PettyCash,
    page_name: "Petty Cash",
    icon: "add",
  },
  {
    path: "Root",
    subPage: [
      {
        path: "/requestForPayment/",
        exact: true,
        component: Requests,
        page_name: "Request for Payment",
        icon: "AddCircleOutlineIcon",
      },
      {
        path: "/checkVoucher/",
        exact: true,
        component: CheckVoucher,
        page_name: "Check Voucher",
        icon: "AddCircleOutlineIcon",
      },
      {
        path: "/accountPayableVoucher/",
        exact: true,
        component: AccountPayableVoucher,
        page_name: "Account Payable Voucher",
        icon: "AddCircleOutlineIcon",
      },
      ,
      {
        path: "/createCheckVoucher/",
        exact: true,
        component: CreateCheckVoucher,
        page_name: "Create Check Voucher",
        icon: "AddCircleOutlineIcon",
      },
    ],
    exact: true,
    component: Requests,
    page_name: "Disbursement",
    icon: "attach_money",
  },

  {
    path: "/bankAccounts/",
    exact: true,
    subPage: [],
    component: BankAccounts,
    page_name: "Bank Accounts",
    icon: "account_balance",
  },
  {
    path: "/requestlogs/",
    exact: true,
    subPage: [],
    component: TransactionIndex,
    page_name: "Request Logs",
    icon: "format_list_bulleted",
  },
  {
    path: "root",
    exact: true,
    subPage: [
      {
        path: "/supplierRequestLists/",
        exact: true,
        component: SupplierRequests,
        page_name: "New Supplier Requests",
        icon: "people_alt",
      },
      {
        path: "/supplierMasterList/",
        exact: true,
        component: Supplier,
        page_name: "Supplier Master List",
        icon: "people_alt",
      },
    ],
    component: Supplier,
    page_name: "Supplier",
    icon: "people_alt",
  },
  {
    path: "/financialReport/",
    exact: true,
    subPage: [],
    component: FinancialReport,
    page_name: "Reports",
    icon: "bar_chart",
  },
  {
    path: "/clientMasterList/",
    exact: true,
    subPage: [],
    component: Client,
    page_name: "Client Master List",
    icon: "groups",
  },
  {
    path: "root",
    exact: true,
    subPage: [
      {
        path: "/billing/",
        exact: true,
        component: Billing,
        page_name: "Billing",
        icon: "settings",
      },
      {
        path: "/collection/",
        exact: true,
        component: Collection,
        page_name: "Collection",
        icon: "settings",
      },
    ],
    component: Billing,
    page_name: "Billing & Collection",
    icon: "attach_money",
  },

  {
    path: "root",
    exact: true,
    subPage: [
      {
        path: "/chartOfAccounts/",
        exact: true,
        component: ChartOfAccounrs,
        page_name: "Chart of Accounts",
        icon: "settings",
      },
      {
        path: "/billingAmount/",
        exact: true,
        component: BillingSettings,
        page_name: "Billing Amount",
        icon: "settings",
      },
    ],
    component: ChartOfAccounrs,
    page_name: "Settings",
    icon: "settings",
  },
  {
    path: "/journalEntry/",
    exact: true,
    subPage: [],
    component: JournalEntry,
    page_name: "Journal Entry",
    icon: "groups",
  },
];
export const routes = [
  {
    path: "/",
    exact: true,
    subPage: [],
    component: CreateReaquest,
    page_name: "My Request",
    icon: "add",
  },
  {
    path: "/allRequest/",
    exact: true,
    subPage: [],
    component: AllRequestForPayment,
    page_name: "All Request",
    icon: "format_list_bulleted",
  },
  {
    path: "/requestForPayment/",
    exact: true,
    subPage: [],
    component: Requests,
    page_name: "Requests",
    icon: "add",
  },
  {
    path: "/pettyCash",
    exact: true,
    subPage: [],
    component: PettyCash,
    page_name: "Petty Cash",
    icon: "add",
  },
  {
    path: "/accountPayableVoucher/",
    exact: true,
    subPage: [],
    component: AccountPayableVoucher,
    page_name: "Account Payable Voucher",
    icon: "add",
  },
  {
    path: "/checkVoucher/",
    exact: true,
    subPage: [],
    component: CheckVoucher,
    page_name: "Check Voucher",
    icon: "add",
  },
  {
    path: "/createCheckVoucher/",
    exact: true,
    subPage: [],
    component: CreateCheckVoucher,
    page_name: "Create Check Voucher",
    icon: "add",
  },
  {
    path: "/bankAccounts/",
    exact: true,
    subPage: [],
    component: BankAccounts,
    page_name: "Bank Accounts",
    icon: "add",
  },
  {
    path: "/bankDetails/",
    exact: true,
    subPage: [],
    component: BankDetails,
    page_name: "Bank Details",
    icon: "add",
  },
  {
    path: "/supplierMasterList/",
    exact: true,
    subPage: [],
    component: Supplier,
    page_name: "Supplier Master List",
    icon: "add",
  },
  {
    path: "/supplierRequestLists/",
    exact: true,
    subPage: [],
    component: SupplierRequests,
    page_name: "New Supplier Requests",
    icon: "add",
  },
  {
    path: "/supplierDetails/",
    exact: true,
    subPage: [],
    component: SupplierDetails,
    page_name: "Supplier Details",
    icon: "add",
  },
  {
    path: "/chartOfAccounts/",
    exact: true,
    subPage: [],
    component: ChartOfAccounrs,
    page_name: "Chart of Accounts",
    icon: "settings",
  },
  {
    path: "/addChartOfAccounts/",
    exact: true,
    subPage: [],
    component: AddChartofAccounts,
    page_name: "Add Chart Of Accounts",
    icon: "settings",
  },
  {
    path: "/financialReport/",
    exact: true,
    subPage: [],
    component: FinancialReport,
    page_name: "Financial Reports",
    icon: "reports",
  },
  {
    path: "/addBalanceSheetGroup/",
    exact: true,
    subPage: [],
    component: AddBalanceSheetGroup,
    page_name: "Add Balance Sheet Group",
    icon: "settings",
  },
  {
    path: "/profitAndLossStatement/",
    exact: true,
    subPage: [],
    component: ProfitandLossStatement,
    page_name: "Profit And Loss Statement",
    icon: "settings",
  },
  {
    path: "/createReport/",
    exact: true,
    subPage: [],
    component: CreateReport,
    page_name: "Collection",
    icon: "settings",
  },
  {
    path: "/requestlogs/",
    exact: true,
    subPage: [],
    component: TransactionIndex,
    page_name: "Request Logs",
    icon: "format_list_bulleted",
  },
  {
    path: "/billingAmount/",
    exact: true,
    subPage: [],
    component: BillingSettings,
    page_name: "Billing Amount",
    icon: "format_list_bulleted",
  },
  {
    path: "/addBillingAmountSettings/",
    exact: true,
    subPage: [],
    component: AddBillingAmountSettings,
    page_name: "Billing Amount",
    icon: "format_list_bulleted",
  },
  {
    path: "/clientMasterList/",
    exact: true,
    subPage: [],
    component: Client,
    page_name: "Client Master List",
    icon: "format_list_bulleted",
  },
  {
    path: "/services/",
    exact: true,
    subPage: [],
    component: Services,
    page_name: "Services",
    icon: "format_list_bulleted",
  },
  {
    path: "/addServices/",
    exact: true,
    subPage: [],
    component: AddServices,
    page_name: "Add Services",
    icon: "format_list_bulleted",
  },
  {
    path: "/billing/",
    exact: true,
    subPage: [],
    component: Billing,
    page_name: "Billing",
    icon: "format_list_bulleted",
  },
  {
    path: "/billingForm/",
    exact: true,
    subPage: [],
    component: AddBillingForm,
    page_name: "Billing Form",
    icon: "format_list_bulleted",
  },
  {
    path: "/clientDetails/",
    exact: true,
    subPage: [],
    component: ClientDetails,
    page_name: "Client Details",
    icon: "format_list_bulleted",
  },
  {
    path: "/addClientBranch/",
    exact: true,
    subPage: [],
    component: ClientBranch,
    page_name: "Add Client Branch",
    icon: "format_list_bulleted",
  },
  {
    path: "/branchContracts/",
    exact: true,
    subPage: [],
    component: BranchContracts,
    page_name: "Branch Contracts",
    icon: "format_list_bulleted",
  },
  {
    path: "/billingDetails/",
    exact: true,
    subPage: [],
    component: BillingDetails,
    page_name: "Billing Details",
    icon: "format_list_bulleted",
  },
  {
    path: "/collection/",
    exact: true,
    subPage: [],
    component: Collection,
    page_name: "Collection",
    icon: "format_list_bulleted",
  },
  {
    path: "/balanceSheet/",
    exact: true,
    subPage: [],
    component: BalanceSheet,
    page_name: "Collection",
    icon: "format_list_bulleted",
  },
];
