// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  TextareaAutosize,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { DropzoneDialog } from "material-ui-dropzone";
// import { getData } from "../../../src/components/api/api"
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiAlert from "@material-ui/lab/Alert";
import PositionedSnackbar from "../../alert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { getData } from "../../api/api";
import CloseIcon from "@material-ui/icons/Close";
import ChatIcon from "@material-ui/icons/Chat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SendIcon from "@material-ui/icons/Send";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import OnlineForm from "./onlineForm";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

const RowInputs = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      {state.display == "Details" ? (
        <StyledTableRow>
          <StyledTableCell
            readOnly
            style={{ width: "20%", whiteSpace: "nowrap" }}
          >
            {state.detailsRowAPV[index].particular}
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
          >
            {state.detailsRowAPV[index].debit}
          </StyledTableCell>
          <StyledTableCell style={{ width: "20%", textAlign: "center" }}>
            {state.detailsRowAPV[index].credit}
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        <StyledTableRow>
          <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
            <TextareaAutosize
              style={{ width: "100%", borderColor: "#c5bfbf" }}
              name="particular"
              minRows={2.3}
              value={state.detailsRowAPV[index].particular}
              aria-label="empty textarea"
              placeholder="Empty"
              onChange={onChangeValue}
            />
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
          >
            {" "}
            <TextField
              style={{ width: "100%", background: "#fff" }}
              variant="outlined"
              name="debit"
              onChange={onChangeValue}
              value={state.detailsRowAPV[index].debit}
              size="small"
              type="number"
            ></TextField>
          </StyledTableCell>
          <StyledTableCell
            style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
          >
            {" "}
            <TextField
              style={{ width: "100%", background: "#fff" }}
              variant="outlined"
              name="credit"
              onChange={onChangeValue}
              value={state.detailsRowAPV[index].credit}
              size="small"
              type="number"
            ></TextField>
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
};

const RowInputsCV = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell
          readOnly
          style={{ width: "20%", whiteSpace: "nowrap" }}
        >
          {state.detailsRow[index].description}
        </StyledTableCell>
        <StyledTableCell style={{ whiteSpace: "pre-line", textAlign: "right" }}>
          {formatNumber(state.detailsRow[index].total)}
        </StyledTableCell>
        {/* <StyledTableCell style={{ width: '20%', textAlign: 'center' }}>{state.detailsRowAPV[index].credit}</StyledTableCell> */}
      </StyledTableRow>
    </>
  );
};
function Dropzone({ val, index, setState, handleChangeFileV2 }) {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid item xs={12} md={12}>
      <Tooltip title="Attach Files">
        <IconButton
          style={{
            backgroundColor: "#2c6164",
            color: "#fff",
            marginRight: 10,
            height: 40,
            width: 40,
          }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <div style={{ position: "relative" }}>
            <AttachFileIcon />
            <div
              style={{
                height: 20,
                width: 20,
                borderRadius: 10,
                background: "#eb4d4b",
                position: "absolute",
                top: -10,
                right: -15,
                display: val.file.length == 0 ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="p" style={{ color: "#fff", fontSize: 13 }}>
                {val.file.length}
              </Typography>
            </div>
          </div>
        </IconButton>
      </Tooltip>
      <DropzoneDialog
        acceptedFiles={["image/*"]}
        cancelButtonText={"cancel"}
        filesLimit={1}
        submitButtonText={"done"}
        maxFileSize={5000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={() => setOpen(false)}
        fileObjects={val.file}
        onChange={(e) => handleChangeFileV2(e, index)}
        clearOnUnmount={false}
        onDelete={(e) => {
          for (let index2 = 0; index2 < val.file.length; index2++) {
            const element = val.file[index2];
            if (element.name === e.name) {
              val.file.splice(index2, 1);
            }
            setState((prev) => ({ ...prev }));
          }
        }}
        // onSave={(files) => {
        //     console.log('Files:', files);
        //     setOpen(false);
        // }}
        showPreviews={false}
        // showFileNames={true}
        showFileNamesInPreview={false}
        showPreviewsInDropzone={true}
      />
    </Grid>
  );
}
function RequestDetails({
  handleCloseModal,
  refreshFunc,
  action,
  selectedRequest,
  onChangeDisplay,
  onRefresh,
  bankList,
  cheque_details,
}) {
  const [state, setState] = React.useState({
    detailsRow: [
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        total: "",
      },
    ],
    detailsRowAPV: [
      {
        particular: "",
        debit: "",
        credit: "",
      },
    ],
    payee: "",
    company_name: "",
    open_modal_attachment: false,
    file: [],
    payeeList: [],
    company_id: "",
    branchList: [],
    branch_name: "",
    branch_id: "",
    department: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    attachments: [],
    balanceStatus: "",
    display: "",
    bank_account: "",
    check_number: "",
    company_logo: "",
    readOnly: false,
    edit: false,
    actionButton: "",
    multiple_data: [
      {
        bank_accounts: "",
        check_number: "",
        bank_name: "",
        file: [],
        amount: "",
      },
    ],
    addChequeModel: false,
    cheque_number: "",
    cheque_amount: "",
    bank_account_id: "",
    onSubmitChequetails: [],
    actionCheque: "Add Cheque",
    editIndex: "",
    selected_cheque: [],
    selectedCancelCheque: [],
    cancel_reason: "",
    onlineFormModal: false,
    postDatedCheque: "",
    post_date: "",
  });
  const [open, setOpen] = React.useState(false);
  const [filterOption, setFilterOption] = React.useState(null);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const handleClickOption = (event) => {
    setFilterOption(event.currentTarget);
  };
  const handleCloseOption = () => {
    setFilterOption(null);
  };
  const homeReducer = useSelector((state) => state.HomeReducer);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleNotification = (firstApprover) => {
    // homeReducer.socket.emit("sendNotification", {
    //     senderName: homeReducer.loginData.user_id,
    //     receiverName: firstApprover,
    //     type: ''
    // })
    let pendingCreateCV = parseInt(homeReducer.pendingCreateCV) - 1;
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        onRefresh: !homeReducer.onRefresh,
        pendingCreateCV: pendingCreateCV,
      },
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const addComment = () => {
    let request_comments = {
      app_id: "7",
      user_id: homeReducer.loginData.user_id,
      comment_type: "Text",
      comment: state.comment_input,
      req_id: selectedRequest.req_id,
      req_reference_no: selectedRequest.req_reference_no,
      req_user_id: selectedRequest.user_id,
    };
    getData("HumanResource/LogRequestCommentRequestor", request_comments).then(
      () => {
        let data = {
          comment: state.comment_input,
          comment_date_added: "2022-03-07 15:46:53",
          comment_type: "Text",
          req_comment_id: "",
          req_id: "",
          user_fname: homeReducer.loginData.user_fname,
          user_lname: homeReducer.loginData.user_fname,
        };
        selectedRequest.comments.push(data);

        setState((prev) => ({
          ...prev,
        }));
        refreshFunc();
        handleClose();
      }
    );
  };
  const onSubmit = (e) => {
    e.preventDefault();
    onAddCheck("Initial");
  };
  const onAddCheck = (action) => {
    let totalAmount = state.detailsRow.reduce((count, val) => {
      let total = val.total;
      if (val.total === "") {
        total = 0;
      }
      return (count += parseFloat(total));
    }, 0);
    let onSubmitChequetails = [];
    let check_number = "";
    if (state.display === "Details") {
      let match_bank = filter_bankList.filter(
        (val) => val.bank_account_id === state.bank_account_id
      );
      let data_add = {
        bank_account_id: state.bank_account_id,
        bank_name: match_bank[0].bank_name,
        cheque_number: state.cheque_number,
        cheque_amount: state.cheque_amount,
        file: state.file,
        post_dated_cheque: state.postDatedCheque,
        post_date: state.post_date,
      };
      check_number = selectedRequest.req_check_number;
      check_number += ",";
      check_number += state.cheque_number;
      onSubmitChequetails.push(data_add);
    } else {
      onSubmitChequetails = state.onSubmitChequetails;
      check_number = onSubmitChequetails.reduce((checks, val, index) => {
        checks += val.cheque_number;
        if (index != onSubmitChequetails.length - 1) {
          checks += ",";
        }
        return checks;
      }, "");
    }

    let totalAmountCheque = onSubmitChequetails.reduce(
      (count, val) => (count += parseFloat(val.cheque_amount)),
      0
    );
    let new_file_array = onSubmitChequetails.map((val) => val.file[0]);

    let data = {
      req_id: selectedRequest.req_id,
      new_multiple_data: onSubmitChequetails,
      data: selectedRequest,
      user_id: localStorage.getItem("u"),
      totalAmount: totalAmount,
      type_name: "CV",
      actionButton: state.actionButton,
      attachments: state.attachments,
      check_number: check_number,
      action: action,
    };
    if (
      parseFloat(totalAmountCheque).toFixed(2) !== totalAmount.toFixed(2) &&
      state.display !== "Details"
    ) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Invalid Amount, Please check your Request for Payment",
        alertColor: "#f39c12",
      }));
    } else {
      if (
        typeof new_file_array[0] === "undefined" &&
        data.check_number != "Online Transaction"
      ) {
        setState((prev) => ({
          ...prev,
          alert: true,
          alertMessage: "Warning, Please attach file",
          alertColor: "#f39c12",
        }));
      } else {
        if (
          window.confirm("Are you sure you want to create this Check Voucher ?")
        ) {
          console.log(data);
          console.log(new_file_array);

          createCheckVoucher(data, new_file_array);
        }
      }
    }
  };
  const createCheckVoucher = (data, new_file_array) => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    const formData = new FormData();
    for (let i = 0; i < new_file_array.length; i++) {
      formData.append("file" + i, new_file_array[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    let url = "createCheckVoucherTest2";
    if (data.check_number == "Online Transaction") {
      url = "createCheckVoucherOnlineTransaction";
    }
    getData("aam/checkBalance", data).then((response) => {
      if (response == "Insufficient") {
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
        if (
          window.confirm(
            "Warning, Insufficient Balance! ,Do you want to proceed?"
          )
        ) {
          axios
            .post(
              "https://api.workflow.gzonetechph.com/aam/" + url,
              formData,
              config
            )
            .then((res) => {
              if (res.data.result === "Success") {
                onRefresh();
                setState((prev) => ({
                  ...prev,
                  balanceStatus: "",
                  display: "Details",
                  alert: true,
                  alertMessage: "Success, Your request has been created.",
                  alertColor: "#2ecc71",
                  attachments: res.data.attachments,
                }));
                onChangeDisplay("Details");
                handleCloseModal();

                let firstApprover = "";
                if (res.data.hierarchy !== "") {
                  firstApprover = JSON.parse(res.data.hierarchy)[0].approver[0]
                    .id;
                }
                // handleNotification(firstApprover)
              } else if (res.data.res === "Insufficient Balance") {
                setState((prev) => ({
                  ...prev,
                  alert: true,
                  alertMessage:
                    "Warning, Insufficient Balance, Remaining balance : Php" +
                    res.data.balance,
                  alertColor: "#f39c12",
                }));
                dispatch({
                  type: "onChangeHomeReducer",
                  data: {
                    loading: false,
                  },
                });
              } else if (res.data.result === "Not Allowed") {
                setState((prev) => ({
                  ...prev,
                  alert: true,
                  alertMessage:
                    "Warning, Your not allowed to edit the Check Voucher, someone has taken an action with your request",
                  alertColor: "#f39c12",
                }));
                dispatch({
                  type: "onChangeHomeReducer",
                  data: {
                    loading: false,
                  },
                });
              }
            });
        }
      } else {
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: true,
          },
        });
        axios
          .post(
            "https://api.workflow.gzonetechph.com/aam/" + url,
            formData,
            config
          )
          .then((res) => {
            if (res.data.result === "Success") {
              onRefresh();
              setState((prev) => ({
                ...prev,
                balanceStatus: "",
                display: "Details",
                alert: true,
                alertMessage: "Success, Your request has been created.",
                alertColor: "#2ecc71",
                attachments: res.data.attachments,
              }));
              onChangeDisplay("Details");
              handleCloseModal();

              let firstApprover = "";
              if (res.data.hierarchy !== "") {
                firstApprover = JSON.parse(res.data.hierarchy)[0].approver[0]
                  .id;
              }
              // handleNotification(firstApprover)
            } else if (res.data.res === "Insufficient Balance") {
              setState((prev) => ({
                ...prev,
                alert: true,
                alertMessage:
                  "Warning, Insufficient Balance, Remaining balance : Php" +
                  res.data.balance,
                alertColor: "#f39c12",
              }));
              dispatch({
                type: "onChangeHomeReducer",
                data: {
                  loading: false,
                },
              });
            } else if (res.data.result === "Not Allowed") {
              setState((prev) => ({
                ...prev,
                alert: true,
                alertMessage:
                  "Warning, Your not allowed to edit the Check Voucher, someone has taken an action with your request",
                alertColor: "#f39c12",
              }));
              dispatch({
                type: "onChangeHomeReducer",
                data: {
                  loading: false,
                },
              });
            }
          });
      }
    });

    // getData('aam/createCheckVoucher', data).then((res) => {
    //     if (res.result === 'Success') {
    //         onRefresh()
    //         setState(prev => ({
    //             ...prev,
    //             balanceStatus: '',
    //             display: 'Details',
    //             alert: true,
    //             alertMessage: "Success, Your request has been created.",
    //             alertColor: '#2ecc71',
    //         }))
    //         onChangeDisplay('Details')
    //         let firstApprover = '';
    //         if (res.data.hierarchy !== "") {
    //             firstApprover = JSON.parse(res.data.hierarchy)[0].approver[0].id
    //         }
    //         handleNotification(firstApprover)

    //     } else if (res.res === "Insufficient Balance") {
    //         setState(prev => ({
    //             ...prev,
    //             alert: true,
    //             alertMessage: "Warning, Insufficient Balance, Remaining balance : Php" + res.balance,
    //             alertColor: '#f39c12',
    //         }))
    //     }
    //     dispatch({
    //         type: 'onChangeHomeReducer',
    //         data: {
    //             loading: false,
    //         }
    //     })

    // })
  };
  const uploadForm = (data) => {
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/createRequest",
        formData,
        config
      )
      .then((response) => {
        // if(response.data.response){
        setState((prev) => ({
          ...prev,
          detailsRow: [
            {
              date: moment(new Date()).format("YYYY-MM-DD"),
              description: "",
              total: "",
            },
          ],

          payee: "",
          company_name: "",
          open_modal_attachment: false,
          alert: true,
          alertMessage: "Success, Your request has been created.",
          alertColor: "#2ecc71",
          file: [],
          company_id: "",
          branchList: [],
          branch_name: "",
          branch_id: "",
          department: "",
        }));
        // handleCloseModal(    )
        // }
        refreshFunc();
        dispatch({
          type: "Loading",
          data: false,
        });
      });
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeFile = (files) => {
    console.log(files);
    if (files.length > 0) {
      setState({
        ...state,
        file: files,
      });
    }
  };
  const handleClose_modal = () => {
    setState((prev) => ({ ...prev, open_modal_attachment: false }));
  };

  React.useEffect(() => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: false,
      },
    });
    let branches = [];
    homeReducer.branches.forEach((val) => {
      branches.push(val.branch_id);
    });
    let company_logo_match = homeReducer.branches.filter(
      (val) => val.branch_id === selectedRequest.req_branch_id
    );
    let company_logo = "";
    if (company_logo_match.length > 0) {
      let split = String(company_logo_match[0].company_logo).split("/");
      company_logo = split[split.length - 1];
    }
    // if (action === "Details") {
    let req_details = JSON.parse(selectedRequest.req_details);
    let company_id = selectedRequest.req_company_id;
    let branch_id = selectedRequest.req_branch_id;
    let company_name = selectedRequest.company_name;
    let branch_name = selectedRequest.branch_name;
    let department = selectedRequest.department;
    let payee = selectedRequest.req_payee;
    let bank_account_id = selectedRequest.req_bank_id;

    let attachments = [];
    if (
      selectedRequest.req_cv_attachment !== null &&
      selectedRequest.req_cv_attachment != ""
    ) {
      attachments = JSON.parse(selectedRequest.req_cv_attachment);
    }
    let req_details_particular = [
      {
        particular: "",
        debit: "",
        credit: "",
      },
    ];
    let display = "Create";
    let check_number = selectedRequest.req_check_number;
    let bank_number = selectedRequest.req_bank_number;

    if (state.multiple_data.length > 0) {
      state.multiple_data[0].amount = selectedRequest.req_quantity;
    }
    let req_bank_details_all = [
      {
        bank_accounts: "",
        check_number: "",
        bank_name: "",
        file: [],
        amount: selectedRequest.req_quantity,
      },
    ];
    if (
      (selectedRequest.req_check_number != null &&
        selectedRequest.req_check_number != "") ||
      selectedRequest.req_bank_details_all != null
    ) {
      // req_details_particular = JSON.parse(selectedRequest.req_details_particular)
      display = "Details";
      req_bank_details_all = [
        {
          bank_accounts: selectedRequest.req_bank_id,
          check_number: selectedRequest.req_check_number,
          bank_name: selectedRequest.req_bank_number,
          file: [],
          amount: selectedRequest.req_quantity,
        },
      ];
    }

    if (selectedRequest.req_bank_details_all !== null) {
      req_bank_details_all = JSON.parse(selectedRequest.req_bank_details_all);
    }
    // let branches = homeReducer.branches.filter((val) => (val.company_name == company_name))
    onChangeDisplay(display);

    let new_cheque_details = [];
    cheque_details.forEach((val, index) => {
      let cheque_images = JSON.parse(val.attachment);
      let post_dated_cheque = "No";
      let post_date = val.post_date;

      if (val.post_dated_cheque == 1) {
        post_dated_cheque = "Yes";
      }
      let data_cheque = {
        bank_account_id: val.bank_id,
        bank_name: val.bank_name,
        cheque_number: val.check_number,
        cheque_amount: val.amount,
        finance_id: val.finance_id,
        finance_status: val.finance_status,
        attachment: val.attachment,
        post_dated_cheque: post_dated_cheque,
        post_date: post_date,
        file: [
          "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
            cheque_images[0],
        ],
      };
      new_cheque_details.push(data_cheque);
    });
    setState((prev) => ({
      ...prev,
      detailsRow: req_details,
      company_id: company_id,
      branch_id: branch_id,
      company_name: company_name,
      branch_name: branch_name,
      department: department,
      branchList: branches,
      payee: payee,
      attachments: attachments,
      detailsRowAPV: req_details_particular,
      display: display,
      check_number: check_number,
      bank_account: bank_number,
      company_logo: company_logo,
      bank_account_id: bank_account_id,
      multiple_data: req_bank_details_all,
      onSubmitChequetails: new_cheque_details,
      cheque_amount: parseFloat(selectedRequest.req_quantity),
    }));
    // }

    // getData('aam/getPayee', branches).then((res) => {
    //     setState(prev => ({ ...prev, payeeList: res,detailsRow:selectedRequest }))
    // })
  }, []);

  const onChangeCompany = (e) => {
    let company_name = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_name == company_name
    );
    let company_id = "";
    if (branches.length > 0) {
      company_id = branches[0].company_id;
    }
    setState({
      ...state,
      company_name: company_name,
      rule: [],
      emp_type: "",
      payroll_rule_group_id: "",
      branchList: branches,
      company_id: company_id,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.branch_id == branch_id
    );
    if (branches.length > 0) {
      setState((prev) => ({
        ...prev,
        branch_id: branch_id,
        branch_name: branches[0].branch_name,
      }));
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };

  const onChangeBankAccount = (e) => {
    let value = e.target.value;
    let matchAccount = bankList.filter((val) => val.bank_account_id === value);

    if (matchAccount.length > 0) {
      setState((prev) => ({
        ...prev,
        bank_account_id: value,
        bank_account: matchAccount[0].bank_name,
      }));
    } else {
    }
  };

  const onClickProceed = () => {
    let totalAmount = state.detailsRow.reduce((count, val) => {
      let total = val.total;
      if (val.total === "") {
        total = 0;
      }
      return (count += parseFloat(total));
    }, 0);

    let data = {
      req_id: selectedRequest.req_id,
      bank_account: state.onSubmitChequetails[0].bank_name,
      bank_account_id: state.onSubmitChequetails[0].bank_account_id,
      check_number: state.check_number,
      data: selectedRequest,
      user_id: localStorage.getItem("u"),
      totalAmount: totalAmount,
      type_name: "CV",
      actionButton: state.actionButton,
    };

    if (
      window.confirm("Are you sure you want to proceed this check voucher ?")
    ) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/onProceedCV", data).then((res) => {
        if (res.res === true) {
          onRefresh();
          setState((prev) => ({
            ...prev,
            balanceStatus: "",
            display: "Details",
            alert: true,
            alertMessage: "Success, Your request has been proceed.",
            alertColor: "#2ecc71",
          }));
          handleCloseModal();
        } else if (res.res === "Insufficient Balance") {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage:
              "Warning, Insufficient Balance, Remaining balance : Php" +
              res.balance,
            alertColor: "#f39c12",
          }));
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  };
  let filter_bankList = bankList.filter(
    (val) => val.company_id === selectedRequest.req_company_id
  );
  // console.log(selectedRequest)
  const voidRequests = (type) => {
    if (window.confirm("Are you sure you want to proceed this action ?")) {
      let data = {
        type: type,
        data: selectedRequest,
        cheques: state.onSubmitChequetails,
        user_id: homeReducer.loginData.user_id,
      };
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/voidRequests", data).then((res) => {
        onRefresh();
        setState((prev) => ({
          ...prev,
          display: "Details",
          alert: true,
          alertMessage: "Success, All process has been cancelled.",
          alertColor: "#2ecc71",
        }));
        handleCloseModal();
      });
    }
  };

  const addCheque = () => {
    let amount = parseFloat(
      parseFloat(selectedRequest.req_quantity) /
        (state.multiple_data.length + 1)
    ).toFixed(2);
    let data = {
      bank_accounts: "",
      check_number: "",
      bank_name: "",
      file: [],
      amount: amount,
    };
    state.multiple_data.forEach((val, index) => {
      val.amount = amount;
    });
    state.multiple_data.push(data);
    setState((prev) => ({
      ...prev,
    }));
  };
  const removeCheque = (index) => {
    state.multiple_data.splice(index, 1);
    setState((prev) => ({
      ...prev,
    }));
  };

  const onChangeMulitipleBankAccount = (e, selectedIndex) => {
    let name = e.target.name;
    let value = e.target.value;
    let matchAccount = bankList.filter((val) => val.bank_account_id === value);
    if (name === "bank_accounts") {
      setState((prev) => ({
        ...prev,
        multiple_data: state.multiple_data.map((val, index) =>
          index === selectedIndex
            ? { ...val, [name]: value, bank_name: matchAccount[0].bank_name }
            : val
        ),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        multiple_data: state.multiple_data.map((val, index) =>
          index === selectedIndex ? { ...val, [name]: value } : val
        ),
      }));
    }
  };

  const handleChangeFileV2 = (files, selectedIndex) => {
    console.log(selectedIndex);
    setState((prev) => ({
      ...prev,
      multiple_data: state.multiple_data.map((val, index) =>
        index === selectedIndex ? { ...val, file: files } : val
      ),
    }));
  };
  const onSubmitAddCheque = (e) => {
    e.preventDefault();
    let match_bank = filter_bankList.filter(
      (val) => val.bank_account_id === state.bank_account_id
    );
    let cheque_number = state.cheque_number;
    if (state.actionCheque == "Add Online Transaction") {
      cheque_number = "Online Transaction";
    }
    let data = {
      bank_account_id: state.bank_account_id,
      bank_name: match_bank[0].bank_name,
      cheque_number: cheque_number,
      cheque_amount: state.cheque_amount,
      file: state.file,
      post_date: state.post_date,
      post_dated_cheque: state.postDatedCheque,
    };

    if (state.file.length === 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please attach files !",
        alertColor: "#f39c12",
      }));
    } else {
      if (
        state.actionCheque === "Edit Cheque" &&
        state.actionCheque === "Edit Online Transaction"
      ) {
        setState((prev) => ({
          ...prev,
          addChequeModel: false,
          onSubmitChequetails: state.onSubmitChequetails.map((val, index) =>
            index === state.editIndex
              ? {
                  ...val,
                  bank_account_id: state.bank_account_id,
                  bank_name: match_bank[0].bank_name,
                  cheque_number: state.cheque_number,
                  cheque_amount: state.cheque_amount,
                  file: state.file,
                }
              : val
          ),
        }));
      } else {
        setState((prev) => ({
          ...prev,
          onSubmitChequetails: state.onSubmitChequetails.concat(data),
          bank_account_id: "",
          cheque_number: "",
          cheque_amount: "",
          file: [],
          addChequeModel: false,
        }));
      }
    }
  };

  const onEditCheque = (val, index) => {
    let actionCheque = "Edit Cheque";
    if (val.cheque_number == "Online Transaction") {
      actionCheque = "Edit Online Transaction";
    }
    setState((prev) => ({
      ...prev,
      bank_account_id: val.bank_account_id,
      cheque_number: val.cheque_number,
      cheque_amount: val.cheque_amount,
      file: val.file,
      addChequeModel: true,
      actionCheque: actionCheque,
      editIndex: index,
      selected_cheque: val,
      post_date: val.post_date,
      postDatedCheque: val.post_dated_cheque,
    }));
  };

  const onUpdateCheck = () => {
    let match_bank = filter_bankList.filter(
      (val) => val.bank_account_id === state.bank_account_id
    );
    let updateBank = false;
    if (
      parseFloat(state.cheque_amount).toFixed(2) !==
      parseFloat(state.selected_cheque.cheque_amount).toFixed(2)
    ) {
      updateBank = true;
    }
    let check_number = state.onSubmitChequetails.reduce(
      (checks, val, index) => {
        if (index === state.editIndex) {
          val.cheque_number = state.cheque_number;
        }
        checks += val.cheque_number;
        if (index != state.onSubmitChequetails.length - 1) {
          checks += ",";
        }
        return checks;
      },
      ""
    );

    let data = {
      bank_account_id: state.bank_account_id,
      cheque_amount: state.cheque_amount,
      cheque_number: state.cheque_number,
      bank_name: match_bank[0].bank_name,
      finance_id: state.selected_cheque.finance_id,
      updateBank: updateBank,
      req_id: selectedRequest.req_id,
      company_id: selectedRequest.company_id,
      req_reference_no: selectedRequest.req_reference_no,
      user_id: homeReducer.loginData.user_id,
      prev_amount: state.selected_cheque.cheque_amount,
      prev_cheque_number: state.selected_cheque.cheque_number,
      prev_bank_name: state.selected_cheque.bank_name,
      prev_bank_account_id: state.selected_cheque.bank_account_id,
      check_number: check_number,
      attachment: state.selected_cheque.attachment,
      post_dated_cheque: state.postDatedCheque,
      post_date: state.post_date,
    };

    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));

    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/updateCheck",
        formData,
        config
      )
      .then((response) => {
        if (response.data.res) {
          onRefresh();
          setState((prev) => ({
            ...prev,
            onSubmitChequetails: state.onSubmitChequetails.map((val, index) =>
              index === state.editIndex
                ? {
                    ...val,
                    bank_name: data.bank_name,
                    cheque_number: data.cheque_number,
                    cheque_amount: data.cheque_amount,
                    finance_id:
                      response.data.last_id === ""
                        ? val.finance_id
                        : response.data.last_id,
                    file:
                      response.data.image.length === 0
                        ? val.file
                        : [
                            "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                              response.data.image[0],
                          ],
                  }
                : val
            ),
            addChequeModel: false,
            alert: true,
            alertMessage: "Success, Your cheque has been updated.",
            alertColor: "#2ecc71",
          }));
        } else if (response.data.res === "Insufficient Balance") {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage:
              "Warning, Insufficient Balance, Remaining balance : Php" +
              response.data.balance,
            alertColor: "#f39c12",
          }));
        } else if (!response.data.res) {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Warning, There is no changes in your cheque",
            alertColor: "#f39c12",
          }));
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
  };

  const updateCheckInitialData = () => {
    if (state.onSubmitChequetails.length > 0) {
      let bank_name = filter_bankList.filter(
        (val) => val.bank_account_id == state.bank_account_id
      );
      setState((prev) => ({
        ...prev,
        onSubmitChequetails: state.onSubmitChequetails.map((val, index) =>
          index == state.editIndex
            ? {
                ...val,
                bank_account_id: state.bank_account_id,
                bank_name: bank_name[0].bank_name,
                cheque_amount: state.cheque_amount,
                cheque_number: state.cheque_number,
              }
            : val
        ),
        addChequeModel: false,
      }));
    }
  };

  const onCancelCheque = (e) => {
    e.preventDefault();
    // Cancel Request
    let details = state.selectedCancelCheque;
    details["req_id"] = selectedRequest.req_id;
    details["cancel_reason"] = state.cancel_reason;
    details["data"] = selectedRequest;
    details["user_id"] = homeReducer.loginData.user_id;
    details["fullname"] =
      homeReducer.loginData.user_lname + " " + homeReducer.loginData.user_fname;
    let match_apv_cv_access = homeReducer.branches.filter(
      (val) => parseInt(selectedRequest.branch_id) === parseInt(val.branch_id)
    );
    let apv_cv_access = "";

    if (match_apv_cv_access.length > 0) {
      apv_cv_access = match_apv_cv_access[0].account_voucher_access;
    }
    details["apv_cv_access"] = apv_cv_access;

    if (window.confirm("Are you sure you want to cancel this Cheque?")) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/voidCheques", details).then((res) => {
        if (res.res) {
          selectedRequest.status = "Disabled";
          onRefresh();
          handleCloseModal();
          setState((prev) => ({
            ...prev,
            onSubmitChequetails: state.onSubmitChequetails.map((val, index) =>
              index === state.editIndex
                ? {
                    ...val,
                    finance_status: "Cancelled",
                  }
                : val
            ),
            voidReasonModal: false,
            alert: true,
            alertMessage: "Success, Your cheque has been cancelled.",
            alertColor: "#2ecc71",
            cancel_reason: "",
            selectedCancelCheque: [],
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Warning, Something went wrong",
            alertColor: "#f39c12",
          }));
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  };
  const onChangeOption = (item) => {
    if (item == "Cheque") {
      setState((prev) => ({
        ...prev,
        addChequeModel: true,
        actionCheque: "Add Cheque",
        cheque_number: "",
        bank_account_id: "",
        file: [],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        addChequeModel: true,
        actionCheque: "Add Online Transaction",
        cheque_number: "",
        bank_account_id: "",
        file: [],
      }));
    }
    handleCloseOption();
  };
  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={6}>
          <form onSubmit={onSubmit}>
            <Card elevation={4} style={{ background: "#fff" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} style={{ position: "relative" }}>
                    <img
                      style={{ width: 130, height: 130 }}
                      src={
                        "https://images.workflow.gzonetechph.com/company_logo/" +
                        state.company_logo
                      }
                    />
                    <div style={{ position: "absolute", right: 0, top: 0 }}>
                      {selectedRequest.status != "Cancelled" &&
                      selectedRequest.req_cheque_status != "Released" &&
                      homeReducer.loginData.create_check_voucher_priviledge ===
                        "1" ? (
                        <Button
                          startIcon={<BlockIcon />}
                          type="button"
                          onClick={handleClick}
                          style={{ backgroundColor: "#e74c3c", color: "#fff" }}
                          variant="contained"
                        >
                          Void
                        </Button>
                      ) : undefined}
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {/* <MenuItem onClick={() => voidRequests("Void and Create")}> <Typography variant='p'>Void and Create New Voucher</Typography>

                                                </MenuItem> */}
                        <MenuItem onClick={() => voidRequests("Void All")}>
                          <Typography variant="p">Void all Process</Typography>
                        </MenuItem>
                        {state.onSubmitChequetails.length > 0 ? (
                          <MenuItem
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                selectedCancelCheque:
                                  state.onSubmitChequetails[0],
                                voidReasonModal: true,
                                editIndex: 1,
                              }))
                            }
                          >
                            <Typography variant="p">
                              Void Check Voucher
                            </Typography>
                          </MenuItem>
                        ) : undefined}
                      </Menu>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      Check Voucher
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div
                      style={{
                        padding: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="p">
                        Payee :{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {state.payee}
                        </span>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <div
                          style={{
                            padding: 10,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="p">
                            CV No. :{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {selectedRequest.req_reference_no}
                            </span>
                          </Typography>
                          <Typography variant="p">
                            Recurring :{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {selectedRequest.recurring}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        padding: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="p">
                        Recipient :{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {selectedRequest.req_recipient}
                        </span>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined">
                      <div style={{ padding: 10 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <Typography variant="p">
                                  Payment Method :{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    Check
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography variant="p">
                                  Payment Date :{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {moment(
                                      selectedRequest.req_date_added
                                    ).format("YYYY-MM-DD")}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography variant="p">
                                  Description :{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedRequest.req_justification}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item xs={12} md={5}><Typography variant='p'>Payment Date : <span style={{ fontWeight: 'bold' }}>{moment(selectedRequest.req_date_added).format('YYYY-MM-DD')}</span></Typography></Grid> */}
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* <Typography style={{ textAlign: 'left', cursor: 'pointer', color: '#2c6164' }}><u><Typography>Add Cheque</Typography></u></Typography> */}
                    {/* <Button type='button' onClick={handleClickOption} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, display: selectedRequest.status === 'Approved' || selectedRequest.status === 'Hold' && selectedRequest.req_start_over === null ? 'none' : undefined }} variant="contained" >Add Cheque</Button> */}
                    <Button
                      type="button"
                      onClick={handleClickOption}
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#fff",
                        width: width < 600 ? "100%" : undefined,
                        display:
                          selectedRequest.status === "Cancelled" ||
                          selectedRequest.status === "Approved" ||
                          (selectedRequest.status === "Hold" &&
                            selectedRequest.req_start_over === null)
                            ? "none"
                            : undefined,
                      }}
                      variant="contained"
                    >
                      Add Transaction
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={filterOption}
                      keepMounted
                      open={Boolean(filterOption)}
                      onClose={handleCloseOption}
                    >
                      {[
                        { value: "Cheque", icon: "add" },
                        { value: "Online", icon: "add" },
                      ].map((val, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => onChangeOption(val.value)}
                          >
                            <Icon style={{ color: "#285293", marginRight: 5 }}>
                              {val.icon}
                            </Icon>
                            {`${val.value}`}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "70%" }}
                              >
                                Bank Name
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Check Number
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Amount
                              </TableCell>
                              {homeReducer.loginData
                                .create_check_voucher_priviledge === "1" ? (
                                <>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      display:
                                        selectedRequest.req_check_number != null
                                          ? "none"
                                          : undefined,
                                    }}
                                  ></TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      display:
                                        selectedRequest.status ===
                                          "Cancelled" ||
                                        (selectedRequest.status === "Hold" &&
                                          selectedRequest.req_start_over ===
                                            null) ||
                                        selectedRequest.req_cheque_status !=
                                          null
                                          ? "none"
                                          : undefined,
                                    }}
                                  ></TableCell>
                                  {/* <TableCell style={{ fontWeight: 'bold', display: state.display !== 'Details' || selectedRequest.status === 'Hold' && selectedRequest.req_start_over === null ? 'none' : undefined }}></TableCell> */}
                                </>
                              ) : undefined}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.onSubmitChequetails.map((val, index) => {
                              let backgroundColor = undefined;
                              let cancel = false;
                              if (val?.finance_status === "Cancelled") {
                                backgroundColor = "#ffa9a1";
                                cancel = true;
                              }
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell
                                    style={{
                                      width: "20%",
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                      background: backgroundColor,
                                    }}
                                  >
                                    {val.bank_name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      width: "20%",
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                      background: backgroundColor,
                                    }}
                                  >
                                    {val.cheque_number}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      width: "20%",
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                      background: backgroundColor,
                                    }}
                                  >
                                    {val.cheque_amount}
                                  </StyledTableCell>

                                  {homeReducer.loginData
                                    .create_check_voucher_priviledge === "1" ? (
                                    <>
                                      <StyledTableCell
                                        style={{
                                          width: "20%",
                                          whiteSpace: "nowrap",
                                          fontWeight: "bold",
                                          background: backgroundColor,
                                          display:
                                            (selectedRequest.status ===
                                              "Hold" &&
                                              selectedRequest.req_start_over ===
                                                null) ||
                                            selectedRequest.req_cheque_status !=
                                              null
                                              ? "none"
                                              : undefined,
                                        }}
                                      >
                                        <Tooltip title="Edit">
                                          <EditIcon
                                            type="button"
                                            onClick={() => {
                                              if (!cancel) {
                                                onEditCheque(val, index);
                                              }
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: cancel
                                                ? "#7f8c8d"
                                                : undefined,
                                            }}
                                          />
                                        </Tooltip>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{
                                          width: "20%",
                                          whiteSpace: "nowrap",
                                          fontWeight: "bold",
                                          background: backgroundColor,
                                          display:
                                            selectedRequest.req_check_number !=
                                            null
                                              ? "none"
                                              : undefined,
                                        }}
                                      >
                                        <Tooltip title="Cancel">
                                          <CloseIcon
                                            type="button"
                                            onClick={() => {
                                              state.onSubmitChequetails.splice(
                                                index,
                                                1
                                              );
                                              setState((prev) => ({
                                                ...prev,
                                              }));
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: cancel
                                                ? "#7f8c8d"
                                                : "#e74c3c",
                                            }}
                                          />
                                        </Tooltip>
                                      </StyledTableCell>
                                      {/* <StyledTableCell style={{ width: '20%', whiteSpace: 'nowrap', fontWeight: 'bold', background: backgroundColor, display: state.display !== 'Details' || selectedRequest.status === 'Hold' && selectedRequest.req_start_over === null ? 'none' : undefined }}><Tooltip title='Cancel' ><BlockIcon type='button' onClick={() => {
                                                                            if (!cancel) {
                                                                                setState(prev => ({
                                                                                    ...prev,
                                                                                    selectedCancelCheque: state.onSubmitChequetails[0],
                                                                                    voidReasonModal: true,
                                                                                    editIndex: index
                                                                                }))
                                                                            }
                                                                        }} style={{ cursor: 'pointer', color: cancel ? '#7f8c8d' : '#e74c3c' }} /></Tooltip></StyledTableCell> */}
                                    </>
                                  ) : undefined}
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  {homeReducer.loginData.create_check_voucher_priviledge ===
                  "1" ? (
                    state.display !== "Details" &&
                    selectedRequest.status !== "Cancelled" ? (
                      <>
                        <Grid container justify="center" item xs={12} md={12}>
                          <Typography
                            variant={"p"}
                            style={{
                              color: "#e74c3c",
                              fontWeight: "bold",
                              fontSize: 28,
                            }}
                          >
                            {state.balanceStatus}
                          </Typography>
                        </Grid>
                        <Grid container item xs={12} md={12} justify="flex-end">
                          <Button
                            type="submit"
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                actionButton: "Create",
                              }));
                            }}
                            style={{
                              backgroundColor: "#2c6164",
                              color: "#fff",
                              width: width < 600 ? "100%" : undefined,
                            }}
                            variant="contained"
                          >
                            Create
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid container item xs={12} md={12} justify="flex-end">
                        {!state.edit ? (
                          <>
                            {(parseInt(selectedRequest.req_start_over) === 1 &&
                              selectedRequest.status === "Hold") ||
                            selectedRequest.status === "Denied" ? (
                              <>
                                {/* <Button type='button' onClick={() => { setState(prev => ({ ...prev, edit: true })) }} style={{ backgroundColor: '#2c6164', marginRight: width < 600 ? undefined : 10, color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Edit</Button> */}
                                <Button
                                  type="button"
                                  onClick={() => {
                                    onClickProceed();
                                  }}
                                  style={{
                                    backgroundColor: "#2c3e50",
                                    color: "#fff",
                                    width: width < 600 ? "100%" : undefined,
                                  }}
                                  variant="contained"
                                >
                                  Proceed
                                </Button>
                              </>
                            ) : undefined}
                            {(parseInt(selectedRequest.req_hierarchy_level) ===
                              0 &&
                              selectedRequest.status === "Pending") ||
                            (parseInt(selectedRequest.req_start_over) === 2 &&
                              selectedRequest.status === "Hold") ? (
                              <>
                                {/* <Button type='button' onClick={() => { setState(prev => ({ ...prev, edit: true })) }} style={{ backgroundColor: '#2c6164', marginRight: width < 600 ? undefined : 10, color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Edit</Button> */}
                                <Button
                                  type="submit"
                                  onClick={() => {
                                    setState((prev) => ({
                                      ...prev,
                                      edit: true,
                                    }));
                                  }}
                                  style={{
                                    backgroundColor: "#2c6164",
                                    display: "none",
                                    marginRight: width < 600 ? undefined : 10,
                                    color: "#fff",
                                    width: width < 600 ? "100%" : undefined,
                                  }}
                                  variant="contained"
                                >
                                  Edits
                                </Button>
                              </>
                            ) : undefined}
                          </>
                        ) : (
                          <>
                            <Button
                              type="button"
                              onClick={() => {
                                setState((prev) => ({ ...prev, edit: false }));
                              }}
                              style={{
                                backgroundColor: "#e74c3c",
                                color: "#fff",
                                marginRight: width < 600 ? undefined : 10,
                                width: width < 600 ? "100%" : undefined,
                              }}
                              variant="contained"
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              onClick={() => {
                                setState((prev) => ({
                                  ...prev,
                                  actionButton: "Update",
                                }));
                              }}
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#fff",
                                width: width < 600 ? "100%" : undefined,
                              }}
                              variant="contained"
                            >
                              Update
                            </Button>
                          </>
                        )}
                      </Grid>
                    )
                  ) : undefined}
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "70%" }}
                              >
                                Document Description
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Amount Paid
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.detailsRow.map((val, index) => {
                              return (
                                <RowInputsCV
                                  state={state}
                                  state_val={val}
                                  setState={setState}
                                  index={index}
                                />
                              );
                            })}
                            <StyledTableRow>
                              <StyledTableCell
                                style={{
                                  width: "20%",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                Total Payment
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  whiteSpace: "pre-line",
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                {formatNumber(
                                  state.detailsRow.reduce((count, val) => {
                                    let total = val.total;
                                    if (val.total === "") {
                                      total = 0;
                                    }
                                    return (count += parseFloat(total));
                                  }, 0)
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  {
                    state.attachments.length > 0 ? (
                      <>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="p"
                            style={{ fontWeight: "bold" }}
                          >
                            Attachment(s)
                          </Typography>
                        </Grid>
                        {!state.edit ? (
                          <Grid item xs={12} md={12}>
                            {state.onSubmitChequetails.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    style={{ fontSize: 15 }}
                                  >
                                    Cheque Number : {val.cheque_number}
                                  </Typography>
                                  <img
                                    onClick={() => {
                                      window.open(val.file[0]);
                                    }}
                                    src={val.file[0]}
                                    style={{
                                      width: 100,
                                      height: 100,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              );
                            })}
                            {/* <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>     */}
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={12}>
                            {state.attachments.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    style={{ fontSize: 15 }}
                                  >
                                    {val}
                                  </Typography>
                                  <div
                                    style={{
                                      position: "relative",
                                      width: 100,
                                      height: 100,
                                    }}
                                  >
                                    <img
                                      onClick={() => {
                                        window.open(
                                          "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                            val
                                        );
                                      }}
                                      src={
                                        "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                        val
                                      }
                                      style={{
                                        width: 100,
                                        height: 100,
                                        cursor: "pointer",
                                      }}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: 1,
                                        top: 1,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        state.attachments.splice(index, 1);
                                        setState((prev) => ({ ...prev }));
                                      }}
                                    >
                                      <CloseIcon />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </Grid>
                        )}
                      </>
                    ) : undefined
                    // <Grid item xs={12} md={12}>
                    //     <Tooltip title='Attach Files'>
                    //         <IconButton style={{ backgroundColor: '#2c6164', color: '#fff', marginRight: 10, height: 40, width: 40 }} variant="contained" onClick={() => setOpen(true)}>
                    //             <div style={{ position: 'relative' }}>
                    //                 <AttachFileIcon />
                    //                 <div style={{ height: 20, width: 20, borderRadius: 10, background: '#eb4d4b', position: 'absolute', top: -10, right: -15, display: state.file.length == 0 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    //                     <Typography variant="p" style={{ color: '#fff', fontSize: 13 }}>{state.file.length}</Typography>
                    //                 </div>

                    //             </div>

                    //         </IconButton>
                    //     </Tooltip>
                    //     <DropzoneDialog
                    //         acceptedFiles={['image/*']}
                    //         cancelButtonText={"cancel"}
                    //         filesLimit={8}
                    //         submitButtonText={"done"}
                    //         maxFileSize={5000000}
                    //         open={open}
                    //         onClose={() => setOpen(false)}
                    //         onSave={() => setOpen(false)}
                    //         initialFiles={state.file}
                    //         onChange={handleChangeFile}
                    //         clearOnUnmount={false}
                    //         onDelete={(e) => {
                    //             for (let index2 = 0; index2 < state.file.length; index2++) {
                    //                 const element = state.file[index2];
                    //                 if (element.name === e.name) {
                    //                     state.file.splice(index2, 1)
                    //                 }
                    //                 setState(prev => ({ ...prev }))
                    //             }
                    //         }}
                    //         // onSave={(files) => {
                    //         //     console.log('Files:', files);
                    //         //     setOpen(false);
                    //         // }}
                    //         showPreviews={false}
                    //         // showFileNames={true}
                    //         showFileNamesInPreview={false}
                    //         showPreviewsInDropzone={true}
                    //     />
                    // </Grid>
                  }

                  <Grid container item xs={12} md={12} justify="flex-end">
                    <Typography variant="p">
                      Received by : ________________________________
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* <Typography variant="p" style={{ color: '#7f8c8d', fontWeight: 'bold', fontSize: 21, marginBottom: 10 }}>{'Request Hierarchy'}</Typography> */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: "#fff",
                              }}
                            >
                              {"Pending"}
                            </Typography>

                            <Typography
                              variant="p"
                              style={{ fontWeight: "bold" }}
                            >
                              {String(
                                `${selectedRequest.user_fname} ${selectedRequest.user_lname}`
                              ).toLocaleUpperCase()}
                            </Typography>
                            <Typography
                              variant="p"
                              style={{ fontSize: 11, fontWeight: "bold" }}
                            >
                              PREPARED BY
                            </Typography>
                          </div>
                        </Grid>
                        {JSON.parse(
                          selectedRequest.req_hierarchy_structure
                        ).map((ary2, index2) => {
                          var colorss = "#2ecc71";
                          let valStatus = "Pending";
                          if (
                            parseInt(index2) <
                            parseInt(selectedRequest.req_hierarchy_level)
                          ) {
                            if (
                              selectedRequest.status === "Denied" &&
                              index2 === selectedRequest.req_hierarchy_level - 1
                            ) {
                              colorss = "#e74c3c";
                              valStatus = "Denied";
                            } else {
                              colorss = "#2ecc71";
                              valStatus = "Approved";
                            }
                          } else {
                            if (selectedRequest.status === "Cancelled") {
                              valStatus = "Cancelled";
                              colorss = "#e74c3c";
                            } else if (selectedRequest.status === "Denied") {
                              valStatus = "Denied";
                              colorss = "#e74c3c";
                            } else {
                              colorss = "#faab2d";
                            }
                          }
                          let name = "";
                          ary2.approver.forEach((approver) => {
                            if (name != "") {
                              name = " / ";
                            }
                            name += approver.name;
                          });
                          // if(selectedRequest.cheque_number != 'Online Transaction')
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="p"
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: colorss,
                                  }}
                                >
                                  {valStatus}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {String(name).toLocaleUpperCase()}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontSize: 11, fontWeight: "bold" }}
                                >
                                  {ary2.title}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" style={{ background: "#fff" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid container justify="flex-start" item xs={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ChatIcon />
                    <Typography variant="h6" style={{ color: "#2c7f84" }}>
                      Comments and Logs
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ height: 500, overflowY: "auto" }}>
                    {selectedRequest?.comments.map((val, index) => {
                      let c_type = val.comment_type;
                      if (c_type === "Text") {
                        c_type = "Comment";
                      }
                      return (
                        <div style={{ marginBottom: 10 }}>
                          <div
                            style={{
                              paddingRight: 25,
                              paddingLeft: 25,
                              borderWidth: 1,
                              borderColor: "#c5bfbf",
                              background: "#f7f9fa",
                              padding: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{ fontWeight: "bold", color: "#2f3640" }}
                            >
                              {c_type}
                            </Typography>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {val.comment_type === "System" ? (
                                <SettingsIcon style={{ color: "#2c3e50" }} />
                              ) : (
                                <AccountCircleIcon
                                  style={{ color: "#3498db" }}
                                />
                              )}

                              <Typography
                                variant="p"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "#2980b9",
                                }}
                              >
                                {`${val.user_lname} ${val.user_fname}`}
                                <span style={{ color: "#000", marginLeft: 10 }}>
                                  {val.comment_date_added}
                                </span>
                              </Typography>
                            </div>
                            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                              <Typography variant="p">{`${val.comment}`}</Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
                <Grid container spacing={1}>
                  {/* {homeReducer.loginData.create_check_voucher_priviledge === "1" ? */}
                  <Card
                    variant="outlined"
                    style={{
                      width: width > 600 ? "100%" : "100%",
                      padding: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      value={state.comment_input}
                      name="comment_input"
                      onChange={onChangeText}
                      placeholder="Add Comment"
                      style={{
                        borderStyle: "none",
                        outline: "none",
                        width: "100%",
                      }}
                    />
                    <SendIcon
                      style={{ cursor: "pointer", color: "#3d7f85" }}
                      onClick={() => addComment()}
                    />
                  </Card>
                  {/* : undefined
                                    } */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
      <Dialog fullWidth maxWidth="sm" open={state.addChequeModel}>
        <div style={{ width: "100%", position: "relative" }}>
          <DialogTitle>{state.actionCheque}</DialogTitle>
          <div
            onClick={() =>
              setState((prev) => ({ ...prev, addChequeModel: false }))
            }
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </div>
        </div>

        <DialogContent>
          <form onSubmit={onSubmitAddCheque}>
            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Bank
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        required
                        name="bank_account_id"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={onChangeText}
                        value={state.bank_account_id}
                      >
                        {filter_bankList.map((val, index2) => {
                          return (
                            <MenuItem key={index2} value={val.bank_account_id}>
                              {String(val.bank_name).toLocaleUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Post Dated Cheque
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        readOnly={state.display == "Details" ? true : false}
                        required
                        name="postDatedCheque"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) =>
                          state.display != "Details" ? onChangeText(e) : false
                        }
                        value={state.postDatedCheque}
                      >
                        {["Yes", "No"].map((val, index2) => {
                          return (
                            <MenuItem key={index2} value={val}>
                              {String(val).toLocaleUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {state.postDatedCheque === "Yes" ? (
                    <Grid container justify="flex-start" item xs={12} md={12}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Date
                      </Typography>
                      <TextField
                        required
                        onChange={onChangeText}
                        type="date"
                        value={state.post_date}
                        name="post_date"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  ) : undefined}

                  {state.actionCheque == "Add Online Transaction" ||
                  state.actionCheque ==
                    "Edit Online Transaction" ? undefined : (
                    <Grid container justify="flex-start" item xs={12} md={12}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Cheque Number
                      </Typography>
                      <TextField
                        required
                        onChange={onChangeText}
                        value={state.cheque_number}
                        name="cheque_number"
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                      />
                    </Grid>
                  )}
                  <Grid container justify="flex-start" item xs={12} md={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Amount
                    </Typography>
                    <TextField
                      required
                      onChange={onChangeText}
                      type="number"
                      value={state.cheque_amount}
                      name="cheque_amount"
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  {(state.display == "Details" &&
                    !JSON.stringify(state.file).includes("https")) ||
                  state.file.length === 0 ||
                  state.display != "Details" ? (
                    <Grid container justify="flex-start" item xs={12} md={12}>
                      <Tooltip title="Attach Files">
                        <IconButton
                          style={{
                            backgroundColor: "#2c6164",
                            color: "#fff",
                            marginRight: 10,
                            height: 40,
                            width: 40,
                          }}
                          variant="contained"
                          onClick={() => setOpen(true)}
                        >
                          <div style={{ position: "relative" }}>
                            <AttachFileIcon />
                            <div
                              style={{
                                height: 20,
                                width: 20,
                                borderRadius: 10,
                                background: "#eb4d4b",
                                position: "absolute",
                                top: -10,
                                right: -15,
                                display:
                                  state.file.length == 0 ? "none" : "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="p"
                                style={{ color: "#fff", fontSize: 13 }}
                              >
                                {state.file.length}
                              </Typography>
                            </div>
                          </div>
                        </IconButton>
                      </Tooltip>
                      <DropzoneDialog
                        acceptedFiles={["image/*"]}
                        cancelButtonText={"cancel"}
                        filesLimit={1}
                        submitButtonText={"done"}
                        maxFileSize={5000000}
                        open={open}
                        onClose={() => setOpen(false)}
                        onSave={() => setOpen(false)}
                        // fileObjects={state.file}
                        initialFiles={state.file}
                        onChange={(e) => handleChangeFile(e)}
                        clearOnUnmount={false}
                        onDelete={(e) => {
                          for (
                            let index2 = 0;
                            index2 < state.file.length;
                            index2++
                          ) {
                            const element = state.file[index2];
                            if (element.name === e.name) {
                              state.file.splice(index2, 1);
                            }
                            setState((prev) => ({ ...prev }));
                          }
                        }}
                        // onSave={(files) => {
                        //     console.log('Files:', files);
                        //     setOpen(false);
                        // }}
                        showPreviews={false}
                        // showFileNames={true}
                        showFileNamesInPreview={false}
                        showPreviewsInDropzone={true}
                      />
                    </Grid>
                  ) : undefined}
                  {state.display == "Details" &&
                  JSON.stringify(state.file).includes("https") ? (
                    <Grid>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          display: state.file.length == 0 ? "none" : undefined,
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: 100,
                            height: 100,
                          }}
                        >
                          <img
                            onClick={() => {
                              window.open(state.file[0]);
                            }}
                            src={state.file[0]}
                            style={{
                              width: 100,
                              height: 100,
                              cursor: "pointer",
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              right: 1,
                              top: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                file: [],
                                onSubmitChequetails:
                                  state.onSubmitChequetails.map((val, index) =>
                                    index === state.editIndex
                                      ? {
                                          ...val,
                                          file: [],
                                        }
                                      : val
                                  ),
                              }));
                            }}
                          >
                            <CloseIcon />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ) : undefined}
                  {state.display == "Details" &&
                  (state.actionCheque === "Edit Cheque" ||
                    state.actionCheque === "Edit Online Transaction") ? (
                    <Grid container justify="flex-end" item xs={12} md={12}>
                      <Button
                        type="button"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                        }}
                        variant="contained"
                        onClick={() => onUpdateCheck()}
                      >
                        Update
                      </Button>
                    </Grid>
                  ) : undefined}
                  {state.display != "Details" &&
                  (state.actionCheque === "Edit Cheque" ||
                    state.actionCheque === "Edit Online Transaction") ? (
                    <Grid container justify="flex-end" item xs={12} md={12}>
                      <Button
                        type="button"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                        }}
                        variant="contained"
                        onClick={() => updateCheckInitialData()}
                      >
                        Update
                      </Button>
                    </Grid>
                  ) : undefined}
                  {state.actionCheque === "Add Cheque" &&
                  state.display != "Details" ? (
                    <Grid container justify="flex-end" item xs={12} md={12}>
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                        }}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Grid>
                  ) : undefined}
                  {state.display == "Details" &&
                  state.actionCheque === "Add Cheque" ? (
                    <Grid container justify="flex-end" item xs={12} md={12}>
                      <Button
                        type="button"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                        }}
                        variant="contained"
                        onClick={() => onAddCheck("Add")}
                      >
                        Add
                      </Button>
                    </Grid>
                  ) : undefined}
                  {state.actionCheque == "Add Online Transaction" ? (
                    <Grid container justify="flex-end" item xs={12} md={12}>
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                        }}
                        variant="contained"
                      >
                        Add
                      </Button>
                    </Grid>
                  ) : undefined}
                </Grid>
              </CardContent>
            </Card>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.voidReasonModal}
        onClose={() => {
          setState((prev) => ({ ...prev, voidReasonModal: false }));
        }}
      >
        <form onSubmit={onCancelCheque}>
          <DialogTitle>{"Reason"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  multiline
                  rows={4}
                  placeholder="Type"
                  name="cancel_reason"
                  value={state.cancel_reason}
                  onChange={onChangeText}
                  variant="outlined"
                  required
                  size="small"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
            >
              Proceed
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.onlineFormModal}
        onClose={() => {
          setState((prev) => ({ ...prev, onlineFormModal: false }));
        }}
      >
        <DialogTitle>{"Online Transaction"}</DialogTitle>
        <DialogContent>
          <OnlineForm
            amount={state.detailsRow.reduce((count, val) => {
              let total = val.total;
              if (val.total === "") {
                total = 0;
              }
              return (count += parseFloat(total));
            }, 0)}
          />
        </DialogContent>
        {/* <form onSubmit={onCancelCheque}>
                    <DialogTitle>{'Reason'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <TextField multiline rows={4} placeholder='Type' name='cancel_reason' value={state.cancel_reason} onChange={onChangeText} variant='outlined' required size='small' style={{ width: '100%' }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained">Proceed</Button>
                    </DialogActions>
                </form> */}
      </Dialog>
    </div>
  );
}
export default React.memo(RequestDetails, renderPropsAreEqual);
