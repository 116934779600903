// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  TextareaAutosize,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { DropzoneDialog } from "material-ui-dropzone";
// import { getData } from "../../../src/components/api/api"
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiAlert from "@material-ui/lab/Alert";
import PositionedSnackbar from "../../alert";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

const RowInputs = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRow: state.detailsRow.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
          {moment(state.detailsRow[index].date).format("YYYY-MM-DD")}
        </StyledTableCell>
        <StyledTableCell
          style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
        >
          {state.detailsRow[index].description}
        </StyledTableCell>
        <StyledTableCell
          style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
        >
          {formatNumber(parseFloat(state.detailsRow[index].total).toFixed(2))}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
function RequestDetails({ handleClose, refreshFunc, action, selectedRequest }) {
  console.log(selectedRequest);
  const [state, setState] = React.useState({
    detailsRow: [
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        total: "",
      },
    ],
    payee: "",
    company_name: "",
    open_modal_attachment: false,
    file: [],
    payeeList: [],
    company_id: "",
    branchList: [],
    branch_name: "",
    branch_id: "",
    department: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    attachments: [],
  });
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const homeReducer = useSelector((state) => state.HomeReducer);
  const onSubmit = (e) => {
    e.preventDefault();
    // dispatch({
    //     type: "Loading",
    //     data: true,
    // });
    let data = {
      payee: state.payee,
      company_name: state.company_name,
      detailsRow: state.detailsRow,
      branch_name: state.branch_name,
      branch_id: state.branch_id,
      company_id: state.company_id,
      type: "Cash/Check Requisition Form",
      type_name: "CRF",
      user_id: localStorage.getItem("u"),
      department: state.department,
    };
    if (state.file.length === 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please attach files !",
        alertColor: "#f39c12",
      }));
    } else {
      uploadForm(data);
    }
  };
  const uploadForm = (data) => {
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/createRequest",
        formData,
        config
      )
      .then((response) => {
        // if(response.data.response){
        setState((prev) => ({
          ...prev,
          detailsRow: [
            {
              date: moment(new Date()).format("YYYY-MM-DD"),
              description: "",
              total: "",
            },
          ],
          payee: "",
          company_name: "",
          open_modal_attachment: false,
          alert: true,
          alertMessage: "Success, Your request has been created.",
          alertColor: "#2ecc71",
          file: [],
          company_id: "",
          branchList: [],
          branch_name: "",
          branch_id: "",
          department: "",
        }));
        // handleClose()
        // }
        refreshFunc();
        dispatch({
          type: "Loading",
          data: false,
        });
      });
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeFile = (files) => {
    setState({
      ...state,
      file: files,
    });
  };
  const handleClose_modal = () => {
    setState((prev) => ({ ...prev, open_modal_attachment: false }));
  };

  React.useEffect(() => {
    dispatch({
      type: "Loading",
      data: false,
    });
    let branches = [];
    homeReducer.branches.forEach((val) => {
      branches.push(val.branch_id);
    });
    let company_logo_match = homeReducer.branches.filter(
      (val) => val.branch_id === selectedRequest.req_branch_id
    );
    let company_logo = "";
    if (company_logo_match.length > 0) {
      let split = String(company_logo_match[0].company_logo).split("/");
      company_logo = split[split.length - 1];
    }
    if (action === "Details") {
      let req_details = JSON.parse(selectedRequest.req_details);
      let company_id = selectedRequest.req_company_id;
      let branch_id = selectedRequest.req_branch_id;
      let company_name = selectedRequest.company_name;
      let branch_name = selectedRequest.branch_name;
      let department = selectedRequest.department;
      let payee = selectedRequest.req_payee;
      let attachments = JSON.parse(selectedRequest.req_attachments);
      let branches = homeReducer.branches.filter(
        (val) => val.company_name == company_name
      );

      setState((prev) => ({
        ...prev,
        detailsRow: req_details,
        company_id: company_id,
        branch_id: branch_id,
        company_name: company_name,
        branch_name: branch_name,
        department: department,
        branchList: branches,
        payee: payee,
        attachments: attachments,
        company_logo: company_logo,
      }));
    }

    // getData('aam/getPayee', branches).then((res) => {
    //     setState(prev => ({ ...prev, payeeList: res,detailsRow:selectedRequest }))
    // })
  }, []);
  const onChangeCompany = (e) => {
    let company_name = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_name == company_name
    );
    let company_id = "";
    if (branches.length > 0) {
      company_id = branches[0].company_id;
    }
    setState({
      ...state,
      company_name: company_name,
      rule: [],
      emp_type: "",
      payroll_rule_group_id: "",
      branchList: branches,
      company_id: company_id,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.branch_id == branch_id
    );
    if (branches.length > 0) {
      setState((prev) => ({
        ...prev,
        branch_id: branch_id,
        branch_name: branches[0].branch_name,
      }));
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };

  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          {selectedRequest?.comments.length > 0 ? (
            <Card style={{ background: "#fce7a4" }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" style={{ color: "#2c7f84" }}>
                      Comments
                    </Typography>
                  </Grid>
                  {selectedRequest?.comments.map((val, index) => {
                    return (
                      <Grid item xs={12} md={12}>
                        <div
                          style={{
                            paddingRight: 25,
                            paddingLeft: 25,
                            borderWidth: 1,
                            borderColor: "#c5bfbf",
                            background: "#fcde7e",
                            padding: 10,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{ fontWeight: "bold", fontSize: 12 }}
                          >{`${val.user_lname} ${val.user_fname}`}</Typography>
                          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Typography variant="p">{`${val.comment}`}</Typography>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          ) : undefined}
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={4}>
            <CardContent>
              {JSON.parse(selectedRequest.req_details).map((val, index) => {
                return (
                  <form key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Typography variant="h4" style={{ color: "#2c7f84" }}>
                          Supplier Details
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }} variant="p">
                          Register Business Name
                        </Typography>
                        <TextField
                          readOnly
                          value={val.supplier_name}
                          name="supplier_name"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%", backgroundColor: "#e9f2f5" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }} variant="p">
                          Address
                        </Typography>
                        <TextField
                          readOnly
                          value={val.supplier_address}
                          name="supplier_address"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%", backgroundColor: "#e9f2f5" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography style={{ fontWeight: "bold" }} variant="p">
                          Contact Person
                        </Typography>
                        <TextField
                          readOnly
                          value={val.supplier_contact_person}
                          name="contact_person"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%", backgroundColor: "#e9f2f5" }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography style={{ fontWeight: "bold" }} variant="p">
                          Contact No.
                        </Typography>
                        <TextField
                          readOnly
                          type="number"
                          value={val.supplier_contact_no}
                          name="contact_number"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%", backgroundColor: "#e9f2f5" }}
                        />
                      </Grid>
                      {/* <Grid container item xs={12} md={12} justify='flex-end'>
                                        <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>
                                    </Grid> */}
                    </Grid>
                  </form>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </div>
  );
}
export default React.memo(RequestDetails, renderPropsAreEqual);
