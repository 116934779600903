// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Tooltip
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import PositionedSnackbar from "../../alert";
import CallMadeIcon from '@material-ui/icons/CallMade';
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function IndexCollection() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };
    return (
        <div>
            <Card variant='outlined'>
                <CardContent>
                <Grid container justify='flex-start' item xs={12} md={12}>
                <Typography style={{ fontWeight: 'bold' }}>Collection</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container spacing={1}>
                            {[{type:'Collected',amount:1000,color:'#3498db'}].map((val, index) => {
                                return <Grid key={index} item xs={12} md={2}>
                                    <Card key={index} style={{ background: val.color, cursor: 'pointer' }}variant='outlined'>
                                        <CardContent>
                                            <div style={{ display: 'flex' }}>
                                                <Typography className={classes.cardFont}>{val.type}</Typography>
                                            </div>
                                            <Typography className={classes.cardFontCount}>{formatNumber(val.amount)}</Typography>
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', cursor: 'pointer' }}>
                                                <Tooltip title='View'>
                                                    <CallMadeIcon className={classes.iconSize} style={{ color: '#fff', fontWeight: 'bold', width: 20, height: 20 }} />
                                                </Tooltip>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            })
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
                </CardContent>
            </Card>
           
        </div>
    );
}
export default IndexCollection;
