import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  Slide,
  AppBar,
  Tooltip,
  TextField,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import RequestForm from './requestForm'
import RequestDetails from "./requestDetails";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Link as NewLink } from "react-router-dom";
import { getData } from "../../api/api";
import { useSelector, useDispatch } from "react-redux";
import CallMadeIcon from "@material-ui/icons/CallMade";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import RequestForm from "./requestForm";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PrintIcon from "@material-ui/icons/Print";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import PanToolIcon from "@material-ui/icons/PanTool";
import PositionedSnackbar from "../../alert";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import Signature from "../../../assets/signature.png";
import Release from "../../../assets/release.png";
import ReleaseDeduction from "../../../assets/release_deduction.png";
import Received from "../../../assets/receivedNew.png";
import BackspaceIcon from "@material-ui/icons/Backspace";
import InputField from "../../reusesableComponents/inputField/imputField";

let width = window.innerWidth;
const columns = [
  { id: "req_reference_no", label: "Reference No." },
  {
    id: "req_check_number",
    label: "Check No.",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "req_date_added",
    label: "Date Request",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "req_payee",
    label: "Payee",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "req_recipient",
    label: "Recipient",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "company_name",
    label: "Company",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "recurring",
    label: "Recurring",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  // {
  //     id: 'department',
  //     label: 'Department',
  //     format: (value) => String(value).toLocaleUpperCase(),
  // },
  // {
  //     id: 'status',
  //     label: 'Status',
  //     format: (value) => String(value).toLocaleUpperCase(),
  // },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 19,
    width: 19,
    cursor: "pointer",
    color: "#646b6b",
  },
});
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const homeReducer = useSelector((state) => state.HomeReducer);
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    requestList: [],
    requestListCount: 0,
    actionButton: "",
    selectedRequest: [],
    reqDetailsModal: false,
    refreshReqList: false,
    countStatus: [],
    status: "Total",
    comment_modal: false,
    comment_value: "",
    searchDriver: "",
    reqFormModal: false,
    display: "",
    bankList: [],
    branchList: [],
    branch_id: "All",
    company_id: "All",
    cheque_details: [],
    oncheckCV: [],
    tagSignature: false,
    oncheckCVRelease: [],
    tagAction: "",
    newAction: "",
    released_date: "",
    to: "",
    from: "",
    received: "",
    recurring: "",
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    getRequest(
      newPage,
      rowsPerPage,
      state.status,
      state.searchDriver,
      state.company_id,
      state.branch_id
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    getRequest(
      0,
      event.target.value,
      state.status,
      state.searchDriver,
      state.company_id,
      state.branch_id
    );
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let comapnies = homeReducer.companies.map((val) => val.company_id);
    let data = {
      companies: comapnies,
    };
    getData("aam/getBankAccountDetails", data).then((res) => {
      setState((prev) => ({
        ...prev,
        bankList: res.data,
      }));
      // dispatch({
      //     type: 'onChangeHomeReducer',
      //     data: {
      //         loading: false,
      //     }
      // })
    });
  }, []);

  React.useEffect(() => {
    getRequest(
      page,
      rowsPerPage,
      state.status,
      state.searchDriver,
      state.company_id,
      state.branch_id
    );
  }, [state.refreshReqList]);
  const getRequest = (
    page,
    row,
    status,
    searchDriver = "",
    company_id = "",
    branch_id = ""
  ) => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    let new_page = 1 + page;
    if (branch_id === "All") {
      branch_id = "";
    }
    if (company_id === "All") {
      company_id = "";
    }
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    // if (typeof homeReducer.loginData.complete_name !== 'undefined') {
    let branches = homeReducer.branches.map((val) => val.branch_id);
    let data = {
      user_id: localStorage.getItem("u"),
      branches: branches,
      page: new_page,
      limit: row,
      status: status,
      company: homeReducer.loginData.company_id,
      app_id: "7",
      searchDriver: searchDriver,
      req_type: "Check Voucher",
      account_payable_priviledge:
        homeReducer.loginData.account_payable_priviledge,
      company_id_filter: company_id,
      branch_id_filter: branch_id,
      from: state.from,
      to: state.to,
    };
    getData("aam/getRequestCV", data).then((res) => {
      setState((prev) => ({
        ...prev,
        requestList: res.data,
        requestListCount: res.count,
        countStatus: res.countStatus,
        status: status,
      }));
      setPage(page);
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: false,
        },
      });
    });
  };

  const onChangeText = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmitApproval = (e, request_status) => {
    e.preventDefault();
    let text = "Are you sure you want to " + request_status + " this request?";
    if (window.confirm(text) == true) {
      let select_req = state.selectedRequest;
      let request = {
        req_id: select_req.req_id,
        req_approval_id: select_req.req_approval_id,
        status: request_status,
        req_hierarchy_level: select_req.req_hierarchy_level,
        approver: "",
        comment: state.comment_value,
        app_id: "7",
        company_id: homeReducer.loginData.company_id,
        branch_id: homeReducer.loginData.branch_id,
        approver_id: homeReducer.loginData.user_id,
      };
      getData("HumanResource/changeRequestStatus", request).then((res) => {
        if (String(res).includes("Success")) {
          getRequest(
            page,
            rowsPerPage,
            state.status,
            state.searchDriver,
            state.company_id,
            state.branch_id
          );
          state.selectedRequest.req_approval_status = request_status;
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Success, Request has been " + request_status,
            alertColor: "#2ecc71",
            comment_value: "",
            comment_modal: false,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Something went wrong",
            alertColor: "#2ecc71",
          }));
        }
      });
    } else {
    }
  };
  const onChangeCompany = (e) => {
    let company_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_id == company_id
    );

    setState({
      ...state,
      company_id: company_id,
      branchList: branches,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    setState((prev) => ({ ...prev, branch_id: branch_id }));
  };
  const onSubmitBranchFilter = () => {
    getRequest(
      0,
      rowsPerPage,
      "Total",
      state.searchDriver,
      state.company_id,
      state.branch_id
    );
  };
  const holdCheckVoucher = (data, status) => {
    if (
      window.confirm(
        "Are you sure you want to hold this Check Number : " +
          data.req_check_number
      )
    ) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/holdCheckVocuher", {
        req_id: data.req_id,
        req_hold: status,
      }).then((res) => {
        let statusVal = "Hold";
        if (status == 0) {
          statusVal = "Unhold";
        }
        let alertMessage = "";
        let alertColor = "";
        let alert = false;
        if (res.res === true) {
          alertMessage = "Success, Check Voucher has been " + statusVal;
          alertColor = "#2ecc71";
          alert = true;
        } else if (res.res === statusVal) {
          alertMessage = "Warning,This Check Voucher is already " + statusVal;
          alertColor = "#f1c40f";
          alert = true;
        } else if (res.res === "Insufficient Balance") {
          alertMessage =
            "Warning, Insufficient Balance, Remaining balance : Php" +
            res.balance;
          alertColor = "#f1c40f";
          alert = true;
        }

        setState((prev) => ({
          ...prev,
          alert: alert,
          alertMessage: alertMessage,
          alertColor: alertColor,
          refreshReqList: !state.refreshReqList,
        }));
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };

  const onGetCheckDetails = (row) => {
    let data = {
      req_id: row.req_id,
      company_id: row.company_id,
    };
    getData("aam/onGetCheckDetails", data).then((res) => {
      setState((prev) => ({
        ...prev,
        actionButton: "Details",
        selectedRequest: row,
        reqFormModal: true,
        cheque_details: res,
      }));
    });
  };
  const onCheck = (details) => {
    let match = state.oncheckCV.findIndex(
      (val) => val.req_id === details.req_id
    );
    if (match === -1) {
      let data = {
        req_id: details.req_id,
        check_number: details.req_check_number,
        reference_number: details.req_reference_no,
        amount: details.req_quantity,
        bank_id: details.req_bank_id,
        company_id: details.company_id,
        date_request: details.req_date_added,
      };
      state.oncheckCV.push(data);
    } else {
      state.oncheckCV.splice(match, 1);
    }
    setState((prev) => ({
      ...prev,
    }));
  };
  const onSubmitSigned = (e) => {
    e.preventDefault();
    let funcName = "chequeSigned";
    let data = {
      user_id: homeReducer.loginData.user_id,
      cheques: state.oncheckCV,
      tagAction: state.tagAction,
      newAction: state.newAction,
      released_date: state.released_date,
      received_by: state.received,
    };
    if (state.tagAction === "Received") {
      funcName = "onReceivedCheck";
      data = {
        user_id: homeReducer.loginData.user_id,
        cheques: state.oncheckCV,
        tagAction: state.tagAction,
        date_received: state.released_date,
        received_by: state.received,
      };
    }

    if (state.oncheckCV.length > 0) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });

      getData("aam/" + funcName, data)
        .then((res) => {
          if (state.tagAction === "Received") {
            window.open(
              "https://api.workflow.gzonetechph.com/aam/excel/" + res,
              "_blank"
            );
            setState((prev) => ({
              ...prev,
              oncheckCV: [],
              alert: true,
              alertMessage: "Success, Receiving copy has been generated",
              alertColor: "#2ecc71",
              tagSignature: false,
              newAction: "",
            }));
          } else {
            if (res.res) {
              setState((prev) => ({
                ...prev,
                oncheckCV: [],
                alert: true,
                alertMessage: "Success, Status has been updated",
                alertColor: "#2ecc71",
                tagSignature: false,
                refreshReqList: !state.refreshReqList,
                newAction: "",
              }));
            } else {
              dispatch({
                type: "onChangeHomeReducer",
                data: {
                  loading: false,
                },
              });
            }
          }
        })
        .finally(() => {
          dispatch({
            type: "onChangeHomeReducer",
            data: {
              loading: false,
            },
          });
        });
    } else {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please select Check Voucher",
        alertColor: "#f1c40f",
      }));
    }
  };
  const onFilterDate = () => {
    getRequest(
      0,
      rowsPerPage,
      state.status,
      state.searchDriver,
      state.company_id,
      state.branch_id
    );
    setPage(page);
  };
  return (
    <Grid container spacing={1}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />

      <Grid container justify="flex-start" item xs={12} md={2}>
        <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
          Company
        </Typography>
        <FormControl
          variant={"outlined"}
          style={{ width: "100%", background: "#fff" }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.company_id}
            // onChange={handleChange}
            onChange={onChangeCompany}
            style={{ textAlign: "left" }}
          >
            <MenuItem value={"All"}>{"All"}</MenuItem>
            {homeReducer.companies.map((val, index) => {
              return (
                <MenuItem key={index} value={val.company_id}>
                  {val.company_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid container justify="flex-start" item xs={12} md={2}>
        <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
          Branch
        </Typography>
        <FormControl
          variant={"outlined"}
          style={{ width: "100%", background: "#fff" }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.branch_id}
            style={{ textAlign: "left" }}
            // onChange={handleChange}
            onChange={onChangeBranch}
          >
            <MenuItem value={"All"}>{"All"}</MenuItem>
            {state.branchList.map((val, index) => {
              return (
                <MenuItem key={index} value={val.branch_id}>
                  {val.branch_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={1}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              onSubmitBranchFilter();
            }}
            type="buttom"
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : undefined,
              marginTop: width < 600 ? undefined : 17,
            }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={5}></Grid>
      <Grid item xs={12} md={7}></Grid>
      <Grid container justify="flex-end" item xs={12} md={2}>
        <Grid container justify="flex-start" item xs={12} md={12}>
          <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
            From
          </Typography>
        </Grid>
        <TextField
          onChange={onChangeText}
          required
          value={state.from}
          type="date"
          style={{ backgroundColor: "#fff", width: "100%" }}
          name="from"
          variant="outlined"
          size="small"
          className={classes.textFieldStyle}
        />
      </Grid>
      <Grid container justify="flex-end" item xs={12} md={2}>
        <Grid container justify="flex-start" item xs={12} md={12}>
          <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
            To
          </Typography>
        </Grid>
        <TextField
          onChange={onChangeText}
          required
          type="date"
          value={state.to}
          style={{ backgroundColor: "#fff", width: "100%" }}
          name="to"
          variant="outlined"
          size="small"
          className={classes.textFieldStyle}
        />
      </Grid>
      <Grid container justify="flex-end" item xs={12} md={1}>
        <Button
          onClick={() => {
            onFilterDate();
          }}
          type="submit"
          style={{
            backgroundColor: "#2c6164",
            color: "#fff",
            width: width < 600 ? "100%" : undefined,
            marginTop: width < 600 ? undefined : 17,
          }}
          variant="contained"
        >
          Filter
        </Button>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={1}>
              {state.countStatus?.map((val, index) => {
                return (
                  <Grid key={index} item xs={12} md={2}>
                    <Card
                      style={{ background: val.color, cursor: "pointer" }}
                      variant="outlined"
                      onClick={() =>
                        getRequest(
                          0,
                          rowsPerPage,
                          val.type,
                          state.searchDriver,
                          state.company_id,
                          state.branch_id
                        )
                      }
                    >
                      <CardContent>
                        <div style={{ display: "flex" }}>
                          <Typography className={classes.cardFont}>
                            {val.type}
                          </Typography>
                        </div>
                        <Typography className={classes.cardFontCount}>
                          {val.count}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Tooltip title="View">
                            <CallMadeIcon
                              className={classes.iconSize}
                              style={{
                                color: "#fff",
                                fontWeight: "bold",
                                width: 20,
                                height: 20,
                              }}
                            />
                          </Tooltip>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item xs={12} md={12}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container spacing={1} >
                            <Grid container item xs={12} md={12} justify='flex-start'>
                                <Typography variant="h6" style={{ color: '#2c7f84' }}>Filter Branch</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Card variant="outlined">
                                    <CardContent >
                                        <Grid container spacing={1}>
                                            <Grid container justify='flex-start' item xs={12} md={4}>
                                                <Typography style={{ fontWeight: 'bold' }} variant="p">Company</Typography>
                                                <FormControl variant={'outlined'} style={{ width: '100%' }} size='small'>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={state.company_id}
                                                        // onChange={handleChange}
                                                        onChange={onChangeCompany}
                                                        style={{ textAlign: 'left' }}
                                                    >
                                                        <MenuItem value={"All"}>{'All'}</MenuItem>
                                                        {homeReducer.companies.map((val, index) => {
                                                            return <MenuItem key={index} value={val.company_id}>{val.company_name}</MenuItem>
                                                        })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid container justify='flex-start' item xs={12} md={4}>
                                                <Typography style={{ fontWeight: 'bold' }} variant="p">Branch</Typography>
                                                <FormControl variant={'outlined'} style={{ width: '100%' }} size='small'>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <Select
                                                        required
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={state.branch_id}
                                                        style={{ textAlign: 'left' }}
                                                        // onChange={handleChange}
                                                        onChange={onChangeBranch}
                                                    >
                                                        <MenuItem value={"All"}>{'All'}</MenuItem>
                                                        {state.branchList.map((val, index) => {
                                                            return <MenuItem key={index} value={val.branch_id}>{val.branch_name}</MenuItem>
                                                        })

                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button onClick={() => {
                                                        onSubmitBranchFilter()
                                                    }} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Submit</Button>
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid> */}
      <Grid item xs={12} md={12}>
        {/* <Card variant='outlined'>
                    <CardContent> */}
        <Grid container spacing={1}>
          <Grid container item xs={12} md={3} justify="flex-start">
            <Card
              variant="outlined"
              style={{
                width: "100%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                value={state.searchDriver}
                placeholder="Search"
                style={{ borderStyle: "none", outline: "none" }}
                name="searchDriver"
                onChange={onChangeText}
              />
              <SearchIcon
                style={{ cursor: "pointer", color: "#787f80" }}
                onClick={() =>
                  getRequest(
                    0,
                    rowsPerPage,
                    state.status,
                    state.searchDriver,
                    state.company_id,
                    state.branch_id
                  )
                }
              />
              <RefreshIcon
                style={{ cursor: "pointer", color: "#787f80" }}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    searchDriver: "",
                  }));
                  setTimeout(() => {
                    getRequest(
                      0,
                      rowsPerPage,
                      state.status,
                      "",
                      state.company_id,
                      state.branch_id
                    );
                  }, 200);
                }}
              />
            </Card>
          </Grid>
          <Grid container item xs={12} md={3} justify="flex-start">
            {homeReducer.loginData?.create_check_voucher_priviledge === "1" ||
            homeReducer.loginData?.release_priviledge === "1" ? (
              <>
                {homeReducer.loginData?.release_priviledge === "1" && (
                  <Tooltip
                    title="Tag with Signed"
                    style={{ position: "relative" }}
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        tagSignature: true,
                        tagAction: "Signed",
                        newAction: "",
                      }));
                    }}
                  >
                    <Card
                      variant="outlined"
                      style={{
                        marginRight: 8,
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 10,
                        paddingRight: 10,
                        cursor: "pointer",
                        background: "#ecf0f1",
                      }}
                    >
                      <img src={Signature} style={{ width: 20, height: 20 }} />
                      <div
                        style={{
                          width: 18,
                          height: 18,
                          borderRadius: 9,
                          background: "#f39c12",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="p" style={{ color: "#fff" }}>
                          {state.oncheckCV.length}
                        </Typography>
                      </div>
                    </Card>
                  </Tooltip>
                )}
                {homeReducer.loginData?.release_priviledge !== "1" && (
                  <>
                    <Tooltip
                      title="Tag with Release"
                      style={{ position: "relative" }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          tagSignature: true,
                          tagAction: "Released",
                          newAction: "",
                        }));
                      }}
                    >
                      <Card
                        variant="outlined"
                        style={{
                          marginRight: 8,
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          cursor: "pointer",
                          background: "#ecf0f1",
                        }}
                      >
                        <img src={Release} style={{ width: 20, height: 20 }} />
                        <div
                          style={{
                            width: 18,
                            height: 18,
                            borderRadius: 9,
                            background: "#f39c12",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="p" style={{ color: "#fff" }}>
                            {state.oncheckCV.length}
                          </Typography>
                        </div>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      title="Tag with Release and Actual Balance Deduction"
                      style={{ position: "relative" }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          tagSignature: true,
                          tagAction: "Released",
                          newAction: "Deduction",
                        }));
                      }}
                    >
                      <Card
                        variant="outlined"
                        style={{
                          display: "flex",
                          marginRight: 8,
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          cursor: "pointer",
                          background: "#ecf0f1",
                        }}
                      >
                        <img
                          src={ReleaseDeduction}
                          style={{ width: 18, height: 18 }}
                        />
                        <div
                          style={{
                            width: 18,
                            height: 18,
                            borderRadius: 9,
                            background: "#f39c12",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="p" style={{ color: "#fff" }}>
                            {state.oncheckCV.length}
                          </Typography>
                        </div>
                      </Card>
                    </Tooltip>
                    <Tooltip
                      title="Receive by"
                      style={{ position: "relative" }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          tagSignature: true,
                          tagAction: "Received",
                          newAction: "",
                        }));
                      }}
                    >
                      <Card
                        variant="outlined"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: 10,
                          paddingRight: 10,
                          cursor: "pointer",
                          background: "#ecf0f1",
                        }}
                      >
                        <img src={Received} style={{ width: 27, height: 27 }} />
                        <div
                          style={{
                            width: 18,
                            height: 18,
                            borderRadius: 9,
                            background: "#f39c12",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="p" style={{ color: "#fff" }}>
                            {state.oncheckCV.length}
                          </Typography>
                        </div>
                      </Card>
                    </Tooltip>
                  </>
                )}
              </>
            ) : undefined}
          </Grid>
          <Grid container item xs={12} md={6} justify="flex-end">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "cnter",
              }}
            >
              <Typography variant="p" style={{ fontWeight: "bold" }}>
                Filter : {state.status}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper
              className={(classes.root, classes.outlined)}
              variant="outlined"
            >
              <TableContainer
                className={(classes.container, classes.tableHeight)}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      ></TableCell>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2c6164",
                            color: "#ffff",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Post Dated
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Date Approved
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#2c6164" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.requestList.map((row) => {
                      let bgColor = "#3498db";
                      let status = row.status;
                      if (row.status === "Approved") {
                        bgColor = "#2ecc71";
                      }
                      if (row.status === "Pending") {
                        bgColor = "#faab2d";
                      }
                      if (row.status === "Denied") {
                        bgColor = "#e74c3c";
                      }
                      if (row.status === "Disabled") {
                        bgColor = "#7f8c8d";
                        status = "Processing";
                      }
                      if (row.status === "Cancelled") {
                        bgColor = "#f56b5d";
                      }
                      if (
                        row.status === "Hold" &&
                        parseInt(row.req_start_over) !== null
                      ) {
                        bgColor = "#9b59b6";
                      }
                      if (
                        row.status === "Hold" &&
                        parseInt(row.req_start_over) >= 1
                      ) {
                        bgColor = "#2c3e50";
                        status = "Return";
                      }
                      if (parseInt(row.req_hold) === 1) {
                        bgColor = "#9b59b6";
                        status = "Hold";
                      }
                      if (
                        row.req_cheque_status === "Signed" &&
                        parseInt(row.req_hold) === 0
                      ) {
                        bgColor = "#16a085";
                        status = "Signed";
                      }
                      if (row.req_cheque_status === "Released") {
                        bgColor = "#2270a3";
                        status = "Released";
                      }

                      let details = [];
                      if (
                        row.req_justification !== "" &&
                        row.req_justification !== null
                      ) {
                        details = [
                          {
                            description: row.req_justification,
                          },
                        ];
                      } else {
                        details = JSON.parse(row.req_details);
                      }
                      let match = state.oncheckCV.findIndex(
                        (val) => val.req_id === row.req_id
                      );
                      let matchCheck = false;
                      if (match > -1) {
                        matchCheck = true;
                      }
                      let postDated = "";
                      if (row.post_dated_cheque == 1) {
                        postDated = "Yes";
                      }
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell align={"center"}>
                            <FormControlLabel
                              style={{
                                display:
                                  ((homeReducer.loginData
                                    ?.create_check_voucher_priviledge === "1" ||
                                    homeReducer.loginData
                                      ?.release_priviledge === "1") &&
                                    row.req_cheque_status) === null &&
                                  status === "Approved" &&
                                  parseInt(row.req_hold) === 0 &&
                                  postDated == ""
                                    ? undefined
                                    : "none",
                              }}
                              control={
                                <GreenCheckbox
                                  checked={matchCheck}
                                  onChange={() => onCheck(row)}
                                  name="checkedG"
                                />
                              }
                            />
                            <FormControlLabel
                              style={{
                                display:
                                  (homeReducer.loginData
                                    ?.create_check_voucher_priviledge === "1" ||
                                    homeReducer.loginData
                                      ?.release_priviledge === "1") &&
                                  row.req_cheque_status === "Signed"
                                    ? undefined
                                    : "none",
                              }}
                              control={
                                <GreenCheckbox
                                  checked={matchCheck}
                                  onChange={() => onCheck(row)}
                                  name="checkedG"
                                />
                              }
                            />
                          </TableCell>
                          {columns.map((column) => {
                            let value = row[column.id];
                            // let color = '#faab2d';

                            // if(column.id === 'status'){
                            //      value ='Pending'
                            //     if(row.req_apv_created !== null && row.req_apv_created !== ""){
                            //         value = 'Created'
                            //         color = '#2ecc71'
                            //     }
                            // }
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "status" ? (
                                  <span
                                    style={{
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      paddingRight: 5,
                                      paddingLeft: 5,
                                      background: bgColor,
                                      color: "#fff",
                                      borderRadius: 5,
                                    }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </span>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell>{postDated}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {details.map((valDetails, index2) => {
                                return (
                                  <Typography variant="p" key={index2}>
                                    {String(
                                      valDetails.description
                                    ).toLocaleUpperCase()}
                                  </Typography>
                                );
                              })}
                            </div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                paddingTop: 3,
                                paddingBottom: 3,
                                paddingRight: 5,
                                paddingLeft: 5,
                                background: bgColor,
                                color: "#fff",
                                borderRadius: 5,
                              }}
                            >
                              {status}
                            </span>
                          </TableCell>
                          <TableCell>{row.req_date_approved}</TableCell>
                          <TableCell>
                            {formatNumber(row.req_quantity)}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {/* <Tooltip style={{ marginRight: 10 }} onClick={() => {
                                                        setState(prev => ({ ...prev, actionButton: 'Details', selectedRequest: row, reqDetailsModal: true }));

                                                    }} title='Request for Payment'>
                                                        <CallMadeIcon className={classes.iconSize} />
                                                    </Tooltip> */}

                              {(row.req_check_number !== "" &&
                                row.req_check_number !== null) ||
                              row.status == "Denied" ||
                              row.status == "Cancelled" ? (
                                <>
                                  <Tooltip
                                    style={{ marginRight: 15 }}
                                    onClick={() => {
                                      onGetCheckDetails(row);
                                    }}
                                    title="Check Voucher"
                                  >
                                    <ListAltIcon className={classes.iconSize} />
                                  </Tooltip>
                                  {row.status === "Approved" &&
                                  homeReducer.loginData
                                    ?.create_check_voucher_priviledge ===
                                    "1" ? (
                                    <>
                                      <Tooltip
                                        style={{ marginRight: 15 }}
                                        onClick={() => {
                                          window.open(
                                            "http://api.workflow.gzonetechph.com/report/printCheckVoucher/" +
                                              row.req_id
                                          );
                                        }}
                                        title="Print Check Voucher"
                                      >
                                        <PrintIcon
                                          className={classes.iconSize}
                                        />
                                      </Tooltip>
                                      {/* <Tooltip onClick={() => {
                                                                                    releaseCheckVoucher(row)

                                                                                }} title='Realese Check Voucher'>
                                                                                    <ThumbUpAltIcon className={classes.iconSize} />
                                                                                </Tooltip> */}
                                    </>
                                  ) : undefined}
                                </>
                              ) : (
                                homeReducer.loginData
                                  ?.create_check_voucher_priviledge === "1" &&
                                row.status != "Denied" &&
                                row.status != "Cancelled" && (
                                  <Tooltip
                                    onClick={() => {
                                      setState((prev) => ({
                                        ...prev,
                                        actionButton: "Details",
                                        selectedRequest: row,
                                        reqFormModal: true,
                                        cheque_details: [],
                                      }));
                                    }}
                                    title="Create"
                                  >
                                    <CreateIcon className={classes.iconSize} />
                                  </Tooltip>
                                )
                              )}
                              {(row.req_cheque_status === null ||
                                row.req_cheque_status === "Signed") &&
                              parseInt(
                                homeReducer.loginData?.hold_voucher_priviledge
                              ) === 1 &&
                              parseInt(row.req_hold) === 0 &&
                              row.status === "Approved" ? (
                                <Tooltip
                                  style={{ marginRight: 15 }}
                                  onClick={() => {
                                    holdCheckVoucher(row, 1);
                                  }}
                                  title="Hold"
                                >
                                  <PanToolIcon className={classes.iconSize} />
                                </Tooltip>
                              ) : undefined}
                              {parseInt(
                                homeReducer.loginData?.hold_voucher_priviledge
                              ) === 1 &&
                              parseInt(row.req_hold) === 1 &&
                              row.status === "Approved" ? (
                                <Tooltip
                                  onClick={() => {
                                    holdCheckVoucher(row, 0);
                                  }}
                                  title="Unhold"
                                >
                                  <ThumbsUpDownIcon
                                    className={classes.iconSize}
                                  />
                                </Tooltip>
                              ) : undefined}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state.requestListCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
        {/* </CardContent>
                </Card> */}
      </Grid>
      {/* <Dialog fullScreen open={open} onClose={handleClose} >
                <div className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {state.actionButton}
                        </Typography>
                    </Toolbar>
                    <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} />
                </div>
                <RequestForm refresh={()=>{setState(prev=>({...prev,refreshReqList:!state.refreshReqList}))}} handleClose={() => handleClose()} action={state.actionButton} selectedRequest={state.selectedRequest} />

            </Dialog> */}
      <Dialog
        fullScreen
        open={state.reqDetailsModal}
        onClose={() => {
          setState((prev) => ({ ...prev, reqDetailsModal: false }));
        }}
      >
        <AppBar className={classes.appBar} elevation={1}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setState((prev) => ({ ...prev, reqDetailsModal: false }));
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{state.actionButton}</Typography>
          </Toolbar>
          <div
            style={{ backgroundColor: "#2c7f84", height: 4, width: "100%" }}
          />
        </AppBar>
        <RequestDetails
          handleClose={() => handleClose()}
          action={state.actionButton}
          selectedRequest={state.selectedRequest}
        />
        {/* <div style={{ marginTop: 50 }} />
                <Card elevation={6} style={{ position: "fixed", bottom: 1, display: 'flex', alignItems: 'center', background: '#fff', width: '100%', justifyContent: 'flex-end ', height: 50, paddingRight: 20 }}>
                    {state.selectedRequest.req_approval_status === 'Pending' ?
                        <>
                           
                            <Button onClick={(e) => {
                                onSubmitApproval(e, 'Approved')

                            }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>
                        </>
                        : undefined

                    } 

                </Card > */}
      </Dialog>
      <Dialog
        fullScreen
        open={state.reqFormModal}
        onClose={() => {
          setState((prev) => ({ ...prev, reqFormModal: false }));
        }}
      >
        <AppBar className={classes.appBar} elevation={1}>
          <Toolbar>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setState((prev) => ({ ...prev, reqFormModal: false }));
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">{state.display}</Typography>
              </div>

              <div>
                {state.selectedRequest.status === "Approved" ? (
                  <Tooltip
                    onClick={() => {
                      window.open(
                        "http://api.workflow.gzonetechph.com/report/printCheckVoucher/" +
                          state.selectedRequest.req_id
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    title="Print Check Voucher"
                  >
                    <PrintIcon />
                  </Tooltip>
                ) : undefined}
              </div>
            </div>
          </Toolbar>
          {/* <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} /> */}
        </AppBar>
        <div style={{ marginTop: 60 }} />
        <div style={{ background: "#f5f7f7" }}>
          <RequestDetails
            handleClose={() => handleClose()}
            action={state.actionButton}
            selectedRequest={state.selectedRequest}
          />
          <RequestForm
            bankList={state.bankList}
            handleCloseModal={() =>
              setState((prev) => ({ ...prev, reqFormModal: false }))
            }
            action={state.actionButton}
            selectedRequest={state.selectedRequest}
            onChangeDisplay={(data) => {
              setState((prev) => ({ ...prev, display: data }));
            }}
            cheque_details={state.cheque_details}
            onRefresh={() => {
              setState((prev) => ({
                ...prev,
                refreshReqList: !state.refreshReqList,
              }));
            }}
          />
        </div>
        {/* <div style={{ marginTop: 50 }} />
                <Card elevation={6} style={{ position: "fixed", bottom: 1, display: 'flex', alignItems: 'center', background: '#fff', width: '100%', justifyContent: 'flex-end ', height: 50, paddingRight: 20 }}>
                    {state.selectedRequest.req_approval_status === 'Pending' ?
                        <>
                           
                            <Button onClick={(e) => {
                                onSubmitApproval(e, 'Approved')

                            }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>
                        </>
                        : undefined

                    } 

                </Card > */}
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={state.comment_modal}
        onClose={() => {
          setState((prev) => ({ ...prev, comment_modal: false }));
        }}
      >
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              onSubmitApproval(e, "Denied");
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  style={{ width: "100%" }}
                  onChange={onChangeText}
                  name="comment_value"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#fff",
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={state.tagSignature}
        onClose={() => {
          setState((prev) => ({ ...prev, tagSignature: false }));
        }}
      >
        <form onSubmit={onSubmitSigned}>
          <DialogTitle>Preview</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {state.tagAction === "Received" ? (
                <React.Fragment>
                  <Grid item xs={12} md={12}>
                    <InputField
                      label={"Received by"}
                      name="received"
                      onChange={onChangeText}
                      value={state.received}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      size="small"
                      variant="outlined"
                      type={"date"}
                      style={{ width: "100%" }}
                      required
                      onChange={onChangeText}
                      name="released_date"
                    />
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item xs={12} md={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Date Released / Signed
                  </Typography>
                  <TextField
                    type={"date"}
                    style={{ width: "100%" }}
                    required
                    onChange={onChangeText}
                    name="released_date"
                  />
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <Paper
                  className={(classes.root, classes.outlined)}
                  variant="outlined"
                >
                  <TableContainer
                    className={(classes.container, classes.tableHeight)}
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: "#2c6164",
                              color: "#ffff",
                              whiteSpace: "nowrap",
                            }}
                          >
                            #
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#2c6164",
                              color: "#ffff",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Reference No.
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#2c6164",
                              color: "#ffff",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Cheque No.
                          </TableCell>
                          <TableCell
                            style={{
                              backgroundColor: "#2c6164",
                              color: "#ffff",
                              whiteSpace: "nowrap",
                            }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.oncheckCV.map((val, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{val.reference_number}</TableCell>
                              <TableCell>{val.check_number}</TableCell>
                              <TableCell>
                                <Tooltip
                                  title={"Remove"}
                                  onClick={() => {
                                    state.oncheckCV.splice(index, 1);
                                    setState((prev) => ({
                                      ...prev,
                                    }));
                                  }}
                                >
                                  <BackspaceIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "#646b6b",
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Grid>
  );
}
