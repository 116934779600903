import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "../../components/api/api";
import axios from "axios";
const PettyCashHooks = () => {
  const companies = useSelector((state) => state.HomeReducer.companies);
  const branches = useSelector((state) => state.HomeReducer.branches);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    branches: [],
    open: false,
    selectedBranch: [],
    purpose: "",
    employeeId: "",
    requestor_fname: "",
    requestor_lname: "",
    requestor_mname: "",
    date: "",
    route: "",
    assigned: "",
    accountTitle: [],
    particulars: "",
    amount: "",
    accounts: [],
    file: [],
    dataList: [],
    invoice_no: "",
    supplier: "",
    address: "",
    tin: "",
    openAddSupplier: false,
  });

  const stateForm = React.useMemo(() => {
    return {
      selectedBranch: state.selectedBranch,
      purpose: state.purpose,
      requestor_fname: state.requestor_fname,
      requestor_lname: state.requestor_lname,
      requestor_mname: state.requestor_mname,
      date: state.date,
      route: state.route,
      assigned: state.assigned,
      accountTitle: state.accountTitle,
      particulars: state.particulars,
      amount: state.amount,
      file: state.file,
      invoice_no: state.invoice_no,
      supplier: state.supplier,
      address: state.address,
      tin: state.tin,
    };
  }, [
    state.selectedBranch,
    state.purpose,
    state.requestor_fname,
    state.requestor_lname,
    state.requestor_mname,
    state.date,
    state.route,
    state.assigned,
    state.accountTitle,
    state.particulars,
    state.amount,
    JSON.stringify(state.file),
    state.invoice_no,
    state.supplier,
    state.address,
    state.tin,
  ]);
  const handleOpenCreate = () => {
    setState((prev) => ({
      ...prev,
      open: true,
    }));
  };
  const handleCloseCreate = () => {
    setState((prev) => ({
      ...prev,
      open: false,
    }));
  };
  const onSelectBranch = (e, val) => {
    setState((prev) => ({
      ...prev,
      selectedBranch: val,
    }));
  };
  const getAccounts = () => {
    let data = { company_id: "", type: "Profit and Loss Statement" };
    getData("aam/balance_sheet_group", data).then((res) => {
      let accounts = [];
      res.res.forEach((element) => {
        if (element.balance_sheet_group_name === "EXPENSE") {
          accounts = element.child;
        }
      });
      setState((prev) => ({
        ...prev,
        accounts: accounts,
      }));
    });
  };
  React.useEffect(() => {
    let isCancelled = false;
    getAccounts(isCancelled);
    getPettyCash(isCancelled);
    return () => {
      isCancelled = true;
    };
  }, []);

  const onChangeText = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    const data = {
      req_justification: state.purpose,
      // employeeId: state.employeeId,
      requestor_fname: state.requestor_fname,
      requestor_lname: state.requestor_lname,
      requestor_mname: state.requestor_mname,
      req_date_start: state.date,
      req_date_end: state.date,
      route: state.route,
      assigned_count: state.assigned,
      account_title: state.accountTitle.balance_sheet_group_name,
      account_id: state.accountTitle.balance_sheet_group,
      req_details_particular: state.particulars,
      req_quantity: state.amount,
      req_quantity_unit: "Php",
      req_type: "Petty Cash",
      branch_id: state.selectedBranch.branch_id,
      supplier: state.supplier,
      supplier_address: state.address,
      tin: state.tin,
    };
    formData.append("data", JSON.stringify(data));

    axios
      .post(
        "https://api.workflow.gzonetechph.com/pettyCash/uploadPettyCash",
        formData,
        config
      )
      .then((response) => {
        console.log(response);
        if (response.data.res == true) {
          handleCloseCreate();
        } else if (response.data.res === "No Hierarchy") {
          alert("No Hierarchy , Please set the hierarchy for this branch!");
        } else if (response.data.res === "Please Add Funds") {
          alert("No Funds , Please add funds!");
        } else if (response.data.res === "Insufficient Funds") {
          alert("Warning , Insufficient Funds!");
        }
      })
      .finally(() => {
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    // getData('pettyCash/uploadPettyCash',data).then(()=>{

    // })
  };
  const onSelectAccountTitle = (e, val) => {
    setState((prev) => ({
      ...prev,
      accountTitle: val,
    }));
  };
  const onChangePic = (file) => {
    setState((prev) => ({
      ...prev,
      file: file,
    }));
  };
  const getPettyCash = (isCancelled) => {
    const data = {
      limit: 10,
      page: 1,
      user_id: localStorage.getItem("u"),
      req_type: "Petty Cash",
    };
    getData("pettyCash/getPettyCash", data).then((res) => {
      if (!isCancelled) {
        setState((prev) => ({
          ...prev,
          dataList: res.dataList,
        }));
      }
    });
  };
  const handleCloseCreateSupplier = () => {
    setState((prev) => ({
      ...prev,
      openAddSupplier: false,
    }));
  };
  const handleOpenCreateSupplier = () => {
    setState((prev) => ({
      ...prev,
      openAddSupplier: true,
    }));
  };

  return {
    companies,
    ...state,
    onSelectBranch,
    handleOpenCreate,
    handleCloseCreate,
    branches,
    onChangeText,
    onSubmit,
    onSelectAccountTitle,
    stateForm,
    onChangePic,
    handleCloseCreateSupplier,
    handleOpenCreateSupplier,
  };
};

export default PettyCashHooks;
