import axios from "axios";
// import swal from "sweetalert/dist/sweetalert.min.js";
// let serverurl = "http://api.pacificweb.com.ph/";
// let serverurl = "http://localhost/api";
import { useSelector, useDispatch } from "react-redux";

let serverurl = "https://api.workflow.gzonetechph.com/";
// let serverurl = "http://localhost/api/";
// let serverurl ="http://192.168.5.21/backend/beta_api/";
// let serverurl = "http://beta.api.pacificweb.com.ph/";
// let serverurl ="http://192.168.1.44/api_pacific/";
// let serverurl ="http://192.168.2.36/beta_api3/";

let key = "?key=PocketHR@20190208&type=web";
let key2 = "?key=12345ABCFleet";
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
let count = 0;
export function getData(method, param, discon) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        serverurl + method + key,
        {
          param,
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        resolve(res.data);
        count = 0;
      })
      .catch((error) => {
        console.log(error);
        let errorRes = "Error";
        if (String(error).includes("Network Error")) {
          if (count === 0) {
            // alert('Network Error ! Please check your internet connection')
            errorRes = "Network Error";
          }
          count++;
        }
        resolve(errorRes);
      });
  });
}

export function getDataApi(method, param, discon) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        serverurl + method + key,
        {
          param,
        },
        {
          cancelToken: source.token,
        }
      )
      .then((res) => {
        resolve(res.data);
        count = 0;
      })
      .catch((error) => {
        console.log(error);
        let errorRes = "Error";
        if (String(error).includes("Network Error")) {
          if (count === 0) {
            // alert('Network Error ! Please check your internet connection')
            errorRes = "Network Error";
          }
          count++;
        }
        resolve(errorRes);
      });
  });
}

export function cancelRequest() {
  source.cancel("Operation canceled by the user.");
  source = CancelToken.source();
}
export const serverImageMeter =
  "https://api.workflow.gzonetechph.com/assets/img/meter/";
export const serverProfile =
  "https://images.workflow.gzonetechph.com/pockethr/profilepic/";
