import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { CardContent, Card, Typography,Tooltip} from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';

let width = window.innerWidth;
const columns = [
    { id: 'req_reference_no', label: 'Reference No.' },
    {
        id: 'req_date_added',
        label: 'Date Request',
        format: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
        id: 'req_payee',
        label: 'Payee',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'company_name',
        label: 'Company',
        format: (value) => String(value).toLocaleUpperCase(),
    }
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 300,
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

const StickyHeadTable = React.memo((props) => {
    const { getDateTransaction,setState } = props

    const classes = useStyles();
    const dispatch = useDispatch()
    const requestLogsDetails = useSelector(state => state.RequestLogsDetails.requestLogsDetails)
    const page = useSelector(state => state.RequestLogsDetails.page)
    const rowsPerPage = useSelector(state => state.RequestLogsDetails.rowsPerPage)
    const totalRow = useSelector(state => state.RequestLogsDetails.totalRow)
    console.log('TransanctionTable')

    const handleChangePage = (event, newPage) => {
        dispatch({
            type: 'onChnageRequestLogs',
            data: {
                page: newPage
            }
        })
        getDateTransaction(newPage, rowsPerPage)
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch({
            type: 'onChnageRequestLogs',
            data: {
                page: 0,
                rowsPerPage: + event.target.value
            }
        })
        getDateTransaction(0, +event.target.value)

    };

    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };

    return (
        <Card variant='outlined'>
            <CardContent>
                <Paper variant='outlined' className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    >
                                        {'Details'}
                                    </TableCell>
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    >
                                        {'Amount'}
                                    </TableCell>
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    >
                                        {'Status'}
                                    </TableCell>
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    >

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {requestLogsDetails.map((row) => {
                                    let bgColor = '#3498db'
                                    let status = row.status
                                    if (row.status === 'Approved') {
                                        bgColor = '#2ecc71'
                                    }
                                    if (row.status === 'Pending') {
                                        bgColor = '#faab2d'
                                    }
                                    if (row.status === 'Denied') {
                                        bgColor = '#bf7bdb'
                                    }
                                    if (row.status === 'Cancelled') {
                                        bgColor = '#f56b5d'
                                    }
                                    if (row.status === 'Return') {
                                        bgColor = '#2c3e50'
                                    }
                                    if (row.status === 'Approved' && row.autogenerateCV == 0 && row.cv_req_id == null && row.req_apv_created !== null) {
                                        bgColor = '#7f8c8d'
                                        status = 'Ready for CV'
                                    }
                                    let details = JSON.parse(row.req_details)

                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    {details.map((valDetails, index2) => {
                                                        return <Typography variant={'p'} key={index2}>{String(valDetails.description).toLocaleUpperCase()}</Typography>
                                                    })
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant={'p'} >{formatNumber(row.req_quantity)}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <span style={{ paddingTop: 3, paddingBottom: 3, paddingRight: 5, paddingLeft: 5, background: bgColor, color: '#fff', borderRadius: 5 }}>
                                                    {status}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                            <Tooltip onClick={() => {
                                                setState(prev => ({ ...prev, actionButton: 'Details', selectedRequest: row, reqDetailsModal: true }));
                                            }} title='View'>
                                                <CallMadeIcon className={classes.iconSize} />
                                            </Tooltip>
                                            </TableCell>
                                       
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={totalRow}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </CardContent>
        </Card>

    );
}, (prevProps, nextProps) => {
    if (prevProps.refreshTransactionTable !== nextProps.refreshTransactionTable) {
        return false
    }
    return true
})
export default StickyHeadTable