// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    TextField,
    Divider,
    Icon
} from "@material-ui/core";
import { Breadcrumbs, Backdrop, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import {
    useHistory
  } from "react-router-dom";
let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    },
    textFieldStyle: {
        width: '100%',
        backgroundColor: '#f7fbfc'
    }
});

const ClientDetails = React.memo((props) => {
    const history = useHistory()
    const { getInitialData } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const onBack =()=>{
        history.push('/clientMasterList')
    }
    return (
        <Grid container spacing={1}>
            <Grid container item xs={6} md={6} justify='flex-start'>
                <Typography variant="h5" style={{ color: '#2c7f84' }}>Client Details</Typography>
            </Grid>
            <Grid container item xs={6} md={6} justify='flex-end'>
                <Button onClick={onBack} style={{ backgroundColor: '#2c6164', color: '#fff' }} startIcon={<Icon style={{ fontSize: 18 }}>arrow_back_ios</Icon>} variant="contained" >Back</Button>
            </Grid>
            <Grid item xs={12} md={12} >
                <Divider />
            </Grid>
            <Grid container item xs={12} md={12} justify='flex-start'>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container spacing={1}>

                            <Grid container justify={'flex-start'} item xs={12} md={12}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Client Name</Typography>
                                <TextField readOnly value={selectedClient.client_name} name='client_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={8}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Branch</Typography>
                                <TextField readOnly value={selectedClient.client_name} name='client_branch_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={4}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Contact Person</Typography>
                                <TextField readOnly value={selectedClient.contact_person} name='contact_person' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={4}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Contact Number</Typography>
                                <TextField readOnly value={selectedClient.contact_number} name='contact_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={8}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Address</Typography>
                                <TextField readOnly value={selectedClient.address} name='address' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={4}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">TIN</Typography>
                                <TextField readOnly value={selectedClient.tin} name='tin' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={8}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Assigned Company</Typography>
                                <TextField readOnly value={selectedClient.company_name} name='tin' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
})
export default ClientDetails;
