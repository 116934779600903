import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSelector, useDispatch} from "react-redux";
import { getData } from "../../api/api";
import EditIcon from '@material-ui/icons/Edit';
import {
    HashRouter as Router,
    BrowserRouter,
    Route,
    useParams,
    Redirect,
    Link as NewLink,
    useHistory
} from "react-router-dom";
let width = window.innerWidth;

const columns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },


];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});
const SubContent = ({child}) => {
    let history = useHistory()
    const dispatch = useDispatch()
    return (
        <TableRow role="checkbox">
            <TableCell style={{ borderBottomColor: 'transparent' }}>
            </TableCell>
            <TableCell style={{ borderBottomColor: 'transparent', textAlign: 'left' }}>
            <EditIcon  style={{color:'#16a085',fontSize:16,cursor:'pointer'}} onClick={()=>{
                     dispatch({
                        type: 'onChangeChartOFAccountsReducer',
                        data: { selected_type:child.group_type,actionStatus:'Edit',selectedValue:child}
                    })
                    let route = '/addBalanceSheetGroup'
                    if(child.data_type === 'Account'){
                        route = '/addChartOfAccounts'
                    }
                    history.push(route)

                }}/> <Typography variant='p' style={{ fontWeight: child.data_type === 'Group' ? 'bold' : undefined }}>{child.balance_sheet_group_name}</Typography>
                {child.child.map((val2, index2) => {
                    return <SubContent child={val2} />
                })
                }
            </TableCell>
        </TableRow>
    )
}

export default function StickyHeadTable({ type }) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const dispatch = useDispatch()

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const chartoFAccountsReducer = useSelector((state) => state.ChartoFAccountsReducer);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [state, setState] = React.useState({
        groupList: [],
    })
    React.useEffect(() => {
        let data = { company_id: chartoFAccountsReducer.selected_company_id, type: type }
        getData('aam/balance_sheet_group', data).then((res) => {
            if (type === 'Balance Sheet') {
                dispatch({
                    type: 'onChangeChartOFAccountsReducer',
                    data: { groupList: res.data, groupListDisplay: res.res }
                })
             
            }else{
                dispatch({
                    type: 'onChangeChartOFAccountsReducer',
                    data: { groupList2: res.data, groupListDisplay2: res.res }
                })
              
            }
        })
    }, [chartoFAccountsReducer.selected_company_id])
    let groupListDisplay = type === 'Balance Sheet'? chartoFAccountsReducer.groupListDisplay:chartoFAccountsReducer.groupListDisplay2
    return (
        <Grid container spacing={1}>
            <Grid container justify='Flex-start' item xs={12} md={12}>
                <Typography variant="h6" style={{ color: '#2c7f84' }}>{type}</Typography>
            </Grid>
            <Grid container justify='Flex-start' item xs={12} md={3}>
                <Button  onClick={()=>{
                    
                     dispatch({
                        type: 'onChangeChartOFAccountsReducer',
                        data: { selected_type:type,actionStatus:'Add',selectedValue:[]}
                    })
                }} component={NewLink} to='/addBalanceSheetGroup' style={{ backgroundColor:  '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />}>Group</Button>

            </Grid>
            <Grid container justify='Flex-start' item xs={12} md={3}>

                <Button onClick={()=>{
                     dispatch({
                        type: 'onChangeChartOFAccountsReducer',
                        data: { selected_type:type,actionStatus:'Add',selectedValue:[]}
                    })
                }} component={NewLink} to='/addChartOfAccounts' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />}>Account</Button>
            </Grid>
            <Grid item xs={12} md={12}>
                <Paper className={classes.root} variant='outlined'>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))} */}
                                    <TableCell

                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    >
                                        { }
                                    </TableCell>

                                    <TableCell

                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    >
                                        Name
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupListDisplay.map((val, index) => {
                                    return <TableRow hover role="checkbox" key={index}>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell>
                                           <Typography variant='p' style={{ fontWeight: 'bold' }}>{val.balance_sheet_group_name}</Typography>
                                            {val.child.map((val2, index) => {
                                                return <SubContent child={val2} />
                                            })
                                            }
                                        </TableCell>
                                    </TableRow>
                                })

                                }
                                {/* {chartoFAccountsReducer.groupListDisplay.map((val, index) => {
                                    return <TableRow hover role="checkbox" key={index}>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell>
                                            <Typography variant='p' style={{ fontWeight: 'bold' }}>{val.balance_sheet_group_name}</Typography>
                                            {val.sub_group_balance.map((val2, index2) => {
                                                return <TableRow >
                                                    <TableCell style={{ borderBottomColor: '#fff' }}>
                                                    </TableCell>
                                                    <TableCell style={{ borderBottomColor: '#fff' }}>
                                                    <Typography variant='p' style={{ fontWeight: 'bold' }}>{val2.sub_balance_sheet_group_name}
                                                    </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            })
                                            }
                                        </TableCell>
                                    </TableRow>
                                })

                                } */}
                                {/* {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </Grid>
        </Grid >

    );
}