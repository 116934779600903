import { CardContent, Card, Typography, Tooltip } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
const useStyles = makeStyles({
  root: {
    width: "100%",
    // overflowX: "auto",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
    zIndex: 22,
    // padding: 10,
  },
  cardFontCount: {
    fontSize: 30,
    // fontWeight: "bold",
    color: "#fff",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps.count) === JSON.stringify(nextProps.count);
};
const CardStatus = (props) => {
  const { ...param } = props;
  const classes = useStyles();
  return (
    <div>
      <Card
        style={{
          background: param.color,
          cursor: "pointer",
          position: "relative",
        }}
        variant="outlined"
        onClick={param.onClick}
      >
        <div style={{ position: "absolute", top: 0, left: -20 }}>
          {param.icon}
        </div>
        {/* <CardContent> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            padding: 10,
          }}
        >
          <Typography className={classes.cardFontCount}>
            {param.count}
          </Typography>
          <Typography className={classes.cardFont}>{param.type}</Typography>
        </div>

        {/* </CardContent> */}
      </Card>
    </div>
  );
};

export default React.memo(CardStatus, renderEqualProps);
