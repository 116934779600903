import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { CardContent, Card, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Icon, AppBar, Toolbar, Divider } from '@material-ui/core';
import { getData } from "../api/api"
import { useSelector, useDispatch } from "react-redux";
import CallMadeIcon from '@material-ui/icons/CallMade';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    HashRouter as Router,
    Route,
    Redirect, useHistory
} from "react-router-dom";
import AddClient from '../client/addingClient'
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from '@material-ui/icons/Refresh';
let width = window.innerWidth;
const columns = [


    {
        id: 'client_name',
        label: 'Client Name',

        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'client_branch_name',
        label: 'Branch',
        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'company_name',
        label: 'Assigned Company',
        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'client_contact_person',
        label: 'Contact Person',

        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'contact_no',
        label: 'Contact No.',

        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'client_address',
        label: 'Address',

        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'tin',
        label: 'TIN',
        align: 'left',
        format: (value) => String(value).toLocaleUpperCase(),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}
const MultiButton = (props) => {
    const dispatch = useDispatch()
    const { data, openEditModal } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const history = useHistory()
    const handleClose = (option) => {
        if (option === 'Edit') {
            dispatch({
                type: 'onChangeClientReducer',
                data: {
                    client_name: data.client_name,
                    contact_person: data.client_contact_person,
                    contact_number: data.contact_no,
                    address: data.client_address,
                    client_id: data.client_id,
                    actionType: 'Edit',
                    company_id: data.company_id,
                    client_branch_name: data.client_branch_name,
                    tin: data.tin
                }
            })
            openEditModal()
        } else if (option === 'View') {
            dispatch({
                type: 'onChangeClientReducer',
                data: {
                    selectedClient: data
                }
            })
            history.push('/clientDetails')
        }

        setAnchorEl(null);
    }
    return <div>
        <div
            type='button'
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <MoreVertIcon style={{ color: '#2c6164' }} />
        </div>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option.type)}>
                    <Icon style={{ marginRight: 10, color: '#2c6164' }}>{option.icon}</Icon>
                    {option.type}
                </MenuItem>
            ))}
        </Menu>
    </div>
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 390,
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    margin: {
        marginTop: 90
    }
});

const options = [
    { type: 'View', icon: 'launched' },
    { type: 'Edit', icon: 'edit' },
];

const ITEM_HEIGHT = 48;
const StickyHeadTable = React.memo(forwardRef((props, ref) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dispatch = useDispatch()
    const actionType = useSelector(state => state.ClientReducer.actionType)
    const [state, setState] = React.useState({
        addModal: false,
        searchDriver: '',
        clientList: [],
        clientListDisplay: []
    })
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const getClients = () => {
        let data = {
            user_id: localStorage.getItem('u')
        }
        getData('aam/getClients', data).then((res) => {
            setState(prev => ({
                ...prev,
                clientList: res.data,
                clientListDisplay: res.data
            }))
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }
    React.useEffect(() => {
        getClients()
    }, [])
    useImperativeHandle(ref, () => ({
        get_nitial_data() {
            getClients()
        },
    }));
    const openAddModal = () => {
        setState(prev => ({
            ...prev,
            addModal: true
        }))
        dispatch({
            type: 'onChangeClientReducer',
            data: {
                actionType: 'Add',
                client_name:'',
                contact_person:'',
                contact_number:'',
                client_branch_name:'',
                address:'',
                tin:'',
                company_id:'',
                client_id:''
            }
        })
    }
    const openEditModal = () => {
        setState(prev => ({
            ...prev,
            addModal: true
        }))
    }
    const onChangeText = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const onSearch = (type) => {
        let clientList = state.clientList
        if (type == 'Find') {
            clientList = state.clientList.filter((files) => {
                return (
                    (files?.client_name != null && files?.client_name != '') && files?.client_name
                        .toLowerCase()
                        .indexOf(state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    (files?.client_branch_name != null && files?.client_branch_name != '') && files?.client_branch_name.toLowerCase()
                        .indexOf(state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    (files?.tin != null && files?.tin != '') && files?.tin.toLowerCase()
                        .indexOf(state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    (files?.client_contact_person != null && files?.client_contact_person != '') && files?.client_contact_person.toLowerCase()
                        .indexOf(state.searchDriver.toLocaleLowerCase()) !== -1 ||
                    (files?.client_address != null && files?.client_address != '') && files?.client_address.toLowerCase()
                        .indexOf(state.searchDriver.toLocaleLowerCase()) !== -1
                );
            });
            setState((prev) => ({
                ...prev,
                clientListDisplay: clientList
            }))
        } else {
            setState((prev) => ({
                ...prev,
                clientListDisplay: clientList,
                searchDriver: ''
            }))
        }
    }
    return (
        <Grid container spacing={1}>
            <Grid container item xs={12} md={6} justify='flex-start'>
                <Typography variant="h5" style={{ color: '#2c7f84' }}>Client List</Typography>
            </Grid>
            <Grid container justify='flex-end' item xs={12} md={6}>
                <Button onClick={openAddModal} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<Icon>add</Icon>}>New Client</Button>
            </Grid>
            <Grid item xs={12} md={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} md={6} justify='flex-start'>
                <Card variant='outlined' style={{ width: 230, padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input value={state.searchDriver} placeholder="Search" style={{ borderStyle: 'none', outline: 'none' }} name='searchDriver' onChange={onChangeText} />
                    <SearchIcon style={{ cursor: 'pointer', color: '#787f80' }} onClick={() => onSearch('Find')} />
                    <RefreshIcon style={{ cursor: 'pointer', color: '#787f80' }} onClick={() => onSearch('Reset')} />
                </Card>
            </Grid>
            <Grid item xs={12} md={12}>
                <Paper className={classes.root} variant='outlined'>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap', width: 20 }}
                                    >
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.clientListDisplay.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell >
                                                <MultiButton data={row} openEditModal={openEditModal} />
                                            </TableCell>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align} style={{ whiteSpace: 'nowrap' }}>
                                                        {value != null ?column.format ? column.format(value) : value:''}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={state.clientListDisplay.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <Dialog fullScreen open={state.addModal} onClose={() => {
            }} >
                <AppBar className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            setState(prev => ({ ...prev, addModal: false }))
                        }} aria-label="close">
                            <Icon>close</Icon>
                        </IconButton>
                        <Typography variant="h6" >
                            {actionType == 'Add' ? 'Create' : actionType}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ display: 'flex', flex: 1, background: '#f5f7f7' }}>
                    <Grid container spacing={1} className={classes.margin}>
                        <Grid item xs={12} md={3}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AddClient getInitialData={getClients} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}))
export default StickyHeadTable