const initialState = {
    appNav : [],
    branches : [],
    companies : [],
    loginData : [],
    department : [],
    loading : false,
    socket:null,
    user:"",
    onRefresh:false,
    pendingReqForPayment:0,
    pendingCheckVoucher:0,
    pendingAPV:0,
    pendingCreateCV:0,
    accessButton:[],
    pendingNewSupplier:0
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'onChangeHomeReducer':
            return {
                ...state,
                ...action.data,
            }
        default:
            return state;
    }

}
export default dataReducer; 