import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import { TableRow, Grid, Button, Typography, TextField, Menu, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getData } from '../../api/api';
import { useSelector, useDispatch } from "react-redux";
import PositionedSnackbar from "../../alert";
import MoreVertIcon from '@material-ui/icons/MoreVert';
let width = window.innerWidth;
const ITEM_HEIGHT = 48;
const columns = [

    {
        id: 'service_name',
        label: 'Service Name',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'contract_no',
        label: 'Contract No.',
        format: (value) => (value),
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'unit',
        label: 'Type',
        format: (value) => String(value).toLocaleUpperCase(),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});
const options = [
    'Remove'
];

const MultiButton = (props) => {
    const dispatch = useDispatch()
    const { data, setRefresh } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (option) => {
        if (option === 'Remove') {
            let details = {
                client_branch_contract_id: data.client_branch_contract_id
            }
            if (window.confirm('Are you sure you want to remove this contract?')) {
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: true,
                    }
                })
                getData('aam/removeClientBranchContract', details).then((res) => {
                    if (res.res == true) {
                        dispatch({
                            type: 'onChangeClientReducer',
                            data: {
                                alert: true,
                                alertMessage: "Success , Contract has been remove.",
                                alertColor: '#2ecc71'
                            }
                        })
                        setRefresh()
                    }
                    dispatch({
                        type: 'onChangeHomeReducer',
                        data: {
                            loading: false,
                        }
                    })

                })
            }

        }

        setAnchorEl(null);
    }
    return <div>
        <div
            type='button'
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <MoreVertIcon />
        </div>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option)}>
                    {option}
                </MenuItem>
            ))}
        </Menu>
    </div>
}

export default function ContractList() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [refresh, setRefresh] = React.useState(false);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const serviceList = useSelector(state => state.ClientBranchReducer.serviceList)
    const selectedServiceId = useSelector(state => state.ClientBranchReducer.selectedServiceId)
    const selectedClientBranch = useSelector(state => state.ClientBranchReducer.selectedClientBranch)
    const contractList = useSelector(state => state.ClientBranchReducer.contractList)

    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)

    const dispatch = useDispatch();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        dispatch({
            type: 'onChangeClientBranchReducer',
            data: {
                selectedServiceId: ''
            }
        })
    };
    React.useEffect(() => {
        getContractDetails()
    }, [])

    const getContractDetails = () => {
        let data = {
            client: selectedClient.client_id
        }
        getData('aam/getContractDetails', data).then((res) => {
            dispatch({
                type: 'onChangeClientBranchReducer',
                data: {
                    serviceList: res.res
                }
            })
        })

    }
    const onChangeTextPayee = (e, values) => {
        let service_id = values.service_id
        dispatch({
            type: 'onChangeClientBranchReducer',
            data: {
                selectedServiceId: service_id,
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        let data = {
            service_id: selectedServiceId,
            client_id: selectedClient.client_id,
            client_branch_id: selectedClientBranch.client_branch_id,
            user_id: localStorage.getItem('u')
        }
        if (selectedServiceId == '') {
            dispatch({
                type: 'onChangeClientReducer',
                data: {
                    alert: true,
                    alertMessage: "Warning , Please select contract.",
                    alertColor: '#f39c12'
                }
            })
        } else {
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: true,
                }
            })
            getData('aam/insertAssignContract', data).then((res) => {
                let alertMessage = ''
                let alertColor = ''
                let alert = false
                if (res.res == true) {
                    alertMessage = "Success , Contract has been added"
                    alertColor = '#2ecc71'
                    alert = true
                    handleClose()
                } else if (res.res === 'Exist') {
                    alertMessage = "Warning , Contract is already exist"
                    alertColor = '#f39c12'
                    alert = true
                }
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: false,
                    }
                })
                dispatch({
                    type: 'onChangeClientReducer',
                    data: {
                        alert: alert,
                        alertMessage: alertMessage,
                        alertColor: alertColor
                    }
                })
                dispatch({
                    type: 'onChangeClientBranchReducer',
                    data: {
                        selectedServiceId: ''
                    }
                })

                setRefresh(!refresh)

            })
        }

    }

    React.useEffect(() => {
        let data = {
            client_branch_id: selectedClientBranch.client_branch_id
        }
        getData('aam/getClientContract', data).then((res) => {
            dispatch({
                type: 'onChangeClientBranchReducer',
                data: {
                    contractList: res.res
                }
            })
        })
    }, [refresh])

    return (
        <Grid container spacing={1}>
            <PositionedSnackbar open={alert} vertical={'top'} horizontal={'center'} alertMessage={alertMessage} alertColor={alertColor} handleClose={() => {
                dispatch({
                    type: 'onChangeClientReducer',
                    data: { alert: false }
                })
            }} />
            <Grid container justify='flex-start' item xs={12} md={12}>
                <Button onClick={handleClickOpen} variant="contained" type='button' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }}>Add Contract</Button>
            </Grid>
            <Grid item xs={12} md={12}>
                <Paper className={classes.root} variant='outlined'>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#fff' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap', width: 20 }}
                                    >
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contractList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell >
                                                <MultiButton data={row} setRefresh={() => setRefresh(!refresh)} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={contractList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Select Contract"}</DialogTitle>
                <form onSubmit={onSubmit}>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                {/* <Typography style={{ fontWeight: 'bold' }} variant="p">Service</Typography> */}
                                <Autocomplete
                                    required
                                    id="combo-box-demo"
                                    size="small"
                                    options={serviceList}
                                    getOptionLabel={(option) => option.contract_no + ' - ' + String(option.service_name).toLocaleUpperCase()}
                                    onChange={onChangeTextPayee}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => <TextField size="small" {...params} variant="outlined" />}
                                />

                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button type='button' style={{ backgroundColor: '#7f8c8d', color: '#fff' }} onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff' }} color="primary" autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </Grid>

    );
}