// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    FormControl,
    Select,
    InputLabel,
    Button,
    MenuItem
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";  
import Loading from '../../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { getData } from "../../api/api"
import PositionedSnackbar from '../../alert'
import axios from "axios"
let width = window.innerWidth;

function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
const useStyles = makeStyles({
    root: {
        // display: 'flex',
        // flex: 1
    },
    container: {
        maxHeight: 440,
    },
    textFieldStyle: {
        width: '100%',
        
    }
});

function AddSupplierForm({refresh}) {
    const classes = useStyles()
    const dispatch = useDispatch();

    const homeReducer = useSelector(state => state.HomeReducer)
    const [state, setState] = React.useState({
        supplier_name:'',
        supplier_address:'',
        contact_person:'',
        contact_number:'',
        file:[]
    })
    const onChangeText = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const onSubmit = (e) =>{
        e.preventDefault()
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let data2 = {
            user_id : localStorage.getItem('u'),
            supplier_name : String(state.supplier_name).toLocaleUpperCase(),
            supplier_address : String(state.supplier_address).toLocaleUpperCase(),
            supplier_contact_person : String(state.contact_person).toLocaleUpperCase(),
            supplier_contact_no : state.contact_number,
        }
        let data = {
            payee:'',
            payee_id: '',
            company_name: '',
            detailsRow: [data2],
            branch_name: '',
            branch_id: homeReducer.loginData.branch_id,
            company_id: homeReducer.loginData.company_id,
            type: "Request New Supplier",
            type_name: 'Request New Supplier',
            user_id: localStorage.getItem('u'),
            department: '',
        }
        const formData = new FormData();
        for (let i = 0; i < state.file.length; i++) {
            formData.append('file' + i, state.file[i])
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append("data", JSON.stringify(data));
        axios.post("https://api.workflow.gzonetechph.com/aam/createRequest", formData, config)
            .then((response) => {
                if(response.data.result === 'Exist'){
                    setState(prev => ({
                        ...prev,
                        alert: true,
                        alertMessage: "Warning, Supplier is already exist",
                        alertColor: '#f39c12'
                    }))
                }else if(response.data.result === 'Success'){
                    setState(prev => ({
                        ...prev,
                        alert: true,
                        alertMessage: "Success, Details has been added",
                        alertColor: '#2ecc71',
                        supplier_name : '',
                        // supplier_address : '',
                        // contact_person : '',
                        // contact_number : '',
                    }))
                }
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: false,
                    }
                })

            })
    }
 
    return (
        <div style={{ marginTop: 90,padding:10 }}>
             <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card elevation={4}>
                        <CardContent>
                            <form onSubmit={onSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h4" style={{ color: '#2c7f84' }}>Supplier Details</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Company</Typography>
                                        <FormControl variant={'outlined'} className={classes.textFieldStyle} size='small'>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='company_name'
                                                value={state.company_name}
                                                onChange={onChangeText}
                                            >
                                                {homeReducer.companies.map((val, index) => {
                                                    return <MenuItem key={index} value={val.company_name}>{val.company_name}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                                    
                                    <Grid item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Register Business Name</Typography>
                                        <TextField required onChange={onChangeText}  value={state.supplier_name} name='supplier_name' variant='outlined' size='small' className={classes.textFieldStyle} />

                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Address</Typography>
                                        <TextField required onChange={onChangeText} value={state.supplier_address} name='supplier_address' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Contact Person</Typography>
                                        <TextField required  onChange={onChangeText}  value={state.contact_person} name='contact_person' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Contact No.</Typography>
                                        <TextField required type='number'  onChange={onChangeText} value={state.contact_number} name='contact_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid container item xs={12} md={12} justify='flex-end'>
                                        <Button type='submit'  style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} md={3}>

                </Grid>

            </Grid>
        </div>



    );
}
export default React.memo(AddSupplierForm, renderPropsAreEqual);
