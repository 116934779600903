// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    TextField,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Button,
    Menu
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import PositionedSnackbar from "../../alert";
import {
    HashRouter as Router,
    Route,
    Redirect, useHistory
} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    },
    textFieldStyle: {
        width: '100%'
    }
});

let width = window.innerWidth;
const BranchForm = React.memo(() => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const clientBranchAddress = useSelector(state => state.ClientBranchReducer.clientBranchAddress)
    const clientBranchName = useSelector(state => state.ClientBranchReducer.clientBranchName)
    const businessCenter = useSelector(state => state.ClientBranchReducer.businessCenter)
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    const actionType = useSelector(state => state.ClientBranchReducer.actionType)
    const selectedClientBranch = useSelector(state => state.ClientBranchReducer.selectedClientBranch)
    const branchList = useSelector((state) => state.BillingReducer.branchList);
    const branch_id = useSelector((state) => state.ClientBranchReducer.branch_id);
    const branches = useSelector((state) => state.HomeReducer.branches);

    const history = useHistory()

    const onChangeText = (e) => {
        let value = e.target.value
        let name = e.target.name
        dispatch({
            type: 'onChangeClientBranchReducer',
            data: {
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let data = {
            client_branch_address: clientBranchAddress,
            client_branch_name: clientBranchName,
            user_id: localStorage.getItem('u'),
            client_branch_id: selectedClientBranch?.client_branch_id,
            client_id: selectedClient.client_id,
            actionType: actionType,
            assigned_branch_id:branch_id,
            business_center:businessCenter,
        }
        getData('aam/insertClientBranch', data).then((res) => {
            let alertMessage = ''
            let alertColor = ''
            let alert = false
            if (res.res == true) {
                // alertMessage = "Success , Branch details has been added"
                // alertColor = '#2ecc71'
                dispatch({
                    type: 'onChangeClientBranchReducer',
                    data: {
                        clientBranchAddress: "",
                        clientBranchName: "",
                        branch_id:""
                    }
                })
                // setTimeout(()=>{
                history.push('/clientDetails')
                // },1000)

            } else if (res.res === 'Exist') {

                alertMessage = "Warning , Service name is already Exist"
                alertColor = '#f39c12'
                alert = true

            }
            dispatch({
                type: 'onChangeClientReducer',
                data: {
                    alert: alert,
                    alertMessage: alertMessage,
                    alertColor: alertColor
                }
            })

            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }
    const onChangeBranch = (e) => {
        let branch_id = e.target.value
        dispatch({
            type: 'onChangeClientBranchReducer',
            data: {
                branch_id: branch_id,
            }
        })
    }
    React.useEffect(()=>{
        const branchesFilter = branches.filter((val) => (val.company_id == selectedClient.company_id))
        dispatch({
            type: 'onChangeBilling',
            data: {
                branchList: branchesFilter,
            }
        })
    },[])

    return (
        <div>
            <PositionedSnackbar open={alert} vertical={'top'} horizontal={'center'} alertMessage={alertMessage} alertColor={alertColor} handleClose={() => {
                dispatch({
                    type: 'onChangeClientReducer',
                    data: { alert: false }
                })
            }} />
            <form onSubmit={onSubmit}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid container item xs={12} md={12} justify='flex-start'>
                                <Typography variant="h5" style={{ color: '#2c7f84' }}>{actionType} Branch</Typography>
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={12}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Branch Name</Typography>
                                <TextField onChange={onChangeText} value={clientBranchName} required name='clientBranchName' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={12}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Address</Typography>
                                <TextField placeholder="Optional" onChange={onChangeText} multiple minRows={4} value={clientBranchAddress} name='clientBranchAddress' variant='outlined' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={12}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Assigned Branch</Typography>
                                <FormControl variant={'outlined'} style={{ width: '100%' }} size='small'>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        style={{ textAlign: 'left'}}
                                        value={branch_id ?? branch_id}
                                        onChange={onChangeBranch}
                                    >
                                        {branchList.map((val, index) => {
                                            return <MenuItem key={index} value={val.branch_id}>{val.branch_name}</MenuItem>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={12}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Business Center</Typography>
                                <TextField onChange={onChangeText} value={businessCenter} name='businessCenter' variant='outlined' size='small' className={classes.textFieldStyle} />
                            </Grid>
                            <Grid container justify={'flex-start'} item xs={12} md={8}></Grid>
                            <Grid container justify={'flex-end'} item xs={12} md={2}>
                                {actionType === 'Edit' ?
                                    <Button onClick={() => {
                                        dispatch({
                                            type: 'onChangeClientBranchReducer',
                                            data: {
                                                actionType: 'Add',
                                                clientBranchAddress: '',
                                                clientBranchName: '',
                                            }
                                        })
                                        history.push('/clientDetails')
                                    }} type='buttom' style={{ backgroundColor: '#7f8c8d', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >{'Cancel'}</Button>
                                    : undefined

                                }

                            </Grid>
                            <Grid container justify={'flex-end'} item xs={12} md={2}>
                                <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >{actionType}</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </div>


    );
}, (prevProps, nextProps) => {
    if (prevProps !== nextProps) {
        return false
    }
    return true
})
export default BranchForm;
