// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    MenuItem, Menu
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import {
    HashRouter as Router,
    BrowserRouter,
    Route,
    useParams,
    Redirect,
    Link as NewLink
} from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import BillingList from './billingList'
import AddBilling from './addBilling'
import PositionedSnackbar from "../../alert";
import CardStatus from "./cardStatus"
import { BillingProvider } from "./billingContext";
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import Received from '../../../assets/received.png'
let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function BillingDetails() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    const selectedBillingInvoice = useSelector(state => state.BillingReducer.selectedBillingInvoice)
    const branches = useSelector((state) => state.HomeReducer.branches);
    const selectedService = useSelector((state) => state.BillingReducer.selectedService);
    const company_match = branches.filter((val) => (val.company_id == selectedBillingInvoice.company_id))
    const [anchorEl, setAnchorEl] = React.useState(null);
    let company_logo = ''
    if (company_match.length > 0) {
        let split = String(company_match[0].company_logo).split('/')
        company_logo = split[split.length - 1]
    }
    let bru = ''
    let business_center = false
    let details = JSON.parse(selectedBillingInvoice.req_details)
    for (let index = 0; index < details.length; index++) {
        const element = details[index];
        let new_bru = element.client_branch_name
        if (element.business_center != '') {
            new_bru = element.business_center
            business_center = true
        }
        bru += new_bru
        if (index < details.length - 1) {
            bru += ', '
        }
    }

    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };

    const onCancelBilling = () => {
        if (window.confirm('Are you sure you want to cancelled this Billing Invoice')) {
            let data = {
                user_id: localStorage.getItem('u'),
                req_id: selectedBillingInvoice.req_id,
                status: 'Cancelled'
            }
            getData('aam/onCancelBilling', data).then((res) => {

            })
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onDownload = (val) => {
        const link = document.createElement("a");
        link.download = val;
        link.href = "/http://192.168.2.85/api/assets/"+val;
        link.click();
      };

    return (

        <Grid container spacing={1}>
           
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={10}>
                <Grid container spacing={1}>
                    <Grid container justify='flex-end' item xs={12} md={12}>
                        <Button onClick={()=>{
                            window.open("http://192.168.2.85/api/report/printBillingRequests/"+selectedBillingInvoice.req_id);
                        }}  style={{ fontWeight: 'bolder', fontSize: 13, backgroundColor: '#2980b9', color: '#fff', marginRight: width > 600 ? 10 : undefined }} startIcon={<PrintIcon />}>{String('Print').toLocaleUpperCase()}</Button>
                        <Button onClick={handleClick} style={{ fontWeight: 'bolder', fontSize: 13, backgroundColor: '#3d7f85', color: '#fff', marginRight: width > 600 ? 10 : undefined }}>{String('ATTACHMENT(S)').toLocaleUpperCase()}</Button>
                        {selectedBillingInvoice.status === 'Pending' ?
                            <Button onClick={() => onCancelBilling()} style={{ fontWeight: 'bolder', fontSize: 13, backgroundColor: '#e74c3c', color: '#fff' }}>{String('Cancel').toLocaleUpperCase()}</Button>
                            : undefined
                        }

                    </Grid>
                </Grid>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {JSON.parse(selectedBillingInvoice.req_attachments).map((val, index) => {
                        return <MenuItem key={index} onClick={()=>onDownload(val)}> <Typography variant='p'>{val}</Typography>
                            <IconButton  >
                                <GetAppIcon style={{ color: '#2c6164' }} />
                            </IconButton>
                        </MenuItem>
                    })

                    }


                </Menu>
                <div style={{ marginTop: 10 }} />
                <Card variant='outlined'>
                    <CardContent >
                        <div style={{ paddingRight: 50, paddingLeft: 50 }}>
                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={12}>
                                    <div style={{ padding: 10, background:selectedBillingInvoice.status === 'Received' ? '#2980b9' : selectedBillingInvoice.status === 'Cancelled' ? '#e74c3c' : moment(selectedBillingInvoice.invoice_due).format('MMMM DD, YYYYY') < moment(new Date()).format('MMMM DD, YYYYY') ? '#8e44ad' : '#f39c12', display: 'flex', alignItems: 'center' }}>
                                        <Typography style={{ color: '#fff', fontWeight: 'bold' }}>
                                            {selectedBillingInvoice.status === 'Recieved' ? selectedBillingInvoice.status : moment(selectedBillingInvoice.invoice_due).format('MMMM DD, YYYYY') < moment(new Date()).format('MMMM DD, YYYYY') ?
                                                'Overdue Payment'
                                                :
                                                selectedBillingInvoice.status
                                            }
                                        </Typography>

                                    </div>
                                </Grid>

                            </Grid>
                            <div style={{ marginTop: 20 }} />
                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={6}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img style={{ width: 50, height: 50, marginRight: 10 }} src={'https://images.workflow.gzonetechph.com/company_logo/' + company_logo} />
                                        <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 20 }}>{String(selectedBillingInvoice.req_company_name).toLocaleUpperCase()}</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <div style={{ height: '100%', width: 2, backgroundColor: '#3d7f85' }}>
                                    </div>
                                </Grid>
                                <Grid container justify='flex-start' item xs={12} md={5}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85', textAlign: 'left' }}>{String(selectedBillingInvoice.req_branch_address).toLocaleUpperCase()}</Typography>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85', textAlign: 'left' }}>{'Tel. No. (043) 300-4338 / 723-6288 / 722-0437'}</Typography>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85', textAlign: 'left' }}>{'VAT Reg. TIN 128-3-16-587'}</Typography>
                                </Grid>
                            </Grid>
                            <div style={{ marginTop: 20 }} />
                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={6}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 30, color: '#3d7f85' }}>{String('SERVICE INVOICE').toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-end' item xs={12} md={6}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 15, }}>{"INVOICE NO. :" + " " + selectedBillingInvoice.req_reference_no}</Typography>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginTop: 5, height: 35, width: '50%', background: '#3d7f85', }} />
                                <div style={{ marginTop: 5, height: 35, width: '50%', background: '#5dbec7', }} />
                            </div>
                            <div style={{ marginTop: 20 }} />

                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={2}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('CLIENT').toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-start' item xs={12} md={5}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {String(selectedBillingInvoice.client_name).toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-end' item xs={12} md={5}>
                                    <Grid container spacing={1}>
                                        <Grid container justify='flex-start' item xs={12} md={4}>
                                            <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('DATE').toLocaleUpperCase()}</Typography>
                                        </Grid>
                                        <Grid container justify='flex-start' item xs={12} md={8}>
                                            <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {moment(selectedBillingInvoice.req_date_added).format('MMMM DD, YYYYY')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={2}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('ADDRESS').toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-start' item xs={12} md={5}>
                                    <Typography variant='p' style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: 13, }}>: {String(selectedBillingInvoice.client_address).toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-end' item xs={12} md={5}>
                                    <Grid container spacing={1}>
                                        <Grid container justify='flex-start' item xs={12} md={4}>
                                            <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('INVOICE DUE').toLocaleUpperCase()}</Typography>
                                        </Grid>
                                        <Grid container justify='flex-start' item xs={12} md={8}>
                                            <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {moment(selectedBillingInvoice.invoice_due).format('MMMM DD, YYYYY')}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={2}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('TIN #').toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-start' item xs={12} md={10}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {String(selectedBillingInvoice.tin).toLocaleUpperCase()}</Typography>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginTop: 10, height: 2.5, width: '50%', background: '#3d7f85', }} />
                                <div style={{ marginTop: 10, height: 2.5, width: '50%', background: '#5dbec7', }} />
                            </div>
                            <div style={{ marginTop: 20 }} />

                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={12}>
                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 15, color: '#3d7f85' }}>{String('DESCRIPTION').toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid container justify='flex-start' item xs={12} md={12}>
                                    <Card variant='outlined' style={{ width: '100%' }}>
                                        <CardContent>
                                            <Grid container spacing={1}>
                                                <Grid container justify='flex-start' item xs={12} md={3}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('SERVICE DELIVERED').toLocaleUpperCase()}</Typography>
                                                </Grid>
                                                <Grid container justify='flex-start' item xs={12} md={9}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {String(selectedBillingInvoice.service_name).toLocaleUpperCase()}</Typography>
                                                </Grid>
                                                <Grid container justify='flex-start' item xs={12} md={3}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('CONTRACT NO.').toLocaleUpperCase()}</Typography>
                                                </Grid>
                                                <Grid container justify='flex-start' item xs={12} md={9}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {String(selectedBillingInvoice.service_contract_no).toLocaleUpperCase()}</Typography>
                                                </Grid>

                                                <Grid container justify='flex-start' item xs={12} md={3}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String(business_center ? 'Business Center' : 'BRU SERVED').toLocaleUpperCase()}</Typography>
                                                </Grid>
                                                <Grid container justify='flex-start' item xs={12} md={9}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {String(bru).toLocaleUpperCase()}</Typography>
                                                </Grid>
                                                <Grid container justify='flex-start' item xs={12} md={3}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, color: '#3d7f85' }}>{String('PERIOD COVERED').toLocaleUpperCase()}</Typography>
                                                </Grid>
                                                <Grid container justify='flex-start' item xs={12} md={9}>
                                                    <Typography variant='p' style={{ fontWeight: 'bolder', fontSize: 13, }}>: {moment(selectedBillingInvoice.req_date_start).format('MMM DD , YYYY') + ' - ' + moment(selectedBillingInvoice.req_date_end).format('MMM DD , YYYY')}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid container justify='flex-start' item xs={12} md={12}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>

                                                <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', width: 20 }}>BRANCH</TableCell>
                                                <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', width: 20, textAlign: 'right' }}>AMOUNT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {details.map((val, index) => {
                                                return <TableRow hover key={index}>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: 13 }}>{val.client_branch_name}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'right' }}>{formatNumber(val.total_amount)}</TableCell>
                                                </TableRow>
                                            })

                                            }

                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginTop: 20, height: 2.5, width: '50%', background: '#3d7f85', }} />
                                <div style={{ marginTop: 20, height: 2.5, width: '50%', background: '#5dbec7', }} />
                            </div>
                            <div style={{ marginTop: 20 }} />
                            <Grid container spacing={1}>
                                <Grid container justify='flex-start' item xs={12} md={12}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', width: 20 }}>Description</TableCell>
                                                <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', width: 20, textAlign: 'right' }}>AMOUNT</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow hover >
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13 }}>{'TOTAL AMOUNT BEFORE TAX'}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'right' }}>{formatNumber(selectedBillingInvoice.req_quantity)}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13 }}>{'ADD : ' + selectedBillingInvoice.vat_percent + '% VAT'}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'right' }}>{formatNumber(selectedBillingInvoice.vat_amount)}</TableCell>
                                            </TableRow>
                                            <TableRow hover style={{ backgroundColor: '#000' }}>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, color: '#fff' }}>{'GROSS AMOUNT'}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, color: '#fff', textAlign: 'right' }}>{formatNumber(selectedBillingInvoice.gross_amount)}</TableCell>
                                            </TableRow>
                                            <TableRow hover>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13 }}>{'LESS : ' + selectedBillingInvoice.with_holding_tax_percent + '% WITH HOLDING TAX'}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, textAlign: 'right' }}>{formatNumber(selectedBillingInvoice.with_holding_tax_amount)}</TableCell>
                                            </TableRow>
                                            <TableRow hover style={{ backgroundColor: '#000' }}>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, color: '#fff' }}>{'NET AMOUNT DUE'}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontSize: 13, color: '#fff', textAlign: 'right' }}>{formatNumber(selectedBillingInvoice.net_amount_due)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid container justify='flex-end' item xs={12} md={12}>
                                    <div style={{ background: '#3d7f85', padding: 10 }}>
                                        <Typography variant='p' style={{ color: '#fff', textAlign: 'left', fontWeight: 'bold' }}>TOTAL AMOUNT</Typography>
                                        <Typography variant='h4' style={{ fontWeight: 'bold', color: '#fff' }}>{formatNumber(selectedBillingInvoice.net_amount_due)}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={1}></Grid>

        </Grid>

    );
}
export default BillingDetails;
