import React, { useEffect } from "react";
import clsx from "clsx";
// import '../../src/App.css';
import {
  HashRouter as Router,
  BrowserRouter,
  Route,
  useParams,
  Redirect,
  Link as NewLink,
} from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { routes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import Navigation from "./drawer";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  List,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import DrawerData from "./drawer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Auth from "./authPage";
// import { io } from 'socket.io-client'
import { getData } from "../components/api/api";
import OneSignal from "react-onesignal";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LeakRemoveTwoTone } from "@material-ui/icons";
import { usePageVisibility } from "./utilities/visibility";
import Loading from ".././loading.gif";
import SettingsIcon from "@material-ui/icons/Settings";
import LockIcon from "@material-ui/icons/Lock";
import PositionedSnackbar from "../components/alert";

const drawerWidth = 210;
let width = window.innerWidth;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    // backgroundImage: `linear-gradient(to top, #95a5a6, #636e72)`,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#2c7f84",
    // backgroundImage: `linear-gradient(to right,#636e72,#5b6366)`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    width: "80%",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
let activateBackup = null;

let pageDetector = 0;
export default function LoginPg() {
  const isVisible = usePageVisibility();
  // const timerVal = useCurrentVisibilityTimer(isVisible);
  // const totalTimeVal = useTotalVisibilityTimer(isVisible);

  // Change the title based on page visibility
  if (isVisible) {
    document.title = "Active";
    pageDetector = "Active";
    if (activateBackup === false) {
      // so it will not triggered on initial render
      activateBackup = true;
    }
  } else {
    document.title = "Inactive";
    pageDetector = "Inactive";
    activateBackup = false;
  }

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(width > 600 ? true : false);
  const homeReducer = useSelector((state) => state.HomeReducer);
  const dispatch = useDispatch();
  const [user, setUser] = React.useState("Angelo");
  const [socket, setSocket] = React.useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const history = useHistory();
  const [notification, setNotification] = React.useState([]);
  const [state, setState] = React.useState({
    pendingReqForPayment: 0,
    accountPayableAccess: false,
    createCheckVoucherAccess: false,
    newSupplierRequestAccess: false,
    requestForPaymentAccess: false,
    checkVoucherAccess: false,
    changePasswordModal: false,
    current_password: "",
    new_password: "",
    confirm_password: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [settings, setSettings] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickSetting = (event) => {
    setSettings(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setSettings(null);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onesSignalFunction = (loginData) => {
    OneSignal.setExternalUserId(loginData.user_id);
    OneSignal.getExternalUserId().then(function (externalUserId) {
      let myInterval = setInterval(() => {
        onesSignalFunction(loginData);
      }, 1000);
      if (externalUserId !== "") {
        clearInterval(myInterval);
      }
    });
  };

  React.useEffect(() => {
    // if (window.location.href.split("/")[0] === "http:") {
    //     window.location.replace("https://accounting.gzonetechph.com/")
    //     setTimeout(() => {
    //         window.location.reload();
    //     }, 50);
    // }
    Promise.all([
      getData("audit/getappHomeNav", {
        app_id: 16,
        user_id: localStorage.getItem("u"),
      }),
      getData("users/getPrivilege2", localStorage.getItem("u")),
      getData("HumanResource/getHandleBranch", {
        user_id: localStorage.getItem("u"),
      }),
      // getData('aam/getRequestCRFToBeApprovedCount', { user_id: localStorage.getItem('u'), req_type: 'Request for Payment', app_id: 7 }),
      // getData('aam/getRequestCRFToBeApprovedCount', { user_id: localStorage.getItem('u'), req_type: 'Request for Payment', app_id: 7 }),
    ]).then((res) => {
      let appnav_priv = res[0].appHomeNav;
      let loginData = res[1].user_login_data[0];
      let company = [];
      let branch = res[2].response;
      let department = res[1].department;
      let accessButton = res[2].validation;

      appnav_priv.sort(function (a, b) {
        return a["parent_name"].localeCompare(b["parent_name"]);
      });

      branch.map((item) => {
        let match = false;
        company.map((val) => {
          if (val.company_name == item.company_name) {
            match = true;
          }
        });
        if (!match) {
          company.push({
            company_name: item.company_name,
            company_id: item.company_id,
          });
        }
      });

      branch.sort(function (a, b) {
        return a["branch_name"].localeCompare(b["branch_name"]);
      });
      // setSocket(io("https://socket.gzonetechph.com/"));
      // setSocket(io("https://socket.gzonetechph.com/"));

      dispatch({
        type: "onChangeHomeReducer",
        data: {},
      });
      OneSignal.init({
        appId: "834409ea-dedf-4d43-8850-cda86fcbc6b8",
        allowLocalhostAsSecureOrigin: true,
      });
      // OneSignal.removeExternalUserId()
      setTimeout(() => {
        onesSignalFunction(loginData);

        // OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        //     if (isEnabled) {
        //         console.log("Push notifications are enabled.");
        //         onesSignalFunction(loginData)
        //     }

        //     else
        //         console.log("Push notifications are not enabled yet.");
        // });
      }, 1000);
      // setState(prev => ({ ...prev, pendingReqForPayment: res[3].count }))
      let accountPayableAccess = false;
      let createCheckVoucherAccess = false;
      let newSupplierRequestAccess = false;
      let checkVoucherAccess = false;
      let requestForPaymentAccess = false;
      for (let index = 0; index < appnav_priv.length; index++) {
        const element = appnav_priv[index];
        if (element.parent_name === "Account Payable Voucher") {
          accountPayableAccess = true;
          // setState(prev => ({ ...prev, accountPayableAccess: true }))
        }
        if (element.parent_name === "Create Check Voucher") {
          createCheckVoucherAccess = true;
        }
        if (element.parent_name === "New Supplier Requests") {
          newSupplierRequestAccess = true;
        }
        if (element.parent_name === "Check Voucher") {
          checkVoucherAccess = true;
        }
        if (element.parent_name === "Requests") {
          requestForPaymentAccess = true;
        }
        setState((prev) => ({
          ...prev,
          accountPayableAccess: accountPayableAccess,
          createCheckVoucherAccess: createCheckVoucherAccess,
          newSupplierRequestAccess: newSupplierRequestAccess,
          checkVoucherAccess: checkVoucherAccess,
          requestForPaymentAccess: requestForPaymentAccess,
        }));
      }
      CountNotify(accountPayableAccess, createCheckVoucherAccess, branch);
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          appNav: appnav_priv,
          branches: branch,
          companies: company,
          loginData: loginData,
          department: department,
          // socket: io("https://socket.gzonetechph.com/"), user: loginData.user_id,
          accessButton: accessButton,
        },
      });
      //   OneSignal.getUserId(function (userId) {
      //     getData('aam/saveOneSingleID').then(()=>{

      //     })

      //   });
    });
  }, []);
  const firstUpdate = React.useRef(true);
  // React.useEffect(() => {
  //     if (firstUpdate.current) {
  //         firstUpdate.current = false;
  //         return;
  //     } else {
  //         if (homeReducer.socket?.connected) {
  //         } else {
  //             setSocket(io("https://socket.gzonetechph.com/"));
  //         }
  //     }

  // }, [activateBackup])

  // useEffect(() => {
  //     homeReducer.socket?.emit("newUser", homeReducer.user);
  // }, [homeReducer.socket, homeReducer.user]);

  // React.useEffect(() => {
  //     homeReducer.socket?.on("getNotification", data => {
  //         setNotification(prev => [...prev, data])
  //     })
  // }, [homeReducer.socket])

  // React.useEffect(() => {
  //     CountNotify(state.accountPayableAccess,state.createCheckVoucherAccess, homeReducer.branches)
  // }, [notification.length])
  const CountNotify = (
    accountPayableAccess,
    createCheckVoucherAccess,
    branch
  ) => {
    // Promise.all([
    //     getData('aam/getRequestCRFToBeApprovedCount', { user_id: localStorage.getItem('u'), req_type: 'Request for Payment', app_id: 7 }),
    //     getData('aam/getRequestCRFToBeApprovedCount', { user_id: localStorage.getItem('u'), req_type: 'Check Voucher', app_id: 7 }),
    //     getData('aam/getRequestCRFToBeApprovedCountAPV', { user_id: localStorage.getItem('u'), req_type: 'Request for Payment', app_id: 7 }),
    //     getData('aam/getRequestCRFToBeApprovedCountCreateCV', { user_id: localStorage.getItem('u'), req_type: 'Check Voucher', app_id: 7 }),
    //     getData('aam/getRequestCRFToBeApprovedCount', { user_id: localStorage.getItem('u'), req_type: 'Request New Supplier', app_id: 7 }),
    // ])
    let branches = branch.map((val) => val.branch_id);
    getData("aam/pendingRequestsAccounting", {
      user_id: localStorage.getItem("u"),
      req_type: "Request for Payment",
      app_id: 7,
      branches: branches,
    }).then((res) => {
      let apvCount = 0;
      let CreateCVCount = 0;

      if (accountPayableAccess) {
        apvCount = res.pendingAPV;
      }
      if (createCheckVoucherAccess) {
        CreateCVCount = res.pendingCreateCV;
      }

      dispatch({
        type: "onChangeHomeReducer",
        data: {
          onRefresh: !homeReducer.onRefresh,
          pendingReqForPayment: res.pendingReqForPayment,
          pendingCheckVoucher: res.pendingCheckVoucher,
          pendingAPV: apvCount,
          pendingCreateCV: CreateCVCount,
          pendingNewSupplier: res.pendingNewSupplier,
        },
      });
      setTimeout(() => {
        CountNotify(accountPayableAccess, createCheckVoucherAccess, branch);
      }, 60000);
    });
  };

  const logout = () => {
    localStorage.clear();
    // window.location.replace(websitelink);
    window.location.replace("https://accounting.gzonetechph.com/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const onChangeText = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    let data = {
      current_password: state.current_password,
      new_password: state.new_password,
      confirm_password: state.confirm_password,
      user_id: homeReducer.loginData.user_id,
    };
    if (state.new_password !== state.confirm_password) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Warning , Your password is not match!",
        alertColor: "#f39c12",
        confirm_password: "",
      }));
    } else {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/changePassword", data).then((res) => {
        if (res.type === "Current Password" && !res.res) {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Warning , Please check your current password!",
            alertColor: "#f39c12",
            current_password: "",
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Success , Your pasword has been changed.",
            alertColor: "#2ecc71",
            current_password: "",
            confirm_password: "",
            new_password: "",
            changePasswordModal: false,
          }));
          logout();
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  };
  return (
    <div className="App">
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Backdrop open={homeReducer.loading} style={{ zIndex: 9999999 }}>
        <div>
          {/* <div class="loadingio-spinner-spin-ihraavdfyi"><div class="ldio-vl3gedm00so">
                    <div></div> */}
          {/* </div></div> */}
          {/* <Loading/> */}
          <img src={Loading} style={{ widht: 60, height: 60 }} />
          <Typography style={{ color: "#fff" }}>Loading</Typography>
        </div>
      </Backdrop>
      <Router>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar elevation={1} position="fixed" className={classes.appBar}>
            <Toolbar
              style={{ displa: "flex", justifyContent: "space-between" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="#7f8c8d"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  color="#7f8c8d"
                  aria-label="open drawer"
                  onClick={handleDrawerClose}
                  edge="start"
                  className={clsx(classes.menuButton, !open && classes.hide)}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ fontWeight: "bold", color: "#7f8c8d" }}
                >
                  Accounting++
                </Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <div style={{ position: "relaive" }}>
                    <IconButton onClick={handleClick}>
                      <NotificationsIcon style={{ color: "#2c6164" }} />
                    </IconButton>
                    <div
                      style={{
                        height: 19,
                        width: 19,
                        borderRadius: 9.5,
                        background: "red",
                        position: "absolute",
                        top: 10,
                        right: 75,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {homeReducer.loginData
                        ?.create_check_voucher_priviledge === "0" ? (
                        <Typography
                          style={{ fontWeight: "bold", fontSize: 13 }}
                        >
                          {homeReducer.pendingNewSupplier +
                            homeReducer.pendingReqForPayment +
                            homeReducer.pendingCheckVoucher +
                            homeReducer.pendingAPV}
                        </Typography>
                      ) : (
                        <Typography
                          style={{ fontWeight: "bold", fontSize: 13 }}
                        >
                          {homeReducer.pendingNewSupplier +
                            homeReducer.pendingReqForPayment +
                            homeReducer.pendingCheckVoucher +
                            homeReducer.pendingAPV +
                            homeReducer.pendingCreateCV}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {state.requestForPaymentAccess ? (
                      <MenuItem component={NewLink} to="/requestForPayment/">
                        {" "}
                        <Typography variant="p">Request for Payment</Typography>
                        <div
                          style={{
                            marginLeft: 10,
                            height: 18,
                            width: 18,
                            borderRadius: 9,
                            background: "red",
                            display: homeReducer.pendingReqForPayment
                              ? "flex"
                              : "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 11,
                              color: "#fff",
                            }}
                          >
                            {homeReducer.pendingReqForPayment}
                          </Typography>
                        </div>
                      </MenuItem>
                    ) : undefined}
                    {state.checkVoucherAccess ? (
                      <MenuItem
                        component={NewLink}
                        to="/checkVoucher/"
                        onClick={handleClose}
                      >
                        {" "}
                        <Typography variant="p">Check Voucher</Typography>
                        <div
                          style={{
                            marginLeft: 10,
                            height: 18,
                            width: 18,
                            borderRadius: 9,
                            background: "red",
                            display:
                              homeReducer.pendingCheckVoucher > 0
                                ? "flex"
                                : "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 11,
                              color: "#fff",
                            }}
                          >
                            {homeReducer.pendingCheckVoucher}
                          </Typography>
                        </div>
                      </MenuItem>
                    ) : undefined}
                    {state.accountPayableAccess ? (
                      <MenuItem
                        component={NewLink}
                        to="/accountPayableVoucher/"
                        onClick={handleClose}
                      >
                        {" "}
                        <Typography variant="p">
                          Account Payable Voucher
                        </Typography>
                        <div
                          style={{
                            marginLeft: 10,
                            height: 18,
                            width: 18,
                            borderRadius: 9,
                            background: "red",
                            display:
                              homeReducer.pendingAPV > 0 ? "flex" : "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 11,
                              color: "#fff",
                            }}
                          >
                            {homeReducer.pendingAPV}
                          </Typography>
                        </div>
                      </MenuItem>
                    ) : undefined}
                    {state.createCheckVoucherAccess &&
                    homeReducer.loginData?.create_check_voucher_priviledge ===
                      "1" ? (
                      <MenuItem
                        component={NewLink}
                        to="/createCheckVoucher/"
                        onClick={handleClose}
                      >
                        {" "}
                        <Typography variant="p">
                          Create Check Voucher
                        </Typography>
                        <div
                          style={{
                            marginLeft: 10,
                            height: 18,
                            width: 18,
                            borderRadius: 9,
                            background: "red",
                            display: homeReducer.pendingCreateCV
                              ? "flex"
                              : "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 11,
                              color: "#fff",
                            }}
                          >
                            {homeReducer.pendingCreateCV}
                          </Typography>
                        </div>
                      </MenuItem>
                    ) : undefined}
                    {state.newSupplierRequestAccess ? (
                      <MenuItem
                        component={NewLink}
                        to="/supplierRequestLists/"
                        onClick={handleClose}
                      >
                        {" "}
                        <Typography variant="p">
                          New Supplier Requests
                        </Typography>
                        <div
                          style={{
                            marginLeft: 10,
                            height: 18,
                            width: 18,
                            borderRadius: 9,
                            background: "red",
                            display: homeReducer.pendingNewSupplier
                              ? "flex"
                              : "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 11,
                              color: "#fff",
                            }}
                          >
                            {homeReducer.pendingNewSupplier}
                          </Typography>
                        </div>
                      </MenuItem>
                    ) : undefined}
                  </Menu>
                </div>
                <div>
                  <IconButton onClick={handleClickSetting}>
                    <SettingsIcon style={{ color: "#2c6164" }} />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={settings}
                    keepMounted
                    open={Boolean(settings)}
                    onClose={handleCloseSetting}
                  >
                    <MenuItem
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          changePasswordModal: true,
                        }));
                        handleCloseSetting();
                      }}
                    >
                      {" "}
                      <Typography variant="p">Change Password</Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DrawerData
              state={state}
              closeDrawer={() => (width > 600 ? undefined : setOpen(false))}
            />
            <List style={{ marginTop: `auto` }}>
              <Divider />
              <ListItem
                button
                style={{ backgroundColor: "#2c6164" }}
                onClick={() => {
                  logout();
                }}
              >
                <ListItemText style={{ color: "#fff" }}>Logout</ListItemText>
                <ExitToAppIcon style={{ color: "#fff" }} />
              </ListItem>
              <Divider />
            </List>
            <Divider />
          </Drawer>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {routes.map((value, index) => {
              return (
                <Route
                  key={index}
                  exact={value.exact}
                  path={value.path}
                  component={Auth(value.page_name, value.component)}
                />
              );
            })}
          </main>
        </div>
      </Router>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={state.changePasswordModal}
        onClose={() => {
          setState((prev) => ({ ...prev, changePasswordModal: false }));
        }}
      >
        <form onSubmit={onChangePassword}>
          <DialogTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              <LockIcon style={{ width: 20, height: 20 }} />
              <Typography>Change Password</Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            {/* <Card variant='outlined'>
                            <CardContent> */}
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{ fontWeight: "bold", color: "#f39c12" }}
                  variant="p"
                >
                  <i>
                    Note : After you have changed your password, please clear
                    the data of your PocketHR in your phone settings to sync
                    your credential.
                  </i>
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography style={{ fontWeight: "bold" }} variant="p">
                  Current Password
                </Typography>
                <TextField
                  type="password"
                  placeholder="Type"
                  name="current_password"
                  value={state.current_password}
                  onChange={onChangeText}
                  variant="outlined"
                  required
                  size="small"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography style={{ fontWeight: "bold" }} variant="p">
                  New Password
                </Typography>
                <TextField
                  type="password"
                  placeholder="Type"
                  name="new_password"
                  value={state.new_password}
                  onChange={onChangeText}
                  variant="outlined"
                  required
                  size="small"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography style={{ fontWeight: "bold" }} variant="p">
                  Confirm Password
                </Typography>
                <TextField
                  type="password"
                  placeholder="Type"
                  name="confirm_password"
                  value={state.confirm_password}
                  onChange={onChangeText}
                  variant="outlined"
                  required
                  size="small"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            {/* </CardContent>
                        </Card> */}
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
            >
              Change
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
