const initialState = {
    searchDriver : "",
    searchCompanyDriver : "",
    selectedCompanyID:'',
    branchList:[],
    selectedBranchID:'',
    clientList : [],
    clientUnique : [],
    selectedClientID:'All',
    selectedClientBranches:[],
    searchClientID:'',
    searchClientDriver : "",
    selectedClientData:[],

    item_name:'',
    item_code:'',
    price:'',
    unit:''

   }
   const dataReducer = (state = initialState, action) => {
       switch (action.type) {
           case 'onChangeBillingSetting':
               return {
                   ...state,
                   ...action.data,
               }
           default:
               return state;
       }
   
   }
   export default dataReducer; 