const initialState = {
    selectedSupplier:[],
    page_no:0
  }
  const dataReducer = (state = initialState, action) => {
      switch (action.type) {
          case 'onChangeSupplierReducer':
              return {
                  ...state,
                  ...action.data,
              }
             
          default:
              return state;
      }
  
  }
  export default dataReducer; 