// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from './tableRequest'
import Loading from '../../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";

function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}


function IndexRequests() {
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                       
                        <Link color="inherit">
                           Supplier 
                        </Link>
                        <Typography color="textPrimary">New Supplier Requests</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TableRequest />
                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(IndexRequests, renderPropsAreEqual);
