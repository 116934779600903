// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, DialogActions,
    Input,
    Chip
} from "@material-ui/core";
import {
    Breadcrumbs, Backdrop,
    FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AddIcon from '@material-ui/icons/Add';
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import TableProfitAndLossStatement from './tableProfitLossStatement'
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import { DateRangePicker, DateRange } from 'react-date-range';
let width = window.innerWidth;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
const useStyles = makeStyles((theme) =>({
    root: {
        // display: 'flex',
        // flex: 1
    },
    container: {
        maxHeight: 440,
    },
    textFieldStyle: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
}));
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
function CreateProfitAndLossStatement() {
    const [personName, setPersonName] = React.useState([]);
    const handleChange = (event) => {
        setPersonName(event.target.value);
      };
    const classes = useStyles()
    const theme = useTheme();
    const [state, setState] = React.useState({
        comperative: [{
            from: new Date(),
            to: new Date(),
            columnName: ''

        }],
        date_range_modal: false,
        selection: {
            startDate: new Date(moment(new Date()).format('YYYY-MM-01')),
            endDate: new Date(),
            key: 'selection'
        },
        selectedIndex: ''
    })
    const dispatch = useDispatch();
    const homeReducer = useSelector((state) => state.HomeReducer);
    const reportsReducer = useSelector((state) => state.ReportsReducer);
    const onChangeCompany = (e) => {
        let company_id = e.target.value
        console.log(company_id)
        dispatch({
            type: 'onChangeChartOFAccountsReducer',
            data: { selected_company_id: company_id }
        })
    }
    const addComperative = () => {
        let data = {
            from: new Date(),
            to: new Date(),
            columnName: ''
        }
        state.comperative.push(data)
        setState(prev => ({
            ...prev,
        }))
    }
    const removeItem = (index) => {
        state.comperative.splice(index, 1)
        setState(prev => ({
            ...prev
        }))
    }
    const onChangeComperative = (e, index) => {
        let name = e.target.name
        let value = e.target.value
        setState(prev => ({
            ...prev,
            comperative: state.comperative.map((val, index2) => (
                index === index2 ? { ...val, [name]: value } : val
            ))
        }))
    }
    const openDateRange = (index) => {
        let matchIndex = state.comperative.filter((val, index2) => (index === index2))
        console.log(matchIndex)
        let date_start = new Date()
        let date_to = new Date()

        for (let index = 0; index < matchIndex.length; index++) {
            const element = matchIndex[index];
            date_start = element.from
            date_to = element.to

        }
        setState(prev => ({
            ...prev,
            selection: { ...state.selection, startDate: date_start, endDate: date_to },
            date_range_modal: !state.date_range_modal,
            selectedIndex: index


        }))
    }
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/financialReport">
                            Reports
                        </Link>
                        <Link color="inherit" href="#/profitAndLossStatement">
                            Profit and Loss Statement
                        </Link>
                        <Typography color="textPrimary">Create Report</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card variant='outlined'>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid container justify='Flex-start' item xs={12} md={12}>
                                    <Typography variant="h6" style={{ color: '#2c7f84' }}>Profit and Loss Statement</Typography>
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={6}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Company</Typography>
                                    <FormControl variant={'outlined'} style={{ width: '100%', textAlign: 'left' }} size='small'>
                                        <InputLabel id="demo-simple-select-label"></InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={reportsReducer.selected_company_id}
                                            // onChange={handleChange}
                                            onChange={onChangeCompany}
                                        >
                                            {homeReducer.companies.map((val, index) => {
                                                return <MenuItem key={index} value={val.company_id}>{val.company_name}</MenuItem>
                                            })

                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={6}></Grid>
                                <Grid container justify='Flex-start' item xs={12} md={4}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Title</Typography>
                                    <TextField placeholder="Profit and Loss Statement" name='bank_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={7}></Grid>
                                <Grid container justify='Flex-start' item xs={12} md={7}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Description</Typography>
                                    <TextField name='bank_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={5}></Grid>
                                {state.comperative.map((val, index) => {
                                    return <>
                                        <Grid container justify='Flex-start' item xs={12} md={2}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">From</Typography>
                                            <Card variant='outlined' style={{ width: '100%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <input readOnly value={moment(val.from).format('YYYY-MM-DD')} placeholder="Date start" style={{ width: '100%', borderStyle: 'none', outline: 'none' }} name='searchDriver' />
                                                <EventNoteIcon style={{ cursor: 'pointer', color: '#2c6164' }} onClick={() => openDateRange(index)} />
                                            </Card>
                                        </Grid>
                                        <Grid container justify='Flex-start' item xs={12} md={2}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">To</Typography>
                                            <Card variant='outlined' style={{ width: '100%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <input readOnly value={moment(val.to).format('YYYY-MM-DD')} placeholder="Date start" style={{ width: '100%', borderStyle: 'none', outline: 'none' }} name='searchDriver' />
                                                <EventNoteIcon style={{ cursor: 'pointer', color: '#2c6164' }} onClick={() => openDateRange(index)} />
                                            </Card>
                                        </Grid>
                                        <Grid container justify='Flex-start' item xs={12} md={3}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">	Column name</Typography>
                                            <TextField value={val.columnName} onChange={(e) => { onChangeComperative(e, index) }} placeholder="Optional" name='columnName' variant='outlined' size='small' className={classes.textFieldStyle} />
                                        </Grid>
                                        <Grid container justify='Flex-start' item xs={12} md={5}>
                                            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                                                <CloseIcon onClick={() => {
                                                    removeItem(index)
                                                }} style={{ marginTop: 20 }} />
                                            </div>

                                        </Grid>
                                    </>
                                })

                                }

                                <Grid container justify='Flex-start' item xs={12} md={12}>
                                    <Button onClick={() => {
                                        addComperative()
                                    }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />}>Comparative Column</Button>
                                </Grid>
                                {/* <Grid container justify='Flex-start' item xs={12} md={3}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">	Accounting Method</Typography>
                                    <TextField placeholder="Optional" name='bank_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={9}></Grid> */}
                                <Grid container justify='Flex-start' item xs={12} md={1}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">	Rounding</Typography>
                                    <FormControl variant={'outlined'} style={{ width: '100%', textAlign: 'left' }} size='small'>
                                        <InputLabel id="demo-simple-select-label"></InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                        // value={reportsReducer.selected_company_id}
                                        // onChange={handleChange}
                                        // onChange={onChangeCompany}
                                        >
                                            {['Off', 'On'].map((val, index) => {
                                                return <MenuItem key={index} value={val}>{val}</MenuItem>
                                            })

                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={11}></Grid>
                                <Grid container justify='Flex-start' item xs={12} md={5}>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Group to Collapse</Typography>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            labelId="demo-mutiple-chip-label"
                                            id="demo-mutiple-chip"
                                            multiple
                                            value={personName}
                                            onChange={handleChange}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {names.map((name) => (
                                                <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox name="jason" />}
                                        label={<Typography variant='p'>Exclude zero balances</Typography>}
                                    />
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={12}>
                                    <Button onClick={() => {
                                        // openCloseAddAccountModal(true)
                                    }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
            <Dialog open={state.date_range_modal} onClose={() => openDateRange()} fullWidth maxWidth='xs'>
                <DialogTitle>Date Range</DialogTitle>
                <DialogContent>
                    <Grid item xs={12} md={12}>
                        <div style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'center' }}>
                            <DateRange
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                onChange={item => {
                                    setState(prev => ({
                                        ...prev, ...item,
                                        comperative: state.comperative.map((val, index) => (
                                            index === state.selectedIndex ? {
                                                ...val,
                                                from: item.selection.startDate,
                                                to: item.selection.endDate
                                            } : val
                                        ))
                                    }))

                                }}
                                ranges={[state.selection]}
                            />
                        </div>
                        {/* <DateRange
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={true}
                            onChange={item => setState({ ...state, ...item })}
                            ranges={[state.selection]}
                        /> */}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => openDateRange()} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Done</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default React.memo(CreateProfitAndLossStatement, renderPropsAreEqual);
