// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    TextField,
    Button,
    CardContent, Card,
    TextareaAutosize,
    IconButton,
    Tooltip,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    TableContainer
} from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from './tableRequest'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios"
import moment from "moment"
import { DropzoneDialog } from 'material-ui-dropzone'
// import { getData } from "../../../src/components/api/api"
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MuiAlert from '@material-ui/lab/Alert';
import PositionedSnackbar from '../../alert'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getData } from "../../api/api";
import $ from "jquery";
import Autocomplete from '@material-ui/lab/Autocomplete';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}

const RowInputs = ({ state, state_val, setState, index }) => {
    const onChangeValue = (e) => {
        let name = e.target.name
        let value = e.target.value

        setState(prev => ({
            ...prev,
            detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
                index === index_req ? {
                    ...val,
                    [name]: value,
                } : val
            )
        }))
    }
    const [rowState, rowSetState] = React.useState({
        finance_type: ''
    })
    const onChangeValueParticular = (e) => {

        let name = e.target.name
        let value = e.target.value
        let particular_id = $("#cars option[value='" + $('#input' + index).val() + "']").attr('data-id');
        let filterParticularID = state.accountList.filter((val) => (val.balance_sheet_group === particular_id))
        // let particular_name = ''
        if (filterParticularID.length > 0) {
            let finance_type = filterParticularID[0].finance_type

            rowSetState(prev => ({
                ...prev,
                finance_type: finance_type
            }))
        }
        setState(prev => ({
            ...prev,
            detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
                index === index_req ? {
                    ...val,
                    particular: value,
                    particular_id: particular_id
                } : val
            )
        }))
    }
    const onChangeValueParticularv2 = (e, values) => {

        let value = values.balance_sheet_group_name
        let particular_id = values.balance_sheet_group

        let finance_type = values.finance_type
        console.log(particular_id)
        rowSetState(prev => ({
            ...prev,
            finance_type: finance_type
        }))

        setState(prev => ({
            ...prev,
            detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
                index === index_req ? {
                    ...val,
                    particular: value,
                    particular_id: particular_id
                } : val
            )
        }))
    }
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };
    return <>
        {state.display == "Details" ?
            <StyledTableRow>
                <StyledTableCell readOnly style={{ width: '20%', whiteSpace: 'nowrap', }}>{state.detailsRowAPV[index].particular}</StyledTableCell>
                <StyledTableCell readOnly style={{ width: '20%', whiteSpace: 'nowrap', }}>{state.detailsRowAPV[index].description}</StyledTableCell>
                <StyledTableCell style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>{state.detailsRowAPV[index].debit}</StyledTableCell>
                <StyledTableCell style={{ width: '20%', textAlign: 'center' }}>{state.detailsRowAPV[index].credit}</StyledTableCell>
            </StyledTableRow>
            :

            <StyledTableRow>
                <StyledTableCell style={{ width: '20%', whiteSpace: 'nowrap', }}>
                    <Autocomplete
                        required
                        id="combo-box-demo"
                        size="small"
                        options={state.accountList}
                        getOptionLabel={(option) => option.balance_sheet_group_name}
                        onChange={onChangeValueParticularv2}
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField size="small" {...params} variant="outlined" />}
                    />
                    {/* <input required id={'input' + index} type="text" list="cars" style={{ width: '100%', borderColor: '#c5bfbf', height: 39, borderStyle: 'solid', borderRadius: 3, borderWidth: 1 }} value={state.detailsRowAPV[index].particular} aria-label="empty textarea" placeholder="Empty" onChange={onChangeValueParticular} />
                    <datalist id="cars">
                        {state.accountList.map((val2, key) => {
                            return <option data-id={val2.balance_sheet_group} value={val2.balance_sheet_group_name}>{val2.balance_sheet_group_name}</option>
                        })
                        }
                    </datalist> */}
                </StyledTableCell>
                <StyledTableCell style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>  <TextField required style={{ width: '100%', background: '#fff' }} variant='outlined' name='description' onChange={onChangeValue} value={(state.detailsRowAPV[index].description)} size="small" ></TextField></StyledTableCell>
                <StyledTableCell style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>  <TextField style={{ width: '100%', background: '#fff' }} variant='outlined' name='debit' onChange={onChangeValue} value={(state.detailsRowAPV[index].debit)} size="small" type='number'></TextField></StyledTableCell>
                <StyledTableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}> <TextField style={{ width: '100%', background: '#fff' }} variant='outlined' name='credit' onChange={onChangeValue} value={(state.detailsRowAPV[index].credit)} size="small" type='number'></TextField></StyledTableCell>
            </StyledTableRow>
        }



    </>
}

const RowInputsCV = ({ state, state_val, setState, index }) => {
    const onChangeValue = (e) => {
        let name = e.target.name
        let value = e.target.value

        setState(prev => ({
            ...prev,
            detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
                index === index_req ? {
                    ...val,
                    [name]: value
                } : val
            )
        }))
    }
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };
    return <>
        <StyledTableRow>
            <StyledTableCell readOnly style={{ width: '20%', whiteSpace: 'nowrap', }}>{state.detailsRow[index].description}</StyledTableCell>
            <StyledTableCell style={{ whiteSpace: 'pre-line', textAlign: 'right' }}>{formatNumber(state.detailsRow[index].total)}</StyledTableCell>
            {/* <StyledTableCell style={{ width: '20%', textAlign: 'center' }}>{state.detailsRowAPV[index].credit}</StyledTableCell> */}
        </StyledTableRow>


    </>
}
function RequestDetails({ handleClose, refreshFunc, action, selectedRequest, onChangeDisplay, onRefresh }) {
    const [state, setState] = React.useState({
        detailsRow: [{
            date: moment(new Date).format('YYYY-MM-DD'),
            description: '',
            total: ""
        }],
        detailsRowAPV: [{
            particular: '',
            debit: '',
            credit: "",
            particular_id: '',
            description: ''
        }],
        payee: "",
        company_name: "",
        open_modal_attachment: false,
        file: [],
        payeeList: [],
        company_id: "",
        branchList: [],
        branch_name: "",
        branch_id: "",
        department: '',
        alert: false,
        alertMessage: "",
        alertColor: "",
        attachments: [],
        balanceStatus: '',
        display: '',
        accountList: [],
        company_logo: "",
        narration: '',
        start_over: '',
        req_apv_created: '',
        rfp_apv_amount_equal: true.valueOf,
        req_recipient:''
    })
    const [open, setOpen] = React.useState(false)
    const dispatch = useDispatch();
    const dispatch_data = (type, data) => {
        dispatch({
            type: type,
            data: data,
        });
    };
    const homeReducer = useSelector((state) => state.HomeReducer);

    const onSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })

        let credit = state.detailsRowAPV.reduce((count, val) => {
            if (val.credit !== "") {
                count += parseFloat(val.credit)
            }
            return count
        }, 0)
        let debit = state.detailsRowAPV.reduce((count, val) => {
            if (val.debit !== "") {
                count += parseFloat(val.debit)
            }
            return count
        }, 0)

        let countParticular = 0;
        for (let index = 0; index < state.detailsRowAPV.length; index++) {
            const element = state.detailsRowAPV[index];
            if (element.credit !== "") {
                countParticular++
            }
        }

        if (state.detailsRowAPV.length > 1) {
            let match_apv_cv_access = homeReducer.branches.filter(val => (parseInt(selectedRequest.branch_id) === parseInt(val.branch_id)))
            let apv_cv_access = ''

            if (match_apv_cv_access.length > 0) {
                apv_cv_access = match_apv_cv_access[0].account_voucher_access
            }
            let req_hierarchy = homeReducer.branches.filter((val) => (val.company_id === state.company_id))
            let req_hierarchy_array = []
            if (req_hierarchy.length > 0) {
                if (req_hierarchy[0].request_voucher_hierarchy !== null && req_hierarchy[0].request_voucher_hierarchy !== "") {
                    req_hierarchy_array = JSON.parse(req_hierarchy[0].request_voucher_hierarchy)
                }
            }
            if (parseFloat(credit).toFixed(2) === parseFloat(debit).toFixed(2)) {
                let data = {
                    payee: state.payee,
                    narration: state.narration,
                    company_name: state.company_name,
                    detailsRowAPV: state.detailsRowAPV,
                    detailsRow: state.detailsRow,
                    branch_name: state.branch_name,
                    branch_id: state.branch_id,
                    company_id: state.company_id,
                    type: "Check Voucher",
                    type_name: 'CRF',
                    user_id: localStorage.getItem('u'),
                    department: state.department,
                    req_id: selectedRequest.req_id,
                    req_hierarchy_array: req_hierarchy_array,
                    apv_cv_access: apv_cv_access,
                    dataType: state.display,
                    autogenerateCV: selectedRequest.autogenerateCV,
                }
                createAPV(data)
            } else {
                setState(prev => ({ ...prev, balanceStatus: 'UNBALANCED' }))
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: { loading: false }
                })
            }
        } else {
            setState(prev => ({
                ...prev,
                alert: true,
                alertMessage: "Warning, Please complete the details.",
                alertColor: '#f39c12',
            }))
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        }

    }
    const handleNotification = (firstApprover) => {
        // homeReducer.socket.emit("sendNotification", {
        //     senderName: homeReducer.loginData.user_id,
        //     receiverName: firstApprover,
        //     type: ''
        // })
        let pendingAPV = parseInt(homeReducer.pendingAPV) - 1
        dispatch({
            type: 'onChangeHomeReducer',
            data: { onRefresh: !homeReducer.onRefresh, pendingAPV: pendingAPV }
        })
    }

    const createAPV = (data) => {
        getData('aam/createAPVV2', { data: data, req_data: selectedRequest }).then((res) => {
            if (res.res === true) {
                onChangeDisplay('Details')
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: { pendingAPV: homeReducer.pendingAPV - 1 }
                })
                onRefresh()
                setState(prev => ({
                    ...prev,
                    balanceStatus: '',
                    display: 'Details',
                    alert: true,
                    alertMessage: "Success, Your request has been created.",
                    alertColor: '#2ecc71',
                }))
                handleNotification(data.apv_cv_access)
            }
            else if (res.res === "Insufficient Balance") {
                setState(prev => ({
                    ...prev,
                    alert: true,
                    alertMessage: "Warning, Insufficient Balance, Remaining balance : Php " + res.balance,
                    alertColor: '#f39c12',
                }))
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: false,
                    }
                })
            } else {
                onChangeDisplay('Details')
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: { pendingAPV: homeReducer.pendingAPV - 1 }
                })
                onRefresh()
                setState(prev => ({
                    ...prev,
                    balanceStatus: '',
                    display: 'Details',
                    alert: true,
                    alertMessage: "Warning, Account Payable has been already created.",
                    alertColor: '#f39c12',
                }))
            }
        })
    }

    const uploadForm = (data) => {
        const formData = new FormData();
        for (let i = 0; i < state.file.length; i++) {
            formData.append('file' + i, state.file[i])
        }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        formData.append("data", JSON.stringify(data));
        axios.post("https://api.workflow.gzonetechph.com/aam/createRequest", formData, config)
            .then((response) => {
                // if(response.data.response){
                setState(prev => ({
                    ...prev,
                    detailsRow: [{
                        date: moment(new Date).format('YYYY-MM-DD'),
                        description: '',
                        total: ""
                    }],

                    payee: "",
                    company_name: "",
                    open_modal_attachment: false,
                    alert: true,
                    alertMessage: "Success, Your request has been created.",
                    alertColor: '#2ecc71',
                    file: [],
                    company_id: "",
                    branchList: [],
                    branch_name: "",
                    branch_id: "",
                    department: ""
                }))
                // handleClose()
                // }
                refreshFunc()
                dispatch({
                    type: "Loading",
                    data: false,
                });
            })
    }
    const onChangeText = (e) => {
        let name = e.target.name
        let value = e.target.value
        setState(prev => ({ ...prev, [name]: value }))
    }
    const handleChangeFile = (files) => {
        setState({
            ...state,
            file: files
        });
    }
    const handleClose_modal = () => {
        setState(prev => ({ ...prev, open_modal_attachment: false }))
    }
    React.useEffect(() => {
        dispatch({
            type: "Loading",
            data: false,
        });
        let branches = []
        homeReducer.branches.forEach((val) => {
            branches.push(val.branch_id)
        })
        let company_logo_match = homeReducer.branches.filter((val) => (val.branch_id === selectedRequest.req_branch_id))
        let company_logo = ''
        if (company_logo_match.length > 0) {
            let split = String(company_logo_match[0].company_logo).split('/')
            company_logo = split[split.length - 1]

        }
        // if (action === "Details") {
        let req_details = JSON.parse(selectedRequest.req_details)
        let company_id = (selectedRequest.req_company_id)
        let branch_id = (selectedRequest.req_branch_id)
        let company_name = (selectedRequest.company_name)
        let branch_name = (selectedRequest.branch_name)
        let department = (selectedRequest.department)
        let payee = (selectedRequest.req_payee)
        let narration = selectedRequest.req_narration
        let attachments = JSON.parse(selectedRequest.req_attachments)
        let start_over = (selectedRequest.req_start_over)
        let req_apv_created = (selectedRequest.req_apv_created)


        let req_details_particular = [{
            particular: '',
            debit: '',
            credit: ""
        }]
        let display = 'Create'
        if (selectedRequest.req_details_particular != null && selectedRequest.req_details_particular != "") {
            req_details_particular = JSON.parse(selectedRequest.req_details_particular)
            display = 'Details'
        }
        // let branches = homeReducer.branches.filter((val) => (val.company_name == company_name))
        onChangeDisplay(display)
        setState(prev => ({
            ...prev,
            detailsRow: req_details,
            company_id: company_id,
            branch_id: branch_id,
            company_name: company_name,
            branch_name: branch_name,
            department: department,
            branchList: branches,
            payee: payee,
            attachments: attachments,
            detailsRowAPV: req_details_particular,
            display: display,
            company_logo: company_logo,
            narration: narration,
            start_over: start_over,
            req_apv_created: req_apv_created,

        }))
        // }

        // getData('aam/getPayee', branches).then((res) => {
        //     setState(prev => ({ ...prev, payeeList: res,detailsRow:selectedRequest }))
        // })
    }, [])
    const onChangeCompany = (e) => {
        let company_name = e.target.value
        const branches = homeReducer.branches.filter((val) => (val.company_name == company_name))
        let company_id = ''
        if (branches.length > 0) {
            company_id = branches[0].company_id
        }
        setState({
            ...state,
            company_name: company_name, rule: [], emp_type: '', payroll_rule_group_id: '',
            branchList: branches, company_id: company_id
        })
    }
    const onChangeBranch = (e) => {
        let branch_id = e.target.value
        const branches = homeReducer.branches.filter((val) => (val.branch_id == branch_id))
        if (branches.length > 0) {
            setState(prev => ({ ...prev, branch_id: branch_id, branch_name: branches[0].branch_name }))
        }
    }
    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };
    React.useEffect(() => {
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let branches = [selectedRequest.req_company_id]
        // homeReducer.branches.forEach((val) => {
        //     branches.push(val.company_id)
        // })
        getData('aam/getChartOfAccounts', branches).then((res) => {
            let selectedData = selectedRequest
            let rfp_apv_amount_equal = true

            if (selectedData.req_details_particular !== null) {
                let rfp = JSON.parse(selectedData.req_details)
                let total_rfp = rfp.reduce((count, val) => {
                    count += parseFloat(val.total)
                    return count
                }, 0)

                let apv = JSON.parse(selectedData.req_details_particular)
                let total_apv = apv.reduce((count, val) => {
                    if (val.credit !== "") {
                        count += parseFloat(val.credit)
                    }
                    return count
                }, 0)
                if (parseFloat(total_apv).toFixed(2) !== parseFloat(total_rfp).toFixed(2)) {
                    rfp_apv_amount_equal = false
                }
            }


            setState(prev => ({ ...prev, accountList: res, rfp_apv_amount_equal: rfp_apv_amount_equal }))
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }, [])
    const onClickProceed = () => {
        let data = {
            req_start_over: 3,
            req_id: selectedRequest.req_id,
            req_vendor: selectedRequest.req_vendor,
            req_quantity: selectedRequest.req_quantity,
            dataType: 'Proceed'

        }
        if (window.confirm('Are you sure you want to proceed this Account Payable Voucher?')) {
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: true,
                }
            })
            let match_apv_cv_access = homeReducer.branches.filter(val => (parseInt(selectedRequest.branch_id) === parseInt(val.branch_id)))
            let apv_cv_access = ''

            if (match_apv_cv_access.length > 0) {
                apv_cv_access = match_apv_cv_access[0].account_voucher_access
            }
            let req_hierarchy = homeReducer.branches.filter((val) => (val.company_id === state.company_id))
            let req_hierarchy_array = []
            if (req_hierarchy.length > 0) {
                if (req_hierarchy[0].request_voucher_hierarchy !== null && req_hierarchy[0].request_voucher_hierarchy !== "") {
                    req_hierarchy_array = JSON.parse(req_hierarchy[0].request_voucher_hierarchy)
                }
            }

            let data = {
                payee: state.payee,
                narration: state.narration,
                company_name: state.company_name,
                detailsRowAPV: state.detailsRowAPV,
                detailsRow: state.detailsRow,
                branch_name: state.branch_name,
                branch_id: state.branch_id,
                company_id: state.company_id,
                type: "Check Voucher",
                type_name: 'CRF',
                user_id: localStorage.getItem('u'),
                department: state.department,
                req_id: selectedRequest.req_id,
                req_hierarchy_array: req_hierarchy_array,
                apv_cv_access: apv_cv_access,
                dataType: 'Proceed',
                req_vendor: selectedRequest.req_vendor
            }

            getData('aam/createAPVV2', { data: data, req_data: selectedRequest }).then((res) => {
                if (res.res) {
                    onRefresh()
                    setState(prev => ({
                        ...prev,
                        start_over: 3
                    }))
                }
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: false,
                    }
                })

            })
        }

    }
    console.log(selectedRequest)
    return (
        <div style={{ marginTop: 20, padding: 10 }}>
            <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid container spacing={1}>

                <Grid item xs={12} md={1}>
                </Grid>
                <Grid item xs={12} md={10}>

                    <form onSubmit={onSubmit}>
                        <Card elevation={4} style={{ background: '#fff' }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid>
                                        <img style={{ width: 130, height: 130 }} src={'https://images.workflow.gzonetechph.com/company_logo/' + state.company_logo} />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h4" style={{ color: '#2c7f84' }}>Account Payable Voucher</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="p" >Payee : <span style={{ fontWeight: 'bold' }}>{state.payee}</span></Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={12}>
                                                <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="p" >Date : <span style={{ fontWeight: 'bold' }}>{moment(new Date()).format('YYYY-MM-DD')}</span></Typography>
                                                    <Typography variant="p" >APV No. : <span style={{ fontWeight: 'bold' }}>{selectedRequest.req_reference_no}</span></Typography>
                                                </div>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <div style={{ backgroundColor: '#2c7f84', height: 1.5, width: '100%' }} />
                                    </Grid>
                                    {/* <Grid item xs={12} md={12}>
                                        <Card variant="outlined" style={{ padding: 10 }}>
                                            <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="p"> Transaction(s) : </Typography>
                                                {state.detailsRow.map((val, index) => {
                                                    return <div key={index} style={{ marginLeft: 30 }}>
                                                        <FiberManualRecordIcon style={{ marginRight: 5, fontSize: 11 }} /><Typography variant="p" style={{ fontWeight: 'bold', fontSize: 11 }}>{val.description}</Typography>
                                                    </div>
                                                })

                                                }
                                            </div>
                                        </Card>

                                    </Grid> */}
                                    <Grid item xs={12} md={12}>
                                        <Card variant="outlined" style={{ padding: 10, background: '#f0f0f2' }}>
                                            <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="p" style={{ fontWeight: 'bold' }} >Narration </Typography>
                                                {state.display == "Details" ?
                                                    <TextField readOnly style={{ width: '100%', background: '#e9f2f5' }} variant='outlined' name='narration' value={state.narration} size="small" ></TextField>
                                                    :
                                                    <TextField required style={{ width: '100%', background: '#fff' }} variant='outlined' name='narration' value={state.narration} onChange={onChangeText} size="small" ></TextField>
                                                }

                                            </div>
                                        </Card>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                    </Grid>

                                    {/* <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid xs={12}>
                                                <Typography variant="p"  >Requestor : <span style={{ fontWeight: 'bold' }}>{selectedRequest.fullname}</span></Typography>

                                            </Grid>
                                            <Grid xs={12}>
                                                <Typography variant="p"  > Company : <span style={{ fontWeight: 'bold' }}>{state.company_name}</span></Typography>

                                            </Grid>
                                            <Grid xs={12}>
                                                <Typography variant="p" >Branch : <span style={{ fontWeight: 'bold' }}>{state.branch_name}</span></Typography>

                                            </Grid>
                                            <Grid xs={12}>
                                                <Typography variant="p"  >Department : <span style={{ fontWeight: 'bold' }}>{state.department}</span></Typography>

                                            </Grid>

                                        </Grid>

                                    </Grid> */}
                                    {/* <Grid item xs={12}>
                                        <Typography variant="p" >Payee : <span style={{ fontWeight: 'bold' }}>{state.payee}</span></Typography>
                                    </Grid > */}


                                    <Grid item xs={12} md={12}>
                                        <div style={{ backgroundColor: '#2c7f84', height: 1.5, width: '100%' }} />
                                    </Grid>
                                    {state.display !== "Details" ?
                                        <>
                                            <Grid item xs={12} md={12} >
                                                <Tooltip title='Add Row'>
                                                    <IconButton onClick={() => {
                                                        state.detailsRowAPV.push({
                                                            particular: "",
                                                            debit: '',
                                                            credit: "",
                                                        })
                                                        setState(prev => ({ ...prev }))
                                                    }} style={{ backgroundColor: '#2c6164', color: '#fff', marginRight: 10, height: 40, width: 40 }} variant="contained" ><AddIcon /></IconButton>
                                                </Tooltip>
                                                <Tooltip title='Remove Row'>
                                                    <IconButton onClick={() => {
                                                        state.detailsRowAPV.splice(state.detailsRowAPV.length - 1, 1)
                                                        setState(prev => ({ ...prev }))
                                                    }} style={{ backgroundColor: '#2c6164', color: '#fff', marginRight: 10, height: 40, width: 40 }} variant="contained"><RemoveIcon /></IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {/* <Grid container justify='center' item xs={12} md={12}>
                                                <Typography variant={'p'} style={{ color: '#e74c3c', fontWeight: 'bold', fontSize: 28 }}>{state.balanceStatus}</Typography>
                                            </Grid> */}
                                            {/* <Grid container item xs={12} md={12} justify='flex-end'>
                                                <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>

                                            </Grid> */}
                                        </>

                                        : undefined

                                    }

                                    <Grid item xs={12} md={12} style={{ marginTop: -10 }}>
                                        <Card variant="outlined" style={{ background: '#fafafc' }}>
                                            <TableContainer>
                                                <Table border={1} style={{ borderCollapse: 'collapse', borderColor: '#bdc3c7' }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', width: '30%' }}>Particulars</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', width: '30%' }}>Description</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Debit</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Credit</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {state.detailsRowAPV.map((val, index) => {
                                                            return <RowInputs state={state} state_val={val} setState={setState} index={index} />
                                                        })
                                                        }
                                                        <StyledTableRow>
                                                            <StyledTableCell style={{ width: '20%', whiteSpace: 'nowrap', fontWeight: 'bold' }}>Total Amount Due</StyledTableCell>
                                                            <StyledTableCell style={{ width: '20%', whiteSpace: 'nowrap', fontWeight: 'bold' }}></StyledTableCell>
                                                            <StyledTableCell style={{ whiteSpace: 'pre-line', wordWrap: "break-word", fontWeight: 'bold' }}>{formatNumber((state.detailsRowAPV.reduce((count, val) => {
                                                                let total = val.debit
                                                                if (val.debit === "") {
                                                                    total = 0
                                                                }
                                                                return count += parseFloat(total)
                                                            }, 0)))}</StyledTableCell>
                                                            <StyledTableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>{formatNumber((state.detailsRowAPV.reduce((count, val) => {
                                                                let total = val.credit
                                                                if (val.credit === "") {
                                                                    total = 0
                                                                }
                                                                return count += parseFloat(total)
                                                            }, 0)))}</StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Card>
                                    </Grid>
                                    {state.display == "Create" ?
                                        <>
                                            {/* <Grid item xs={12} md={12}>
                                                <Tooltip title='Add Row'>
                                                    <IconButton onClick={() => {
                                                        state.detailsRowAPV.push({
                                                            particular: "",
                                                            debit: '',
                                                            credit: "",
                                                        })
                                                        setState(prev => ({ ...prev }))
                                                    }} style={{ backgroundColor: '#2c6164', color: '#fff', marginRight: 10, height: 40, width: 40 }} variant="contained" ><AddIcon /></IconButton>
                                                </Tooltip>
                                                <Tooltip title='Remove Row'>
                                                    <IconButton onClick={() => {
                                                        state.detailsRowAPV.splice(state.detailsRowAPV.length - 1, 1)
                                                        setState(prev => ({ ...prev }))
                                                    }} style={{ backgroundColor: '#2c6164', color: '#fff', marginRight: 10, height: 40, width: 40 }} variant="contained"><RemoveIcon /></IconButton>
                                                </Tooltip>
                                            </Grid> */}
                                            <Grid container justify='center' item xs={12} md={12}>
                                                <Typography variant={'p'} style={{ color: '#e74c3c', fontWeight: 'bold', fontSize: 28 }}>{state.balanceStatus}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={12} justify='flex-end'>
                                                <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Create</Button>

                                            </Grid>
                                        </>

                                        : undefined
                                    }
                                    {state.display == "Details" ?
                                        <>
                                            <Grid container justify='center' item xs={12} md={12}>
                                                <Typography variant={'p'} style={{ color: '#e74c3c', fontWeight: 'bold', fontSize: 28 }}>{state.balanceStatus}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={12} justify='flex-end'>
                                                {state.req_apv_created == 1 && state.start_over == 1 ? undefined
                                                    :
                                                    <Button type='button' onClick={() => setState(prev => ({
                                                        ...prev,
                                                        display: 'Edit'
                                                    }))} style={{ backgroundColor: '#2c6164', color: '#fff', marginRight: width < 600 ? undefined : 10, width: width < 600 ? '100%' : undefined }} variant="contained" >Edit</Button>
                                                }

                                                {state.start_over == 2 && state.rfp_apv_amount_equal === true ?
                                                    <Button onClick={() => onClickProceed()} type='button' style={{ backgroundColor: '#2c3e50', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Proceed</Button>
                                                    : undefined

                                                }


                                            </Grid>
                                        </> : undefined

                                    }
                                    {state.display == "Edit" ?
                                        <>
                                            <Grid container justify='center' item xs={12} md={12}>
                                                <Typography variant={'p'} style={{ color: '#e74c3c', fontWeight: 'bold', fontSize: 28 }}>{state.balanceStatus}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={12} justify='flex-end'>
                                                <Button type='button' onClick={() => setState(prev => ({
                                                    ...prev,
                                                    display: 'Details'
                                                }))} style={{ backgroundColor: '#7f8c8d', color: '#fff', marginRight: width < 600 ? undefined : 10, width: width < 600 ? '100%' : undefined }} variant="contained" >Cancel</Button>
                                                <Button type='submit' onClick={() => setState(prev => ({
                                                    ...prev,
                                                    display: 'Edit'
                                                }))} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Update</Button>

                                            </Grid>

                                        </> : undefined

                                    }




                                    {/* <Grid container justify='flex-end' item xs={12} md={12}>
                                        <Typography variant={"p"} style={{ fontWeight: 'bold', fontSize: 20 }}>Total : {formatNumber((state.detailsRow.reduce((count, val) => {
                                            let total = val.total
                                            if (val.total === "") {
                                                total = 0
                                            }
                                            return count += parseFloat(total)
                                        }, 0)))}</Typography>

                                    </Grid> */}
                                    {/* <Grid item xs={12} md={12}>
                                        <div style={{ backgroundColor: '#2c7f84', height: 1.4, width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="p" style={{ fontWeight: 'bold' }} >Attachment(s)</Typography>
                                    </Grid>
                                    {

                                    }
                                    <Grid item xs={12} md={12}>
                                        {state.attachments.map((val, index) => {
                                            console.log(val)
                                            return <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant='p' style={{ fontSize: 15 }}>{val}</Typography>
                                                <img onClick={() => {
                                                    window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                                }} src={'https://images.workflow.gzonetechph.com/pockethr/attachments/' + val} style={{ width: 100, height: 100, cursor: 'pointer' }} />

                                            </div>
                                        })

                                        }



                                        <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>    
                                    </Grid> */}

                                </Grid>
                            </CardContent>
                        </Card>

                    </form>
                </Grid>
                <Grid item xs={12} md={12}>

                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(RequestDetails, renderPropsAreEqual);
