import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Container, Card, CardContent, Grid, Button ,Icon} from '@material-ui/core';
import {
    HashRouter as Router,
    Route,
    Redirect, useHistory,
} from "react-router-dom";
import { getData } from '../../api/api';
import { useSelector, useDispatch } from "react-redux";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
const ITEM_HEIGHT = 48;
const columns = [
    { id: 'service_id', label: 'Service ID', },
    {
        id: 'service_name',
        label: 'Service Name',
        format: (value) => String(value).toLocaleUpperCase(),
    },
    {
        id: 'contract_no',
        label: 'Contract No.',
        format: (value) => (value),
    },
    {
        id: 'amount',
        label: 'Amount',
    },
    {
        id: 'unit',
        label: 'Type',
        format: (value) => String(value).toLocaleUpperCase(),
    },
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 300,
    },
});
const options = [
    'Edit'
];
const MultiButton = (props) => {
    const dispatch = useDispatch()
    const { data } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const history = useHistory()
    const handleClose = (option) => {
        if (option === 'Edit') {
            dispatch({
                type: 'onChangeServiceReducer',
                data: {
                    service_name: data.service_name,
                    contract_no: data.contract_no,
                    amount: data.amount,
                    unit: data.unit,
                    actionType: 'Edit',
                    selectedServices: data
                }
            })
            history.push('/addServices')
        }

        setAnchorEl(null);
    }
    return <div>
        <div
            type='button'
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <MoreVertIcon />
        </div>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option)}>
                   <Icon style={{marginRight:10,color:'#2c6164'}}>edit</Icon> {option}
                </MenuItem>
            ))}
        </Menu>
    </div>
}

let width = window.innerWidth;
export default function StickyHeadTable() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const history = useHistory()
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const serviceList = useSelector(state => state.ServiceReducer.serviceList)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    React.useEffect(() => {
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let data = {
            client_id: selectedClient.client_id
        }
        getData('aam/getServices', data).then((res) => {
            dispatch({
                type: 'onChangeServiceReducer',
                data: {
                    serviceList: res.res
                }
            })
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }, [])

    return (
        <Grid container spacing={1}>
            <Grid container justify={'flex-start'} item xs={12} md={12}>
                <Button onClick={() => {dispatch({
                    type:'onChangeServiceReducer',
                    data:{
                        actionType:'Add',
                        service_name:'',
                        contract_no:'',
                        amount:'',
                        unit:'',
                        selectedServices:[]
                    }
                }); history.push('/addServices')}} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Add Service</Button>
            </Grid>
            <Grid item xs={12} md={12}>
                <Paper variant='outlined' className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap', width: 20 }}
                                    >
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#fff' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {serviceList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell >
                                                <MultiButton data={row} />
                                            </TableCell>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={serviceList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}