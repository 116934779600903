// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import TableSupplierList from './tableSupplierList'
import Loading from '../../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";

function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}


function IndexSupplier() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" >
                        Supplier
                    </Link>
                    <Typography color="textPrimary">Supplier Master List</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={12}>
                <TableSupplierList />
            </Grid>
        </Grid>
    );
}
export default React.memo(IndexSupplier, renderPropsAreEqual);
