// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  TextareaAutosize,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  DialogActions,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { DropzoneDialog } from "material-ui-dropzone";
import { getData } from "../../../src/components/api/api";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiAlert from "@material-ui/lab/Alert";
import PositionedSnackbar from "../alert";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

const RowInputs = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRow: state.detailsRow.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
          {moment(state.detailsRow[index].date).format("YYYY-MM-DD")}
        </StyledTableCell>
        <StyledTableCell
          style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
        >
          {" "}
          <TextareaAutosize
            required
            style={{ width: "100%", borderColor: "#c5bfbf" }}
            name="description"
            minRows={2.3}
            value={state.detailsRow[index].description}
            aria-label="empty textarea"
            placeholder="Empty"
            onChange={onChangeValue}
          />
        </StyledTableCell>
        <StyledTableCell
          style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
        >
          {" "}
          <TextField
            required
            style={{ width: "100%", background: "#fff" }}
            variant="outlined"
            name="total"
            onChange={onChangeValue}
            value={state.detailsRow[index].total}
            size="small"
            type="number"
          ></TextField>
        </StyledTableCell>
      </StyledTableRow>
      {/* <Grid item xs={4} md={4}>

            <TextField readOnly style={{ width: '100%' }} variant='outlined' name='date' onChange={onChangeValue} size="small" value={moment(state.detailsRow[index].date).format('YYYY-MM-DD')}></TextField>
        </Grid>
        <Grid item xs={4} md={4}>
            <TextareaAutosize required style={{ width: '100%', borderColor: '#c5bfbf'}} name='description' minRows={2.3} value = {state.detailsRow[index].description} aria-label="empty textarea" placeholder="Empty" onChange={onChangeValue} />

        </Grid>
        <Grid item xs={4} md={4}>

            <TextField required style={{ width: '100%' }} variant='outlined' name='total' onChange={onChangeValue} value = {state.detailsRow[index].total} size="small" type='number'></TextField>
        </Grid> */}
    </>
  );
};
function RequestForm({
  handleNotification,
  refresh,
  handleClose,
  refreshFunc,
  action,
  selectedRequest,
}) {
  const [state, setState] = React.useState({
    detailsRow: [
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        total: "",
      },
    ],
    payee: "",
    payee_id: "",
    company_name: "",
    open_modal_attachment: false,
    file: [],
    payeeList: [],
    company_id: "",
    branchList: [],
    branch_name: "",
    branch_id: "",
    department: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    count: 0,
    optionCV: false,
    autogenerateCV: true,
    recipient: "",
    dateEstimatedReleased: "",
    recurring: "",
    // clear:false
  });
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const homeReducer = useSelector((state) => state.HomeReducer);
  let count = 0;
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: false,
      },
    });
    let req_hierarchy = homeReducer.branches.filter(
      (val) => val.branch_id === state.branch_id
    );
    let req_hierarchy_array = [];
    if (req_hierarchy.length > 0) {
      if (req_hierarchy[0].request_voucher_hierarchy !== null) {
        req_hierarchy_array = JSON.parse(
          req_hierarchy[0].request_voucher_hierarchy
        );
      }
    }

    state.count++;
    let autogenerateCV = 1;
    if (!state.autogenerateCV) {
      autogenerateCV = 0;
    }
    let data = {
      payee: state.payee,
      payee_id: state.payee_id,
      company_name: state.company_name,
      detailsRow: state.detailsRow,
      branch_name: state.branch_name,
      branch_id: state.branch_id,
      company_id: state.company_id,
      type: "Request for Payment",
      type_name: "RFP",
      user_id: localStorage.getItem("u"),
      department: state.department,
      req_hierarchy_structure: req_hierarchy_array,
      count: state.count,
      autogenerateCV: autogenerateCV,
      req_recipient: state.recipient,
      estimated_date_release: state.dateEstimatedReleased,
      recurring: state.recurring,
    };
    if (state.file.length === 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please attach files !",
        alertColor: "#f39c12",
      }));
    } else {
      uploadForm(data);
    }
  };
  const uploadForm = (data) => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/createRequest",
        formData,
        config
      )
      .then((response) => {
        if (response.data.result === "No Hierarchy") {
          alert("No hierarchy is set up on this company.");
          dispatch({
            type: "onChangeHomeReducer",
            data: {
              loading: false,
            },
          });
        } else if (response.data.result == "Success") {
          // getData('notification/sendMessage',).then(()=>{

          // })
          let firstApprover = "";
          if (response.data.hierarchy !== "") {
            firstApprover = JSON.parse(response.data.hierarchy)[0].approver[0]
              .id;
          }

          setState((prev) => ({
            ...prev,
            detailsRow: [
              {
                date: moment(new Date()).format("YYYY-MM-DD"),
                description: "",
                total: "",
              },
            ],
            payee: "",
            company_name: "",
            open_modal_attachment: false,
            alert: true,
            alertMessage: "Success, Your request has been created.",
            alertColor: "#2ecc71",
            file: [],
            company_id: "",
            branchList: [],
            branch_name: "",
            branch_id: "",
            department: "",
            payee_id: "",
            clear: false,
          }));
          handleNotification(firstApprover);
          refresh();
          setTimeout(() => {
            handleClose();
          }, 1000);
        } else {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Something went wrong",
            alertColor: "#f39c12",
          }));
          dispatch({
            type: "onChangeHomeReducer",
            data: {
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        let errorRes = "Error";
        if (String(error).includes("Network Error")) {
          // if(count === 0){
          alert("Network Error ! Please check your internet connection");
          errorRes = "Network Error";
          dispatch({
            type: "onChangeHomeReducer",
            data: {
              loading: false,
            },
          });
          // }
          // count++
        }
      });
  };

  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeTextPayee = (e, values) => {
    let name = "payee";
    let value = values.supplier_name;
    let payee_id = values.supplier_id;
    setState((prev) => ({ ...prev, [name]: value, payee_id: payee_id }));
  };

  const handleChangeFile = (files) => {
    setState((prev) => ({
      ...prev,
      file: files,
      clear: true,
    }));
  };
  const handleClose_modal = () => {
    setState((prev) => ({ ...prev, open_modal_attachment: false }));
  };

  React.useEffect(() => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    let branches = [];
    homeReducer.branches.forEach((val) => {
      branches.push(val.branch_id);
    });
    getData("aam/getSupplierDetails", branches).then((res) => {
      setState((prev) => ({ ...prev, payeeList: res.data }));
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: false,
        },
      });
    });
  }, []);
  const onChangeCompany = (e) => {
    let company_name = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_name == company_name
    );
    let company_id = "";
    if (branches.length > 0) {
      company_id = branches[0].company_id;
    }
    setState({
      ...state,
      company_name: company_name,
      rule: [],
      emp_type: "",
      payroll_rule_group_id: "",
      branchList: branches,
      company_id: company_id,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.branch_id == branch_id
    );
    if (branches.length > 0) {
      setState((prev) => ({
        ...prev,
        branch_id: branch_id,
        branch_name: branches[0].branch_name,
      }));
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const onOpenOption = (e) => {
    e.preventDefault();
    if (state.payee_id == "") {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Warning , Please select a payee!",
        alertColor: "#f39c12",
      }));
    } else {
      setState((prev) => ({
        ...prev,
        optionCV: true,
      }));
    }
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <form onSubmit={onOpenOption}>
            <Card elevation={4} style={{ background: "#fff" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      Request for Payment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Company
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.company_name}
                        // onChange={handleChange}
                        onChange={onChangeCompany}
                      >
                        {homeReducer.companies.map((val, index) => {
                          return (
                            <MenuItem key={index} value={val.company_name}>
                              {val.company_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Branch
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.branch_id}
                        // onChange={handleChange}
                        onChange={onChangeBranch}
                      >
                        {state.branchList.map((val, index) => {
                          return (
                            <MenuItem key={index} value={val.branch_id}>
                              {val.branch_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Department
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        required
                        name="department"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.department}
                        onChange={onChangeText}
                      >
                        {homeReducer.department.map((val, index) => {
                          return (
                            <MenuItem key={index} value={val.dept_name}>
                              {val.dept_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Payee
                    </Typography>
                    <Autocomplete
                      required
                      id="combo-box-demo"
                      size="small"
                      options={state.payeeList}
                      getOptionLabel={(option) => option.supplier_name}
                      onChange={onChangeTextPayee}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  {/* {state.payee == "G-XCHANGE INC." ? ( */}
                  <Grid item xs={12} md={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Recipient
                    </Typography>
                    <TextField
                      onChange={onChangeText}
                      name="recipient"
                      size="small"
                      variant="outlined"
                      required
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  {/* ) : undefined} */}
                  <Grid item xs={12} md={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Date Needed
                      </Typography>
                      <TextField
                        variant="outlined"
                        type="date"
                        size="small"
                        name="dateEstimatedReleased"
                        onChange={onChangeText}
                        value={state.dateEstimatedReleased}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Recurring Request
                    </Typography>
                    <FormControl
                      variant={"outlined"}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        required
                        name="recurring"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.recurring}
                        onChange={onChangeText}
                      >
                         <MenuItem  value={"Yes"}>
                            {"Yes"}
                          </MenuItem>
                          <MenuItem  value={"No"}>
                            {"No"}
                          </MenuItem>
                       
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Date
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table
                        border={1}
                        style={{
                          borderCollapse: "collapse",
                          borderColor: "#bdc3c7",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ fontWeight: "bold", width: "20%" }}
                            >
                              Date
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Description
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.detailsRow.map((val, index) => {
                            return (
                              <RowInputs
                                state={state}
                                state_val={val}
                                setState={setState}
                                index={index}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid container justify="flex-end" item xs={12} md={12}>
                    <Typography
                      variant={"p"}
                      style={{ fontWeight: "bold", fontSize: 20 }}
                    >
                      Total :{" "}
                      {formatNumber(
                        state.detailsRow.reduce((count, val) => {
                          let total = val.total;
                          if (val.total === "") {
                            total = 0;
                          }
                          return (count += parseFloat(total));
                        }, 0)
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Tooltip title="Add Row">
                      <IconButton
                        onClick={() => {
                          state.detailsRow.push({
                            date: new Date(),
                            description: "",
                            total: "",
                          });
                          setState((prev) => ({ ...prev }));
                        }}
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          marginRight: 10,
                          height: 40,
                          width: 40,
                        }}
                        variant="contained"
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Row">
                      <IconButton
                        onClick={() => {
                          state.detailsRow.splice(
                            state.detailsRow.length - 1,
                            1
                          );
                          setState((prev) => ({ ...prev }));
                        }}
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          marginRight: 10,
                          height: 40,
                          width: 40,
                        }}
                        variant="contained"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Attach Files">
                      <IconButton
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          marginRight: 10,
                          height: 40,
                          width: 40,
                        }}
                        variant="contained"
                        onClick={() => setOpen(true)}
                      >
                        <div style={{ position: "relative" }}>
                          <AttachFileIcon />
                          <div
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 10,
                              background: "#eb4d4b",
                              position: "absolute",
                              top: -10,
                              right: -15,
                              display: state.file.length == 0 ? "none" : "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{ color: "#fff", fontSize: 13 }}
                            >
                              {state.file.length}
                            </Typography>
                          </div>
                        </div>
                      </IconButton>
                    </Tooltip>

                    <DropzoneDialog
                      acceptedFiles={["image/*,application/pdf"]}
                      cancelButtonText={"cancel"}
                      filesLimit={50}
                      submitButtonText={"done"}
                      maxFileSize={50000000}
                      open={open}
                      onClose={() => setOpen(false)}
                      onSave={() => setOpen(false)}
                      initialFiles={state.file}
                      onChange={handleChangeFile}
                      clearOnUnmount={false}
                      onDelete={(e) => {
                        for (
                          let index2 = 0;
                          index2 < state.file.length;
                          index2++
                        ) {
                          const element = state.file[index2];
                          if (element.name === e.name) {
                            state.file.splice(index2, 1);
                          }
                          setState((prev) => ({ ...prev }));
                        }
                      }}
                      // onSave={(files) => {
                      //     console.log('Files:', files);
                      //     setOpen(false);
                      // }}
                      showPreviews={false}
                      // showFileNames={true}
                      showFileNamesInPreview={false}
                      showPreviewsInDropzone={true}
                    />
                  </Grid>

                  <Grid container item xs={12} md={12} justify="flex-end">
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#fff",
                        width: width < 600 ? "100%" : undefined,
                      }}
                      variant="contained"
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={state.optionCV}
            onClose={() => {
              setState((prev) => ({ ...prev, optionCV: false }));
            }}
          >
            <form onSubmit={onSubmit}>
              <div style={{ position: "relative", width: "100%" }}>
                <DialogTitle>Option</DialogTitle>
                <div
                  style={{
                    position: "absolute",
                    right: 5,
                    top: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setState((prev) => ({ ...prev, optionCV: false }));
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p">
                      Please enable the toggle button to auto generate the check
                      voucher once your request for payment has been approved
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography
                      variant="p"
                      style={{ color: "#27ae60", fontWeight: "bold" }}
                    >
                      <i>
                        Note : If the toogle option is off and your request for
                        payment is approved you can create one check voucher for
                        multiple request.
                      </i>{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.autogenerateCV}
                          onChange={handleChange}
                          name="autogenerateCV"
                          color="primary"
                        />
                      }
                      label="Auto generate Check Voucher"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#fff",
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                >
                  Proceed
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </div>
  );
}
export default React.memo(RequestForm, renderPropsAreEqual);
