import {combineReducers} from 'redux'
import HomeReducer from './reducer/homeReducer'
import BankReducer from './reducer/bankReducer'
import SupplierReducer from './reducer/supplierReducer'
import ChartoFAccountsReducer from './reducer/chartofAccounts'
import ReportsReducer from './reducer/reportsReducer'
import RequestLogsDetails from './reducer/requestsLogsReducer'
import BillingSettingReducer from './reducer/billingSettingReducer'
import ClientReducer from './reducer/clientReducer'
import ServiceReducer from './reducer/serviceReducer'
import BillingReducer from './reducer/billingReducer'
import ClientBranchReducer from './reducer/clientBranchReducer'
import RegisterPaymentReducer from './reducer/registerPaymentReducer'
import CreateCheckVoucher from './reducer/createCheckVoucher'

export default combineReducers({
    HomeReducer:HomeReducer,
    BankReducer:BankReducer,
    SupplierReducer:SupplierReducer,
    ChartoFAccountsReducer:ChartoFAccountsReducer,
    ReportsReducer:ReportsReducer,
    RequestLogsDetails:RequestLogsDetails,
    BillingSettingReducer:BillingSettingReducer,
    ClientReducer:ClientReducer,
    ServiceReducer:ServiceReducer,
    BillingReducer:BillingReducer,
    ClientBranchReducer:ClientBranchReducer,
    RegisterPaymentReducer:RegisterPaymentReducer,
    CreateCheckVoucher:CreateCheckVoucher
})