// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import ContractTable from './contractTable'
// import BranchTable from './branchTable'
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function IndexContracts() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const selectedClientBranch = useSelector(state => state.ClientBranchReducer.selectedClientBranch)


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/clientMasterList">
                            Client Master List
                        </Link>
                        <Link color="inherit" href="#/clientDetails">
                            Details
                        </Link>
                        <Typography color="textPrimary">{selectedClient.client_name}</Typography>
                        <Typography color="textPrimary">{selectedClientBranch.client_branch_name}</Typography>
                        <Typography color="textPrimary">{'Contracts'}</Typography>

                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={12}>
                    <ContractTable />
                </Grid>
            </Grid>
        </div>
    );
}
export default IndexContracts;
