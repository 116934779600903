// import MarkerClusterer from '@google/markerclustererplus';
import { Typography, Grid, Card, CardContent } from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
import TableBankAccounts from "./tableBankAccounts";
import Loading from "../../assets/loading2.gif";
import { useSelector, useDispatch } from "react-redux";
import BankForm from "./bankForm";
function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

function IndexBankAccounts() {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            {/* <Link color="inherit" href="/">
                          Home
                        </Link> */}
            <Typography color="textPrimary">Bank Accounts</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12} md={12}>
          <TableBankAccounts />
        </Grid>
      </Grid>
    </div>
  );
}
export default React.memo(IndexBankAccounts, renderPropsAreEqual);
