const initialState = {
   selected_company_id : "",
   groupList:[],
   groupListDisplay:[],
   groupList2:[],
   groupListDisplay2:[],
   selected_type:'',
   actionStatus:'',
   selectedValue:[]

  }
  const dataReducer = (state = initialState, action) => {
      switch (action.type) {
          case 'onChangeChartOFAccountsReducer':
              return {
                  ...state,
                  ...action.data,
              }
             
          default:
              return state;
      }
  
  }
  export default dataReducer; 