// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  TextareaAutosize,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  DialogContent,
  Dialog,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { DropzoneDialog } from "material-ui-dropzone";
// import { getData } from "../../../src/components/api/api"
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiAlert from "@material-ui/lab/Alert";
import PositionedSnackbar from "../../alert";
import FilesDIsplay from "./filesDIsplay";
import Lightbox from "react-awesome-lightbox";
import PdfPic from "../../../assets/pdf.png";
import ChatIcon from "@material-ui/icons/Chat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";

// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import CustomizedTimeline from "../../timeline/indexTimeline";
import { getData } from "../../api/api";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

const RowInputs = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRow: state.detailsRow.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
          {moment(state.detailsRow[index].date).format("YYYY-MM-DD")}
        </StyledTableCell>
        <StyledTableCell
          style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
        >
          {state.detailsRow[index].description}
        </StyledTableCell>
        <StyledTableCell
          style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
        >
          {formatNumber(parseFloat(state.detailsRow[index].total).toFixed(2))}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
function RequestDetails({ handleClose, refreshFunc, action, selectedRequest }) {
  console.log(selectedRequest);
  const [state, setState] = React.useState({
    detailsRow: [
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        total: "",
      },
    ],
    payee: "",
    company_name: "",
    open_modal_attachment: false,
    file: [],
    payeeList: [],
    company_id: "",
    branchList: [],
    branch_name: "",
    branch_id: "",
    department: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    attachments: [],
    openFile: false,
    imageDisplay: [],
    pdfDisplay: [],
    openFilePDF: false,
    indexPdf: 0,
    req_recipient: "",
    recurring: "",
    autogenerateCV: "0",
  });
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };

  const homeReducer = useSelector((state) => state.HomeReducer);
  const updateRequest = (data) => {
    if (window.confirm("Are you sure you want to update the request")) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      data["req_id"] = selectedRequest.req_id;
      data["user_id"] = localStorage.getItem("u");

      getData("aam/updateRFPRequest", data).then((res) => {
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    // dispatch({
    //     type: "Loading",
    //     data: true,
    // });
    let data = {
      payee: state.payee,
      company_name: state.company_name,
      detailsRow: state.detailsRow,
      branch_name: state.branch_name,
      branch_id: state.branch_id,
      company_id: state.company_id,
      type: "Cash/Check Requisition Form",
      type_name: "CRF",
      user_id: localStorage.getItem("u"),
      department: state.department,
    };
    if (state.file.length === 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please attach files !",
        alertColor: "#f39c12",
      }));
    } else {
      uploadForm(data);
    }
  };
  const uploadForm = (data) => {
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/createRequest",
        formData,
        config
      )
      .then((response) => {
        // if(response.data.response){
        setState((prev) => ({
          ...prev,
          detailsRow: [
            {
              date: moment(new Date()).format("YYYY-MM-DD"),
              description: "",
              total: "",
            },
          ],
          payee: "",
          company_name: "",
          open_modal_attachment: false,
          alert: true,
          alertMessage: "Success, Your request has been created.",
          alertColor: "#2ecc71",
          file: [],
          company_id: "",
          branchList: [],
          branch_name: "",
          branch_id: "",
          department: "",
        }));
        refreshFunc();

        // handleClose()
        // }
        // dispatch({
        //     type: "Loading",
        //     data: false,
        // });
      });
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeFile = (files) => {
    setState({
      ...state,
      file: files,
    });
  };
  const handleClose_modal = () => {
    setState((prev) => ({ ...prev, open_modal_attachment: false }));
  };

  React.useEffect(() => {
    dispatch({
      type: "Loading",
      data: false,
    });
    let branches = [];
    homeReducer.branches.forEach((val) => {
      branches.push(val.branch_id);
    });
    let company_logo_match = homeReducer.branches.filter(
      (val) => val.branch_id === selectedRequest.req_branch_id
    );
    let company_logo = "";
    if (company_logo_match.length > 0) {
      let split = String(company_logo_match[0].company_logo).split("/");
      company_logo = split[split.length - 1];
    }
    if (action === "Details") {
      let req_details = JSON.parse(selectedRequest.req_details);
      let company_id = selectedRequest.req_company_id;
      let branch_id = selectedRequest.req_branch_id;
      let company_name = selectedRequest.company_name;
      let branch_name = selectedRequest.branch_name;
      let department = selectedRequest.department;
      let payee = selectedRequest.req_payee;
      let attachments = JSON.parse(selectedRequest.req_attachments);
      let branches = homeReducer.branches.filter(
        (val) => val.company_name == company_name
      );
      let imageDisplay = [];
      let pdfDisplay = [];

      for (let index = 0; index < attachments.length; index++) {
        const element = attachments[index];
        let data_details = {
          url:
            "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
            element,
          title: element,
        };
        let ext = String(element).split(".");
        let ext2 = ext[ext.length - 1];
        if (ext2 == "pdf") {
          pdfDisplay.push(data_details);
        } else {
          imageDisplay.push(data_details);
        }
      }
      setState((prev) => ({
        ...prev,
        detailsRow: req_details,
        company_id: company_id,
        branch_id: branch_id,
        company_name: company_name,
        branch_name: branch_name,
        department: department,
        branchList: branches,
        payee: payee,
        attachments: attachments,
        pdfDisplay: pdfDisplay,
        company_logo: company_logo,
        imageDisplay: imageDisplay,
        req_recipient: selectedRequest.req_recipient,
        recurring:
          selectedRequest.recurring == null ? "No" : selectedRequest.recurring,
        autogenerateCV: selectedRequest.autogenerateCV == "0" ? false : true,
      }));
    }

    // getData('aam/getPayee', branches).then((res) => {
    //     setState(prev => ({ ...prev, payeeList: res,detailsRow:selectedRequest }))
    // })
  }, []);
  const onChangeCompany = (e) => {
    let company_name = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_name == company_name
    );
    let company_id = "";
    if (branches.length > 0) {
      company_id = branches[0].company_id;
    }
    setState({
      ...state,
      company_name: company_name,
      rule: [],
      emp_type: "",
      payroll_rule_group_id: "",
      branchList: branches,
      company_id: company_id,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.branch_id == branch_id
    );
    if (branches.length > 0) {
      setState((prev) => ({
        ...prev,
        branch_id: branch_id,
        branch_name: branches[0].branch_name,
      }));
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        {/* <Grid item xs={12} md={3}>
                    {selectedRequest?.comments.length > 0 ?
                        <Card style={{ background: '#fce7a4' }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" style={{ color: '#2c7f84' }}>Comments</Typography>
                                    </Grid>
                                    {selectedRequest?.comments.map((val, index) => {
                                        return <Grid item xs={12} md={12} >
                                            <div style={{ paddingRight: 25, paddingLeft: 25, borderWidth: 1, borderColor: '#c5bfbf', background: '#fcde7e', padding: 10, display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="p" style={{ fontWeight: 'bold', fontSize: 12 }}>{`${val.user_lname} ${val.user_fname}`}</Typography>
                                                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                    <Typography variant="p">{`${val.comment}`}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    })
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                        : undefined

                    }
                </Grid> */}
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10}>
          <CustomizedTimeline req_id={selectedRequest.req_id} />
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={6}>
          <form onSubmit={onSubmit}>
            <Card elevation={4} style={{ background: "#fff" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid>
                    <img
                      style={{ width: 130, height: 130 }}
                      src={
                        "https://images.workflow.gzonetechph.com/company_logo/" +
                        state.company_logo
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      Request for Payment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Requestor :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {selectedRequest.fullname}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          {" "}
                          Company :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.company_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Branch :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.branch_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Department :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.department}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Recurring{" "}
                          <FormControl
                            variant={"outlined"}
                            style={{ width: "100%" }}
                            size="small"
                          >
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                              readOnly={
                                homeReducer.loginData.rfp_options == "1"
                                  ? false
                                  : true
                              }
                              required
                              name="recurring"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={state.recurring}
                              onChange={(e) => {
                                if (
                                  homeReducer.loginData.rfp_options == "1" &&
                                  selectedRequest.status != "Approved"
                                ) {
                                  onChangeText(e);
                                  updateRequest({ recurring: e.target.value });
                                }
                              }}
                            >
                              <MenuItem value={"Yes"}>{"Yes"}</MenuItem>
                              <MenuItem value={"No"}>{"No"}</MenuItem>
                            </Select>
                          </FormControl>
                          {/* <span style={{ fontWeight: "bold" }}>
                            {state.recurring}
                          </span> */}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Auto Generate CheckVoucher{" "}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.autogenerateCV}
                                onChange={(e) => {
                                  if (
                                    homeReducer.loginData.rfp_options == "1" &&
                                    selectedRequest.status != "Approved"
                                  ) {
                                    handleChange(e);
                                    updateRequest({
                                      autogenerateCV: e.target.checked ? 1 : 0,
                                    });
                                  }
                                }}
                                name="autogenerateCV"
                                color="primary"
                              />
                            }
                            label="Auto generate Check Voucher"
                          />
                          {/* <span style={{ fontWeight: "bold" }}>
                            {state.recurring}
                          </span> */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      Payee :{" "}
                      <span style={{ fontWeight: "bold" }}>{state.payee}</span>
                    </Typography>
                  </Grid>
                  {state.payee == "G-XCHANGE INC." ? (
                    <Grid item xs={12}>
                      <Typography variant="p">
                        Recipient :{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {state.req_recipient}
                        </span>
                      </Typography>
                    </Grid>
                  ) : undefined}
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "20%" }}
                              >
                                Date
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Description
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.detailsRow.map((val, index) => {
                              return (
                                <RowInputs
                                  state={state}
                                  state_val={val}
                                  setState={setState}
                                  index={index}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12} md={12}>
                    <Typography
                      variant={"p"}
                      style={{ fontWeight: "bold", fontSize: 20 }}
                    >
                      Total :{" "}
                      {formatNumber(
                        state.detailsRow.reduce((count, val) => {
                          let total = val.total;
                          if (val.total === "") {
                            total = 0;
                          }
                          return (count += parseFloat(total));
                        }, 0)
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.4,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Attachment(s)
                    </Typography>
                  </Grid>
                  {}
                  <Grid item xs={12} md={12}>
                    {state.imageDisplay.map((val, index) => {
                      if (index == 0)
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="p" style={{ fontSize: 15 }}>
                              {val.title}
                            </Typography>
                            <div
                              style={{
                                position: "relative",
                                width: 100,
                                height: 100,
                              }}
                            >
                              {index == 0 ? (
                                <div
                                  onClick={() => {
                                    setState((prev) => ({
                                      ...prev,
                                      openFile: true,
                                    }));
                                    // window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                    background: "#00000047",
                                    position: "absolute",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{ color: "#ffff" }}
                                  >
                                    +{state.imageDisplay.length - 1}
                                  </Typography>
                                </div>
                              ) : undefined}

                              <img
                                src={val.url}
                                style={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        );
                    })}

                    {state.pdfDisplay.map((val, index) => {
                      if (index == 0)
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="p" style={{ fontSize: 15 }}>
                              {val.title}
                            </Typography>
                            <div
                              style={{
                                position: "relative",
                                width: 100,
                                height: 100,
                              }}
                            >
                              {index == 0 ? (
                                <div
                                  onClick={() => {
                                    setState((prev) => ({
                                      ...prev,
                                      openFilePDF: true,
                                      indexPdf: 0,
                                    }));
                                    // window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                    background: "#00000047",
                                    position: "absolute",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{ color: "#ffff" }}
                                  >
                                    +{state.pdfDisplay.length - 1}
                                  </Typography>
                                </div>
                              ) : undefined}

                              <img
                                src={PdfPic}
                                style={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        );
                    })}

                    {/* <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>     */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" style={{ background: "#fff" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid container justify="flex-start" item xs={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ChatIcon />
                    <Typography variant="h6" style={{ color: "#2c7f84" }}>
                      Comments and Logs
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ height: 500, overflowY: "auto" }}>
                    {selectedRequest?.comments.map((val, index) => {
                      let c_type = val.comment_type;
                      if (c_type === "Text") {
                        c_type = "Comment";
                      }
                      return (
                        <div style={{ marginBottom: 10 }}>
                          <div
                            style={{
                              paddingRight: 25,
                              paddingLeft: 25,
                              borderWidth: 1,
                              borderColor: "#c5bfbf",
                              background: "#f7f9fa",
                              padding: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{ fontWeight: "bold", color: "#2f3640" }}
                            >
                              {c_type}
                            </Typography>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {val.comment_type === "System" ? (
                                <SettingsIcon style={{ color: "#2c3e50" }} />
                              ) : (
                                <AccountCircleIcon
                                  style={{ color: "#3498db" }}
                                />
                              )}

                              <Typography
                                variant="p"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "#2980b9",
                                }}
                              >
                                {`${val.user_lname} ${val.user_fname}`}
                                <span style={{ color: "#000", marginLeft: 10 }}>
                                  {val.comment_date_added}
                                </span>
                              </Typography>
                            </div>
                            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                              <Typography variant="p">{`${val.comment}`}</Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFile: false,
          }));
        }}
        open={state.openFile}
      >
        {/* <DialogContent> */}
        {/* <FilesDIsplay />
         */}
        <Lightbox
          images={state.imageDisplay}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              openFile: false,
            }));
          }}
        />
        {/* {state.imageDisplay.map((val,index)=>{
                    
                    if(index == 0 )
                    <iframe src={val.url} title="W3Schools Free Online Web Tutorials"></iframe>

                })

                } */}

        {/* </DialogContent> */}
      </Dialog>
      <Dialog
        fullScreen
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFilePDF: false,
          }));
        }}
        open={state.openFilePDF}
      >
        <FilesDIsplay
          state={state}
          onClosePDF={() => {
            setState((prev) => ({
              ...prev,
              openFilePDF: false,
            }));
          }}
          setState={setState}
        />
      </Dialog>
    </div>
  );
}
export default React.memo(RequestDetails, renderPropsAreEqual);
