// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Tooltip,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CallMadeIcon from '@material-ui/icons/CallMade';
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

const Dashboard = React.memo((props) => {
    const dispatch = useDispatch()
    const {getDateTransaction} = props
    const dashboard = useSelector(state => state.RequestLogsDetails.dashboard)
    const rowsPerPage = useSelector(state => state.RequestLogsDetails.rowsPerPage)
    console.log(dashboard)
    const classes = useStyles();
    return (
        <Card variant='outlined'>
            <CardContent>
                <Grid container spacing={1}>
                    {dashboard.map((val, index) => {
                        return <Grid key={index} item xs={12} md={2}>
                            <Card style={{ background: val.color, cursor: 'pointer' }} onClick={()=>{getDateTransaction(0,rowsPerPage,val.type)
                             dispatch({
                                type: 'onChnageRequestLogs',
                                data: {
                                    page: 0,
                                }
                            })
                            }} variant='outlined'>
                                <CardContent>
                                    <div style={{ display: 'flex' }}>
                                        <Typography className={classes.cardFont}>{val.type}</Typography>
                                    </div>
                                    <Typography className={classes.cardFontCount}>{val.count}</Typography>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                        <Tooltip title='View'>
                                            <CallMadeIcon  style={{ color: '#fff', fontWeight: 'bold', width: 20, height: 20 }} />
                                        </Tooltip>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    })}
                </Grid>
            </CardContent>
        </Card>
    );
}, (prevProps, nextProps) => {
    if (prevProps.refreshDashboard !== nextProps.refreshDashboard) {
       return false
    }
    return true
   })
export default Dashboard;
