// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem

} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import EventNoteIcon from '@material-ui/icons/EventNote';
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
let width = window.innerWidth;

const FilterBranch = React.memo((props)=> {
    const dispatch = useDispatch()
    const branches = useSelector(state => state.HomeReducer.branches)
    const companies = useSelector(state => state.HomeReducer.companies)
    const selectedCompanyID = useSelector(state => state.BillingSettingReducer.selectedCompanyID)
    const branchList = useSelector(state => state.BillingSettingReducer.branchList)
    const selectedBranchID = useSelector(state => state.BillingSettingReducer.selectedBranchID)

    const clientUnique = useSelector(state => state.BillingSettingReducer.clientList)
    const clientList = useSelector(state => state.BillingSettingReducer.clientList)
    const selectedClientBranches = useSelector(state => state.BillingSettingReducer.selectedClientBranches)
    const selectedClientID = useSelector(state => state.BillingSettingReducer.selectedClientID)



    const onChangeClient = (e) => {
        let client_id = e.target.value
        const selectedClientBranches = clientList.filter((val) => (val.client_id == client_id))
        dispatch({
            type:'onChangeBillingSetting',
            data:{
                selectedClientID:client_id,
                selectedClientBranches:selectedClientBranches,
                selectedBranchID:'All'
            }
        })
    }
    const onChangeBranch = (e) => {
        let branch_id = e.target.value
        dispatch({
            type:'onChangeBillingSetting',
            data:{
                selectedBranchID:branch_id
            }
        })
    }
    const onSubmitBranchFilter = () =>{
        let searchBranch_id = selectedBranchID
        let searchClient_id = selectedClientID
        if(searchBranch_id == 'All'){
            searchBranch_id = ''
        }
        if(searchClient_id == 'All'){
            searchClient_id = ''
        }
        dispatch({
            type:'onChangeBillingSetting',
            data:{
                searchDriver:searchBranch_id,
                searchClientDriver:searchClient_id
            }
        })
    }
    return (
        <Grid container spacing={1}>
              <Grid item xs={12} md={5}>
            </Grid>
            <Grid container justify='flex-start' item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold',fontSize:12  }} variant="p">Client</Typography>
                <FormControl variant={'outlined'} style={{ width: '100%' ,background:'#fff' }} size='small'>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedClientID}
                        // onChange={handleChange}
                        onChange={onChangeClient}
                        style={{ textAlign: 'left' }}
                    >
                        <MenuItem value={"All"}>{'All'}</MenuItem>
                        {clientUnique.map((val, index) => {
                            return <MenuItem key={index} value={val.client_id}>{val.client_name}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid container justify='flex-start' item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold',fontSize:12 }} variant="p">Branch</Typography>
                <FormControl variant={'outlined'} style={{ width: '100%',background:'#fff' }} size='small'>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBranchID}
                        style={{ textAlign: 'left' }}
                        // onChange={handleChange}
                        onChange={onChangeBranch}
                    >
                        <MenuItem value={"All"}>{'All'}</MenuItem>
                        {selectedClientBranches.map((val, index) => {
                            return <MenuItem key={index} value={val.branch_id}>{val.branch_name}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={1}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button onClick={() => {
                        onSubmitBranchFilter()
                    }} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Submit</Button>
                </div>

            </Grid>
          
        </Grid>
    );
}, (prevProps, nextProps) => {
    if (prevProps !== nextProps) {
        return false
    }
    return true
})
export default FilterBranch;
