// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    TextField,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
    DialogContent
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import TransformIcon from '@material-ui/icons/Transform';
import ChartOfAccounts from './chartOfAccounts'
let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function RegisterPayment() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    const dataStucturePayment = useSelector(state => state.RegisterPaymentReducer.dataStucturePayment)
    const bankList = useSelector(state => state.RegisterPaymentReducer.bankList)
    const selectedBillingInvoice = useSelector(state => state.BillingReducer.selectedBillingInvoice)
    const selectedPostDiff = useSelector(state => state.RegisterPaymentReducer.selectedPostDiff)
    const selectedAccounts = useSelector(state => state.RegisterPaymentReducer.selectedAccounts)
    const modalChartOfAccounts = useSelector(state => state.RegisterPaymentReducer.modalChartOfAccounts)
    const postDiffLabel = useSelector(state => state.RegisterPaymentReducer.postDiffLabel)

    let paymentDiff = parseFloat(selectedBillingInvoice?.req_quantity) - parseFloat(dataStucturePayment?.amount)
    const [value, setValue] = React.useState('Partially Paid');
    // const [modalChartOfAccounts,setModalChartOfAccounts] = React.useState(false)
    const handleChange = (event) => {
        setValue(event.target.value);
        dispatch({
            type: 'onChangeRegisterPayment',
            data: {
                dataStucturePayment: {
                    ...dataStucturePayment,
                    payment_status: event.target.value
                }
            }
        })
    };

    const onChangeText = (e) => {
        const name = e.target.name
        const value = e.target.value
        dispatch({
            type: 'onChangeRegisterPayment',
            data: {
                dataStucturePayment: {
                    ...dataStucturePayment,
                    [name]: value
                }
            }
        })
    }

    const onCLoseModalAccounts = (value, type = '') => {
        dispatch({
            type: 'onChangeRegisterPayment',
            data: {
                modalChartOfAccounts: value,
                type: type
            }
        })
    }
    return (

        <div>
            <div style={{ display: 'flex', flexDirection: width > 600 ? 'row' : 'column' }}>
                <div style={{ width: width > 600 ? '45%' : '100%' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Journal</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl style={{ width: '100%' }} size='small'>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: 'left', }}
                                    value={dataStucturePayment?.journal_id}
                                // onChange={onChangeCompany}
                                >
                                    {[{ journal_name: 'Bank', journal_id: '2' }].map((val, index) => {
                                        return <MenuItem key={index} value={val.journal_id}>{val.journal_name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Account</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div style={{ position: 'relative' }}>
                                <TextField required style={{ width: '90%' }} value={selectedAccounts?.balance_sheet_group_name} />
                                <TransformIcon onClick={() => onCLoseModalAccounts(true, 'Account')} style={{ position: 'absolute', top: 2, right: 2, color: '#3a7b81', cursor: 'pointer' }} />
                            </div>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Payment Method</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl style={{ width: '100%' }} size='small'>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: 'left', }}
                                    value={dataStucturePayment?.payment_method}
                                // onChange={onChangeCompany}
                                >
                                    {["Manual"].map((val, index) => {
                                        return <MenuItem key={index} value={val}>{val}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Recipient Bank Account</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl required style={{ width: '100%' }} size='small'>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select

                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name='bank_account_id'
                                    style={{ textAlign: 'left', }}
                                    value={dataStucturePayment?.bank_account_id}
                                    onChange={onChangeText}
                                >
                                    {bankList.map((val, index) => {
                                        return <MenuItem key={index} value={val.bank_account_id}>{val.bank_name}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ width: width > 600 ? '10%' : undefined }} />
                <div style={{ width: width > 600 ? '45%' : '100%' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Amount</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField onChange={onChangeText} name='amount' value={dataStucturePayment?.amount} style={{ width: '100%', marginTop: 10 }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Payment Date</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField value={(dataStucturePayment?.payment_date)} style={{ width: '100%', marginTop: 10 }} type='Date' />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Memo</Typography>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField readOnly value={dataStucturePayment?.reference_name} style={{ width: '100%', marginTop: 10 }} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {paymentDiff > 0 && paymentDiff != NaN > 0 ?
                <div style={{ width: width > 600 ? '45%' : '100%', marginTop: 10 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-start', height: '100%', justifyContent: 'flex-start' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Payment Difference</Typography>
                            </div>
                        </Grid>
                        <Grid container justify="flex-start" item xs={12} md={8}>
                            <div style={{ width: '100%' }}>
                                <Typography>{parseFloat(paymentDiff).toFixed(2)}</Typography>
                            </div>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <FormControlLabel value="Partially Paid" control={<Radio />} label={<Typography style={{ fontSize: 13 }}>Keep open</Typography>} />
                                <FormControlLabel value="Fully Paid" control={<Radio />} label={<Typography style={{ fontSize: 13 }}>Mark as fully paid</Typography>} />
                            </RadioGroup>
                            <div style={{ width: '100%', marginTop: 10, display: value == 'Fully Paid' ? undefined : 'none' }}>
                                <Typography style={{ fontSize: 13 }}>Post Difference In</Typography>
                                <div style={{ position: 'relative' }}>
                                    <TextField required style={{ width: '90%' }} value={selectedPostDiff?.balance_sheet_group_name} />
                                    <TransformIcon onClick={() => onCLoseModalAccounts(true)} style={{ position: 'absolute', top: 2, right: 2, color: '#3a7b81', cursor: 'pointer' }} />
                                </div>

                            </div>
                            <div style={{ width: '100%', marginTop: 10, display: value == 'Fully Paid' ? undefined : 'none' }}>
                                <Typography style={{ fontSize: 13 }}>Label</Typography>
                                <TextField value={postDiffLabel} style={{ width: '100%' }} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                :
                undefined
            }
            <Dialog fullWidth maxWidth='md' open={modalChartOfAccounts} aria-labelledby="responsive-dialog-title" >
                <form>
                    <div style={{ position: 'relative' }}>
                        <div style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                            <Typography variant="p" style={{ fontWeight: 'bold', fontSize: 17 }}>Chart of Accounts</Typography>
                        </div>
                        <div style={{ position: 'absolute', top: 10, right: 10 }}>
                            <CloseIcon onClick={() => onCLoseModalAccounts(false)} style={{ color: '#919b9c', fontSize: 19, cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div style={{ height: 1, width: '100%', backgroundColor: '#95a5a6' }} />
                    <DialogContent>
                        <ChartOfAccounts />
                    </DialogContent>
                    {/* <div style={{ height: 1, width: '100%', backgroundColor: '#95a5a6', marginBottom: 10, marginTop: 30 }} /> */}

                </form>
            </Dialog>

        </div>
    );
}
export default RegisterPayment;
