import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import {
    Tabs,
    Tab,
    Typography,
    Box,
    Grid,
    Breadcrumbs,
    Paper,
    Link
} from '@material-ui/core';
import ClientDetails from './services/clientDetails';
import Services from './services/index'
import Branches from './branches/index'
import { useSelector, useDispatch } from "react-redux";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    tabs: {

        "& .MuiTabs-indicator": {
            backgroundColor: "#fff",
            height: 3,
        },
        "& .MuiTab-root.Mui-selected": {
            color: '#fff'
        }
    }
}));

export default function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        // setValue(newValue);
        dispatch({
            type:'onChangeClientReducer',
            data:{
                tabIndex:newValue
            }
        })
    };
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const tabIndex = useSelector(state => state.ClientReducer.tabIndex)
    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/clientMasterList">
                            Client Master List
                        </Link>
                        <Typography color="textPrimary">{selectedClient.client_name}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={12}>
                    <ClientDetails />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Paper variant='outlined'>
                        <AppBar style={{ backgroundColor: '#2c6164' }} position="static">
                            <Tabs
                                value={tabIndex}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                className={classes.tabs}
                            >
                                <Tab label="Services and Contract" {...a11yProps(0)} />
                                {/* <Tab label="Branches" {...a11yProps(1)} /> */}

                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabIndex} index={0}>
                            <Services />
                        </TabPanel>
                        {/* <TabPanel value={tabIndex} index={1}>
                           <Branches/>
                        </TabPanel> */}
                    </Paper>

                </Grid>
            </Grid>
        </div>
    );
}