import {
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Box,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import React from "react";
import PropTypes from "prop-types";
// import { Tabs, Tab } from 'react-bootstrap';
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import BillingDetails2 from "./billingDetailsOldVersion";
import JournalEntry from "./journalEntry";
import BillingDetails from "./billingDetails";
import { getData } from "../../api/api";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ReceivedChangeStatus from "./receivedStatus";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  HashRouter as Router,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import RegisterPayment from "./registerPayment";
import CloseIcon from "@material-ui/icons/Close";
let width = window.innerWidth;

const useStyles2 = makeStyles((theme) => ({
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
}));
const useStyles = makeStyles({
  root: {
    width: "100%",
    // overflowX: "auto",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
  labelContainer: {
    padding: 0,
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function IndexBillingAmount() {
  const classes = useStyles();
  const classes2 = useStyles2();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.ClientReducer.alert);
  const branches = useSelector((state) => state.HomeReducer.branches);
  const alertMessage = useSelector((state) => state.ClientReducer.alertMessage);
  const alertColor = useSelector((state) => state.ClientReducer.alertColor);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selectedBillingInvoice = useSelector(
    (state) => state.BillingReducer.selectedBillingInvoice
  );
  const activities = useSelector((state) => state.BillingReducer.activities);
  const company_match = branches.filter(
    (val) => val.company_id == selectedBillingInvoice.company_id
  );
  const dataStucturePayment = useSelector(
    (state) => state.RegisterPaymentReducer.dataStucturePayment
  );
  const selectedPostDiff = useSelector(
    (state) => state.RegisterPaymentReducer.selectedPostDiff
  );
  const selectedAccounts = useSelector(
    (state) => state.RegisterPaymentReducer.selectedAccounts
  );
  const selectedJournal = useSelector(
    (state) => state.RegisterPaymentReducer.selectedJournal
  );
  const postDiffLabel = useSelector(
    (state) => state.RegisterPaymentReducer.postDiffLabel
  );
  const bill_reference_no = useSelector(
    (state) => state.BillingReducer.bill_reference_no
  );
  let company_logo = "";
  const [value, setValue] = React.useState(0);
  const [action, setAction] = React.useState(0);
  const [modalPayment, setModalPayment] = React.useState(false);
  const [openOnReceived, setOpenOnReceived] = React.useState(false);
  const [state, setState] = React.useState({
    receivedBy: "",
    receivedDate: new Date(),
  });

  const handleClickOpenOnReceive = () => {
    setOpenOnReceived(true);
  };
  const handleCloseOnReceive = () => {
    setOpenOnReceived(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (company_match.length > 0) {
    let split = String(company_match[0].company_logo).split("/");
    company_logo = split[split.length - 1];
  }
  let bru = "";
  let details = JSON.parse(selectedBillingInvoice.req_details);
  for (let index = 0; index < details.length; index++) {
    const element = details[index];
    let new_bru = element.client_branch_name;
    bru += new_bru;
    if (index < details.length - 1) {
      bru += ", ";
    }
  }
  const onCancelBilling = () => {
    if (
      window.confirm("Are you sure you want to cancelled this Billing Invoice")
    ) {
      let data = {
        user_id: localStorage.getItem("u"),
        req_id: selectedBillingInvoice.req_id,
        status: "Cancelled",
      };
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      getData("aam/onCancelBilling", data).then((res) => {
        let alertMessage = "";
        let alertColor = "";
        let alert = false;
        if (res.res == true) {
          alertMessage = "Success , Branch details has been added";
          alertColor = "#2ecc71";
          alert = true;
        } else if (res.res === "Exist") {
          alertMessage = "Warning , Billing Invoice is already Exist";
          alertColor = "#f39c12";
          alert = true;
        }
        dispatch({
          type: "onChangeClientReducer",
          data: {
            alert: alert,
            alertMessage: alertMessage,
            alertColor: alertColor,
          },
        });
        if (res.res == true) {
          history.push("/billing");
        }
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onDownload = (val) => {
    const link = document.createElement("a");
    link.download = val;
    link.href = "/http://192.168.2.85/api/assets/" + val;
    link.click();
  };

  const sections = [
    {
      title: "Billing Invoice",
      content: "Test",
    },
    {
      title: "Journal Items",
      content: "Test",
    },
  ];
  const getDataStructure = () => {
    let data = {
      selectedBillingInvoice: selectedBillingInvoice,
      user_id: localStorage.getItem("u"),
    };
    getData("aam/registerPaymentGetInitial", data).then((res) => {
      dispatch({
        type: "onChangeRegisterPayment",
        data: {
          dataStucturePayment: res.initial,
          bankList: res.bankList,
          selectedPostDiff: [],
          selectedAccounts: [],
          selectedJournal: res.journal[0],
          journalList: res.journal,
        },
      });
      setModalPayment(true);
    });
  };
  const onSubmitPayment = (e) => {
    e.preventDefault();

    let journalEntries = [];
    let entry = {
      account_id: selectedAccounts.balance_sheet_group,
      account_name: selectedAccounts.balance_sheet_group_name,
      type: "Debit",
      description: "Payment " + selectedBillingInvoice?.service_name,
      journal_name: selectedJournal.journal_name,
      journal_id: selectedJournal.journal_id,
      service_id: selectedBillingInvoice?.service_id,
      debit: dataStucturePayment?.amount,
      credit: "",
      bill_company: selectedBillingInvoice?.company_id,
      bill_branch_id: selectedBillingInvoice?.branch_id,
      client_id: selectedBillingInvoice?.client_id,
      reference_id: selectedBillingInvoice?.req_id,
      date_added: "",
      user_id: localStorage.getItem("u"),
    };
    journalEntries.push(entry);

    let paymentDiff = (
      parseFloat(selectedBillingInvoice?.req_quantity) -
      parseFloat(dataStucturePayment?.amount)
    ).toFixed(2);
    if (selectedPostDiff?.balance_sheet_group) {
      let entryError = [];
      entryError = {
        account_id: selectedPostDiff.balance_sheet_group,
        account_name: selectedPostDiff.balance_sheet_group_name,
        type: "Debit",
        description: postDiffLabel,
        journal_name: selectedJournal.journal_name,
        journal_id: selectedJournal.journal_id,
        service_id: selectedBillingInvoice?.service_id,
        debit: paymentDiff,
        credit: "",
        bill_company: selectedBillingInvoice?.company_id,
        bill_branch_id: selectedBillingInvoice?.branch_id,
        client_id: selectedBillingInvoice?.client_id,
        reference_id: selectedBillingInvoice?.req_id,
        date_added: "",
        user_id: localStorage.getItem("u"),
      };
      journalEntries.push(entryError);
    }

    let accountReceivableEntry = {
      account_id: 59,
      account_name: "ACCOUNTS RECEIVABLE",
      type: "Credit",
      description: "Payment " + selectedBillingInvoice?.service_name,
      journal_name: selectedJournal.journal_name,
      journal_id: selectedJournal.journal_id,
      service_id: selectedBillingInvoice?.service_id,
      debit: dataStucturePayment?.amount,
      credit: "",
      bill_company: selectedBillingInvoice?.company_id,
      bill_branch_id: selectedBillingInvoice?.branch_id,
      client_id: selectedBillingInvoice?.client_id,
      reference_id: selectedBillingInvoice?.req_id,
      date_added: "",
      user_id: localStorage.getItem("u"),
    };

    journalEntries.push(accountReceivableEntry);

    if (
      parseFloat(selectedBillingInvoice.req_quantity).toFixed(2) ==
      parseFloat(dataStucturePayment.amount).toFixed(2)
    ) {
      dataStucturePayment.payment_status = "Paid";
    }
    dataStucturePayment.client_id = selectedBillingInvoice?.client_id;
    dataStucturePayment.company_id = selectedBillingInvoice?.company_id;

    let data = {
      dataStucturePayment: dataStucturePayment,
      journalEntries: journalEntries,
    };
    getData("aam/insertPayment", data).then((res) => {});
  };
  const onClickEdit = () => {
    dispatch({
      type: "onChangeBilling",
      data: {
        actionType: "Edit",
      },
    });
    history.push("/billingForm");
  };

  const onSubmitReceived = (e) => {
    e.preventDefault();
    let data = {
      received_by: state.receivedBy,
      received_date: moment(state.receivedDate).format("YYYY-MM-DD"),
      req_id: selectedBillingInvoice?.req_id,
      status: "Received",
    };
    getData("aam/onBillReceived", data).then((res) => {
      if (res) {
        handleCloseOnReceive();
        dispatch({
          type: "onChangeBilling",
          data: {
            selectedBillingInvoice: {
              ...selectedBillingInvoice,
              received_by: state.receivedBy,
              received_date: moment(state.receivedDate).format("YYYY-MM-DD"),
              status: "Received",
            },
          },
        });
      }
    });
  };

  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  let invoiceDueDate = new Date(selectedBillingInvoice.received_date);
  if (
    selectedBillingInvoice?.invoice_due_type == "By Days" &&
    selectedBillingInvoice?.status == "Received"
  ) {
    invoiceDueDate.setDate(
      invoiceDueDate.getDate() + parseInt(selectedBillingInvoice?.invoice_due)
    );
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Billing And Collection </Typography>
            <Typography color="textPrimary">Billing</Typography>
            <Typography color="textPrimary">Billing Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <Grid container spacing={1}>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <div style={{ marginTop: 3, marginBottom: 3, marginLeft: 3 }}>
                  {selectedBillingInvoice?.status === "Pending" ? (
                    <button
                      onClick={() => handleClickOpenOnReceive()}
                      style={{
                        backgroundColor: "#75388f",
                        color: "#fff",
                        textDecoration: "none",
                        border: "none",
                        padding: 5,
                        cursor: "pointer",
                        marginRight: 5,
                      }}
                    >
                      TAG AS RECEIVED BY CLIENT
                    </button>
                  ) : undefined}
                  {selectedBillingInvoice?.status === "Received" ? (
                    <button
                      onClick={() => getDataStructure()}
                      style={{
                        backgroundColor: "#75388f",
                        color: "#fff",
                        textDecoration: "none",
                        border: "none",
                        padding: 5,
                        cursor: "pointer",
                        marginRight: 5,
                      }}
                    >
                      REGISTER PAYMENT
                    </button>
                  ) : undefined}

                  {/* <button
                    style={{
                      backgroundColor: undefined,
                      color: "#75388f",
                      textDecoration: "none",
                      border: "none",
                      padding: 5,
                      cursor: "pointer",
                      marginRight: 5,
                    }}
                  >
                    PRINT
                  </button> */}
                  {selectedBillingInvoice.status == "Pending" ? (
                    <button
                      onClick={onClickEdit}
                      style={{
                        backgroundColor: undefined,
                        color: "#75388f",
                        textDecoration: "none",
                        border: "none",
                        padding: 5,
                        cursor: "pointer",
                        marginRight: 5,
                      }}
                    >
                      EDIT
                    </button>
                  ) : undefined}
                  {selectedBillingInvoice?.status === "Pending" ? (
                    <button
                      style={{
                        backgroundColor: undefined,
                        color: "#75388f",
                        textDecoration: "none",
                        border: "none",
                        padding: 5,
                        cursor: "pointer",
                      }}
                    >
                      CANCEL
                    </button>
                  ) : undefined}
                </div>
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={6}>
                {/* <div style={{ display:'flex',alignItems:'center', borderWidth: 1, borderTopWidth: 0, borderBottomWidth: 0, backgroundColor: '#2c6164', color: '#fff', borderStyle: 'solid', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderColor: '#bdc3c7', borderTopRight: 50 }}>
                                    <Typography variant='p'>Created</Typography>

                                </div> */}
                {/* <div className="">

                                </div>
                                <div style={{ display:'flex',alignItems:'center', borderWidth: 1, borderTopWidth: 0, borderBottomWidth: 0, borderStyle: 'solid', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderColor: '#bdc3c7' }}>
                                    <Typography variant='p'>Recieved</Typography>

                                </div>
                                <div style={{ display:'flex',alignItems:'center', borderWidth: 1, borderTopWidth: 0, borderBottomWidth: 0, borderStyle: 'solid', paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, borderColor: '#bdc3c7' }}>
                                    <Typography variant='p'>Paid</Typography>

                                </div> */}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined" style={{ position: "relative" }}>
            <Grid
              container
              spacing={1}
              style={{ padding: 20, marginTop: width > 600 ? undefined : 20 }}
            >
              <Grid container justify="flex-end" item xs={12} md={12}>
                {/* <Button onClick={() => {
                                    window.open("http://192.168.2.85/api/report/printBillingRequests/" + selectedBillingInvoice.req_id);
                                }} style={{ fontWeight: 'bolder', fontSize: 13, backgroundColor: '#2980b9', color: '#fff', marginRight: 10 }} startIcon={<PrintIcon />}>{String('Print').toLocaleUpperCase()}</Button>
                                {selectedBillingInvoice.status === 'Pending' ?
                                    <Button onClick={() => onCancelBilling()} style={{ fontWeight: 'bolder', fontSize: 13, backgroundColor: '#e74c3c', color: '#fff' }}>{String('Cancel').toLocaleUpperCase()}</Button>
                                    : undefined
                                }
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {JSON.parse(selectedBillingInvoice.req_attachments).map((val, index) => {
                                        return <MenuItem key={index} onClick={() => onDownload(val)}> <Typography variant='p'>{val}</Typography>
                                            <IconButton  >
                                                <GetAppIcon style={{ color: '#2c6164' }} />
                                            </IconButton>
                                        </MenuItem>
                                    })

                                    }
                                </Menu> */}
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={12}>
                <Typography style={{ marginTop: 20 }}>
                  Billing Invoice
                </Typography>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={12}>
                <Typography variant="h4" style={{ fontWeight: "bold" }}>
                  {String(
                    selectedBillingInvoice?.req_reference_no
                  ).toLocaleUpperCase()}
                </Typography>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Client
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p">
                      {String(
                        selectedBillingInvoice?.client_name
                      ).toLocaleUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Date
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p">
                      {moment(selectedBillingInvoice?.req_date_added).format(
                        "YYYY-MM-DD"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Bill Reference
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p">
                      {selectedBillingInvoice?.docs_reference_no}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Invoice Due
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p">
                      {selectedBillingInvoice?.invoice_due_type == "By Days" &&
                      selectedBillingInvoice?.status == "Received"
                        ? moment(invoiceDueDate).format("YYYY-MM-DD")
                        : selectedBillingInvoice?.invoice_due}
                    </Typography>
                    {selectedBillingInvoice?.invoice_due_type == "By Days" &&
                    selectedBillingInvoice?.status == "Pending" ? (
                      <Typography variant="p" style={{ marginLeft: 5 }}>
                        Days
                      </Typography>
                    ) : undefined}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Service
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p" style={{ textAlign: "left" }}>
                      {String(
                        selectedBillingInvoice.service_name
                      ).toLocaleUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Status
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <div
                      style={{
                        padding: 2,
                        background:
                          selectedBillingInvoice.status === "Received"
                            ? "#2980b9"
                            : selectedBillingInvoice.status === "Cancelled"
                            ? "#e74c3c"
                            : new Date(invoiceDueDate) < new Date()
                            ? "#8e44ad"
                            : "#f39c12",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="p"
                        style={{ color: "#fff", fontWeight: "bold" }}
                      >
                        {selectedBillingInvoice.status === "Recieved"
                          ? selectedBillingInvoice.status
                          : new Date(invoiceDueDate) < new Date()
                          ? "Overdue Payment"
                          : selectedBillingInvoice.status}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Period Covered
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p">
                      {String(
                        moment(selectedBillingInvoice.req_date_start).format(
                          "MMM DD , YYYY"
                        ) +
                          " - " +
                          moment(selectedBillingInvoice.req_date_end).format(
                            "MMM DD , YYYY"
                          )
                      ).toLocaleUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                {selectedBillingInvoice?.received_date == null ? undefined : (
                  <Grid container spacing={1}>
                    <Grid container justify="flex-start" item xs={12} md={3}>
                      <Typography variant="p" style={{ fontWeight: "bold" }}>
                        Received Date
                      </Typography>
                    </Grid>
                    <Grid container justify="flex-start" item xs={12} md={9}>
                      <Typography variant="p">
                        {moment(selectedBillingInvoice?.received_date).format(
                          "YYYY-MM-DD"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Branch(s)
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={9}>
                    <Typography variant="p">
                      {String(bru).toLocaleUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" item xs={12} md={6}>
                {selectedBillingInvoice?.received_date == null ? undefined : (
                  <Grid container spacing={1}>
                    <Grid container justify="flex-start" item xs={12} md={3}>
                      <Typography variant="p" style={{ fontWeight: "bold" }}>
                        Received By
                      </Typography>
                    </Grid>
                    <Grid container justify="flex-start" item xs={12} md={9}>
                      <Typography variant="p">
                        {selectedBillingInvoice?.received_by}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <AppBar position="static" color="default" elevation={2}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                style={{ backgroundColor: "#fff" }}
              >
                <Tab label="Billing Invoice" {...a11yProps(0)} />
                <Tab label="Journal Items" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <BillingDetails />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <JournalEntry />
            </TabPanel>

            {/* <Tabs /> */}
            {/* <Tabs defaultActiveKey="billing" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="billing" title="Billing Invoice">
                                <BillingDetails />
                            </Tab>
                            <Tab eventKey="journal" title="Journal Items">
                                <JournalEntry />
                            </Tab>

                        </Tabs> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 1,
                left: 15,
              }}
            >
              <img
                style={{ width: 50, height: 50, marginRight: 10 }}
                src={
                  "https://images.workflow.gzonetechph.com/company_logo/" +
                  company_logo
                }
              />
              <Typography
                variant="p"
                style={{ fontWeight: "bolder", fontSize: 20 }}
              >
                {String(
                  selectedBillingInvoice.req_company_name
                ).toLocaleUpperCase()}
              </Typography>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} justify="flex-start" container>
                  <QueryBuilderIcon style={{ color: "#3498db" }} />
                  <Typography style={{ fontWeight: "bold", color: "#3498db" }}>
                    Activities
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} justify="flex-start" container>
                  {activities.map((val, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: "#ecf0f1",
                          width: "100%",
                          padding: 10,
                          display: "flex",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              borderRadius: 20,
                              background: "#8e44ad",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                              fontSize: 19,
                            }}
                          >
                            {val.name.charAt(0)}
                          </div>
                        </div>
                        <div style={{ marginLeft: 10 }}>
                          <Grid item xs={12} md={12} justify="flex-start">
                            <Typography style={{ fontWeight: 600 }} variant="p">
                              {val.name} - <span>{val.comment_date_added}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: 30,
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  height: 7,
                                  width: 7,
                                  borderRadius: 7 / 2,
                                  background: "#000",
                                }}
                              />
                              <Typography
                                variant="p"
                                style={{ marginLeft: 10 }}
                              >
                                {val.comment}
                              </Typography>
                            </div>
                          </Grid>
                        </div>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="md"
        open={modalPayment}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={onSubmitPayment}>
          <div style={{ position: "relative" }}>
            <div
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Typography
                variant="p"
                style={{ fontWeight: "bold", fontSize: 17 }}
              >
                Register Payment
              </Typography>
            </div>
            <div style={{ position: "absolute", top: 10, right: 10 }}>
              <CloseIcon
                onClick={() => setModalPayment(false)}
                style={{ color: "#919b9c", fontSize: 19, cursor: "pointer" }}
              />
            </div>
          </div>
          <div
            style={{ height: 1, width: "100%", backgroundColor: "#95a5a6" }}
          />
          <DialogContent>
            <RegisterPayment />
          </DialogContent>
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "#95a5a6",
              marginBottom: 10,
              marginTop: 30,
            }}
          />
          <div style={{ display: "flex", padding: 10, paddingBottom: 10 }}>
            <button
              type="submit"
              style={{
                backgroundColor: "#3a7b81",
                color: "#fff",
                textDecoration: "none",
                border: "none",
                padding: 8,
                cursor: "pointer",
                marginRight: 5,
              }}
            >
              REGISTER PAYMENT
            </button>
            <button
              type="button"
              onClick={() => setModalPayment(false)}
              style={{
                color: "#3a7b81",
                textDecoration: "none",
                border: "none",
                padding: 8,
                cursor: "pointer",
                marginRight: 5,
              }}
            >
              CANCEL
            </button>
          </div>
        </form>
      </Dialog>
      <ReceivedChangeStatus
        open={openOnReceived}
        handleClickOpen={handleClickOpenOnReceive}
        handleClose={handleCloseOnReceive}
        receivedBy={state.receivedBy}
        receivedDate={state.receivedDate}
        onChangeText={onChangeText}
        onSubmitReceived={onSubmitReceived}
      />
    </div>
  );
}
export default IndexBillingAmount;
