// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    Button
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import TableSupplierList from './tableSupplierList'
import Loading from '../../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import axios from "axios"
import { getData } from "../../api/api";
import PositionedSnackbar from '../../alert'
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
const useStyles = makeStyles({
    root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start'

    },
    container: {
        maxHeight: 440,
    },
    textFieldStyle: {
        width: '100%',
        backgroundColor: '#e9f2f5'
    },
    textFieldStyle2: {
        width: '100%',

    }
});


function SupplierDetails() {
    const classes = useStyles()
    const supplierReducer = useSelector(state => state.SupplierReducer)
    const homeReducer = useSelector(state => state.HomeReducer)
    const dispatch = useDispatch();
    let width = window.innerWidth;
    const [state, setState] = React.useState({
        edit: false,
        supplier_name: '',
        supplier_address: '',
        supplier_contact_person: '',
        supplier_contact_no: '',
        file: []
    })

    const editFunction = (val) => {
        if (val) {
            setState(prev => ({
                ...prev,
                edit: val,
            }))
        } else {
            setState(prev => ({
                ...prev,
                edit: val,
                supplier_name: supplierReducer.selectedSupplier.supplier_name,
                supplier_address: supplierReducer.selectedSupplier.supplier_address,
                supplier_contact_person: supplierReducer.selectedSupplier.supplier_contact_person,
                supplier_contact_no: supplierReducer.selectedSupplier.supplier_contact_no

            }))
        }

    }

    const onChangeText = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const onSubmitEdit = (e) => {
        e.preventDefault()
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let data = {
            // supplier_name: state.supplier_name,
            supplier_address: String(state.supplier_address).toLocaleUpperCase(),
            supplier_contact_person: String(state.supplier_contact_person).toLocaleUpperCase(),
            supplier_contact_no: state.supplier_contact_no,
            supplier_id: supplierReducer.selectedSupplier.supplier_id,
            user_id : homeReducer.loginData.user_id
        }

        let prev = {
            supplier_name: supplierReducer.selectedSupplier.supplier_name,
            supplier_address: supplierReducer.selectedSupplier.supplier_address,
            supplier_contact_person: supplierReducer.selectedSupplier.supplier_contact_person,
            supplier_contact_no: supplierReducer.selectedSupplier.supplier_contact_no
        }
        getData('aam/updateSupplier', { data: data, prev: prev }).then((res) => {
            if (res.res) {
                supplierReducer.selectedSupplier.supplier_address = String(state.supplier_address).toLocaleUpperCase()
                supplierReducer.selectedSupplier.supplier_contact_person = String(state.supplier_contact_person).toLocaleUpperCase()
                supplierReducer.selectedSupplier.supplier_contact_no = String(state.supplier_contact_no).toLocaleUpperCase()
                setState(prev => ({
                    ...prev,
                    alert: true,
                    alertMessage: "Success, Details has been updated",
                    alertColor: '#2ecc71',
                    edit: false
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    alert: true,
                    alertMessage: "Warning, Something went wrong ",
                    alertColor: '#f39c12'
                }))
            }
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })

    }

    React.useEffect(() => {
        setState(prev => ({
            ...prev,
            supplier_name: supplierReducer.selectedSupplier.supplier_name,
            supplier_address: supplierReducer.selectedSupplier.supplier_address,
            supplier_contact_person: supplierReducer.selectedSupplier.supplier_contact_person,
            supplier_contact_no: supplierReducer.selectedSupplier.supplier_contact_no
        }))
    }, [])
    return (
        <div>
            <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="#/supplierMasterList">
                            Back
                        </Link>
                        <Link color="inherit" href="/">
                            Home
                        </Link>
                        <Link color="inherit" href="#/supplierMasterList">
                            Supplier Master List
                        </Link>
                        <Typography color="textPrimary">Supplier Details</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid style={{ display: state.edit ? 'none' : undefined }} item xs={12} md={12}>
                    <Card variant='outlined' >
                        <CardContent>
                            <Grid container spacing={1} >
                                <Grid container item xs={12} md={12} justify='flex-start'>
                                    <Typography variant="h4" style={{ color: '#2c7f84' }}>Supplier Details</Typography>
                                </Grid>
                                <Grid container item xs={12} md={12} justify='flex-start'>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Supplier Name</Typography>
                                    <TextField readOnly name='bank_name' value={supplierReducer.selectedSupplier.supplier_name} variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container item xs={12} md={12} justify='flex-start'>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Address </Typography>
                                    <TextField readOnly name='bank_name' value={supplierReducer.selectedSupplier.supplier_address} variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>

                                <Grid container item xs={12} md={6} justify='flex-start'>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Contanct Person</Typography>
                                    <TextField readOnly name='bank_name' value={supplierReducer.selectedSupplier.supplier_contact_person} variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container item xs={12} md={3} justify='flex-start'>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Contact No.</Typography>
                                    <TextField readOnly name='bank_name' value={supplierReducer.selectedSupplier.supplier_contact_no} variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container item xs={12} md={3} justify='flex-start'>
                                    <Typography style={{ fontWeight: 'bold' }} variant="p">Date Added</Typography>
                                    <TextField readOnly name='bank_name' value={supplierReducer.selectedSupplier.supplier_date_added} variant='outlined' size='small' className={classes.textFieldStyle} />
                                </Grid>
                                <Grid container item xs={12} md={12} justify='flex-end'>
                                    <Button onClick={() => editFunction(true)} startIcon={<EditIcon />} type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Edit</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid style={{ display: state.edit ? undefined : 'none' }} item xs={12} md={12}>
                    <form onSubmit={onSubmitEdit}>
                        <Card variant='outlined' >
                            <CardContent>
                                <Grid container spacing={1} >
                                    <Grid container item xs={12} md={12} justify='flex-start'>
                                        <Typography variant="h4" style={{ color: '#2c7f84' }}>Edit Supplier Details</Typography>
                                    </Grid>
                                    <Grid container item xs={12} md={12} justify='flex-start'>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Supplier Name</Typography>
                                        <TextField readOnly name='supplier_name' value={state.supplier_name} variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid container item xs={12} md={12} justify='flex-start'>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Address </Typography>
                                        <TextField required onChange={onChangeText} name='supplier_address' value={state.supplier_address} variant='outlined' size='small' className={classes.textFieldStyle2} />
                                    </Grid>

                                    <Grid container item xs={12} md={6} justify='flex-start'>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Contanct Person</Typography>
                                        <TextField required onChange={onChangeText} name='supplier_contact_person' value={state.supplier_contact_person} variant='outlined' size='small' className={classes.textFieldStyle2} />
                                    </Grid>
                                    <Grid container item xs={12} md={3} justify='flex-start'>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Contact No.</Typography>
                                        <TextField required onChange={onChangeText} name='supplier_contact_no' value={state.supplier_contact_no} variant='outlined' size='small' className={classes.textFieldStyle2} />
                                    </Grid>
                                    <Grid container item xs={12} md={3} justify='flex-start'>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Date Added</Typography>
                                        <TextField readOnly name='supplier_date_added' value={supplierReducer.selectedSupplier.supplier_date_added} variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid container item xs={12} md={10} justify='flex-end'>

                                    </Grid>
                                    <Grid container item xs={12} md={1} justify='flex-end'>
                                        <Button type='button' onClick={() => editFunction(false)} style={{ backgroundColor: '#747d8c', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Cancel</Button>
                                    </Grid>
                                    <Grid container item xs={12} md={1} justify='flex-end'>
                                        <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Update</Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>

                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(SupplierDetails, renderPropsAreEqual);
