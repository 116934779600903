// import MarkerClusterer from '@google/markerclustererplus';
import { Typography, Grid, Card } from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import Loading from "../../../assets/loading2.gif";
import { useSelector, useDispatch } from "react-redux";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import Lightbox from "react-awesome-lightbox";
import "../../../custome.css";
function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

function FileDisplay({ state, onClosePDF, setState }) {
  console.log(state);
  const back = () => {
    if (state.indexPdf !== 0) {
      setState((prev) => ({
        ...prev,
        indexPdf: state.indexPdf - 1,
      }));
    }
  };
  const next = () => {
    if (state.pdfDisplay.length - 1 > state.indexPdf) {
      setState((prev) => ({
        ...prev,
        indexPdf: state.indexPdf + 1,
      }));
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <div
            style={{
              padding: 10,
              width: "100%",
              backgroundColor: "#000",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Typography style={{ color: "#fff" }}>ATTACHMENT(S)</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowBackIosIcon
                onClick={() => back()}
                style={{
                  color: "#fff",
                  fontSize: 15,
                  marginRight: 15,
                  cursor: "pointer",
                }}
              />
              <Typography style={{ color: "#fff", fontWeight: "bold" }}>
                {state.indexPdf + 1} &nbsp; / &nbsp;
                {String(state.pdfDisplay.length)}
              </Typography>
              <ArrowForwardIosIcon
                onClick={() => next()}
                style={{
                  color: "#fff",
                  fontSize: 15,
                  marginLeft: 15,
                  cursor: "pointer",
                }}
              />
            </div>
            <div style={{ cursor: "pointer" }} onClick={() => onClosePDF()}>
              <CloseIcon style={{ color: "#fff" }} />
            </div>
          </div>
        </Grid>
      </Grid>
      {String(state.pdfDisplay[state.indexPdf].url).includes("pdf") ? (
        <iframe
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
          src={
            state.pdfDisplay.length > 0
              ? state.pdfDisplay[state.indexPdf].url
              : undefined
          }
          title="W3Schools Free Online Web Tutorials"
        ></iframe>
      ) : (
        <div className="newDesign">
          <Lightbox image={state.pdfDisplay[state.indexPdf].url} />
        </div>
      )}
    </>
  );
}
export default React.memo(FileDisplay, renderPropsAreEqual);
