const initialState = {
  selectedBank: [],
  selectedBankTransaction: [],
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "onChangeBankReducer":
      return {
        ...state,
        ...action.data,
      };
    case "updateBalance":
      return {
        ...state,
        selectedBank: {
          ...state.selectedBank,
          bank_balance: action.data,
          bank_actual_balance: action.actual,
          borrowed_amount: action.borrowed,
        },
      };
    default:
      return state;
  }
};
export default dataReducer;
