// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog

} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import EventNoteIcon from '@material-ui/icons/EventNote';
import { DateRangePicker } from 'react-date-range';
import { getData } from "../../../src/components/api/api"
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
let width = window.innerWidth;
const IndexTransaction = React.memo((props) => {
    const dispatch = useDispatch()
    const rowsPerPage = useSelector(state => state.RequestLogsDetails.rowsPerPage)
    const selection = useSelector(state => state.RequestLogsDetails.selection)

    const { refreshFilter, openDateRange, getDateTransaction, state, setState } = props
    console.log('Filter')
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={7}>
            </Grid>
            <Grid item xs={12} md={2}>
                <Card variant='outlined' style={{ width: '100%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input readOnly value={moment(state.selection.startDate).format('YYYY-MM-DD')} placeholder="Date start" style={{ width: '100%', borderStyle: 'none', outline: 'none' }} name='searchDriver' />
                    <EventNoteIcon onClick={() => openDateRange()} style={{ cursor: 'pointer', color: '#2c6164' }} />
                </Card>
            </Grid>
            <Grid item xs={12} md={2}>
                <Card variant='outlined' style={{ width: '100%', padding: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input readOnly value={moment(state.selection.endDate).format('YYYY-MM-DD')} placeholder="Date end" style={{ width: '100%', borderStyle: 'none', outline: 'none' }} name='searchDriver' />
                    <EventNoteIcon onClick={() => openDateRange()} style={{ cursor: 'pointer', color: '#2c6164' }} />
                </Card>
            </Grid>
            <Grid item xs={12} md={1}>
                <Button type='buttom' onClick={() => {
                    getDateTransaction(0, rowsPerPage)
                    dispatch({
                        type: 'onChnageRequestLogs',
                        data: {
                            page: 0,
                        }
                    })
                }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Filter</Button>
            </Grid>
            <Dialog open={state.date_range_modal} onClose={() => openDateRange()} fullWidth maxWidth='md'>
                <DialogTitle>Date Range</DialogTitle>
                <DialogContent>
                    <Grid item xs={12} md={5}>
                        {/* <DateRange
                            editableDateInputs={true}
                            moveRangeOnFirstSelection={true}
                            onChange={item => setState({ ...state, ...item })}
                            ranges={[state.selection]}
                        /> */}
                        <DateRangePicker
                            onChange={item =>{ 
                                setState({
                                ...state, ...item,
                                refreshFilter: !state.refreshFilter,
                                refreshFilterBranch: !state.refreshFilterBranch
                            })}}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={[state.selection]}
                            direction="horizontal"
                        />;
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => openDateRange()} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Done</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}, (prevProps, nextProps) => {
    if (prevProps.refreshFilter !== nextProps.refreshFilter) {
        return false
    }
    return true
})
export default IndexTransaction;
