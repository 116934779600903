// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  TextareaAutosize,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Dialog,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { DropzoneDialog } from "material-ui-dropzone";
import { getData } from "../../../src/components/api/api";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiAlert from "@material-ui/lab/Alert";
import PositionedSnackbar from "../alert";
import CloseIcon from "@material-ui/icons/Close";
import PdfPic from "../../assets/pdf.png";
import FilesDIsplay from "../expenses/requestList/filesDIsplay";
import Lightbox from "react-awesome-lightbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ChatIcon from "@material-ui/icons/Chat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import SendIcon from "@material-ui/icons/Send";
import { AddComment } from "@material-ui/icons";
import CustomizedTimeline from "../timeline/indexTimeline";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

const RowInputs = ({ state, state_val, setState, index, edit }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRow: state.detailsRow.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      {state.edit ? (
        <StyledTableRow>
          <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
            {moment(state.detailsRow[index].date).format("YYYY-MM-DD")}
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
          >
            {" "}
            <TextareaAutosize
              required
              style={{ width: "100%", borderColor: "#c5bfbf" }}
              name="description"
              minRows={2.3}
              value={state.detailsRow[index].description}
              aria-label="empty textarea"
              placeholder="Empty"
              onChange={onChangeValue}
            />
          </StyledTableCell>
          <StyledTableCell
            style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
          >
            {" "}
            <TextField
              required
              style={{ width: "100%", background: "#fff" }}
              variant="outlined"
              name="total"
              onChange={onChangeValue}
              value={state.detailsRow[index].total}
              size="small"
              type="number"
            ></TextField>
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        <StyledTableRow>
          <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
            {moment(state.detailsRow[index].date).format("YYYY-MM-DD")}
          </StyledTableCell>
          <StyledTableCell
            style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
          >
            {state.detailsRow[index].description}
          </StyledTableCell>
          <StyledTableCell
            style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
          >
            {formatNumber(parseFloat(state.detailsRow[index].total).toFixed(2))}
          </StyledTableCell>
        </StyledTableRow>
      )}
    </>
  );
};
function RequestDetails({
  handleClose,
  refreshFunc,
  action,
  selectedRequest,
  edit,
  onSubmitCancelled,
}) {
  const [state, setState] = React.useState({
    detailsRow: [
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        total: "",
      },
    ],
    payee: "",
    company_name: "",
    open_modal_attachment: false,
    file: [],
    payeeList: [],
    company_id: "",
    branchList: [],
    branch_name: "",
    branch_id: "",
    department: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    attachments: [],
    refresh: false,
    status: "",
    comment: [],
    statusColor: "",
    edit: false,
    payee_id: "",
    company_logo: "",
    valStatus: "Pending",
    openFile: false,
    imageDisplay: [],
    pdfDisplay: [],
    openFilePDF: false,
    indexPdf: 0,
    selected_payee: [],
    comment_input: "",
    proceed: false,
    req_recipient: "",
    estimated_date_release: "",
    recurring: "",
  });
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const homeReducer = useSelector((state) => state.HomeReducer);
  const onSubmit = (e) => {
    e.preventDefault();
    onProceed();
  };

  const onProceed = () => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });

    if (state.attachments.length === 0 && state.file.length === 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please attach files !",
        alertColor: "#f39c12",
      }));
    } else {
      let data = {
        payee: state.payee,
        company_name: state.company_name,
        detailsRow: state.detailsRow,
        branch_name: state.branch_name,
        branch_id: state.branch_id,
        company_id: state.company_id,
        type: "Request for Payment",
        type_name: "RFP",
        user_id: localStorage.getItem("u"),
        department: state.department,
        req_attachments: state.attachments,
        req_id: selectedRequest.req_id,
        method: "Edit",
        payee_id: state.payee_id,
        req_hierarchy_structure: JSON.parse(
          selectedRequest.req_hierarchy_structure
        ),
        proceed: state.proceed,
        req_recipient: state.req_recipient,
      };

      uploadForm(data);
    }
  };

  const uploadForm = (data) => {
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/createRequest",
        formData,
        config
      )
      .then((response) => {
        if (response.data.result == "Success") {
          let imageDisplay = [];
          let pdfDisplay = [];
          let attachments = response.data.file;
          for (let index = 0; index < attachments.length; index++) {
            const element = attachments[index];
            let data_details = {
              url:
                "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                element,
              title: element,
            };
            let ext = String(element).split(".");
            let ext2 = ext[ext.length - 1];
            if (ext2 == "pdf") {
              pdfDisplay.push(data_details);
            } else {
              imageDisplay.push(data_details);
            }
          }
          setState((prev) => ({
            ...prev,
            edit: false,
            alert: true,
            alertMessage: "Success, Your request has been created.",
            alertColor: "#2ecc71",
            file: [],
            attachments: attachments,
            pdfDisplay: pdfDisplay,
            imageDisplay: imageDisplay,
          }));
          handleClose();
        }
        refreshFunc();
        dispatch({
          type: "Loading",
          data: false,
        });
      });
  };
  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeFile = (files) => {
    setState({
      ...state,
      file: files,
    });
  };
  const handleClose_modal = () => {
    setState((prev) => ({ ...prev, open_modal_attachment: false }));
  };

  React.useEffect(() => {
    dispatch({
      type: "Loading",
      data: false,
    });
    let branches = [];
    homeReducer.branches.forEach((val) => {
      branches.push(val.branch_id);
    });
    let company_logo_match = homeReducer.branches.filter(
      (val) => val.branch_id === selectedRequest.req_branch_id
    );
    let company_logo = "";
    if (company_logo_match.length > 0) {
      let split = String(company_logo_match[0].company_logo).split("/");
      company_logo = split[split.length - 1];
    }
    let payee = selectedRequest.req_payee;
    if (action === "Details") {
      let req_details = JSON.parse(selectedRequest.req_details);
      let company_id = selectedRequest.req_company_id;
      let branch_id = selectedRequest.req_branch_id;
      let company_name = selectedRequest.company_name;
      let branch_name = selectedRequest.branch_name;
      let department = selectedRequest.department;

      let attachments = JSON.parse(selectedRequest.req_attachments);
      let status = selectedRequest.status;
      let comment = selectedRequest.comments;
      let branches = homeReducer.branches.filter(
        (val) => val.company_name == company_name
      );
      let bgColor = "#3498db";
      let imageDisplay = [];
      let pdfDisplay = [];

      for (let index = 0; index < attachments.length; index++) {
        const element = attachments[index];
        let data_details = {
          url:
            "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
            element,
          title: element,
        };
        let ext = String(element).split(".");
        let ext2 = ext[ext.length - 1];
        if (ext2 == "pdf") {
          pdfDisplay.push(data_details);
        } else {
          imageDisplay.push(data_details);
        }
      }
      if (status === "Approved") {
        bgColor = "#2ecc71";
      }
      if (status === "Pending") {
        bgColor = "#faab2d";
      }
      if (status === "Denied") {
        bgColor = "#bf7bdb";
      }
      setState((prev) => ({
        ...prev,
        imageDisplay: imageDisplay,
        pdfDisplay: pdfDisplay,
        detailsRow: req_details,
        company_id: company_id,
        branch_id: branch_id,
        company_name: company_name,
        branch_name: branch_name,
        department: department,
        branchList: branches,
        payee: payee,
        attachments: attachments,
        status: status,
        comment: comment,
        statusColor: bgColor,
        company_logo: company_logo,
        payee_id: selectedRequest.req_payee_id,
        req_recipient: selectedRequest.req_recipient,
        estimated_date_release: selectedRequest.estimated_date_release,
        recurring: selectedRequest.recurring,
      }));
    }
    getData("aam/getSupplierDetails", branches).then((res) => {
      let valStatus = "Pending";
      JSON.parse(selectedRequest.req_hierarchy_structure).map(
        (ary2, index2) => {
          var colorss = "";

          if (
            parseInt(index2) < parseInt(selectedRequest.req_hierarchy_level)
          ) {
            if (
              selectedRequest.status === "Denied" &&
              index2 === selectedRequest.req_hierarchy_level - 1
            ) {
              colorss = "#e74c3c";
              valStatus = "Denied";
            } else {
              colorss = "#2ecc71";
              valStatus = "Approved";
            }
          } else {
            if (selectedRequest.status === "Cancelled") {
              valStatus = "Cancelled";
              colorss = "#e74c3c";
            } else if (selectedRequest.status === "Denied") {
              valStatus = "Denied";
              colorss = "#e74c3c";
            } else {
              colorss = "#faab2d";
            }
          }
          let name = "";
          ary2.approver.forEach((approver) => {
            if (name != "") {
              name = " / ";
            }
            name += approver.name;
          });
        }
      );
      let selected_payee = res.data.filter(
        (val_payee) => val_payee.supplier_name === payee
      );
      setState((prev) => ({
        ...prev,
        payeeList: res.data,
        valStatus: valStatus,
        selected_payee: selected_payee[0],
      }));
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: false,
        },
      });
    });
  }, []);
  const onChangeCompany = (e) => {
    let company_name = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_name == company_name
    );
    let company_id = "";
    if (branches.length > 0) {
      company_id = branches[0].company_id;
    }
    setState({
      ...state,
      company_name: company_name,
      rule: [],
      emp_type: "",
      payroll_rule_group_id: "",
      branchList: branches,
      company_id: company_id,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.branch_id == branch_id
    );
    if (branches.length > 0) {
      setState((prev) => ({
        ...prev,
        branch_id: branch_id,
        branch_name: branches[0].branch_name,
      }));
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const onChangeTextPayee = (e, values) => {
    let name = "payee";
    let value = values.supplier_name;
    let payee_id = values.supplier_id;
    setState((prev) => ({
      ...prev,
      selected_payee: values,
      [name]: value,
      payee_id: payee_id,
    }));
  };
  function daysDifference(d0, d1) {
    var diff = new Date(d1).setHours(12) - new Date(d0).setHours(12);
    return Math.round(diff / 8.64e7);
  }
  const addComment = () => {
    let request_comments = {
      app_id: "7",
      user_id: homeReducer.loginData.user_id,
      comment_type: "Text",
      comment: state.comment_input,
      req_id: selectedRequest.req_id,
      req_reference_no: selectedRequest.req_reference_no,
      req_user_id: selectedRequest.user_id,
    };
    getData("HumanResource/LogRequestCommentRequestor", request_comments).then(
      () => {
        let data = {
          comment: state.comment_input,
          comment_date_added: "2022-03-07 15:46:53",
          comment_type: "Text",
          req_comment_id: "",
          req_id: "",
          user_fname: homeReducer.loginData.user_fname,
          user_lname: homeReducer.loginData.user_fname,
        };
        selectedRequest.comments.push(data);

        setState((prev) => ({
          ...prev,
        }));
        refreshFunc();
        handleClose();
      }
    );
  };

  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10}>
          <CustomizedTimeline req_id={selectedRequest.req_id} />
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={6}>
          {!state.edit ? (
            <Card elevation={4} style={{ background: "#fff" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid>
                    <img
                      style={{ width: 130, height: 130 }}
                      src={
                        "https://images.workflow.gzonetechph.com/company_logo/" +
                        state.company_logo
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      Request for Payment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid xs={12}>
                        <Typography variant="p">
                          {" "}
                          Company :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.company_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Branch :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.branch_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Department :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.department}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Estimated Date Release :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.estimated_date_release}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Recurring :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.recurring}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      Payee :{" "}
                      <span style={{ fontWeight: "bold" }}>{state.payee}</span>
                    </Typography>
                  </Grid>
                  {state.payee == "G-XCHANGE INC." ? (
                    <Grid item xs={12}>
                      <Typography variant="p">
                        Recipient :{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {state.req_recipient}
                        </span>
                      </Typography>
                    </Grid>
                  ) : undefined}

                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "20%" }}
                              >
                                Date
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Description
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.detailsRow.map((val, index) => {
                              return (
                                <RowInputs
                                  state={state}
                                  state_val={val}
                                  setState={setState}
                                  index={index}
                                  edit={edit}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12} md={12}>
                    <Typography
                      variant={"p"}
                      style={{ fontWeight: "bold", fontSize: 20 }}
                    >
                      Total :{" "}
                      {formatNumber(
                        state.detailsRow.reduce((count, val) => {
                          let total = val.total;
                          if (val.total === "") {
                            total = 0;
                          }
                          return (count += parseFloat(total));
                        }, 0)
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.4,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Attachment(s)
                    </Typography>
                  </Grid>
                  {state.edit ? (
                    <Grid item xs={12} md={12}>
                      {state.attachments.map((val, index) => {
                        let ext = String(val).split(".");
                        let ext2 = ext[ext.length - 1];
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="p" style={{ fontSize: 15 }}>
                              {val}
                            </Typography>
                            <img
                              onClick={() => {
                                window.open(
                                  "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                    val
                                );
                              }}
                              src={
                                ext2 === "pdf"
                                  ? PdfPic
                                  : "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                    val
                              }
                              style={{
                                width: 100,
                                height: 100,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        );
                      })}
                      {/* <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>     */}
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={12}>
                      {state.imageDisplay.map((val, index) => {
                        if (index == 0)
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography variant="p" style={{ fontSize: 15 }}>
                                {val.title}
                              </Typography>
                              <div
                                style={{
                                  position: "relative",
                                  width: 100,
                                  height: 100,
                                }}
                              >
                                {index == 0 ? (
                                  <div
                                    onClick={() => {
                                      setState((prev) => ({
                                        ...prev,
                                        openFile: true,
                                      }));
                                      // window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: 100,
                                      height: 100,
                                      cursor: "pointer",
                                      background: "#00000047",
                                      position: "absolute",
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      style={{ color: "#ffff" }}
                                    >
                                      +{state.imageDisplay.length - 1}
                                    </Typography>
                                  </div>
                                ) : undefined}

                                <img
                                  src={val.url}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          );
                      })}

                      {state.pdfDisplay.map((val, index) => {
                        if (index == 0)
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography variant="p" style={{ fontSize: 15 }}>
                                {val.title}
                              </Typography>
                              <div
                                style={{
                                  position: "relative",
                                  width: 100,
                                  height: 100,
                                }}
                              >
                                {index == 0 ? (
                                  <div
                                    onClick={() => {
                                      setState((prev) => ({
                                        ...prev,
                                        openFilePDF: true,
                                        indexPdf: 0,
                                      }));
                                      // window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: 100,
                                      height: 100,
                                      cursor: "pointer",
                                      background: "#00000047",
                                      position: "absolute",
                                    }}
                                  >
                                    <Typography
                                      variant="h6"
                                      style={{ color: "#ffff" }}
                                    >
                                      +{state.pdfDisplay.length - 1}
                                    </Typography>
                                  </div>
                                ) : undefined}

                                <img
                                  src={PdfPic}
                                  style={{
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div>
                          );
                      })}

                      {/* <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>     */}
                    </Grid>
                  )}
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.4,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Request Hierarchy
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* <Typography variant="p" style={{ color: '#7f8c8d', fontWeight: 'bold', fontSize: 21, marginBottom: 10 }}>{'Request Hierarchy'}</Typography> */}
                      <Grid container spacing={1}>
                        {JSON.parse(
                          selectedRequest.req_hierarchy_structure
                        ).map((ary2, index2) => {
                          var colorss = "";
                          let valStatus = "Pending";
                          if (
                            parseInt(index2) <
                            parseInt(selectedRequest.req_hierarchy_level)
                          ) {
                            if (
                              selectedRequest.status === "Denied" &&
                              index2 === selectedRequest.req_hierarchy_level - 1
                            ) {
                              colorss = "#e74c3c";
                              valStatus = "Denied";
                            } else {
                              colorss = "#2ecc71";
                              valStatus = "Approved";
                            }
                          } else {
                            if (selectedRequest.status === "Cancelled") {
                              valStatus = "Cancelled";
                              colorss = "#e74c3c";
                            } else if (selectedRequest.status === "Denied") {
                              valStatus = "Denied";
                              colorss = "#e74c3c";
                            } else {
                              colorss = "#faab2d";
                            }
                          }
                          let name = "";
                          ary2.approver.forEach((approver) => {
                            if (name != "") {
                              name = " / ";
                            }
                            name += approver.name;
                          });
                          return (
                            <Grid item xs={12} md={6}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="p"
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colorss,
                                  }}
                                >
                                  {valStatus}
                                </Typography>

                                <Typography variant="p">{name}</Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  {ary2.title}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={selectedRequest.status === "Return" ? 6 : 8}
                  ></Grid>
                  <Grid item xs={12} md={2}>
                    {state.valStatus === "Pending" ? (
                      <Button
                        type="button"
                        onClick={() => {
                          onSubmitCancelled("Cancelled");
                        }}
                        style={{
                          backgroundColor: "#e74c3c",
                          color: "#fff",
                          width: width < 600 ? "100%" : 100,
                        }}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    ) : undefined}
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {selectedRequest.status === "Pending" ||
                    selectedRequest.status === "Return" ? (
                      <>
                        <Button
                          type="button"
                          onClick={() => {
                            setState((prev) => ({ ...prev, edit: true }));
                          }}
                          style={{
                            backgroundColor: "#2c6164",
                            color: "#fff",
                            width: width < 600 ? "100%" : 100,
                          }}
                          variant="contained"
                        >
                          Edit
                        </Button>
                      </>
                    ) : undefined}
                  </Grid>
                  {selectedRequest.status === "Return" ? (
                    <>
                      <Grid item xs={12} md={2}>
                        <Button
                          type="button"
                          onClick={() => {
                            setState((prev) => ({ ...prev, proceed: true }));
                            setTimeout(() => {
                              onProceed();
                            }, 500);
                          }}
                          style={{
                            backgroundColor: "#34495e",
                            color: "#fff",
                            width: width < 600 ? "100%" : 100,
                          }}
                          variant="contained"
                        >
                          Proceed
                        </Button>
                      </Grid>
                    </>
                  ) : undefined}
                </Grid>
              </CardContent>
            </Card>
          ) : (
            <form onSubmit={onSubmit}>
              <Card elevation={4} style={{ background: "#fff" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h4" style={{ color: "#2c7f84" }}>
                        Request for Payment
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="p"
                        style={{ color: "#f39c12", fontWeight: "bold" }}
                      >
                        <i>
                          Note : Changing company is not allowed, please cancel
                          the request and create new one.
                        </i>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Company
                      </Typography>
                      <FormControl
                        variant={"outlined"}
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          readOnly
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={state.company_name}
                          // onChange={handleChange}
                          // onChange={onChangeCompany}
                        >
                          {homeReducer.companies.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.company_name}>
                                {val.company_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Branch
                      </Typography>
                      <FormControl
                        variant={"outlined"}
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={state.branch_id}
                          // onChange={handleChange}
                          onChange={onChangeBranch}
                        >
                          {state.branchList.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.branch_id}>
                                {val.branch_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Department
                      </Typography>
                      <FormControl
                        variant={"outlined"}
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          required
                          name="department"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={state.department}
                          onChange={onChangeText}
                        >
                          {homeReducer.department.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.dept_name}>
                                {val.dept_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Payee
                      </Typography>
                      {/* <input required type="text" list="cars" style={{ width: '100%', borderColor: '#c5bfbf', height: 39, borderStyle: 'solid', borderRadius: 3, borderWidth: 1 }} value={state.payee}
                                                name='payee' aria-label="empty textarea" placeholder="Select" onChange={onChangeTextPayee} />
                                            <datalist id="cars">
                                                {state.payeeList.map((val2, key) => {
                                                    return <option value={val2.category_details}>{val2.category_details}</option>
                                                })
                                                }
                                            </datalist> */}
                      <Autocomplete
                        id="combo-box-demo"
                        size="small"
                        options={state.payeeList}
                        getOptionLabel={(option) => option.supplier_name}
                        onChange={onChangeTextPayee}
                        value={state.selected_payee}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            variant="outlined"
                          />
                        )}
                      />

                      {/* <FormControl variant={'outlined'} style={{ width: '100%' }} size='small'>
                                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={state.payee}
                                                    name='payee'
                                                    onChange={onChangeText}
                                                >
                                                    {state.payeeList.map((val, index) => {
                                                        return <MenuItem key={index} value={val.category_details}>{val.category_details}</MenuItem>
                                                    })

                                                    }
                                                </Select>
                                            </FormControl> */}
                    </Grid>
                    {state.selected_payee?.supplier_name == "G-XCHANGE INC." ? (
                      <Grid item xs={12} md={12}>
                        <Typography style={{ fontWeight: "bold" }} variant="p">
                          Recipient
                        </Typography>
                        <TextField
                          value={state.req_recipient}
                          onChange={onChangeText}
                          name="req_recipient"
                          size="small"
                          variant="outlined"
                          required
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    ) : undefined}
                    <Grid item xs={12} md={12}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography style={{ fontWeight: "bold" }} variant="p">
                          Date Estimated Release
                        </Typography>
                        <TextField
                          variant="outlined"
                          type="date"
                          size="small"
                          name="estimated_date_release"
                          onChange={onChangeText}
                          value={state.estimated_date_release}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div
                        style={{
                          backgroundColor: "#2c7f84",
                          height: 1.5,
                          width: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Date
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography style={{ fontWeight: "bold" }} variant="p">
                        Total
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "20%" }}
                              >
                                Date
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Description
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.detailsRow.map((val, index) => {
                              return (
                                <RowInputs
                                  state={state}
                                  state_val={val}
                                  setState={setState}
                                  index={index}
                                  edit={edit}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>

                    <Grid container justify="flex-end" item xs={12} md={12}>
                      <Typography
                        variant={"p"}
                        style={{ fontWeight: "bold", fontSize: 20 }}
                      >
                        Total :{" "}
                        {formatNumber(
                          state.detailsRow.reduce((count, val) => {
                            let total = val.total;
                            if (val.total === "") {
                              total = 0;
                            }
                            return (count += parseFloat(total));
                          }, 0)
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Tooltip title="Add Row">
                        <IconButton
                          onClick={() => {
                            state.detailsRow.push({
                              date: new Date(),
                              description: "",
                              total: "",
                            });
                            setState((prev) => ({ ...prev }));
                          }}
                          style={{
                            backgroundColor: "#2c6164",
                            color: "#fff",
                            marginRight: 10,
                            height: 40,
                            width: 40,
                          }}
                          variant="contained"
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove Row">
                        <IconButton
                          onClick={() => {
                            state.detailsRow.splice(
                              state.detailsRow.length - 1,
                              1
                            );
                            setState((prev) => ({ ...prev }));
                          }}
                          style={{
                            backgroundColor: "#2c6164",
                            color: "#fff",
                            marginRight: 10,
                            height: 40,
                            width: 40,
                          }}
                          variant="contained"
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Attach Files">
                        <IconButton
                          style={{
                            backgroundColor: "#2c6164",
                            color: "#fff",
                            marginRight: 10,
                            height: 40,
                            width: 40,
                          }}
                          variant="contained"
                          onClick={() => setOpen(true)}
                        >
                          <div style={{ position: "relative" }}>
                            <AttachFileIcon />
                            <div
                              style={{
                                height: 20,
                                width: 20,
                                borderRadius: 10,
                                background: "#eb4d4b",
                                position: "absolute",
                                top: -10,
                                right: -15,
                                display:
                                  state.file.length == 0 ? "none" : "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="p"
                                style={{ color: "#fff", fontSize: 13 }}
                              >
                                {state.file.length}
                              </Typography>
                            </div>
                          </div>
                        </IconButton>
                      </Tooltip>
                      <DropzoneDialog
                        acceptedFiles={["image/*,application/pdf"]}
                        cancelButtonText={"cancel"}
                        filesLimit={50}
                        submitButtonText={"done"}
                        maxFileSize={50000000}
                        open={open}
                        onClose={() => setOpen(false)}
                        onSave={() => setOpen(false)}
                        initialFiles={state.file}
                        onChange={handleChangeFile}
                        clearOnUnmount={false}
                        onDelete={(e) => {
                          for (
                            let index2 = 0;
                            index2 < state.file.length;
                            index2++
                          ) {
                            const element = state.file[index2];
                            if (element.name === e.name) {
                              state.file.splice(index2, 1);
                            }
                            setState((prev) => ({ ...prev }));
                          }
                        }}
                        // onSave={(files) => {
                        //     console.log('Files:', files);
                        //     setOpen(false);
                        // }}
                        showPreviews={false}
                        // showFileNames={true}
                        showFileNamesInPreview={false}
                        showPreviewsInDropzone={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="p" style={{ fontWeight: "bold" }}>
                        Attachment(s)
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      {state.attachments.map((val, index) => {
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="p" style={{ fontSize: 15 }}>
                              {val}
                            </Typography>
                            <div
                              style={{
                                position: "relative",
                                width: 100,
                                height: 100,
                              }}
                            >
                              <img
                                onClick={() => {
                                  window.open(
                                    "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                      val
                                  );
                                }}
                                src={
                                  "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                  val
                                }
                                style={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: 1,
                                  top: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  state.attachments.splice(index, 1);
                                  setState((prev) => ({ ...prev }));
                                }}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Grid>
                    <Grid container item xs={12} md={10} justify="flex-end">
                      <Button
                        type="button"
                        onClick={() => {
                          setState((prev) => ({ ...prev, edit: false }));
                        }}
                        style={{
                          backgroundColor: "#747d8c",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                          marginRight: 5,
                        }}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid container item xs={12} md={2} justify="flex-end">
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                        }}
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" style={{ background: "#fff" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid container justify="flex-start" item xs={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ChatIcon />
                    <Typography variant="h6" style={{ color: "#2c7f84" }}>
                      Comments and Logs
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ height: 500, overflowY: "auto" }}>
                    {selectedRequest?.comments.map((val, index) => {
                      let c_type = val.comment_type;
                      if (c_type === "Text") {
                        c_type = "Comment";
                      }
                      return (
                        <div style={{ marginBottom: 10 }}>
                          <div
                            style={{
                              paddingRight: 25,
                              paddingLeft: 25,
                              borderWidth: 1,
                              borderColor: "#c5bfbf",
                              background: "#f7f9fa",
                              padding: 10,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{ fontWeight: "bold", color: "#2f3640" }}
                            >
                              {c_type}
                            </Typography>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {val.comment_type === "System" ? (
                                <SettingsIcon style={{ color: "#2c3e50" }} />
                              ) : (
                                <AccountCircleIcon
                                  style={{ color: "#3498db" }}
                                />
                              )}

                              <Typography
                                variant="p"
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  color: "#2980b9",
                                }}
                              >
                                {`${val.user_lname} ${val.user_fname}`}
                                <span style={{ color: "#000", marginLeft: 10 }}>
                                  {val.comment_date_added}
                                </span>
                              </Typography>
                            </div>
                            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                              <Typography variant="p">{`${val.comment}`}</Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Grid container spacing={1}>
                    <Card
                      variant="outlined"
                      style={{
                        width: width > 600 ? "100%" : "100%",
                        padding: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        value={state.comment_input}
                        name="comment_input"
                        onChange={onChangeText}
                        placeholder="Add Comment"
                        style={{
                          borderStyle: "none",
                          outline: "none",
                          width: "100%",
                        }}
                      />
                      <SendIcon
                        style={{ cursor: "pointer", color: "#3d7f85" }}
                        onClick={() => addComment()}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFile: false,
          }));
        }}
        open={state.openFile}
      >
        {/* <DialogContent> */}
        {/* <FilesDIsplay />
         */}
        <Lightbox
          images={state.imageDisplay}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              openFile: false,
            }));
          }}
        />
        {/* {state.imageDisplay.map((val,index)=>{
                    
                    if(index == 0 )
                    <iframe src={val.url} title="W3Schools Free Online Web Tutorials"></iframe>

                })

                } */}

        {/* </DialogContent> */}
      </Dialog>
      <Dialog
        fullScreen
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFilePDF: false,
          }));
        }}
        open={state.openFilePDF}
      >
        <FilesDIsplay
          state={state}
          onClosePDF={() => {
            setState((prev) => ({
              ...prev,
              openFilePDF: false,
            }));
          }}
          setState={setState}
        />
      </Dialog>
    </div>
  );
}
export default React.memo(RequestDetails, renderPropsAreEqual);
