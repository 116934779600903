const initialState = {
  selected_company_id: "",
  reportType: "",
  reportLastRoute: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "onChangeReportsReducer":
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
};
export default dataReducer;
