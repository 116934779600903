// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    Button
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from './tableRequest'
import { useSelector, useDispatch } from "react-redux";

function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
let width = window.innerWidth;
function OnlineForm({amount}) {
    const dispatch = useDispatch()
    const onProceed = () =>{
        dispatch({
            type:'onChangeCreateCheckvoucher',
            data:{
                onlineTransaction:[{type:'Online',amount:amount}]
            }
        })
    }
    return (
        <div>
            <Card variant='outlined'>
                <CardContent>
                    <Typography style={{ fontWeight: 'bold' }}>Amount</Typography>
                    <TextField readOnly size='small' value={amount} variant='outlined' style={{ width: '100%' }} placeholder="" />
                    <div style={{ marginTop: 20, width: '100%',display:'flex',justifyContent:'flex-end' }}>
                        <Button onClick={onProceed} type='button' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained">Proceed</Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
export default React.memo(OnlineForm, renderPropsAreEqual);
