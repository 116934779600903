// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    FormControl,
    Select,
    InputLabel,
    Button,
    MenuItem,
    Table,
    TableContainer,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TextareaAutosize

} from "@material-ui/core";

import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import TableBankAccounts from './tableBankAccounts'
import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getData } from "../../../src/components/api/api"
import PositionedSnackbar from '../alert'
import moment from 'moment'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
let width = window.innerWidth;
const formatNumber = (num) => {
    if (num != "") {
        var amount = parseFloat(num).toFixed(2);
        var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedString
    } else {
        return 0;
    }
};
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
const useStyles = makeStyles({
    root: {
        // display: 'flex',
        // flex: 1
    },
    container: {
        maxHeight: 440,
    },
    textFieldStyle: {
        width: '100%',
        backgroundColor: '#e9f2f5'
    }
});
const RowInputs = ({ requestForPayment, state_val, setState, index, edit }) => {

    const formatNumber = (num) => {
        if (num != "") {
            var amount = parseFloat(num).toFixed(2);
            var formattedString = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedString
        } else {
            return 0;
        }
    };
    return <>
        <StyledTableRow>
            <StyledTableCell style={{ width: '20%', whiteSpace: 'nowrap', }}>{moment(state_val.date).format('YYYY-MM-DD')}</StyledTableCell>
            <StyledTableCell style={{ whiteSpace: 'pre-line', wordWrap: "break-word" }}>{state_val.description}</StyledTableCell>
            <StyledTableCell style={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>{formatNumber(parseFloat(state_val.total).toFixed(2))}</StyledTableCell>
        </StyledTableRow>
    </>
}

function FormBankAccounts({ company_logo, refresh, requestForPayment }) {
    const classes = useStyles()
    const dispatch = useDispatch();

    const bankReducer = useSelector(state => state.BankReducer)
    const [state, setState] = React.useState({

    })
    return (
        <div style={{ marginTop: 90, padding: 10 }}>
            <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card elevation={4}>
                        <CardContent>
                            <form >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h4" style={{ color: '#2c7f84' }}>Transaction Details</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Transaction ID</Typography>
                                        <TextField readOnly value={bankReducer.selectedBankTransaction.finance_id} name='bank_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Transaction Type</Typography>
                                        <TextField readOnly value={bankReducer.selectedBankTransaction.finance_type} name='account_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    {bankReducer.selectedBankTransaction.finance_payment_method !== '' &&
                                        <Grid item xs={12} md={4}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">Payment Method</Typography>
                                            <TextField readOnly value={bankReducer.selectedBankTransaction.finance_payment_method} name='account_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                                        </Grid>
                                    }
                                    {bankReducer.selectedBankTransaction.finance_payment_reference_number !== '' &&
                                        <Grid item xs={12} md={4}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">Check Voucher No.</Typography>
                                            <TextField readOnly value={bankReducer.selectedBankTransaction.finance_payment_reference_number} name='account_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                                        </Grid>
                                    }
                                    {bankReducer.selectedBankTransaction.finance_payment_reference_number !== '' &&
                                        <Grid item xs={12} md={4}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">Cheque No.</Typography>
                                            <TextField readOnly value={bankReducer.selectedBankTransaction.check_number} name='account_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Encoder</Typography>
                                        <TextField readOnly value={String(bankReducer.selectedBankTransaction.completename).toLocaleUpperCase()} name='initial_balance' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Date</Typography>
                                        <TextField readOnly value={moment(bankReducer.selectedBankTransaction.date_added).format('YYYY-MM-DD')} name='initial_balance' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Time</Typography>
                                        <TextField readOnly value={moment(bankReducer.selectedBankTransaction.date_added).format('hh:mm')} name='initial_balance' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Amount</Typography>
                                        <TextField readOnly value={'Php ' + formatNumber(bankReducer.selectedBankTransaction.amount)} name='initial_balance' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <div style={{ backgroundColor: '#2c7f84', height: 1.5, width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" style={{ color: '#2c7f84' }}>Attachment(s)</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {/* {bankReducer.selectedBankTransaction.attachments !== null && (JSON.parse(bankReducer.selectedBankTransaction.attachments)).map((val, index) => {
                                            return <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant='p' style={{ fontSize: 15 }}>{val}</Typography>
                                                <img onClick={() => {
                                                    window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                                }} src={'https://images.workflow.gzonetechph.com/pockethr/attachments/' + val} style={{ width: 100, height: 100, cursor: 'pointer' }} />

                                            </div>
                                        })
                                        } */}
                                        {bankReducer.selectedBankTransaction.attachment !== null && (JSON.parse(bankReducer.selectedBankTransaction.attachment)).map((val, index) => {
                                            return <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant='p' style={{ fontSize: 15 }}>{val}</Typography>
                                                <img onClick={() => {
                                                    window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                                }} src={'https://images.workflow.gzonetechph.com/pockethr/attachments/' + val} style={{ width: 100, height: 100, cursor: 'pointer' }} />

                                            </div>
                                        })
                                        }
                                        {bankReducer.selectedBankTransaction.attachments !== null && (JSON.parse(bankReducer.selectedBankTransaction.attachments)).map((val, index) => {
                                            return <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant='p' style={{ fontSize: 15 }}>{val}</Typography>
                                                <img onClick={() => {
                                                    window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                                }} src={'https://images.workflow.gzonetechph.com/pockethr/attachments/' + val} style={{ width: 100, height: 100, cursor: 'pointer' }} />

                                            </div>
                                        })
                                        }
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                    {requestForPayment.map((val, index) => {
                        return <form style={{ marginTop: 20 }} key={index}>
                            <Card elevation={4} style={{ background: '#fff' }}>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid>
                                            <img style={{ width: 130, height: 130 }} src={'https://images.workflow.gzonetechph.com/company_logo/' + company_logo} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="h4" style={{ color: '#2c7f84' }}>Request for Payment</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={1}>
                                                <Grid xs={12}>
                                                    <Typography variant="p"  > Company : <span style={{ fontWeight: 'bold' }}>{val.company_name}</span></Typography>

                                                </Grid>
                                                <Grid xs={12}>
                                                    <Typography variant="p" >Branch : <span style={{ fontWeight: 'bold' }}>{val.branch_name}</span></Typography>

                                                </Grid>
                                                <Grid xs={12}>
                                                    <Typography variant="p"  >Department : <span style={{ fontWeight: 'bold' }}>{val.department}</span></Typography>

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="p" >Payee : <span style={{ fontWeight: 'bold' }}>{val.payee}</span></Typography>
                                        </Grid >
                                        <Grid item xs={12} md={12}>
                                            <div style={{ backgroundColor: '#2c7f84', height: 1.5, width: '100%' }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Card variant="outlined" style={{ background: '#fafafc' }}>
                                                <TableContainer>
                                                    <Table border={1} style={{ borderCollapse: 'collapse', borderColor: '#bdc3c7' }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', width: '20%' }}>Date</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {JSON.parse(val.req_details).map((val, index) => {
                                                                return <RowInputs requestForPayment={requestForPayment} state_val={val} setState={setState} index={index} edit={''} />
                                                            })
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                            </Card>
                                        </Grid>
                                        {/* <Grid container justify='flex-end' item xs={12} md={12}>
                                        <Typography variant={"p"} style={{ fontWeight: 'bold', fontSize: 20 }}>Total : {formatNumber((JSON.parse(requestForPayment.req_details).reduce((count, val) => {
                                            let total = val.total
                                            if (val.total === "") {
                                                total = 0
                                            }
                                            return count += parseFloat(total)
                                        }, 0)))}</Typography>

                                    </Grid> */}
                                        <Grid item xs={12} md={12}>
                                            <div style={{ backgroundColor: '#2c7f84', height: 1.4, width: '100%' }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Typography variant="p" style={{ fontWeight: 'bold' }} >Attachment(s)</Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            {JSON.parse(val.req_attachments).map((val2, index2) => {
                                                return <div key={index2} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant='p' style={{ fontSize: 15 }}>{val2}</Typography>
                                                    <img onClick={() => {
                                                        window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val2)
                                                    }} src={'https://images.workflow.gzonetechph.com/pockethr/attachments/' + val2} style={{ width: 100, height: 100, cursor: 'pointer' }} />

                                                </div>
                                            })

                                            }

                                        </Grid>





                                    </Grid>
                                </CardContent>
                            </Card>
                        </form>
                    })

                    }


                </Grid>
                <Grid item xs={12} md={3}>

                </Grid>

            </Grid>
        </div>



    );
}
export default React.memo(FormBankAccounts, renderPropsAreEqual);
