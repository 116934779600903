// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    TextField,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Button
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
import ServiceTable from './servicesTable'
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import PositionedSnackbar from "../../alert";
import {
    HashRouter as Router,
    Route,
    Redirect,useHistory
} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    },
    textFieldStyle: {
        width: '100%'
    }
});

let width = window.innerWidth;
const ServiceForm = React.memo(() => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const service_name = useSelector(state => state.ServiceReducer.service_name)
    const contract_no = useSelector(state => state.ServiceReducer.contract_no)
    const amount = useSelector(state => state.ServiceReducer.amount)
    const unit = useSelector(state => state.ServiceReducer.unit)
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    const actionType = useSelector(state => state.ServiceReducer.actionType)
    const selectedServices = useSelector(state => state.ServiceReducer.selectedServices)
    const history = useHistory()

    const onChangeText = (e) => {
        let name = e.target.name
        let value = e.target.value
        dispatch({
            type: 'onChangeServiceReducer',
            data: {
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        let data = {
            service_name:service_name,
            contract_no:contract_no,
            amount:amount,
            unit:unit,
            client_id:selectedClient.client_id,
            user_id:localStorage.getItem('u'),
            actionType:actionType,
            service_id : selectedServices?.service_id
        }
        getData('aam/insertServices',data).then((res)=>{
            if(res.res === 'Exist'){
                dispatch({
                    type:'onChangeClientReducer',
                    data:{
                        alert:true,
                        alertMessage: "Warning , Service name is already Exist",
                        alertColor: '#f39c12'
                    }
                })
            }else if(res.res == true && actionType === 'Add'){
                dispatch({
                    type:'onChangeClientReducer',
                    data:{
                        alert:true,
                        alertMessage: "Success , Service details has been added",
                        alertColor: '#2ecc71',
                    }
                })
                dispatch({
                    type:'onChangeServiceReducer',
                    data:{
                        service_name:'',
                        contract_no:'',
                        amount:'',
                        unit:''
                    }
                })
            }
            else if(actionType === 'Edit' && res.res == true ){
                dispatch({
                    type:'onChangeClientReducer',
                    data:{
                        alert:true,
                        alertMessage: "Success , Service details has been edited",
                        alertColor: '#2ecc71',
                    }
                })
            }
            history.push('/clientDetails')
        })
    }

    return (
        <form onSubmit = {onSubmit}>
              <PositionedSnackbar open={alert} vertical={'top'} horizontal={'center'} alertMessage={alertMessage} alertColor={alertColor} handleClose={() => {
               dispatch({
                   type:'onChangeClientReducer',
                   data:{alert:false}
               })
            }} />
            <Card variant='outlined'>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} md={12} justify='flex-start'>
                            <Typography variant="h5" style={{ color: '#2c7f84' }}>{actionType} Service</Typography>
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={12}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Service Name</Typography>
                            <TextField onChange={onChangeText} value={service_name} required name='service_name' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={12}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Contract No.</Typography>
                            <TextField onChange={onChangeText} value={contract_no} type='number' required name='contract_no' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={12}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 12 }} variant="p">Amount</Typography>
                            <TextField onChange={onChangeText} value={amount} placeholder='Optional' type='number' name='amount' variant='outlined' size='small' className={classes.textFieldStyle} />
                        </Grid>
                        <Grid container justify={'flex-start'} item xs={12} md={12}>
                            <Typography style={{ fontWeight: 'bold' }} variant="p">Type</Typography>
                            <FormControl variant={'outlined'} style={{ width: '100%', background: '#f7fbfc' }} size='small'>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={unit}
                                    name='unit'
                                    placeholder='Optional'
                                    style={{ textAlign: 'left' }}
                                    // onChange={handleChange}
                                    onChange={onChangeText}
                                >
                                    <MenuItem value={''}>{''}</MenuItem>
                                    {['Piece', 'Semi-Monthly', 'Monthly'].map((val, index) => {
                                        return <MenuItem key={index} value={val}>{val}</MenuItem>
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container justify={'flex-end'} item xs={12} md={12}>
                            <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >{actionType}</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>

    );
}, (prevProps, nextProps) => {
    if (prevProps !== nextProps) {
        return false
    }
    return true
})
export default ServiceForm;
