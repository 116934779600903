import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Divider, Button, Box } from "@material-ui/core";
import InputField from "../reusesableComponents/inputField/imputField";
import AddIcon from "@material-ui/icons/Add";
import AutoComplete from "../reusesableComponents/autoComplete/autoComplete";
import { DropzoneArea } from "material-ui-dropzone";
let width = window.innerWidth;
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const CreatePettyCash = (props) => {
  const { ...param } = props;
  return (
    <div>
      <form onSubmit={param.onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography style={{ fontWeight: "bold", color: "#2b7b80" }}>
              Purpose
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <AutoComplete
              required={true}
              label="Branch"
              onChange={param.onSelectBranch}
              dataList={param.branches}
              getOptionLabel={(option) => option.branch_name}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <InputField
              label="Purpose"
              name="purpose"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography style={{ fontWeight: "bold", color: "#2b7b80" }}>
              Requestor
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <InputField label="Employee ID" />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <InputField
              label="Last Name"
              name="requestor_lname"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="First Name"
              name="requestor_fname"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="Middle Name"
              name="requestor_mname"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          {/* <Grid item xs={12} md={5}>
          <AutoComplete
            label="Requestor Name"
            name="requestor"
            onChange={param.onChangeText}
          />
        </Grid> */}

          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography style={{ fontWeight: "bold", color: "#2b7b80" }}>
              Add Particulars
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Date"
              type="date"
              name="date"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Route/Book No"
              name="route"
              onChange={param.onChangeText}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Assigned / Download Count"
              name="assigned"
              onChange={param.onChangeText}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoComplete
              required={true}
              dataList={param.accounts}
              label="Account Title"
              name="accountTitle"
              onChange={param.onSelectAccountTitle}
              getOptionLabel={(option) => option.balance_sheet_group_name}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <InputField
              label="Particulars"
              name="particulars"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              label="Amount"
              name="amount"
              onChange={param.onChangeText}
              type="number"
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ fontWeight: "bold", color: "#2b7b80" }}>
              Attachment Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              style={{
                display: "flex",
                alignSelf: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={param.handleOpenCreateSupplier}
                startIcon={<AddIcon />}
                type="button"
                size="small"
                style={{
                  backgroundColor: "#2c6164",
                  color: "#fff",
                  width: width < 600 ? "100%" : 120,
                }}
                variant="contained"
              >
                Supplier
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="OR / Invoice No."
              name="invoice_no"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Supplier"
              name="supplier"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="Address"
              name="address"
              onChange={param.onChangeText}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputField
              label="TIN"
              name="tin"
              onChange={param.onChangeText}
              required={true}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              style={{ fontWeight: "bold", fontSize: 12, textAlign: "left" }}
              variant="p"
            >
              Attachment(s)
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <DropzoneArea onChange={param.onChangePic} />
          </Grid>
          <Grid item xs={12} md={11}></Grid>
          <Grid item xs={12} md={1}>
            <Button
              startIcon={<AddIcon />}
              type="submit"
              size="small"
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : "100%",
              }}
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

CreatePettyCash.propTypes = {};

export default React.memo(CreatePettyCash, renderEqualProps);
