import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { CardContent, Card ,Tooltip} from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import CallMadeIcon from '@material-ui/icons/CallMade';
import { getData,getDataApi } from "../../api/api"
import {
    HashRouter as Router,
    Route,
    Redirect,useHistory
} from "react-router-dom";

const columns = [
    { id: 'client_id', label: 'Client ID', minWidth: 170 },
    {
        id: 'client_name',
        label: 'Client Name',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'branch_name',
        label: 'Branch Name',
        minWidth: 170,
        align: 'left',
        format: (value) => value.toLocaleString('en-US'),
    }

];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 390,
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const dispatch = useDispatch()
    const history = useHistory()

    const branches = useSelector(state => state.HomeReducer.branches)
    const searchDriver = useSelector(state => state.BillingSettingReducer.searchDriver)
    const searchClientDriver = useSelector(state => state.BillingSettingReducer.searchClientDriver)
    const clientList = useSelector(state => state.BillingSettingReducer.clientList)

    let clientListDisplay = clientList.filter((files) => {
        return (
            searchClientDriver === '' ? files.client_id : files.client_id == searchClientDriver && searchDriver === '' ? files.branch_id : files.branch_id == searchDriver
        )
    })

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(()=>{
        let branch_priviledge = branches.map((val)=>(val.branch_id))
        getData('aam/getPriviledgeClients',{branches : branch_priviledge}).then((res)=>{

            dispatch({
                type: 'onChangeBillingSetting',
                data:{
                    clientList:res.data,
                    clientUnique:res.clients,
                    selectedClientID:'All',
                    selectedBranchID:'All',
                    searchDriver:'',
                    searchClientDriver:''
                }
            })
        })
    },[])
    return (
        <Card variant='outlined'>
            <CardContent>
                <Paper variant='outlined' className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                      <TableCell
                                          
                                            style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                        >
                                        </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientListDisplay.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <Tooltip onClick={() => {
                                                    dispatch({
                                                        type:'onChangeBillingSetting',
                                                        data:{selectedClientData:row}
                                                    })
                                                   history.push('/addBillingAmountSettings')
                                                }} title='View'>
                                                    <CallMadeIcon className={classes.iconSize} />
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={clientListDisplay.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </CardContent>
        </Card>

    );
}