// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import BillingList from './billingList'
import AddBilling from './addBilling'
import PositionedSnackbar from "../../alert";
import CardStatus from "./cardStatus"
import { BillingProvider } from "./billingContext";
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function IndexBillingAmount() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const alert = useSelector(state => state.ClientReducer.alert)
    const alertMessage = useSelector(state => state.ClientReducer.alertMessage)
    const alertColor = useSelector(state => state.ClientReducer.alertColor)
    return (
        <div>
            <BillingProvider>
                <PositionedSnackbar open={alert} vertical={'top'} horizontal={'center'} alertMessage={alertMessage} alertColor={alertColor} handleClose={() => {
                    dispatch({
                        type: 'onChangeClientReducer',
                        data: { alert: false }
                    })
                }} />
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="textPrimary">Billing And Collection </Typography>
                            <Typography color="textPrimary">Billing</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <CardStatus />
                    </Grid>
                   
                    <Grid container justify='flex-start' item xs={12} md={12}>
                        <AddBilling />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <BillingList />
                    </Grid>

                </Grid>
            </BillingProvider>

        </div>
    );
}
export default IndexBillingAmount;
