import React from "react";
import clsx from "clsx";
import "../../src/App.css";
import { useSelector, useDispatch } from "react-redux";
import CreateReaquest from "././createRequests/index";
import { routes } from "./routes";

// import Requests from './requestList/index'
function App(page, component) {
  const homeReducer = useSelector((state) => state.HomeReducer);

  let matchPage = false;
  let matchPage2 = "";

  homeReducer.appNav.map((val) => {
    if (page === val.parent_name) {
      matchPage = true;
    }
    if (val.parent_name === "Requests") {
      matchPage2 = "Requests";
    } else if (val.parent_name === "Check Voucher") {
      matchPage2 = "Check Voucher";
    } else if (val.parent_name === "Account Payable Voucher") {
      matchPage2 = "Account Payable Voucher";
    } else if (val.parent_name === "Create Check Voucher") {
      matchPage2 = "Create Check Voucher";
    } else if (val.parent_name === "Bank Accounts") {
      matchPage2 = "Bank Accounts";
    } else if (val.parent_name === "Supplier Master List") {
      matchPage2 = "Supplier Master List";
    } else if (val.parent_name === "Supplier Details") {
      matchPage2 = "Supplier Details";
    } else if (val.parent_name === "Chart of Accounts") {
      matchPage2 = "Chart of Accounts";
    } else if (val.parent_name === "Financial Reports") {
      matchPage2 = "Financial Reports";
    } else if (val.parent_name === "All Request") {
      matchPage2 = "All Request";
    }
  });

  if (page == "Default") {
    // return (CreateReaquest)
  }
  if (matchPage == true) {
    return component;
  } else {
    if (page === "My Request") {
      let component2 = "";
      routes.map((val) => {
        if (matchPage2 === val.page_name) {
          component2 = val.component;
        }
      });
      return component2;
    }
  }
}

export default App;
