import React from 'react';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Tooltip,
    Icon

} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AddSupplierForm from './addSupplierForm'
import { getData } from '../../api/api';
import EditIcon from '@material-ui/icons/Edit';
import CallMadeIcon from '@material-ui/icons/CallMade';
import { useSelector, useDispatch } from "react-redux";
import {
    HashRouter as Router,
    BrowserRouter,
    Route,
    useParams,
    Redirect,
    Link as NewLink,
    useHistory
} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from '@material-ui/icons/Refresh';
let width = window.innerWidth;

const columns = [
    { id: 'supplier_name', label: 'Supplier Name' },
    { id: 'supplier_address', label: 'Address' },
    { id: 'supplier_contact_no', label: 'Contact No.' },
    { id: 'supplier_contact_person', label: 'Contact Person' },
    { id: 'supplier_date_added', label: 'Date Added' }

];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%',
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }

});

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [state, setState] = React.useState({
        addModal: false,
        supplierList: [],
        searchDriver: '',
        supplierList2: []
    })
    const supplierReducer = useSelector(state => state.SupplierReducer)
    const history = useHistory()
    const dispatch = useDispatch()
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch({
            type: 'onChangeSupplierReducer',
            data: {
                page_no: newPage,
            }
        })
    };

    const onChangeText = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const openCloseAddModal = (val) => {
        setState(prev => ({
            ...prev,
            addModal: val
        }))
    }
    const onSearch = (type) => {
        let supplierList = state.supplierList
        if (type == 'Find') {
            supplierList = state.supplierList.filter((files) => {
                return (
                    files.supplier_name
                        .toLowerCase()
                        .indexOf(state.searchDriver.toLocaleLowerCase()) !== -1
                );
            });
            setState((prev) => ({
                ...prev,
                supplierList2: supplierList
            }))
        } else {
            setState((prev) => ({
                ...prev,
                supplierList2: supplierList,
                searchDriver: ''
            }))
        }


    }


    React.useEffect(() => {
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        getData('aam/getSupplierDetails').then((res) => {
            setState(prev => ({
                ...prev,
                supplierList: res.data,
                supplierList2: res.data

            }))
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }, [])

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={9} container justify='flex-start'>
                <Button onClick={() => {
                    openCloseAddModal(true)
                }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />}>Supplier</Button>
            </Grid>
            <Grid container item xs={12} md={3} justify='flex-end'>
                <Card variant='outlined' style={{ width: 230, padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input value={state.searchDriver} placeholder="Supplier Name" style={{ borderStyle: 'none', outline: 'none' }} name='searchDriver' onChange={onChangeText} />
                    <SearchIcon style={{ cursor: 'pointer', color: '#787f80' }} onClick={() => onSearch('Find')} />
                    <RefreshIcon style={{ cursor: 'pointer', color: '#787f80' }} onClick={() => onSearch('Reset')} />
                </Card>
            </Grid>
            <Grid item xs={12} md={12}>
                <Paper className={classes.root} variant='outlined'>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                    ></TableCell>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.supplierList2.slice(supplierReducer.page_no * rowsPerPage, supplierReducer.page_no * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                <Tooltip onClick={() => {
                                                    dispatch({
                                                        type: 'onChangeSupplierReducer',
                                                        data: {
                                                            selectedSupplier: row
                                                        }
                                                    })
                                                    history.push('/supplierDetails/')

                                                }} title='View'>
                                                    <Icon style={{cursor:'pointer',color:'#2c6164'}}>launched</Icon>
                                                </Tooltip>
                                            </TableCell>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={state.supplierList2.length}
                        rowsPerPage={rowsPerPage}
                        page={supplierReducer.page_no}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            <Dialog fullScreen open={state.addModal} onClose={() => {
                openCloseAddModal(false)
            }} >
                <AppBar className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            openCloseAddModal(false)
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {'Create'}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', flex: 1, background: '#f5f7f7', overflowX: 'hidden', }}>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <AddSupplierForm company_logo={state.company_logo} requestForPayment={state.requestForPayment} refresh={() => setState(prev => ({ ...prev, refresh: !state.refresh }))} />

                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </Grid>

    );
}