// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import BalanceSheetList from "./balanceSheetList";

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

function IndexBalanceSheet() {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="#/financialReport">
              Reports
            </Link>
            <Typography color="textPrimary">
              Profit and Loss Statement
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={12}>
          <BalanceSheetList />
        </Grid>
      </Grid>
    </div>
  );
}
export default React.memo(IndexBalanceSheet, renderPropsAreEqual);
