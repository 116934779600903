const initialState = {
      onlineTransaction : []
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'onChangeCreateCheckvoucher':
            return {
                ...state,
                ...action.data,
            }
        default:
            return state;
    }
}
export default dataReducer; 