const initialState = {
  branchList: [],
  company_id: "",
  branch_id: "",
  company_name: "",
  branch_name: "",
  clientBranchAddress: "",
  req_branch_tin: "",
  req_branch_contact: "",

  clientList: [],
  client_id: "",
  address: "",
  tin: "",
  client_name: "",
  client_branch_name: "",
  selectedClient: [],
  refreshClient: false,
  date: new Date(),
  invoice_due: "",

  serviceList: [],
  selectedService: [],

  clientBranchList: [],
  branchServedList: [
    {
      client_branch_id: "",
      client_branch_name: "",
      total_amount: "",
      file: [],
      accountTitleId: "",
      accountTitleName: "",
      description: "",
      quanty: "",
      unit_price: "",
    },
  ],
  periodStart: "",
  periodEnd: "",

  other: [
    {
      description: "",
      quantity: "",
      unit_price: "",
      total_amount: "",
    },
  ],
  rental: [
    {
      description: "",
      total_amount: "",
    },
  ],

  withHoldingTaxPercent: 2,
  vatPercent: 12,
  withHoldingTaxAmount: "",
  vatAmount: "",

  cardStatus: [],
  billingList: [],
  page: 0,
  rowsPerPage: 10,
  selectedType: "Total",
  search: "",
  selectedBillingInvoice: [],
  actionType: "",
  invoice_no: "",
  req_id: "",

  onCheckedBilling: [],
  listCount: 0,

  journal: [],
  account_name: "",
  account_id: "",

  journalEntry: [],

  selectedJournalEntry: [],

  activities: [],
  radioValueDueDate: "By Date",
  bill_reference_no: "",
};
const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "onChangeBilling":
      return {
        ...state,
        ...action.data,
      };
    case "onChangeBillingCheck":
      if (action.data_type === "push") {
        state.onCheckedBilling.push(action.data);
      } else {
        state.onCheckedBilling.splice(action.index, 1);
      }
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default dataReducer;
