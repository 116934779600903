import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import '../../../src/App.css'
import { getData } from '../api/api';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    paper: {
        background: '#cadff4',
        width: '100%',
        height: 60,
        padding: 3
    },
    container: {
        position: 'relative',
        height: '100%',

    },

}));
let width = window.innerWidth;
export default function CustomizedTimeline(props) {
    const { req_id } = props
    const classes = useStyles();
    const [state, setState] = React.useState({
        timelineList: [],
        dateRequest: ''
    })
    React.useEffect(() => {
        let data = {
            type: 'RFP',
            req_id: req_id
        }
        getData('finance/getRequestTimeline', data).then((res) => {
            setState(prev => ({
                ...prev,
                timelineList: res.timeline,
                dateRequest: res.date_requested
            }))
        })
    }, [])
    
    return (
        <div className={classes.paper}>
            <div className={classes.container}>
                <div className="timeline-parent">
                </div>
                <div style={{ position: 'absolute', top: 6, left: 10 }}>
                    <Typography style={{ color: '#fff', fontWeight: 'bold' }}>Date Requested</Typography>
                    <Typography style={{ color: '#fff', fontSize: 13 }}>{moment(state.dateRequest).format('LL')}</Typography>
                </div>
                <div className="timeline-down-backround">
                </div>
                <div style={{ width: '80%', position: 'absolute', top: 10, right: 0, padding: 2, display: 'flex', flexDirection: width > 800 ? 'row' : 'column' }}>
                    {state.timelineList.map((val, index) => {
                        let day = 'days';
                        if(val.date <= 1){
                            day = 'day'
                        }
                        if(val.status !='')
                        return <div  key={index} style={{ width: 165, height: '10%', marginRight: 4 }}>
                            <div className="timeline-container" >
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                                        <Typography style={{ fontWeight: 'bold' }}>{val.type}</Typography>
                                        <Typography style={{ fontSize: 12 }}>{val.status}</Typography>
                                    </div>
                                    <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{val.date+' '+day}</Typography>
                                </div>
                            </div>
                        </div>
                    })
                    }
                </div>
            </div>
        </div>
    );
}
