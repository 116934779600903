import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import {
  TableRow,
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import BankDepositForm from "./bankDepositForm";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "../../../src/components/api/api";
import CallMadeIcon from "@material-ui/icons/CallMade";
import {
  HashRouter as Router,
  BrowserRouter,
  Route,
  useParams,
  Redirect,
  Link as NewLink,
  useHistory,
} from "react-router-dom";
import moment from "moment";
import BankTransactionDetails from "./bankTransactionDetails";

let width = window.innerWidth;
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};
const columns = [
  {
    id: "finance_id",
    label: "Transaction ID",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "check_number",
    label: "Cheque No.",
    format: (value) =>
      value === null ? "" : String(value).toLocaleUpperCase(),
  },

  {
    id: "finance_type",
    label: "Transaction Type",
    format: (value) => String(value).toLocaleUpperCase(),
  },

  {
    id: "completename",
    label: "Encoder",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "date_added",
    label: "Date",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "date_added",
    label: "Time",
    format: (value) => moment(value).format("hh:mm A"),
  },
  {
    id: "amount",
    label: "Amount",
    format: (value) => "Php " + formatNumber(value),
  },
  // { id: 'finance_status', label: 'Status', format: (value) => value },

  // { id: 'bank_balance', label: 'Balance', format: (value) => 'Php' + ' ' + formatNumber(value) },
  // { id: 'name', label: 'Name'},
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});

const BankTransactionTable = React.memo(
  (props) => {
    const { selection, refreshDetails } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const bankReducer = useSelector((state) => state.BankReducer);
    const homeReducer = useSelector((state) => state.HomeReducer);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [state, setState] = React.useState({
      addBankModal: false,
      actionButton: "Create",
      refresh: false,
      transactionList: [],
      transactionListCount: 0,
      transaction_type: "All",
      detailsBankModal: false,
      requestForPayment: [],
      company_logo: "",
      status: "All",
      transactionType: "",
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      getTransaction(newPage, rowsPerPage, true);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const openCloseAddAccountModal = (status, transactionType) => {
      setState((prev) => ({
        ...prev,
        addBankModal: status,
        transactionType: transactionType,
      }));
    };

    const openCloseDetailsAccountModal = (status) => {
      setState((prev) => ({
        ...prev,
        detailsBankModal: status,
      }));
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    React.useEffect(() => {
      getTransaction(0, rowsPerPage, true);
    }, [state.refresh, refreshDetails]);
    const getTransaction = (page, limit, loading) => {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: loading,
        },
      });
      let newPage = page + 1;
      let bank_account_id = bankReducer.selectedBank.bank_account_id;
      let data = {
        bank_account_id: bank_account_id,
        page: newPage,
        limit: limit,
        transaction_type: state.transaction_type,
        finance_status: state.status,
        date_start: moment(selection.startDate).format("YYYY-MM-DD"),
        date_end: moment(selection.endDate).format("YYYY-MM-DD"),
      };
      getData("aam/getBankTransaction", data).then((res) => {
        setState((prev) => ({
          ...prev,
          transactionList: res.data,
          transactionListCount: res.count,
        }));
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    };
    const handleChangeType = (e) => {
      setState((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
    const getRequestsDeatils = (req_id) => {
      getData("aam/getRequestsDeatils", { req_id: req_id }).then((res) => {
        let company_logo = "";
        if (res.data.length > 0) {
          let split = String(res.data[0].company_logo).split("/");
          company_logo = split[split.length - 1];
        }
        setState((prev) => ({
          ...prev,
          requestForPayment: res.data,
          company_logo: company_logo,
        }));
        openCloseDetailsAccountModal(true);
      });
    };
    console.log("hehheehe");
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item container justify="flex-start" xs={12} md={7}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{
                display:
                  homeReducer.accessButton[0]?.deposit_access != 1
                    ? "none"
                    : undefined,
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
              startIcon={<AddIcon />}
            >
              Transaction
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  openCloseAddAccountModal(true, "Debit Memo");
                  handleClose();
                }}
              >
                DEBIT MEMO
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openCloseAddAccountModal(true, "Bank Deposit");
                  handleClose();
                }}
              >
                DEPOSIT
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  openCloseAddAccountModal(true, "SYSTEM BORROW AMOUNT");
                  handleClose();
                }}
              >
                SYSTEM BORROW AMOUNT
              </MenuItem> */}
            </Menu>
            {/* <Button onClick={() => {
                        openCloseAddAccountModal(true)
                    }} style={{ display: homeReducer.accessButton[0]?.deposit_access != 1 ? 'none' : undefined, backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />}>Deposit</Button> */}
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl
              variant={"outlined"}
              style={{ width: "100%" }}
              size="small"
            >
              <InputLabel id="demo-simple-select-label">
                Transaction type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.transaction_type}
                onChange={handleChangeType}
                name="transaction_type"
                value={state.transType}
                style={{ textAlign: "left" }}
              >
                <MenuItem value={"All"}>{"All"}</MenuItem>
                <MenuItem value={"Deposit"}>{"Deposit"}</MenuItem>
                <MenuItem value={"Debit"}>{"Debit"}</MenuItem>
                <MenuItem value={"Disbursement"}>{"Disbursement"}</MenuItem>
                <MenuItem value={"Initial Balance"}>
                  {"Initial Balance"}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControl
              variant={"outlined"}
              style={{ width: "100%" }}
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state.status}
                onChange={handleChangeType}
                name="status"
                value={state.transType}
                style={{ textAlign: "left" }}
              >
                <MenuItem value={"All"}>{"All"}</MenuItem>
                <MenuItem value={"Denied"}>{"Denied"}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container justify="flex-start" xs={12} md={1}>
            <Button
              onClick={() => {
                getTransaction(0, rowsPerPage, true);
              }}
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.root} variant="outlined">
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap' }}>
                                            #
                                        </TableCell> */}
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2c6164",
                            color: "#ffff",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.transactionList.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {/* <TableCell >
                                                    {index + 1}
                                                </TableCell> */}
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format &&
                                typeof value === "bank_balance" ? (
                                  <span style={{ fontWeight: "bold" }}>
                                    {column.format(value)}
                                  </span>
                                ) : (
                                  column.format(value)
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            {row.finance_status !== null && (
                              <span
                                style={{
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  paddingRight: 5,
                                  paddingLeft: 5,
                                  background: "#f56b5d",
                                  color: "#fff",
                                  borderRadius: 5,
                                }}
                              >
                                {row.finance_status}
                              </span>
                            )}
                          </TableCell>

                          <TableCell style={{ fontWeight: "bold" }}>
                            <Tooltip
                              onClick={() => {
                                dispatch({
                                  type: "onChangeBankReducer",
                                  data: {
                                    selectedBankTransaction: row,
                                  },
                                });
                                getRequestsDeatils(row.reference_id);

                                //    history.push('/bankDetails/')
                              }}
                              title="View"
                            >
                              <CallMadeIcon className={classes.iconSize} />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state.transactionListCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
        {/* <Dialog fullScreen open={state.addBankModal} onClose={() => {
                openCloseAddAccountModal(false)
            }} >
                <AppBar className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            openCloseAddAccountModal(false)
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {state.actionButton}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ display: 'flex', flex: 1, background: '#f5f7f7', overflowX: 'hidden', overflowY: 'hidden' }}>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <BankDepositForm refresh={() => setState(prev => ({ ...prev, refresh: !state.refresh }))} />

                        </Grid>
                    </Grid>
                </div>
            </Dialog> */}
        <Dialog
          fullScreen
          open={state.addBankModal}
          onClose={() => {
            openCloseAddAccountModal(false);
          }}
        >
          <AppBar className={classes.appBar} elevation={1}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  openCloseAddAccountModal(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">{state.actionButton}</Typography>
            </Toolbar>
          </AppBar>
          <div
            style={{
              display: "flex",
              flex: 1,
              background: "#f5f7f7",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <BankDepositForm
                  transactionType={state.transactionType}
                  refresh={() =>
                    setState((prev) => ({ ...prev, refresh: !state.refresh }))
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Dialog>
        <Dialog
          fullScreen
          open={state.detailsBankModal}
          onClose={() => {
            openCloseDetailsAccountModal(false);
          }}
        >
          <AppBar className={classes.appBar} elevation={1}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  openCloseDetailsAccountModal(false);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">{"Details"}</Typography>
            </Toolbar>
          </AppBar>
          <div
            style={{
              display: "flex",
              flex: 1,
              background: "#f5f7f7",
              overflowX: "hidden",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <BankTransactionDetails
                  company_logo={state.company_logo}
                  requestForPayment={state.requestForPayment}
                  refresh={() =>
                    setState((prev) => ({ ...prev, refresh: !state.refresh }))
                  }
                />
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  },
  (prevProps, nextProps) => {
    console.log(prevProps); // not getting called
    if (prevProps.refreshDetails !== nextProps.refreshDetails) {
      return false;
    }
    return true;
  }
);

export default BankTransactionTable;
