const initialState = {
    dataStucturePayment:[],
    bankList:[],
    selectedPostDiff:[],
    modalChartOfAccounts:false,
    type:'',
    selectedAccounts:[],
    postDiffLabel:'Write Off',
    selectedJournal:[],
    journalList:[]
   }
   const dataReducer = (state = initialState, action) => {
       switch (action.type) {
           case 'onChangeRegisterPayment':
               return {
                   ...state,
                   ...action.data,
               }
           default:
               return state;
       }
   
   }
   export default dataReducer; 