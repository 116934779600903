import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { Divider, Icon } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Link as NewLink, useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { withStyles } from "@material-ui/core/styles";
import { routesDrawer } from "./routes";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Scrollbars } from "react-custom-scrollbars-2";
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    color: "#fff",
  },
  drawerPaper: {
    width: drawerWidth,
    color: "#fff",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  userDetails: {
    // height: 50,
    display: "flex",
    flexDirection: "column",
    color: "#ffff",
    alignItems: "center",
    // background:'#2c6164'
  },
}));
let width = window.innerWidth;
export default function PersistentDrawerLeft({ state, closeDrawer }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [activeButton, setActiveButton] = React.useState("My Request");

  const dispatch = useDispatch();
  const homeReducer = useSelector((state) => state.HomeReducer);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const history = useHistory();
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState("");

  const handleChange =
    (panel, subPage, url, page_name) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);

      if (subPage.length > 0) {
      } else {
        history.push(url);
        closeDrawer();
      }
      setActiveButton(page_name);
    };
  return (
    <Scrollbars>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <div className={classes.userDetails}>
        <AccountCircleIcon style={{ width: 40, height: 40, marginTop: 10 }} />
        <Typography variant="p" style={{ fontSize: 11.5 }}>
          Welcome
        </Typography>
        <Typography variant="p" style={{ fontSize: 12 }}>
          {homeReducer.loginData.complete_name}
        </Typography>
      </div>
      <List>
        {routesDrawer.map((val, index) => {
          let match = homeReducer.appNav.filter((nav) => nav.href === val.path);
          if (match.length === 0) {
            val.subPage.map((val2) => {
              if (match.length == 0) {
                match = homeReducer.appNav.filter(
                  (nav) => nav.href === val2.path
                );
              }
            });
          }
          let total = "";
          let pendingCreateCV = homeReducer.pendingCreateCV;
          if (homeReducer.loginData?.create_check_voucher_priviledge === "0") {
            pendingCreateCV = 0;
          }
          if (val.page_name === "Disbursement") {
            total =
              homeReducer.pendingReqForPayment +
              homeReducer.pendingCheckVoucher +
              homeReducer.pendingAPV +
              pendingCreateCV +
              homeReducer.pendingNewSupplier;
          }
          if (total === 0) {
            total = "";
          }
          if (match.length > 0)
            return (
              <Accordion
                style={{
                  background:
                    val.page_name === activeButton ? "#2c6164" : "#2c7f84",
                  color: "#fff",
                }}
                square
                expanded={expanded === "panel" + index}
                onChange={handleChange(
                  "panel" + index,
                  val.subPage,
                  val.path,
                  val.page_name
                )}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Icon style={{ marginRight: 10 }}>{val.icon}</Icon>
                  <Typography variant="p">{val.page_name}</Typography>

                  <Typography
                    style={{
                      position: "absolute",
                      top: 17,
                      right: 12,
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {total}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: val.subPage.length === 0 ? "none" : "flex",
                    flexDirection: "column",
                  }}
                >
                  {val.subPage.map((val2) => {
                    let match_nav;
                    match_nav = homeReducer.appNav.filter(
                      (nav) => nav.href === val2.path
                    );
                    let page_name2 = val2.page_name;
                    if (
                      homeReducer.loginData?.create_check_voucher_priviledge ===
                        "0" &&
                      val2.page_name === "Create Check Voucher"
                    ) {
                      page_name2 = "Tracking Check Voucher";
                    }

                    if (match_nav.length > 0)
                      return (
                        <div
                          component={NewLink}
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={() => {
                            if (val2.page_name === "Supplier Master List") {
                              dispatch({
                                type: "onChangeSupplierReducer",
                                data: {
                                  page_no: 0,
                                },
                              });
                            }
                            setActiveButton(val.page_name);
                            closeDrawer();
                            history.push(val2.path);
                          }}
                        >
                          <ListItem button key={index}>
                            {/* <ListItemIcon> <Icon style={{ color: '#fff' }}>{val.icon}</Icon></ListItemIcon> */}

                            <Typography variant="p">{page_name2} </Typography>
                            {/* <div style={{position:'relative'}}>
                                                    <NotificationsIcon style={{color:'#fff',position:'absolute'}}/> */}
                            {val2.page_name === "Request for Payment" &&
                            homeReducer.pendingReqForPayment > 0 ? (
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: 7,
                                  right: 0,
                                  color: "#fff",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {homeReducer.pendingReqForPayment}
                              </Typography>
                            ) : undefined}
                            {val2.page_name === "Check Voucher" &&
                            homeReducer.pendingCheckVoucher > 0 ? (
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: 7,
                                  right: 0,
                                  color: "#fff",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {homeReducer.pendingCheckVoucher}
                              </Typography>
                            ) : undefined}
                            {val2.page_name === "Account Payable Voucher" &&
                            homeReducer.pendingAPV > 0 ? (
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: 7,
                                  right: 0,
                                  color: "#fff",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {homeReducer.pendingAPV}
                              </Typography>
                            ) : undefined}
                            {val2.page_name === "Create Check Voucher" &&
                            homeReducer.pendingCreateCV > 0 &&
                            homeReducer.loginData
                              ?.create_check_voucher_priviledge === "1" ? (
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: 7,
                                  right: 0,
                                  color: "#fff",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {homeReducer.pendingCreateCV}
                              </Typography>
                            ) : undefined}
                            {val2.page_name === "New Supplier Requests" &&
                            homeReducer.pendingNewSupplier > 0 ? (
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: 7,
                                  right: 0,
                                  color: "#fff",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {homeReducer.pendingNewSupplier}
                              </Typography>
                            ) : undefined}

                            {/* </div> */}
                          </ListItem>
                        </div>
                      );
                  })}
                </AccordionDetails>
              </Accordion>
            );
        })}
      </List>
    </Scrollbars>
  );
}
