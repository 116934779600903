import { Grid, Button } from "@material-ui/core";
import React from "react";
import InputField from "../reusesableComponents/inputField/imputField";
const width = window.innerWidth;
const FilterDate = () => {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <InputField label="From" type="date" />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputField label="To" type="date" />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            onClick={() => {
              // onSubmitBranchFilter();
            }}
            type="button"
            size="small"
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : "100%",
              marginTop: width < 600 ? undefined : 17,
            }}
            variant="contained"
          >
            Filter
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterDate;
