import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { CardContent, Button, Grid, Card,Menu,MenuItem } from '@material-ui/core';
import {
    HashRouter as Router,
    Route,
    Redirect, useHistory,
} from "react-router-dom";
import { getData } from '../../api/api';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import MoreVertIcon from '@material-ui/icons/MoreVert';
const ITEM_HEIGHT = 48;
let width = window.innerWidth;
const columns = [
    { id: 'client_branch_id', label: 'Branch ID'},
    {
        id: 'client_branch_name',
        label: 'Branch Name',
        format: (value) => String(value).toLocaleUpperCase,
    },
    {
        id: 'client_branch_address',
        label: 'Address',
        format: (value) => String(value).toLocaleUpperCase,
    },
    {
        id: 'business_center',
        label: 'Business Center',
        format: (value) => String(value).toLocaleUpperCase,
    },
    {
        id: 'date_added',
        label: 'Date Added',
        format: (value) => moment(value).format('Y-m-d'),
    },
   
];

function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 300,
    },
});

const options = [
    'Edit',
    'View Contracts'
];

const MultiButton = (props) => {
    const dispatch = useDispatch()
    const { data } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const history = useHistory()
    const handleClose = (option) => {
        if (option === 'Edit') {
            console.log(data)
            dispatch({
                type: 'onChangeClientBranchReducer',
                data: {
                    clientBranchAddress: data.client_branch_address,
                    clientBranchName: data.client_branch_name,
                    actionType: 'Edit',
                    selectedClientBranch: data,
                    branch_id:data.assigned_branch_id,
                    businessCenter:data.business_center
                }
            })
            history.push('/addClientBranch')
        }else{
            dispatch({
                type: 'onChangeClientBranchReducer',
                data: {
                    selectedClientBranch: data
                }
            })
            history.push('/branchContracts')
        }

        setAnchorEl(null);
    }
    return <div>
        <div
            type='button'
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <MoreVertIcon />
        </div>
        <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >
            {options.map((option) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option)}>
                    {option}
                </MenuItem>
            ))}
        </Menu>
    </div>
}

export default function StickyHeadTable() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const history = useHistory()
    const dispatch = useDispatch()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const selectedClient = useSelector(state => state.ClientReducer.selectedClient)
    const clientBranchList = useSelector(state => state.ClientBranchReducer.clientBranchList)

    React.useEffect(()=>{
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let data = {
            client_id : selectedClient.client_id
        }
        getData('aam/getClientBranch',data).then((res)=>{
            dispatch({
                type:'onChangeClientBranchReducer',
                data:{clientBranchList:res.res}
            })
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    },[])
    return (
        <Grid container spacing={1}>
            <Grid container justify='flex-start' item xs={12} md={12}>
                <Button onClick={() =>{
                    dispatch({
                        type:'onChangeClientBranchReducer',
                        data:{
                            selectedClientBranch:[],
                            actionType:'Add'
                        }
                    })
                    history.push('/addClientBranch')}} type='button' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Add Branch</Button>
            </Grid>
            <Grid item xs={12} md={12}>

                <Paper variant='outlined' className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#2c6164', color: '#fff' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                     <TableCell
                                        style={{ backgroundColor: '#2c6164', color: '#ffff', whiteSpace: 'nowrap',width:20   }}
                                    >
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientBranchList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                    </TableCell>
                                                );
                                            })}
                                             <TableCell >
                                                <MultiButton data={row} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={clientBranchList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>

    );
}