import React from "react";
import { TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const AutoComplete = (props) => {
  const { ...param } = props;
  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <Typography
          style={{ fontWeight: "bold", fontSize: 12, textAlign: "left" }}
          variant="p"
        >
          {param.label}
        </Typography>
      </div>
      <Autocomplete
        fullWidth
        size="small"
        id="combo-box-demo"
        onChange={param.onChange}
        options={param.dataList}
        getOptionLabel={(option) => param.getOptionLabel(option)}
        renderInput={(params) => (
          <TextField required={param.required} {...params} variant="outlined" />
        )}
      />
    </>
  );
};

export default React.memo(AutoComplete, renderEqualProps);
