// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Tooltip
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { getData } from "../../api/api"
import CloseIcon from '@material-ui/icons/Close';
import {
    HashRouter as Router,
    Route,
    Redirect, useHistory
} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { BillingContext } from './billingContext';
import Received from '../../../assets/received.png'

const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});
let width = window.innerWidth;
const AddBilling = React.memo(() => {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();
    const { getBillingData } = React.useContext(BillingContext)
    const rowsPerPage = useSelector((state) => state.BillingReducer.rowsPerPage);
    const search = useSelector((state) => state.BillingReducer.search);
    const onCheckedBilling = useSelector((state) => state.BillingReducer.onCheckedBilling);
    const selectedType = useSelector((state) => state.BillingReducer.selectedType);

    const onChangeText = (e) => {
        let value = e.target.value
        let name = e.target.name
        dispatch({
            type: 'onChangeBilling',
            data: {
                [name]: value
            }
        })
    }

    const onClickReceived = () =>{
        if(onCheckedBilling.length == 0){
            alert('Please select invoice to be tag as released!')
        }else{
            if(window.confirm('Are you sure you want to tag the selected Billing Invoice as Released')){
                let req_ids = []
                onCheckedBilling.forEach((val)=>{
                    req_ids.push(val.req_id)
                })
                let data = {
                    user_id: localStorage.getItem('u'),
                    req_ids: req_ids,
                    status: 'Received'
                }
                dispatch({
                    type: 'onChangeHomeReducer',
                    data: {
                        loading: true,
                    }
                })
                getData('aam/onCancelBilling',data).then((res)=>{
                    dispatch({
                        type:'onChangeBilling',
                        data : {
                            onCheckedBilling:[],
                        }
                    })
                    dispatch({
                        type: 'onChangeHomeReducer',
                        data: {
                            loading: false,
                        }
                    })
                })
            }
        }
     
    }
    return (

        <Grid container spacing={1} >
            <Grid container item xs={12} md={12} justify='flex-start'>
                <Typography style={{fontWeight:'bold'}}>Selected : {selectedType}</Typography>
            </Grid>
            <Grid container item xs={12} md={3} justify='flex-start'>
                <Card variant='outlined' style={{ width: width > 600 ? "100%" : '100%', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input value={search} onChange={onChangeText} placeholder="Search" style={{ borderStyle: 'none', outline: 'none', width: '100%' }} name='search' />
                    <SearchIcon onClick={() => {
                        getBillingData(selectedType, 0, rowsPerPage, search);
                        dispatch({
                            type: 'onChangeBilling',
                            data: {
                                page: 0
                            }
                        })
                    }} style={{ cursor: 'pointer', color: '#787f80' }} />
                    <RefreshIcon onClick={() => {
                        getBillingData(selectedType, 0, rowsPerPage, '');
                        dispatch({
                            type: 'onChangeBilling',
                            data: {
                                page: 0,
                                search: ''
                            }
                        })
                    }} style={{ cursor: 'pointer', color: '#787f80' }} />
                </Card>
            </Grid>
            <Grid item xs={12} md={3} justify='flex-start'>
                {/* <Tooltip title='Tag with Released' onClick={()=>onClickReceived()} style={{ position: 'relative' }}>
                    <Card variant='outlined' style={{ marginRight: 8, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer', background: '#ecf0f1',width:70,height:'100%' }}>
                        <img src={Received} style={{ width: 30, height: 26 }} />
                        <div style={{ width: 20, height: 20, borderRadius: 9, background: '#f39c12', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant='p' style={{ color: '#fff' }}>{onCheckedBilling.length}</Typography>
                        </div>
                    </Card>

                </Tooltip> */}

            </Grid>
            <Grid container justify='flex-end' item xs={12} md={6}>
                <Button onClick={() => {
                    dispatch({
                        type: 'onChangeBilling',
                        data: {
                            actionType: 'Add',
                            withHoldingTaxPercent:2,
                            vatPercent:12
                        }
                    }); history.push('/billingForm')
                }} type='button' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />} >New Billing</Button>
            </Grid>
        </Grid>

    );
})
export default AddBilling;
