import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  Slide,
  AppBar,
  Tooltip,
  TextField,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import RequestForm from './requestForm'
import RequestDetails from "./requestDetails";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

import { getData } from "../../api/api";
import { useSelector, useDispatch } from "react-redux";
import CallMadeIcon from "@material-ui/icons/CallMade";
import moment from "moment";
import { green } from "@material-ui/core/colors";
import PositionedSnackbar from "../../alert";
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
let width = window.innerWidth;
const columns = [
  { id: "req_reference_no", label: "Reference No." },
  {
    id: "req_date_added",
    label: "Date Request",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "fullname",
    label: "Prepared by",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "new_req_payee",
    label: "Payee",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "req_recipient",
    label: "Recipient",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "company_name",
    label: "Company",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "recurring",
    label: "Recurring",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  // {
  //     id: 'department',
  //     label: 'Department',
  //     format: (value) => String(value).toLocaleUpperCase(),
  // },
  // {
  //     id: 'req_approval_status',
  //     label: 'Status',
  //     format: (value) => String(value).toLocaleUpperCase(),
  // },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const homeReducer = useSelector((state) => state.HomeReducer);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    requestList: [],
    requestListCount: 0,
    actionButton: "",
    selectedRequest: [],
    reqDetailsModal: false,
    refreshReqList: false,
    countStatus: [],
    status: "Pending",
    comment_modal: false,
    comment_value: "",
    searchDriver: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    attachments: [],
    actionStatus: "",
    cheque_details: [],
    viewElipses: "",
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    getRequest(newPage, rowsPerPage, state.status, state.searchDriver, true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getRequest(0, event.target.value, state.status, state.searchDriver, true);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else {
      getRequest(page, rowsPerPage, state.status, state.searchDriver, false);
    }
  }, [homeReducer.onRefresh]);
  React.useEffect(() => {
    getRequest(page, rowsPerPage, state.status, state.searchDriver, true);
  }, [state.refreshReqList]);

  const getRequest = (page, row, status, searchDriver = "", loading) => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: loading,
      },
    });
    let new_page = 1 + page;

    // if (typeof homeReducer.loginData.complete_name !== 'undefined') {

    let branches = homeReducer.branches.map((val) => val.branch_id);
    let data = {
      user_id: localStorage.getItem("u"),
      branches: branches,
      page: new_page,
      limit: row,
      status: status,
      company: homeReducer.loginData.company_id,
      app_id: "7",
      searchDriver: searchDriver,
      req_type: "Check Voucher",
    };
    getData("aam/getRequestCVTobeAprrove", data).then((res) => {
      setState((prev) => ({
        ...prev,
        requestList: res.data,
        requestListCount: res.count,
        countStatus: res.countStatus,
        status: status,
      }));
      setPage(page);
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: false,
        },
      });
    });
  };

  const onChangeText = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleNotification = (firstApprover) => {
    // homeReducer.socket.emit("sendNotification", {
    //     senderName: homeReducer.loginData.user_id,
    //     receiverName: firstApprover,
    //     type: ''
    // })
    let pendingCheckVoucher = parseInt(homeReducer.pendingCheckVoucher) - 1;
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        onRefresh: !homeReducer.onRefresh,
        pendingCheckVoucher: pendingCheckVoucher,
      },
    });
  };
  const onSubmitApproval = (e, request_status) => {
    e.preventDefault();
    approvalRequest(request_status, state.selectedRequest);
  };
  const approvalRequest = (request_status, selectedRequest) => {
    let text = "Are you sure you want to " + request_status + " this request?";
    if (window.confirm(text) == true) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      let select_req = selectedRequest;
      let match_apv_cv_access = homeReducer.branches.filter(
        (val) => parseInt(selectedRequest.branch_id) === parseInt(val.branch_id)
      );
      let apv_cv_access = "";

      if (match_apv_cv_access.length > 0) {
        apv_cv_access = match_apv_cv_access[0].check_voucher_access;
      }
      let request = {
        req_id: select_req.req_id,
        req_approval_id: select_req.req_approval_id,
        status: request_status,
        req_hierarchy_level: select_req.req_hierarchy_level,
        req_type: select_req.req_type,
        approver: "",
        comment: state.comment_value,
        app_id: "7",
        company_id: homeReducer.loginData.company_id,
        branch_id: homeReducer.loginData.branch_id,
        approver_id: homeReducer.loginData.user_id,
        apv_cv_access: apv_cv_access,
      };
      getData("HumanResource/changeRequestStatus2", request).then((res) => {
        if (String(res).includes("Success")) {
          selectedRequest.req_approval_status = request_status;
          selectedRequest.status = request_status;
          // getRequest(page, rowsPerPage, state.status, state.searchDriver, true);
          if (selectedRequest.req_hierarchy_structure !== "") {
            let firstApprover = JSON.parse(
              selectedRequest.req_hierarchy_structure
            );
            for (let index = 0; index < firstApprover.length; index++) {
              const element = firstApprover[index];
              let match = element.approver.filter(
                (val) => val.id === homeReducer.loginData.user_id
              );
              if (match.length > 0) {
                let Nextapprover = "";
                if (firstApprover.length - 1 !== index) {
                  Nextapprover = JSON.parse(
                    selectedRequest.req_hierarchy_structure
                  )[index + 1].approver[0].id;
                } else {
                  Nextapprover = apv_cv_access;
                }
                handleNotification(Nextapprover);
              }
            }
          }
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Success, Request has been " + request_status,
            alertColor: "#2ecc71",
            comment_value: "",
            comment_modal: false,
            // refreshReqList: !state.refreshReqList,
            reqDetailsModal: false,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Something went wrong",
            alertColor: "#2ecc71",
          }));
        }
        // dispatch({
        //     type: 'onChangeHomeReducer',
        //     data: {
        //         loading: false,
        //     }
        // })
      });
    } else {
    }
  };

  const AddComment = (e) => {
    e.preventDefault();

    let request_comments = {
      app_id: "7",
      user_id: homeReducer.loginData.user_id,
      comment_type: "Text",
      comment: state.comment_value,
      req_id: state.selectedRequest.req_id,
      req_reference_no: state.selectedRequest.req_reference_no,
      req_user_id: state.selectedRequest.user_id,
    };

    let select_req = state.selectedRequest;
    let actionStatus = state.actionStatus;
    if (state.actionStatus === "Pending") {
      actionStatus = "Hold";
    }
    let request = {
      req_id: select_req.req_id,
      req_approval_id: select_req.req_approval_id,
      status: actionStatus,
      req_hierarchy_level: select_req.req_hierarchy_level,
      approver: "",
      comment: state.comment_value,
      app_id: "7",
      company_id: homeReducer.loginData.company_id,
      branch_id: homeReducer.loginData.branch_id,
      approver_id: homeReducer.loginData.user_id,
    };
    if (window.confirm("Are you sure you want to proceed this action?")) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      Promise.all([
        getData("HumanResource/LogRequestComment", request_comments),
        state.actionStatus === "Pending"
          ? getData("aam/startOverRequest", request)
          : getData("HumanResource/changeRequestStatus", request),
      ]).then((res) => {
        if (String(res[0]).includes("Success")) {
          state.selectedRequest.req_approval_status = state.actionStatus;
          state.selectedRequest.status = actionStatus;
          state.selectedRequest.comments.push({
            user_lname: state.selectedRequest.user_lname,
            user_fname: state.selectedRequest.user_fname,
            comment: state.comment_value,
          });
          setState((prev) => ({
            ...prev,
            comment_modal: false,
            reqDetailsModal: false,
            comment_value: "",
            refreshReqList: !state.refreshReqList,
          }));
          // homeReducer.socket.emit("sendNotification", {
          //     senderName: homeReducer.loginData.user_id,
          //     receiverName: state.selectedRequest.user_id,
          //     type: ''
          // })
        } else {
          setState((prev) => ({
            ...prev,
            comment_modal: false,
          }));
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  };
  const onGetCheckDetails = (row) => {
    let data = {
      req_id: row.req_id,
      company_id: row.company_id,
    };
    getData("aam/onGetCheckDetails", data).then((res) => {
      let details = [];
      res.forEach((element) => {
        if (element.finance_status === null) {
          details.push(element);
        }
      });
      setState((prev) => ({
        ...prev,
        actionButton: "Details",
        selectedRequest: row,
        reqDetailsModal: true,
        cheque_details: details,
      }));
    });
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const onViewElipses = (index) => {
    setState((prev) => ({
      ...prev,
      viewElipses: index,
    }));
  };
  return (
    <Grid container spacing={1}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={1}>
              {state.countStatus.map((val, index) => {
                return (
                  <Grid key={index} item xs={12} md={2}>
                    <Card
                      style={{ background: val.color, cursor: "pointer" }}
                      onClick={() =>
                        getRequest(0, rowsPerPage, val.type, "", true)
                      }
                      variant="outlined"
                    >
                      <CardContent>
                        <div style={{ display: "flex" }}>
                          <Typography className={classes.cardFont}>
                            {val.type}
                          </Typography>
                        </div>
                        <Typography className={classes.cardFontCount}>
                          {val.count}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Tooltip title="View">
                            <CallMadeIcon
                              className={classes.iconSize}
                              style={{
                                color: "#fff",
                                fontWeight: "bold",
                                width: 20,
                                height: 20,
                              }}
                            />
                          </Tooltip>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        {/* <Card variant='outlined'>
                    <CardContent> */}
        <Grid container spacing={1}>
          <Grid container item xs={12} md={3} justify="flex-start">
            <Card
              variant="outlined"
              style={{
                width: 230,
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                value={state.searchDriver}
                placeholder="Search"
                style={{ borderStyle: "none", outline: "none" }}
                name="searchDriver"
                onChange={onChangeText}
              />
              <SearchIcon
                style={{ cursor: "pointer", color: "#787f80" }}
                onClick={() =>
                  getRequest(
                    0,
                    rowsPerPage,
                    state.status,
                    state.searchDriver,
                    true
                  )
                }
              />
              <RefreshIcon
                style={{ cursor: "pointer", color: "#787f80" }}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    searchDriver: "",
                  }));
                  setTimeout(() => {
                    getRequest(0, rowsPerPage, state.status, "", true);
                  }, 200);
                }}
              />
            </Card>
          </Grid>
          <Grid container item xs={12} md={9} justify="flex-end">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "cnter",
              }}
            >
              <Typography variant="p" style={{ fontWeight: "bold" }}>
                Filter : {state.status}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper
              className={(classes.root, classes.outlined)}
              variant="outlined"
            >
              <TableContainer
                className={(classes.container, classes.tableHeight)}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2c6164",
                            color: "#ffff",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Description
                      </TableCell>
                      {/* <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Status
                      </TableCell> */}

                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Amount
                      </TableCell>

                      <TableCell
                        style={{ backgroundColor: "#2c6164" }}
                      ></TableCell>
                      <TableCell
                        style={{ backgroundColor: "#2c6164" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.requestList.map((row, index) => {
                      let bgColor = "#3498db";
                      let req_approval_status = row.req_approval_status;
                      if (row.req_approval_status === "Approved") {
                        bgColor = "#2ecc71";
                      }
                      if (row.req_approval_status === "Pending") {
                        bgColor = "#faab2d";
                      }
                      if (row.req_approval_status === "Denied") {
                        bgColor = "#f56b5d";
                      }
                      if (
                        row.req_start_over === "1" &&
                        row.status == "Pending"
                      ) {
                        bgColor = "#2c3e50";
                        req_approval_status = "Return";
                      }
                      let details = [];
                      if (
                        row.req_justification !== "" &&
                        row.req_justification !== null
                      ) {
                        details = [
                          {
                            description: row.req_justification,
                          },
                        ];
                      } else {
                        details = JSON.parse(row.req_details);
                      }
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "req_approval_status" ? (
                                  <span
                                    style={{
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      paddingRight: 5,
                                      paddingLeft: 5,
                                      background: bgColor,
                                      color: "#fff",
                                      borderRadius: 5,
                                    }}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </span>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {details.map((valDetails, index2) => {
                                return <Elipses valDetails={valDetails} />;
                              })}
                            </div>
                          </TableCell>
                          {/* <TableCell>
                            <span
                              style={{
                                paddingTop: 3,
                                paddingBottom: 3,
                                paddingRight: 5,
                                paddingLeft: 5,
                                background: bgColor,
                                color: "#fff",
                                borderRadius: 5,
                              }}
                            >
                              {req_approval_status}
                            </span>
                          </TableCell> */}
                          <TableCell>
                            {formatNumber(row.req_quantity)}
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              onClick={() => {
                                onGetCheckDetails(row);
                              }}
                              title="View"
                            >
                              <CallMadeIcon className={classes.iconSize} />
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Button
                              style={{
                                display:
                                  row.req_approval_status == "Pending" ||
                                  row.req_approval_status == "Return"
                                    ? null
                                    : "none",
                              }}
                              onClick={() => approvalRequest("Approved", row)}
                              variant="outlined"
                            >
                              Approve
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state.requestListCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
        {/* 
                    </CardContent>
                </Card> */}
      </Grid>
      {/* <Dialog fullScreen open={open} onClose={handleClose} >
                <div className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {state.actionButton}
                        </Typography>
                    </Toolbar>
                    <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} />
                </div>
                <RequestForm refresh={()=>{setState(prev=>({...prev,refreshReqList:!state.refreshReqList}))}} handleClose={() => handleClose()} action={state.actionButton} selectedRequest={state.selectedRequest} />

            </Dialog> */}
      <Dialog
        fullScreen
        open={state.reqDetailsModal}
        onClose={() => {
          setState((prev) => ({ ...prev, reqDetailsModal: false }));
        }}
      >
        <div style={{ background: "#f5f7f7" }}>
          <AppBar className={classes.appBar} elevation={1}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setState((prev) => ({ ...prev, reqDetailsModal: false }));
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">{state.actionButton}</Typography>
            </Toolbar>
            {/* <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} /> */}
          </AppBar>
          <div style={{ marginTop: 60 }} />
          <RequestDetails
            handleCloseModal={() =>
              setState((prev) => ({ ...prev, reqDetailsModal: false }))
            }
            action={state.actionButton}
            selectedRequest={state.selectedRequest}
            onSubmitApproval={(e, status) => {
              onSubmitApproval(e, status);
            }}
            addComment={() => {
              setState((prev) => ({ ...prev, comment_modal: true }));
            }}
            cheque_details={state.cheque_details}
            onRefresh={() => {
              setState((prev) => ({
                ...prev,
                refreshReqList: !state.refreshReqList,
              }));
            }}
          />
          {/* <Card elevation={6} style={{ position: "fixed", bottom: 1, display: 'flex', alignItems: 'center', background: '#fff', width: '100%', justifyContent: 'flex-end ', height: 50, paddingRight: 20 }}>
                        {state.selectedRequest.req_approval_status === 'Pending' ?
                            <>
                            <Button onClick={() => {
                                setState(prev => ({ ...prev, comment_modal: true }))

                            }} style={{ backgroundColor: '#e74c3c', color: '#fff', width: width < 600 ? '100%' : undefined, marginRight: 10 }} variant="contained" >Add Comment</Button>
                             
                                <Button onClick={(e) => {
                                    onSubmitApproval(e, 'Approved')

                                }} style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Approve</Button>
                            </>
                            : undefined
                        }
                    </Card > */}
          <div style={{ marginTop: 60 }} />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.comment_modal}
        onClose={() => {
          setState((prev) => ({ ...prev, comment_modal: false }));
        }}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <DialogTitle>
            Check Voucher<Typography>Add Comment</Typography>
          </DialogTitle>
          <div
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              setState((prev) => ({ ...prev, comment_modal: false }));
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <DialogContent>
          <form onSubmit={AddComment}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  multiline
                  rows={6}
                  required
                  style={{ width: "100%" }}
                  onChange={onChangeText}
                  name="comment_value"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#f39c12",
                    color: "#fff",
                    marginRight: width < 600 ? undefined : 10,
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      actionStatus: "Pending",
                    }))
                  }
                >
                  Start Over
                </Button>
                <Button
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      actionStatus: "Denied",
                    }))
                  }
                  type="submit"
                  style={{
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    marginRight: width < 600 ? undefined : 10,
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                >
                  Denied
                </Button>
                <Button
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      actionStatus: "Approved",
                    }))
                  }
                  type="submit"
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#fff",
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                >
                  Approve
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const Elipses = ({ valDetails }) => {
  const [state, setState] = React.useState({
    open: false,
  });
  const onClickView = (status) => {
    setState((prev) => ({
      ...prev,
      open: status,
    }));
  };
  return (
    <>
      {!state.open && valDetails.description.length > 50 ? (
        <>
          <Typography>
            {valDetails.description.substring(0, 50) + "..."}
          </Typography>
          <div
            onClick={() => onClickView(true)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            <u>View</u>
          </div>
        </>
      ) : (
        <>
          <Typography>{valDetails.description}</Typography>
          {valDetails.description.length > 50 ? (
            <div onClick={() => onClickView(false)}>
              <u style={{ color: "blue", cursor: "pointer" }}>Hide</u>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
