// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Icon,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import { getData } from "../../api/api";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import XLSX from "xlsx";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import readXlsxFile from "read-excel-file";
import { DropzoneDialog } from "material-ui-dropzone";
import axios from "axios";
import {
  HashRouter as Router,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import PositionedSnackbar from "../../alert";
// import ReactExport from "react-data-export";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles({
  root: {
    width: "100%",
    // overflowX: "auto",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
  textFieldStyle: {
    width: "100%",
  },
});
let width = window.innerWidth;
const DropZone = ({ handleChangeFile, val, index }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* <Button onClick={() => { setOpen(true) }} type='button' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" startIcon={<PublishIcon />} >Excel&nbsp;
                <div style={{ height: 18, width: 18, borderRadius: 10, background: '#eb4d4b', display: val.file.length == 0 ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="p" style={{ color: '#fff' }}>{val.file.length}</Typography>

                </div>
            </Button> */}
      <Tooltip title="upload summary">
        <PublishIcon
          style={{ color: "#2c6164", cursor: "pointer" }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </Tooltip>
      <DropzoneDialog
        acceptedFiles={[
          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]}
        cancelButtonText={"cancel"}
        filesLimit={1}
        submitButtonText={"done"}
        name={"excel" + index}
        maxFileSize={50000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={() => setOpen(false)}
        // initialFiles={file}
        onChange={(e) => handleChangeFile(e, index)}
        clearOnUnmount={false}
        // onDelete={(e) => {
        //     for (let index2 = 0; index2 < file.length; index2++) {
        //         const element = file[index2];
        //         if (element.name === e.name) {
        //             file.splice(index2, 1)
        //         }
        //         forceUpdate()
        //     }
        // }}
        // onSave={(files) => {
        //     console.log('Files:', files);
        //     setOpen(false);
        // }}
        showPreviews={false}
        // showFileNames={true}
        showFileNamesInPreview={false}
        showPreviewsInDropzone={true}
      />
    </>
  );
};
const DropZone2 = ({ handleChangeFile }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Card
        onClick={() => {
          setOpen(true);
        }}
        variant="outlined"
        style={{ cursor: "pointer", background: "#ecf0f1" }}
      >
        <CardContent>
          <Icon style={{ color: "#bdc3c7" }}>upload_file</Icon>
          <Typography style={{ color: "#bdc3c7" }}>Upload</Typography>
        </CardContent>
      </Card>
      <DropzoneDialog
        // acceptedFiles={[
        //   ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // ]}
        acceptedFiles={["image/*,application/pdf"]}
        cancelButtonText={"cancel"}
        filesLimit={20}
        submitButtonText={"done"}
        name={"excel"}
        maxFileSize={50000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={() => setOpen(false)}
        onChange={(e) => handleChangeFile(e)}
        clearOnUnmount={false}
        showPreviews={false}
        showFileNamesInPreview={false}
        showPreviewsInDropzone={true}
      />
    </>
  );
};
function AddBillingForm() {
  const history = useHistory();
  const classes = useStyles();
  const companies = useSelector((state) => state.HomeReducer.companies);
  const branches = useSelector((state) => state.HomeReducer.branches);
  const branchList = useSelector((state) => state.BillingReducer.branchList);
  const company_id = useSelector((state) => state.BillingReducer.company_id);
  const branch_id = useSelector((state) => state.BillingReducer.branch_id);
  const clientList = useSelector((state) => state.BillingReducer.clientList);
  const client_id = useSelector((state) => state.BillingReducer.client_id);
  const address = useSelector((state) => state.BillingReducer.address);
  const tin = useSelector((state) => state.BillingReducer.tin);
  const serviceList = useSelector((state) => state.BillingReducer.serviceList);
  const radioValueDueDate = useSelector(
    (state) => state.BillingReducer.radioValueDueDate
  );

  const selectedService = useSelector(
    (state) => state.BillingReducer.selectedService
  );
  const clientBranchList = useSelector(
    (state) => state.BillingReducer.clientBranchList
  );
  const branchServedList = useSelector(
    (state) => state.BillingReducer.branchServedList
  );
  const periodEnd = useSelector((state) => state.BillingReducer.periodEnd);
  const periodStart = useSelector((state) => state.BillingReducer.periodStart);
  const date = useSelector((state) => state.BillingReducer.date);
  const invoice_due = useSelector((state) => state.BillingReducer.invoice_due);
  const selectedClient = useSelector(
    (state) => state.BillingReducer.selectedClient
  );
  const other = useSelector((state) => state.BillingReducer.other);
  const clientBranchAddress = useSelector(
    (state) => state.BillingReducer.clientBranchAddress
  );
  const req_branch_tin = useSelector(
    (state) => state.BillingReducer.req_branch_tin
  );
  const req_branch_contact = useSelector(
    (state) => state.BillingReducer.req_branch_contact
  );
  const vatPercent = useSelector((state) => state.BillingReducer.vatPercent);
  const withHoldingTaxPercent = useSelector(
    (state) => state.BillingReducer.withHoldingTaxPercent
  );
  const client_name = useSelector((state) => state.BillingReducer.client_name);
  const rental = useSelector((state) => state.BillingReducer.rental);
  const company_name = useSelector(
    (state) => state.BillingReducer.company_name
  );
  const branch_name = useSelector((state) => state.BillingReducer.branch_name);
  const bill_reference_no = useSelector(
    (state) => state.BillingReducer.bill_reference_no
  );
  const radioValue = useSelector((state) => state.BillingReducer.radioValue);
  const [file, setFile] = React.useState(false);
  const [updateJournalEntry, setUpdateJournalEntry] = React.useState(false);
  const alert = useSelector((state) => state.ClientReducer.alert);
  const alertMessage = useSelector((state) => state.ClientReducer.alertMessage);
  const alertColor = useSelector((state) => state.ClientReducer.alertColor);
  const actionType = useSelector((state) => state.BillingReducer.actionType);
  const invoice_no = useSelector((state) => state.BillingReducer.invoice_no);
  const req_id = useSelector((state) => state.BillingReducer.req_id);
  const account_name = useSelector(
    (state) => state.BillingReducer.account_name
  );
  const journal = useSelector((state) => state.BillingReducer.journal);
  const refreshUploadFile = useSelector(
    (state) => state.BillingReducer.refreshUploadFile
  );
  const selectedIndexUploadExel = useSelector(
    (state) => state.BillingReducer.selectedIndexUploadExel
  );
  const selectedBillingInvoice = useSelector(
    (state) => state.BillingReducer.selectedBillingInvoice
  );
  const [readOnly, setReadOnly] = React.useState(false);
  const [state, setState] = React.useState({
    accountsTitle: [],
    file: [],
    radioValueDueDate: "By Date",
  });
  const dispatch = useDispatch();
  const forceUpdate = React.useReducer((bool) => !bool)[1];
  const handleChangeRadio = (event) => {
    dispatch({
      type: "onChangeBilling",
      data: {
        branchServedList: [
          {
            client_branch_id: "",
            client_branch_name: "",
            total_amount: "",
            file: [],
            accountTitleId: "",
            accountTitleName: "",
            description: "",
            quanty: "",
            unit_price: "",
          },
        ],
        other: [
          {
            description: "",
            quantity: "",
            unit_price: "",
            total_amount: "",
          },
        ],
        selectedService: [],
        periodStart: "",
        periodEnd: "",
        radioValue: event.target.value,
      },
    });
  };

  const handleChangeRadioDueDate = (event) => {
    setState((prev) => ({
      ...prev,
      radioValueDueDate: event.target.value,
    }));
  };

  const onChangeCompany = (e) => {
    let company_id = e.target.value;
    const branchesFilter = branches.filter(
      (val) => val.company_id == company_id
    );
    dispatch({
      type: "onChangeBilling",
      data: {
        branchList: branchesFilter,
        company_id: company_id,
        company_name: branchesFilter[0].company_name,
        selectedService: [],
      },
    });
    getClientList(company_id);
  };

  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branchesFilter = branches.filter((val) => val.branch_id == branch_id);

    dispatch({
      type: "onChangeBilling",
      data: {
        branch_id: branch_id,
        clientBranchAddress: branchesFilter[0].branch_loc_description,
        req_branch_tin: branchesFilter[0].tin,
        req_branch_contact: branchesFilter[0].contact_no,
        branch_name: branchesFilter[0].branch_name,
      },
    });
  };
  const getClientList = (com_id) => {
    let data = {
      user_id: localStorage.getItem("u"),
      company_id: com_id,
    };
    getData("aam/getClients", data).then((res) => {
      let client = res.data;
      let clientData = [];
      for (let index = 0; index < client.length; index++) {
        const element = client[index];
        let match = clientData.findIndex(
          (val) =>
            String(val.client_name).toLocaleUpperCase() ==
            String(element.client_name).toLocaleUpperCase()
        );

        if (match == -1) {
          clientData.push(element);
        }
      }
      setState((prev) => ({
        ...prev,
        clientListUniqueData: clientData,
      }));
      let client_id = selectedBillingInvoice.client_id;
      let selectedClient = [];
      if (actionType == "Edit") {
        let selectedClientMatch = res.data.filter(
          (val) => val.client_id == client_id
        );
        selectedClient = selectedClientMatch[0];
        onChangeTextClient("", selectedClient);
      }
      dispatch({
        type: "onChangeBilling",
        data: {
          clientList: res.data,
          selectedClient: selectedClient,
        },
      });
    });
  };

  const onChangeTextClient = (e, values) => {
    let client_id = values.client_id;
    let client_name = values.client_name;

    let address = values.client_address;
    let tin = values.tin;
    let data = {
      client_id: client_id,
    };
    let matchClient = clientList.filter(
      (val) => val.client_name == client_name
    );

    getData("aam/getServices", data).then((res) => {
      if (actionType == "Edit") {
        let service_id = selectedBillingInvoice.service_id;
        let matchService = res.res.filter(
          (val) => val.service_id == service_id
        );
        dispatch({
          type: "onChangeBilling",
          data: {
            serviceList: res.res,
            selectedService: matchService[0],
          },
        });
      } else {
        dispatch({
          type: "onChangeBilling",
          data: {
            client_id: client_id,
            address: address,
            tin: tin,
            client_name: client_name,
            serviceList: res.res,
            selectedClient: values,
            selectedService: [],
            clientBranchList: [],
            branchServedList: [
              {
                client_branch_id: "",
                client_branch_name: "",
                total_amount: "",
                file: [],
                accountTitleId: "",
                accountTitleName: "",
                description: "",
                quanty: "",
                unit_price: "",
              },
            ],
            periodStart: "",
            periodEnd: "",
            selectedService: [],
          },
        });
      }

      setState((prev) => ({
        ...prev,
        accountsTitle: res.accountsBilling,
      }));
    });
  };

  const onChangeText = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    dispatch({
      type: "onChangeBilling",
      data: {
        [name]: value,
      },
    });
  };

  const onChangeService = (e, selectedIndex) => {
    let value = e.target.value;
    let selectedService = serviceList.filter(
      (val) => val.service_name == value
    );
    let data = {
      service_id: value,
      client_id: client_id,
      contract_no: selectedService[0].contract_no,
      client_name: client_name,
    };
    getData("aam/getServiceBranch", data).then((res) => {
      dispatch({
        type: "onChangeBilling",
        data: {
          selectedService: selectedService[0],
          clientBranchList: res.res,
        },
      });
      dispatch({
        type: "onChangeBilling",
        data: {
          branchServedList: branchServedList.map((val, index) =>
            index == selectedIndex
              ? {
                  ...val,
                  description: selectedService[0].service_name,
                }
              : val
          ),
        },
      });
    });
  };
  const newLine = (type) => {
    if (type == "Service") {
      dispatch({
        type: "onChangeBilling",
        data: {
          branchServedList: branchServedList.concat({
            client_branch_id: "",
            client_branch_name: "",
            total_amount: "",
            file: [],
            accountTitleId: "",
            accountTitleName: "",
            description: "",
            quanty: "",
            unit_price: "",
          }),
        },
      });
    } else if (type == "Other") {
      dispatch({
        type: "onChangeBilling",
        data: {
          other: other.concat({
            description: "",
            quanty: "",
            unit_price: "",
            total_amount: "",
            accountTitleId: "",
            accountTitleName: "",
          }),
        },
      });
    } else if (type == "Rental") {
      dispatch({
        type: "onChangeBilling",
        data: {
          rental: rental.concat({
            description: "",
            total_amount: "",
            accountTitleId: "",
            accountTitleName: "",
          }),
        },
      });
    }
  };

  const onChangeClientBranch = (e, selectedIndex) => {
    let value = e.target.value;
    let client_branch_name = clientBranchList.filter(
      (val) => val.client_branch_id == value
    );
    let business_center = "";
    if (selectedService.required_business_center == 1) {
      business_center = client_branch_name[0].business_center;
    }
    dispatch({
      type: "onChangeBilling",
      data: {
        branchServedList: branchServedList.map((val, index) =>
          index == selectedIndex
            ? {
                ...val,
                client_branch_id: value,
                business_center: business_center,
                client_branch_name: client_branch_name[0].client_branch_name,
              }
            : val
        ),
      },
    });
  };

  const onChangeAccountTitle = (e, selectedIndex) => {
    let value = e.target.value;
    let accountTitle = state.accountsTitle.filter(
      (val) => val.balance_sheet_group == value
    );

    dispatch({
      type: "onChangeBilling",
      data: {
        branchServedList: branchServedList.map((val, index) =>
          index == selectedIndex
            ? {
                ...val,
                accountTitleId: accountTitle[0].balance_sheet_group,
                accountTitleName: accountTitle[0].balance_sheet_group_name,
              }
            : val
        ),
      },
    });
  };

  const onChangeAmount = (e, selectedIndex) => {
    let value = e.target.value;
    dispatch({
      type: "onChangeBilling",
      data: {
        branchServedList: branchServedList.map((val, index) =>
          index == selectedIndex
            ? {
                ...val,
                total_amount: value,
              }
            : val
        ),
      },
    });
  };

  const onChangeOthers = (e, selectedIndex, otherVal) => {
    let value = e.target.value;
    let name = e.target.name;
    let quantity = otherVal.quantity;
    let unit_price = otherVal.unit_price;

    if (name == "quantity") {
      quantity = value;
    }
    if (name == "unit_price") {
      unit_price = value;
    }
    if (name == "total_amount") {
      dispatch({
        type: "onChangeBilling",
        data: {
          branchServedList: branchServedList.map((val, index) =>
            index == selectedIndex
              ? {
                  ...val,
                  total_amount: value,
                }
              : val
          ),
        },
      });
    } else {
      dispatch({
        type: "onChangeBilling",
        data: {
          branchServedList: branchServedList.map((val, index) =>
            index == selectedIndex
              ? {
                  ...val,
                  [name]: value,
                  total_amount: parseFloat(quantity) * parseFloat(unit_price),
                }
              : val
          ),
        },
      });
    }
  };

  const onChangeRental = (e, selectedIndex, rentalVal) => {
    let value = e.target.value;
    let name = e.target.name;

    dispatch({
      type: "onChangeBilling",
      data: {
        branchServedList: branchServedList.map((val, index) =>
          index == selectedIndex
            ? {
                ...val,
                [name]: value,
              }
            : val
        ),
      },
    });
  };

  const removeItem = (index, type) => {
    if (branchServedList.length > 1) {
      branchServedList.splice(index, 1);
      if (actionType == "Edit") {
        let remainingAttach = JSON.parse(
          selectedBillingInvoice.req_attachments
        ).filter((val, index2) => index2 != index);
        dispatch({
          type: "",
          data: {
            selectedBillingInvoice: {
              ...selectedBillingInvoice,
              req_attachments: JSON.stringify(remainingAttach),
            },
          },
        });
      }
      forceUpdate();
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  let total = 0;
  total = parseFloat(
    branchServedList.reduce((count, val) => {
      let total_amount_value = isNaN(parseFloat(val.total_amount));
      if (!total_amount_value) {
        count += parseFloat(val.total_amount);
      }
      return count;
    }, 0)
  );
  // if (radioValue == 'Service') {
  //     total = parseFloat(branchServedList.reduce((count, val) => {
  //         let total_amount_value = isNaN(parseFloat(val.total_amount))
  //         if (!total_amount_value) {
  //             count += parseFloat(val.total_amount)
  //         }
  //         return count
  //     }, 0))
  // } else if (radioValue == 'Other') {
  //     total = parseFloat(other.reduce((count, val) => {
  //         let total_amount_value = isNaN(parseFloat(val.total_amount))
  //         if (!total_amount_value) {
  //             count += parseFloat(val.total_amount)
  //         }
  //         return count
  //     }, 0))
  // } else if (radioValue == 'Rental') {
  //     total = parseFloat(rental.reduce((count, val) => {
  //         let total_amount_value = isNaN(parseFloat(val.total_amount))
  //         if (!total_amount_value) {
  //             count += parseFloat(val.total_amount)
  //         }
  //         return count
  //     }, 0))
  // }

  let vatAmount = total * parseFloat(vatPercent / 100);
  let grossAmount = total + vatAmount;
  let with_holding_tax = (
    parseFloat(withHoldingTaxPercent / 100) * total
  ).toFixed(2);
  let netAmountDue = grossAmount - with_holding_tax;

  React.useEffect(() => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: false,
      },
    });
    if (actionType != "Edit") {
      dispatch({
        type: "onChangeBilling",
        data: {
          company_id: "",
          branch_id: "",
          client_id: "",
          tin: "",
          address: "",
          client_name: "",
          selectedClient: [],
          withHoldingTaxAmount: "",
          date: moment(new Date()).format("YYYY-MM-DD"),
          invoice_due: "",
          clientBranchAddress: "",
          req_branch_tin: "",
          req_branch_contact: "",
          selectedService: [],
          periodStart: "",
          periodEnd: "",
          branchServedList: [
            {
              client_branch_id: "",
              client_branch_name: "",
              total_amount: "",
              file: [],
              accountTitleId: "",
              accountTitleName: "",
              description: "",
              quanty: "",
              unit_price: "",
            },
          ],
          branchList: [],
          radioValue: "Service",
          invoice_no: "",
          req_id: "",
          bill_reference_no: "",
        },
      });
    } else {
      let company_id = selectedBillingInvoice.company_id;
      const branchesFilter = branches.filter(
        (val) => val.company_id == company_id
      );
      const companiesFilter = companies.filter(
        (val) => val.company_id == company_id
      );
      let req_details = JSON.parse(selectedBillingInvoice.req_details);
      getClientList(company_id);
      dispatch({
        type: "onChangeBilling",
        data: {
          company_id: selectedBillingInvoice.company_id,
          branch_id: selectedBillingInvoice.branch_id,
          client_id: selectedBillingInvoice.client_id,
          client_name: selectedBillingInvoice.client_name,
          req_branch_tin: selectedBillingInvoice.req_branch_tin,
          req_branch_contact: selectedBillingInvoice.req_branch_contact,
          clientBranchAddress: selectedBillingInvoice.req_branch_address,
          tin: selectedBillingInvoice.tin,
          address: selectedBillingInvoice.client_address,
          withHoldingTaxAmount: selectedBillingInvoice.with_holding_tax_amount,
          date: moment(new Date()).format("YYYY-MM-DD"),
          invoice_due: selectedBillingInvoice.invoice_due,
          periodStart: moment(selectedBillingInvoice.req_date_start).format(
            "YYYY-MM-DD"
          ),
          periodEnd: moment(selectedBillingInvoice.req_date_end).format(
            "YYYY-MM-DD"
          ),
          branchServedList: req_details,
          branchList: branchesFilter,
          radioValue: selectedBillingInvoice.invoice_type,
          invoice_no: "",
          req_id: selectedBillingInvoice.req_id,
          company_name: branchesFilter[0].company_name,
          withHoldingTaxPercent: parseInt(
            selectedBillingInvoice.with_holding_tax_percent
          ),
          vatPercent: parseInt(selectedBillingInvoice.vat_percent),
          radioValueDueDate: selectedBillingInvoice.invoice_due_type,
          bill_reference_no: selectedBillingInvoice.docs_reference_no,
        },
      });

      setReadOnly(true);
    }
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    let bru_served = [];
    bru_served = branchServedList;
    let journalEntry = onUpdateJournalEntry();
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    // if (radioValue === 'Rental') {
    //     bru_served = branchServedList
    // } else if (radioValue === 'Service') {
    //     bru_served = branchServedList
    // } else {
    //     bru_served = other
    // }
    let req_attachments = "[]";
    if (actionType == "Edit") {
      req_attachments = selectedBillingInvoice.req_attachments;
    }
    let new_file = state.file;
    // branchServedList.forEach((val) => {
    //   if (typeof val.file[0]?.lastModified != "undefined") {
    //     new_file.push(val.file[0]);
    //   }
    // if (val.client_branch_id == "") {
    //   val.client_branch_id = client_id;
    //   val.client_branch_name = client_name;
    // }
    // });

    let data = {
      date: date,
      invoice_due: invoice_due,
      client_id: client_id,
      branch_id: branch_id,
      company_id: company_id,
      client_address: address,
      tin: tin,
      client_name: client_name,
      service_id: selectedService?.service_id,
      service_name: selectedService?.service_name,
      service_contract_no: selectedService?.contract_no,
      period_start: periodStart,
      period_end: periodEnd,
      bru_served: branchServedList,
      invoice_type: "Service",
      vat_percent: vatPercent,
      with_holding_tax_percent: withHoldingTaxPercent,
      vat_amount: parseFloat(vatAmount).toFixed(2),
      gross_amount: parseFloat(grossAmount).toFixed(2),
      total_amount: parseFloat(total).toFixed(2),
      with_holding_tax_amount: parseFloat(with_holding_tax).toFixed(2),
      net_amount_due: parseFloat(netAmountDue).toFixed(2),
      branch_name: branch_name,
      company_name: company_name,
      type_name: "BILLING_INVOICE",
      user_id: localStorage.getItem("u"),
      actionType: actionType,
      req_id: req_id,
      req_branch_address: clientBranchAddress,
      req_branch_tin: req_branch_tin,
      req_branch_contact: req_branch_contact,
      journalEntry: journalEntry,
      req_attachments: req_attachments,
      invoice_due_type: radioValueDueDate,
      docs_reference_no: bill_reference_no,
    };
    const formData = new FormData();

    for (let i = 0; i < new_file.length; i++) {
      formData.append("file" + i, new_file[i]);
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));

    axios
      .post("http://localhost/api/aam/createBilling", formData, config)
      .then((response) => {
        let alertMessage = "";
        let alertColor = "";
        let alert = false;
        if (response.data.res == true) {
          alertMessage = "Success , Branch details has been added";
          alertColor = "#2ecc71";
          alert = true;
        } else if (response.data.res === "Exist") {
          alertMessage = "Warning , Billing Invoice is already Exist";
          alertColor = "#f39c12";
          alert = true;
        } else if (response.data.res === "Edit") {
          alertMessage = "Success , Billing Invoice is has been Edited";
          alertColor = "#2ecc71";
          alert = true;
        } else if (response.data.res === "Not Allowed") {
          alertMessage =
            "Warning , Billing Invoice is not allowed to be edited";
          alertColor = "#2ecc71";
          alert = true;
        }
        dispatch({
          type: "onChangeClientReducer",
          data: {
            alert: alert,
            alertMessage: alertMessage,
            alertColor: alertColor,
          },
        });
        if (response.data.res == true || response.data.res === "Edit") {
          history.push("/billing");
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
  };
  const generateExcel = () => {
    let sheetData = [
      [
        "Date Accomplished",
        "Service Description",
        "Quantity",
        "Service Rate",
        "Total",
      ],
    ];
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, "template");

    XLSX.writeFile(wb, "Template.xlsx");
  };
  const handleChangeFile = (files, selectedIndex) => {
    readXlsxFile(files[0]).then((row) => {
      let total = 0;
      for (let index = 1; index < row.length; index++) {
        const element = row[index];
        total += parseFloat(element[4]);
      }
      dispatch({
        type: "onChangeBilling",
        data: {
          branchServedList: branchServedList.map((val, index) =>
            index == selectedIndex
              ? {
                  ...val,
                  total_amount: total,
                  file: files,
                }
              : val
          ),
        },
      });
      // setTimeout(()=>{
      // onUpdateJournalEntry(selectedIndex)

      // },1000)
    });
  };

  const handleChangeFile2 = (files) => {
    setState((prev) => ({
      ...prev,
      file: files,
    }));
  };

  const onUpdateJournalEntry = () => {
    let journalEntry = [];
    journal.forEach((val) => {
      let accounts = JSON.parse(val.chart_of_accounts);
      accounts.forEach((val2) => {
        let debit = "";
        let credit = "";
        let type = "";
        if (val2.balance_sheet_group == 59) {
          debit = parseFloat(netAmountDue).toFixed(4);
          type = "Debit";
        } else if (val2.balance_sheet_group == 56) {
          debit = parseFloat(with_holding_tax).toFixed(4);
          type = "Debit";
        } else if (val2.balance_sheet_group == 168) {
          credit = parseFloat(vatAmount).toFixed(4);
          type = "Credit";
        }
        journalEntry.push({
          description: selectedService?.service_name,
          account_name: val2.balance_sheet_group_name,
          account_id: val2.balance_sheet_group,
          type: val2.finance_type,
          credit: credit,
          debit: debit,
          reference_id: "",
          bill_company_id: company_id,
          bill_branch_id: branch_id,
          client_id: client_id,
          journal_id: val.journal_id,
          journal_name: val.journal_name,
          service_id: selectedService?.service_id,
        });
      });
      branchServedList.forEach((val2) => {
        journalEntry.push({
          description: selectedService?.service_name,
          account_name: val2.accountTitleName,
          account_id: val2.accountTitleId,
          type: "Credit",
          credit: parseFloat(val2.total_amount).toFixed(4),
          debit: "",
          reference_id: "",
          bill_company_id: company_id,
          bill_branch_id: branch_id,
          client_id: client_id,
          journal_id: val.journal_id,
          journal_name: val.journal_name,
          service_id: selectedService?.service_id,
        });
      });
    });

    return journalEntry;
  };

  return (
    <form onSubmit={onSubmit}>
      <PositionedSnackbar
        open={alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={alertMessage}
        alertColor={alertColor}
        handleClose={() => {
          dispatch({
            type: "onChangeClientReducer",
            data: { alert: false },
          });
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Billing And Collection </Typography>
            <Link color="inherit" href="#/billing">
              Billing
            </Link>
            <Typography color="textPrimary">Billing Form</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid container justify="flex-start" item xs={12} md={12}>
                  <Typography
                    variant="h5"
                    style={{ color: "#2c7f84", fontWeight: "bold" }}
                  >
                    Invoice Form
                  </Typography>
                </Grid>
                {/* <Grid container justify='flex-start' item xs={12} md={12}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left',marginBottom:5 }} variant="p">Invoice</Typography>
                                        <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: '#7f8c8d', display: 'flex' }}>
                                            <div style={{ backgroundColor: '#7f8c8d', color: '#fff', paddingRight: 5, paddingLeft: 5 }}>
                                                <Typography>#</Typography>
                                            </div>
                                            <input value = {invoice_no} style={{ borderStyle: 'none', outline: 'none', width: width > 600 ? 100 : '100%' }} />
                                        </div>
                                    </div>
                                </Grid> */}
              </Grid>
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: "#95a5a6",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              />
              <Grid container spacing={1}>
                <Grid container justify={"flex-start"} item xs={12} md={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "left",
                        marginBottom: 5,
                      }}
                      variant="p"
                    >
                      Date
                    </Typography>
                    <TextField
                      type="date"
                      readOnly={actionType === "Edit" ? true : false}
                      value={date}
                      required
                      onChange={actionType === "Edit" ? "" : onChangeText}
                      name="date"
                      variant="outlined"
                      size="small"
                      className={classes.textFieldStyle}
                    />
                  </div>
                </Grid>
                <Grid container justify={"flex-start"} item xs={12} md={2}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "left",
                        marginBottom: 5,
                      }}
                      variant="p"
                    >
                      Bill Reference No.
                    </Typography>
                    <TextField
                      value={bill_reference_no}
                      required
                      onChange={onChangeText}
                      name="bill_reference_no"
                      variant="outlined"
                      size="small"
                      className={classes.textFieldStyle}
                    />
                  </div>
                </Grid>
                <Grid
                  container
                  justify={"flex-start"}
                  item
                  xs={12}
                  md={12}
                ></Grid>
                <Grid container justify={"flex-start"} item xs={12} md={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 12,
                      textAlign: "left",
                      marginBottom: 5,
                    }}
                    variant="p"
                  >
                    Invoice Due
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <RadioGroup
                      aria-label="gender"
                      readOnly={actionType === "Edit" ? true : false}
                      name="radioValueDueDate"
                      value={radioValueDueDate}
                      onChange={onChangeText}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        value="By Date"
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          color: "#2c7f84",
                        }}
                        control={<Radio />}
                        label="By Date"
                      />
                      <FormControlLabel
                        value="By Days"
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          color: "#2c7f84",
                        }}
                        control={<Radio />}
                        label="By Days"
                      />
                    </RadioGroup>
                    {radioValueDueDate == "By Date" ? (
                      <TextField
                        type="date"
                        readOnly={actionType === "Edit" ? true : false}
                        value={invoice_due}
                        required
                        variant="outlined"
                        size="small"
                        onChange={onChangeText}
                        name="invoice_due"
                        style={{ width: 150 }}
                      />
                    ) : (
                      <TextField
                        value={invoice_due}
                        required
                        variant="outlined"
                        size="small"
                        onChange={onChangeText}
                        name="invoice_due"
                        // className={classes.textFieldStyle}
                        style={{ width: 150 }}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <div style={{ marginTop: 15, marginBottom: 15 }} />
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid container justify={"flex-start"} item xs={12} md={6}>
                      <Grid container spacing={1}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Company
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <FormControl style={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                              readOnly={actionType === "Edit" ? true : false}
                              required
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{ textAlign: "left" }}
                              value={company_id ?? company_id}
                              onChange={onChangeCompany}
                            >
                              {companies.map((val, index) => {
                                return (
                                  <MenuItem key={index} value={val.company_id}>
                                    {val.company_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Branch
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <FormControl style={{ width: "100%" }} size="small">
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                              readOnly={actionType === "Edit" ? true : false}
                              required
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{ textAlign: "left" }}
                              value={branch_id ?? branch_id}
                              onChange={onChangeBranch}
                            >
                              {branchList.map((val, index) => {
                                return (
                                  <MenuItem key={index} value={val.branch_id}>
                                    {val.branch_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: 20 }}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Address
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <Typography variant="p" style={{ textAlign: "left" }}>
                            {clientBranchAddress}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: 20 }}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Tin #
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <Typography variant="p" style={{ textAlign: "left" }}>
                            {req_branch_tin}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} style={{ marginTop: 20 }}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Contact No.
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <Typography variant="p" style={{ textAlign: "left" }}>
                            {req_branch_contact}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify={"flex-start"}
                      item
                      xs={12}
                      md={1}
                    ></Grid>
                    <Grid container justify={"flex-start"} item xs={12} md={5}>
                      <Grid container spacing={1}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div style={{ display: "flex", marginTop: 20 }}>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Client
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          {readOnly ? (
                            <TextField
                              syle={{ width: "100%" }}
                              readOnly
                              value={selectedClient.client_name}
                            />
                          ) : (
                            <Autocomplete
                              required_business_center
                              // readOnly={actionType === 'Edit' ? true : false}

                              id="combo-box-demo"
                              size="small"
                              value={
                                selectedClient.client_name
                                  ? selectedClient
                                  : undefined
                              }
                              renderOption={(option) => (
                                <Typography style={{ fontSize: 13 }}>
                                  {String(
                                    option.client_name
                                  ).toLocaleUpperCase()}{" "}
                                  <span
                                    style={{ fontSize: 13, color: "#3498db" }}
                                  >
                                    {String(
                                      option.client_branch_name
                                    ).toLocaleUpperCase()}
                                  </span>
                                </Typography>
                              )}
                              options={clientList}
                              getOptionLabel={(option) =>
                                String(option.client_name).toLocaleUpperCase() +
                                " " +
                                option.client_branch_name
                              }
                              onChange={onChangeTextClient}
                              style={{ width: "100%", marginTop: 11 }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Address
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <Typography variant="p" style={{ textAlign: "left" }}>
                            {address}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={4}
                        >
                          <div
                            style={{ display: "flex", alignItems: "fex-start" }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                              variant="p"
                            >
                              Tin #
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          container
                          justify="flex-start"
                          item
                          xs={12}
                          md={8}
                        >
                          <Typography variant="p" style={{ textAlign: "left" }}>
                            {tin}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justify={"flex-start"}
                      item
                      xs={12}
                      md={12}
                    ></Grid>
                  </Grid>
                </CardContent>
              </Card>
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: "#95a5a6",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              />
              <Grid container spacing={1}>
                <Grid container justify="flex-start" item xs={12} md={12}>
                  <RadioGroup
                    aria-label="gender"
                    readOnly={actionType === "Edit" ? true : false}
                    name="gender1"
                    value={radioValue}
                    onChange={handleChangeRadio}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="Service"
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "#2c7f84",
                      }}
                      control={<Radio />}
                      label="Service"
                    />
                    {/* <FormControlLabel
                      value="Rental"
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "#2c7f84",
                      }}
                      control={<Radio />}
                      label="Rental"
                    />
                    <FormControlLabel
                      value="Others"
                      control={<Radio />}
                      style={{
                        fontWeight: "bold",
                        fontSize: 14,
                        color: "#2c7f84",
                      }}
                      label="Others"
                    /> */}
                  </RadioGroup>
                  {/* <Typography style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: '#2c7f84' }} >Service </Typography> */}
                </Grid>
                {radioValue === "Service" && (
                  <>
                    {/* <Grid container justify="flex-start" item xs={12} md={4}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            textAlign: "left",
                            marginBottom: 5,
                          }}
                          variant="p"
                        >
                          Service Deliverd
                        </Typography>

                        <FormControl
                          variant={"outlined"}
                          style={{ width: "100%" }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label"></InputLabel>
                          <Select
                            readOnly={actionType === "Edit" ? true : false}
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            style={{
                              textAlign: "left",
                              backgroundColor: "#f7fbfc",
                            }}
                            value={selectedService?.service_id}
                            onChange={onChangeService}
                          >
                            {serviceList.map((val, index) => {
                              return (
                                <MenuItem key={index} value={val.service_id}>
                                  {val.service_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid> */}
                    {/* <Grid container justify="flex-start" item xs={12} md={3}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            textAlign: "left",
                            marginBottom: 5,
                          }}
                          variant="p"
                        >
                          Contract No.
                        </Typography>
                        <TextField
                          readOnly
                          value={
                            selectedService?.contract_no
                              ? selectedService.contract_no
                              : ""
                          }
                          name="contract_no"
                          variant="outlined"
                          size="small"
                          className={classes.textFieldStyle}
                        />
                      </div>
                    </Grid> */}

                    <Grid container justify="flex-start" item xs={12} md={5}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 12,
                            textAlign: "left",
                            marginBottom: 5,
                          }}
                          variant="p"
                        >
                          Period Covered
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                            type="date"
                            readOnly={actionType === "Edit" ? true : false}
                            onChange={onChangeText}
                            value={periodStart}
                            required
                            name="periodStart"
                            variant="outlined"
                            size="small"
                            className={classes.textFieldStyle}
                          />
                          <Typography> - </Typography>
                          <TextField
                            type="date"
                            readOnly={actionType === "Edit" ? true : false}
                            onChange={onChangeText}
                            value={periodEnd}
                            required
                            name="periodEnd"
                            variant="outlined"
                            size="small"
                            className={classes.textFieldStyle}
                          />
                        </div>
                      </div>
                    </Grid>
                    <div
                      style={{
                        height: 1,
                        width: "100%",
                        background: "#95a5a6",
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    />

                    {/* <Grid container justify="flex-start" item xs={12} md={12}>
                      <Button
                        onClick={() => {
                          generateExcel();
                        }}
                        type="button"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                          marginTop: width < 600 ? undefined : 17,
                        }}
                        variant="contained"
                        startIcon={<GetAppIcon />}
                      >
                        Template
                      </Button>
                    </Grid>
                    <div
                      style={{
                        height: 1,
                        width: "100%",
                        background: "#95a5a6",
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                    /> */}
                    <Grid container justify="flex-start" item xs={12} md={12}>
                      <table style={{ width: "100%" }}>
                        <tr>
                          <th style={{ width: "20%", textAlign: "left" }}>
                            Service
                          </th>
                          <th style={{ width: "25%", textAlign: "left" }}>
                            Branch
                          </th>
                          <th style={{ width: "30%", textAlign: "left" }}>
                            Account Title
                          </th>
                          <th style={{ width: "20%", textAlign: "left" }}>
                            Amount
                          </th>
                          <th style={{ textAlign: "left" }}></th>
                          <th style={{ textAlign: "left" }}></th>
                        </tr>
                        {branchServedList.map((val, index) => {
                          return (
                            <tr>
                              <td>
                                <FormControl
                                  variant={"outlined"}
                                  style={{ width: "100%" }}
                                  size="small"
                                >
                                  <InputLabel id="demo-simple-select-label"></InputLabel>
                                  <Select
                                    // readOnly={
                                    //   actionType === "Edit" ? true : false
                                    // }
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{
                                      textAlign: "left",
                                      backgroundColor: "#f7fbfc",
                                    }}
                                    value={val?.description}
                                    onChange={(e) => onChangeService(e, index)}
                                  >
                                    {serviceList.map((val, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={val.service_name}
                                        >
                                          {val.service_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </td>
                              <td>
                                {" "}
                                <FormControl
                                  variant={"outlined"}
                                  style={{ width: "100%" }}
                                  size="small"
                                >
                                  <InputLabel id="demo-simple-select-label"></InputLabel>
                                  <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: "left" }}
                                    value={val.client_branch_id}
                                    onChange={(e) =>
                                      onChangeClientBranch(e, index)
                                    }
                                  >
                                    {clientBranchList.map((val2, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={val2.client_branch_id}
                                        >
                                          {val2.client_branch_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </td>

                              <td>
                                <FormControl
                                  variant={"outlined"}
                                  style={{ width: "100%" }}
                                  size="small"
                                >
                                  <InputLabel id="demo-simple-select-label"></InputLabel>
                                  <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: "left" }}
                                    value={val.accountTitleId}
                                    onChange={(e) =>
                                      onChangeAccountTitle(e, index)
                                    }
                                  >
                                    {state.accountsTitle.map((val2, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={val2.balance_sheet_group}
                                        >
                                          {val2.balance_sheet_group_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                {/* <TextField style={{ width: '100%' }} readOnly required name='contract_no' value={account_name} variant='outlined' size='small' /> */}
                              </td>
                              <td>
                                <TextField
                                  type="number"
                                  style={{ width: "100%" }}
                                  // readOnly
                                  required
                                  onChange={(e) =>
                                    onChangeOthers(e, index, val)
                                  }
                                  name="total_amount"
                                  value={val.total_amount}
                                  // name="contract_no"
                                  // value={parseFloat(val.total_amount).toFixed(
                                  //   2
                                  // )}
                                  variant="outlined"
                                  size="small"
                                />
                              </td>
                              {/* <td>
                                <DropZone
                                  handleChangeFile={handleChangeFile}
                                  val={val}
                                  index={index}
                                />
                              </td> */}
                              <td>
                                <CloseIcon
                                  onClick={() => {
                                    removeItem(index, "Service");
                                  }}
                                  style={{
                                    color: "#e74c3c",
                                    cursor: "pointer",
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td
                            onClick={() => {
                              newLine("Service");
                            }}
                            style={{
                              textAlign: "left",
                              cursor: "pointer",
                              color: "#2c6164",
                            }}
                          >
                            <u>
                              <Typography>Add a line</Typography>
                            </u>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </table>
                    </Grid>
                  </>
                )}
              </Grid>
              {radioValue === "Others" && (
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={4}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign: "left",
                          marginBottom: 5,
                        }}
                        variant="p"
                      >
                        Service Deliverd
                      </Typography>

                      <FormControl
                        variant={"outlined"}
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          readOnly={actionType === "Edit" ? true : false}
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f7fbfc",
                          }}
                          value={selectedService?.service_id}
                          onChange={onChangeService}
                        >
                          {serviceList.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.service_id}>
                                {val.service_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign: "left",
                          marginBottom: 5,
                        }}
                        variant="p"
                      >
                        Contract No.
                      </Typography>
                      <TextField
                        readOnly
                        value={
                          selectedService?.contract_no
                            ? selectedService.contract_no
                            : ""
                        }
                        name="contract_no"
                        variant="outlined"
                        size="small"
                        className={classes.textFieldStyle}
                      />
                    </div>
                  </Grid>

                  <Grid container justify="flex-start" item xs={12} md={5}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign: "left",
                          marginBottom: 5,
                        }}
                        variant="p"
                      >
                        Period Covered
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <TextField
                          type="date"
                          readOnly={actionType === "Edit" ? true : false}
                          onChange={onChangeText}
                          value={periodStart}
                          required
                          name="periodStart"
                          variant="outlined"
                          size="small"
                          className={classes.textFieldStyle}
                        />
                        <Typography> - </Typography>
                        <TextField
                          type="date"
                          readOnly={actionType === "Edit" ? true : false}
                          onChange={onChangeText}
                          value={periodEnd}
                          required
                          name="periodEnd"
                          variant="outlined"
                          size="small"
                          className={classes.textFieldStyle}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Card variant="outlined">
                    <CardContent>
                      {branchServedList.map((val, index) => {
                        return (
                          <Grid container spacing={1}>
                            <Grid
                              container
                              justify="flex-start"
                              item
                              xs={12}
                              md={3}
                            >
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                  textAlign: "left",
                                  marginBottom: 5,
                                }}
                                variant="p"
                              >
                                Account Title
                              </Typography>
                              <FormControl
                                variant={"outlined"}
                                style={{ width: "100%" }}
                                size="small"
                              >
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                  required
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  style={{ textAlign: "left" }}
                                  value={val.accountTitleId}
                                  onChange={(e) =>
                                    onChangeAccountTitle(e, index)
                                  }
                                >
                                  {state.accountsTitle.map((val2, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={val2.balance_sheet_group}
                                      >
                                        {val2.balance_sheet_group_name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    textAlign: "left",
                                    marginBottom: 5,
                                  }}
                                  variant="p"
                                >
                                  Description
                                </Typography>
                                <TextField
                                  value={val.description}
                                  onChange={(e) =>
                                    onChangeOthers(e, index, val)
                                  }
                                  required
                                  name="description"
                                  variant="outlined"
                                  size="small"
                                  className={classes.textFieldStyle}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    textAlign: "left",
                                    marginBottom: 5,
                                  }}
                                  variant="p"
                                >
                                  QTY
                                </Typography>
                                <TextField
                                  type="number"
                                  onChange={(e) =>
                                    onChangeOthers(e, index, val)
                                  }
                                  required
                                  name="quantity"
                                  value={val.quantity}
                                  variant="outlined"
                                  size="small"
                                  className={classes.textFieldStyle}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    textAlign: "left",
                                    marginBottom: 5,
                                  }}
                                  variant="p"
                                >
                                  Unit Price
                                </Typography>
                                <TextField
                                  type="number"
                                  onChange={(e) =>
                                    onChangeOthers(e, index, val)
                                  }
                                  required
                                  name="unit_price"
                                  value={val.unit_price}
                                  variant="outlined"
                                  size="small"
                                  className={classes.textFieldStyle}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    textAlign: "left",
                                    marginBottom: 5,
                                  }}
                                  variant="p"
                                >
                                  Amount
                                </Typography>
                                <TextField
                                  type="number"
                                  onChange={(e) =>
                                    onChangeOthers(e, index, val)
                                  }
                                  required
                                  value={val.total_amount}
                                  name="total_amount"
                                  variant="outlined"
                                  size="small"
                                  className={classes.textFieldStyle}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={2} md={1}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  marginTop: 6,
                                }}
                              >
                                <CloseIcon
                                  onClick={() => removeItem(index, "Other")}
                                  style={{
                                    color: "#e74c3c",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={6}></Grid>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} md={4}>
                          <div style={{ backgroundColor: "#000" }}>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "bold", color: "#fff" }}
                            >
                              Total :{" "}
                              {formatNumber(
                                branchServedList.reduce((count, val) => {
                                  let total_amount_value = isNaN(
                                    val.total_amount
                                  );
                                  if (!total_amount_value) {
                                    count += val.total_amount;
                                  }
                                  return count;
                                }, 0)
                              )}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Grid container justify="flex-start" item xs={12} md={12}>
                    <div
                      onClick={() => {
                        newLine("Service");
                      }}
                      style={{
                        textAlign: "left",
                        cursor: "pointer",
                        color: "#2c6164",
                      }}
                    >
                      <u>
                        <Typography>Add a line</Typography>
                      </u>
                    </div>
                  </Grid>
                </Grid>
              )}
              {radioValue === "Rental" && (
                <Grid container spacing={1}>
                  <Grid container justify="flex-start" item xs={12} md={4}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign: "left",
                          marginBottom: 5,
                        }}
                        variant="p"
                      >
                        Service Deliverd
                      </Typography>

                      <FormControl
                        variant={"outlined"}
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{
                            textAlign: "left",
                            backgroundColor: "#f7fbfc",
                          }}
                          value={selectedService?.service_id}
                          onChange={onChangeService}
                        >
                          {serviceList.map((val, index) => {
                            return (
                              <MenuItem key={index} value={val.service_id}>
                                {val.service_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid container justify="flex-start" item xs={12} md={3}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign: "left",
                          marginBottom: 5,
                        }}
                        variant="p"
                      >
                        Contract No.
                      </Typography>
                      <TextField
                        readOnly
                        value={
                          selectedService?.contract_no
                            ? selectedService.contract_no
                            : ""
                        }
                        name="contract_no"
                        variant="outlined"
                        size="small"
                        className={classes.textFieldStyle}
                      />
                    </div>
                  </Grid>

                  <Grid container justify="flex-start" item xs={12} md={5}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          textAlign: "left",
                          marginBottom: 5,
                        }}
                        variant="p"
                      >
                        Period Covered
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <TextField
                          type="date"
                          readOnly={actionType === "Edit" ? true : false}
                          onChange={onChangeText}
                          value={periodStart}
                          required
                          name="periodStart"
                          variant="outlined"
                          size="small"
                          className={classes.textFieldStyle}
                        />
                        <Typography> - </Typography>
                        <TextField
                          type="date"
                          readOnly={actionType === "Edit" ? true : false}
                          onChange={onChangeText}
                          value={periodEnd}
                          required
                          name="periodEnd"
                          variant="outlined"
                          size="small"
                          className={classes.textFieldStyle}
                        />
                      </div>
                    </div>
                  </Grid>
                  <div
                    style={{
                      height: 1,
                      width: "100%",
                      background: "#95a5a6",
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  />
                  <Grid container spacing={1}>
                    <Card variant="outlined" style={{ width: "100%" }}>
                      <CardContent>
                        {branchServedList.map((val, index) => {
                          return (
                            <Grid container spacing={1}>
                              <Grid
                                container
                                justify="flex-start"
                                item
                                xs={12}
                                md={3}
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    textAlign: "left",
                                    marginBottom: 5,
                                  }}
                                  variant="p"
                                >
                                  Branch
                                </Typography>
                                <FormControl
                                  variant={"outlined"}
                                  style={{ width: "100%" }}
                                  size="small"
                                >
                                  <InputLabel id="demo-simple-select-label"></InputLabel>
                                  <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ textAlign: "left" }}
                                    value={val.client_branch_id}
                                    onChange={(e) =>
                                      onChangeClientBranch(e, index)
                                    }
                                  >
                                    {clientBranchList.map((val2, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={val2.client_branch_id}
                                        >
                                          {val2.client_branch_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 12,
                                      textAlign: "left",
                                      marginBottom: 5,
                                    }}
                                    variant="p"
                                  >
                                    Description
                                  </Typography>
                                  <TextField
                                    value={val.description}
                                    onChange={(e) =>
                                      onChangeRental(e, index, val)
                                    }
                                    required
                                    name="description"
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldStyle}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 12,
                                      textAlign: "left",
                                      marginBottom: 5,
                                    }}
                                    variant="p"
                                  >
                                    Account Title
                                  </Typography>
                                  <FormControl
                                    variant={"outlined"}
                                    style={{ width: "100%" }}
                                    size="small"
                                  >
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select
                                      required
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      style={{ textAlign: "left" }}
                                      value={val.accountTitleId}
                                      onChange={(e) =>
                                        onChangeAccountTitle(e, index)
                                      }
                                    >
                                      {state.accountsTitle.map(
                                        (val2, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={val2.balance_sheet_group}
                                            >
                                              {val2.balance_sheet_group_name}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                  {/* <TextField value={val.description} onChange={(e) => onChangeRental(e, index, val)} required name='description' variant='outlined' size='small' className={classes.textFieldStyle} /> */}
                                </div>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: 12,
                                      textAlign: "left",
                                      marginBottom: 5,
                                    }}
                                    variant="p"
                                  >
                                    Amount
                                  </Typography>
                                  <TextField
                                    type="number"
                                    onChange={(e) =>
                                      onChangeRental(e, index, val)
                                    }
                                    required
                                    value={val.total_amount}
                                    name="total_amount"
                                    variant="outlined"
                                    size="small"
                                    className={classes.textFieldStyle}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2} md={1}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    marginTop: 6,
                                  }}
                                >
                                  <CloseIcon
                                    onClick={() => removeItem(index, "Rental")}
                                    style={{
                                      color: "#e74c3c",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={1}></Grid>
                          <Grid item xs={12} md={4}>
                            <div style={{ backgroundColor: "#000" }}>
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold", color: "#fff" }}
                              >
                                Total :{" "}
                                {formatNumber(
                                  branchServedList.reduce((count, val) => {
                                    let total_amount_value = isNaN(
                                      val.total_amount
                                    );
                                    if (!total_amount_value) {
                                      count += val.total_amount;
                                    }
                                    return count;
                                  }, 0)
                                )}
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Grid container justify="flex-start" item xs={12} md={12}>
                      <Button
                        onClick={() => {
                          newLine("Rental");
                        }}
                        type="button"
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                          marginTop: width < 600 ? undefined : 17,
                        }}
                        variant="contained"
                      >
                        New Line
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: "#95a5a6",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              />
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#2c7f84",
                    }}
                  >
                    Attachments
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <DropZone2 handleChangeFile={handleChangeFile2} />
                </Grid>
              </Grid>
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: "#95a5a6",
                  marginTop: 15,
                }}
              />
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#2c7f84",
                      marginTop: 20,
                    }}
                  >
                    Other Fees
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "left",
                        marginBottom: 5,
                      }}
                      required
                      variant="p"
                    >
                      Value Added Tax %
                    </Typography>
                    <TextField
                      onChange={onChangeText}
                      required
                      name="vatPercent"
                      value={vatPercent}
                      variant="outlined"
                      size="small"
                      className={classes.textFieldStyle}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "left",
                        marginBottom: 5,
                        marginBottom: 5,
                      }}
                      required
                      variant="p"
                    >
                      With Holding Tax %
                    </Typography>
                    <TextField
                      onChange={onChangeText}
                      required
                      value={withHoldingTaxPercent}
                      name="withHoldingTaxPercent"
                      variant="outlined"
                      size="small"
                      className={classes.textFieldStyle}
                    />
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: "#95a5a6",
                  marginTop: 15,
                  marginBottom: 15,
                }}
              />
              <Grid container spacing={1}>
                {/* <Grid item xs={12} md={12}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: '#2c7f84', marginTop: 20 }} >Summary</Typography>
                                </Grid> */}
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Table>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell style={{ fontWeight: "bold" }}>
                          Total Amount Before Tax
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold", textAlign: "right" }}
                        >
                          {formatNumber(total)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell style={{ fontWeight: "bold" }}>
                          Add : {vatPercent}% VAT
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold", textAlign: "right" }}
                        >
                          {formatNumber(vatAmount)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow style={{ backgroundColor: "#000" }}>
                        <StyledTableCell
                          style={{
                            fontWeight: "bolder",
                            fontSize: 15,
                            color: "#fff",
                          }}
                        >
                          Gross Amount
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "bolder",
                            fontSize: 15,
                            color: "#fff",
                            textAlign: "right",
                          }}
                        >
                          {formatNumber(grossAmount)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell style={{ fontWeight: "bold" }}>
                          Less : {withHoldingTaxPercent}% With Holding Tax
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold", textAlign: "right" }}
                        >
                          {formatNumber(with_holding_tax)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow style={{ backgroundColor: "#000" }}>
                        <StyledTableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "#fff",
                          }}
                        >
                          Net Amount Due
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "#fff",
                            textAlign: "right",
                          }}
                        >
                          {formatNumber(netAmountDue)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ fontWeight: "bold" }}
                        ></StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <div
                  style={{
                    height: 1,
                    width: "100%",
                    background: "#95a5a6",
                    marginTop: 15,
                  }}
                />
                <Grid container justify="flex-end" item xs={12} md={12}>
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#2c6164",
                      color: "#fff",
                      width: width < 600 ? "100%" : undefined,
                      marginTop: width < 600 ? undefined : 17,
                    }}
                    variant="contained"
                  >
                    {actionType}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
    </form>
  );
}
export default AddBillingForm;
