// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem

} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import EventNoteIcon from '@material-ui/icons/EventNote';
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
let width = window.innerWidth;

const IndexTransaction = React.memo((props)=> {
    const dispatch = useDispatch()
    const {setState,state,getDateTransaction} = props
    const homeReducer = useSelector(state => state.HomeReducer)
    const rowsPerPage = useSelector(state => state.RequestLogsDetails.rowsPerPage)
    const onChangeCompany = (e) => {
        let company_id = e.target.value
        const branches = homeReducer.branches.filter((val) => (val.company_id == company_id))
     
        setState({
            ...state,
            company_id: company_id,
            branchList: branches,
            refreshFilterBranch:!state.refreshFilterBranch,
            refreshFilter: !state.refreshFilter,
            branch_id:'All'
        })
    }
    const onChangeBranch = (e) => {
        let branch_id = e.target.value
        setState(prev => ({ ...prev, 
            branch_id: branch_id,
            refreshFilterBranch:!state.refreshFilterBranch,
            refreshFilter: !state.refreshFilter,
        }))
    }
    const onSubmitBranchFilter = () => {
        getDateTransaction(0,rowsPerPage)
        // getRequest(0, rowsPerPage, state.status, true, state.searchDriver)
    }
    console.log('Filter Branch')
    return (
        <Grid container spacing={1}>
            <Grid container justify='flex-start' item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold',fontSize:12  }} variant="p">Company</Typography>
                <FormControl variant={'outlined'} style={{ width: '100%' ,background:'#fff' }} size='small'>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.company_id}
                        // onChange={handleChange}
                        onChange={onChangeCompany}
                        style={{ textAlign: 'left' }}
                    >
                        <MenuItem value={"All"}>{'All'}</MenuItem>
                        {homeReducer.companies.map((val, index) => {
                            return <MenuItem key={index} value={val.company_id}>{val.company_name}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid container justify='flex-start' item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold',fontSize:12 }} variant="p">Branch</Typography>
                <FormControl variant={'outlined'} style={{ width: '100%',background:'#fff' }} size='small'>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.branch_id}
                        style={{ textAlign: 'left' }}
                        // onChange={handleChange}
                        onChange={onChangeBranch}
                    >
                        <MenuItem value={"All"}>{'All'}</MenuItem>
                        {state.branchList.map((val, index) => {
                            return <MenuItem key={index} value={val.branch_id}>{val.branch_name}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={1}>
                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button onClick={() => {
                        onSubmitBranchFilter()
                    }} type='buttom' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined, marginTop: width < 600 ? undefined : 17 }} variant="contained" >Submit</Button>
                </div>

            </Grid>
            <Grid item xs={12} md={5}>
            </Grid>
        </Grid>
    );
}, (prevProps, nextProps) => {
    if (prevProps.refreshFilterBranch !== nextProps.refreshFilterBranch) {
        return false
    }
    return true
})
export default IndexTransaction;
