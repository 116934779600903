// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  TextField,
  Button,
  CardContent,
  Card,
  TextareaAutosize,
  IconButton,
  Tooltip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import React from "react";
import TableRequest from "./tableRequest";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import { DropzoneDialog } from "material-ui-dropzone";
// import { getData } from "../../../src/components/api/api"
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MuiAlert from "@material-ui/lab/Alert";
import PositionedSnackbar from "../../alert";
import FilesDIsplay from "../requestList/filesDIsplay";
import Lightbox from "react-awesome-lightbox";
import PdfPic from "../../../assets/pdf.png";
import { getData } from "../../api/api";
import ChatIcon from "@material-ui/icons/Chat";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsSystemDaydreamIcon from "@material-ui/icons/SettingsSystemDaydream";
import CustomizedTimeline from "../../timeline/indexTimeline";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
let width = window.innerWidth;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}

const RowInputs = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRow: state.detailsRow.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell style={{ width: "20%", whiteSpace: "nowrap" }}>
          {moment(state.detailsRow[index].date).format("YYYY-MM-DD")}
        </StyledTableCell>
        <StyledTableCell
          style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}
        >
          {state.detailsRow[index].description}
        </StyledTableCell>
        <StyledTableCell
          style={{ width: "20%", textAlign: "center", fontWeight: "bold" }}
        >
          {formatNumber(parseFloat(state.detailsRow[index].total).toFixed(2))}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
const RowInputsCV = ({ state, state_val, setState, index }) => {
  const onChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setState((prev) => ({
      ...prev,
      detailsRowAPV: state.detailsRowAPV.map((val, index_req) =>
        index === index_req
          ? {
              ...val,
              [name]: value,
            }
          : val
      ),
    }));
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  return (
    <>
      <StyledTableRow>
        <StyledTableCell readOnly style={{ width: "20%" }}>
          {state.detailsRow[index].description}
        </StyledTableCell>
        <StyledTableCell style={{ whiteSpace: "pre-line", textAlign: "right" }}>
          {formatNumber(state.detailsRow[index].total)}
        </StyledTableCell>
        {/* <StyledTableCell style={{ width: '20%', textAlign: 'center' }}>{state.detailsRowAPV[index].credit}</StyledTableCell> */}
      </StyledTableRow>
    </>
  );
};
function RequestDetails({
  handleCloseModal,
  refreshFunc,
  action,
  selectedRequest,
  onSubmitApproval,
  addComment,
  onRefresh,
  cheque_details,
}) {
  const [state, setState] = React.useState({
    detailsRow: [
      {
        date: moment(new Date()).format("YYYY-MM-DD"),
        description: "",
        total: "",
      },
    ],
    payee: "",
    company_name: "",
    open_modal_attachment: false,
    file: [],
    payeeList: [],
    company_id: "",
    branchList: [],
    branch_name: "",
    branch_id: "",
    department: "",
    alert: false,
    alertMessage: "",
    alertColor: "",
    attachments: [],
    attachments_cv: [],
    check_number: "",
    bank_account: "",
    company_logo: "",
    openFile: false,
    imageDisplay: [],
    pdfDisplay: [],
    openFilePDF: false,
    indexPdf: 0,
    comment_modal: false,
    imageDisplayCV: [],
    pdfDisplayCV: [],
    imageDisplay2: [],
    multiple_data: [],
    comment_display_modal: false,
    comment_display_cv_modal: false,
  });
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const homeReducer = useSelector((state) => state.HomeReducer);
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "Loading",
      data: true,
    });
    let data = {
      payee: state.payee,
      company_name: state.company_name,
      detailsRow: state.detailsRow,
      branch_name: state.branch_name,
      branch_id: state.branch_id,
      company_id: state.company_id,
      type: "Cash/Check Requisition Form",
      type_name: "CRF",
      user_id: localStorage.getItem("u"),
      department: state.department,
    };

    if (state.file.length === 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Please attach files !",
        alertColor: "#f39c12",
      }));
    } else {
      uploadForm(data);
    }
  };
  const uploadForm = (data) => {
    const formData = new FormData();
    for (let i = 0; i < state.file.length; i++) {
      formData.append("file" + i, state.file[i]);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(data));
    axios
      .post(
        "https://api.workflow.gzonetechph.com/aam/createRequest",
        formData,
        config
      )
      .then((response) => {
        // if(response.data.response){
        setState((prev) => ({
          ...prev,
          detailsRow: [
            {
              date: moment(new Date()).format("YYYY-MM-DD"),
              description: "",
              total: "",
            },
          ],
          payee: "",
          company_name: "",
          open_modal_attachment: false,
          alert: true,
          alertMessage: "Success, Your request has been created.",
          alertColor: "#2ecc71",
          file: [],
          company_id: "",
          branchList: [],
          branch_name: "",
          branch_id: "",
          department: "",
        }));
        // handleCloseModal()
        // }
        refreshFunc();
        dispatch({
          type: "Loading",
          data: false,
        });
      });
  };

  const onChangeText = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeFile = (files) => {
    setState({
      ...state,
      file: files,
    });
  };

  const handleClose_modal = () => {
    setState((prev) => ({ ...prev, open_modal_attachment: false }));
  };

  React.useEffect(() => {
    dispatch({
      type: "Loading",
      data: false,
    });
    let branches = [];
    homeReducer.branches.forEach((val) => {
      branches.push(val.branch_id);
    });
    let company_logo_match = homeReducer.branches.filter(
      (val) => val.branch_id === selectedRequest.req_branch_id
    );
    let company_logo = "";
    if (company_logo_match.length > 0) {
      let split = String(company_logo_match[0].company_logo).split("/");
      company_logo = split[split.length - 1];
    }
    // if (action === "Details") {
    let new_attachment = [];
    cheque_details.forEach((val) => {
      if (val.attachment !== null) {
        let attachment = JSON.parse(val.attachment);
        new_attachment.push(attachment[0]);
      }
    });
    let req_details = JSON.parse(selectedRequest.req_details);
    let company_id = selectedRequest.req_company_id;
    let branch_id = selectedRequest.req_branch_id;
    let company_name = selectedRequest.company_name;
    let branch_name = selectedRequest.branch_name;
    let department = selectedRequest.department;
    let payee = selectedRequest.req_payee;
    let attachments = JSON.parse(selectedRequest.req_attachments);
    let attachments_cv = new_attachment;

    // let branches = homeReducer.branches.filter((val) => (val.company_name == company_name))
    let check_number = selectedRequest.req_check_number;
    let bank_number = selectedRequest.req_bank_number;
    let imageDisplay = [];
    let pdfDisplay = [];
    let imageDisplay2 = [];

    for (let index = 0; index < attachments.length; index++) {
      const element = attachments[index];
      let data_details = {
        url:
          "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
          element,
        title: element,
      };
      let ext = String(element).split(".");
      let ext2 = ext[ext.length - 1];
      if (ext2 == "pdf") {
        pdfDisplay.push(data_details);
      } else {
        imageDisplay.push(data_details);
        imageDisplay2.push(element);
      }
    }

    let req_bank_details_all = [
      {
        bank_accounts: "",
        check_number: "",
        bank_name: "",
        file: [],
        amount: "",
      },
    ];

    if (
      (selectedRequest.req_check_number != null &&
        selectedRequest.req_check_number != "") ||
      selectedRequest.req_bank_details_all != null
    ) {
      // req_details_particular = JSON.parse(selectedRequest.req_details_particular)
      req_bank_details_all = [
        {
          bank_accounts: selectedRequest.req_bank_id,
          check_number: selectedRequest.req_check_number,
          bank_name: selectedRequest.req_bank_number,
          file: [],
          amount: selectedRequest.req_quantity,
        },
      ];
    }

    if (selectedRequest.req_bank_details_all !== null) {
      req_bank_details_all = JSON.parse(selectedRequest.req_bank_details_all);
    }
    // let attachments_cv2 = []
    // for (let index = 0; index < attachments_cv.length; index++) {
    //     const element = attachments_cv[index];
    //     let data_details = {
    //         url: 'https://images.workflow.gzonetechph.com/pockethr/attachments/' + element,
    //         title: element
    //     }
    //     let ext = String(element).split('.')
    //     let ext2 = ext[ext.length - 1]
    //     if (ext2 == 'pdf') {
    //         // pdfDisplay.push(data_details)
    //     } else {
    //         attachments_cv2.push(data_details)

    //     }
    // }

    setState((prev) => ({
      ...prev,
      imageDisplay: imageDisplay,
      imageDisplay2: imageDisplay2,
      pdfDisplay: pdfDisplay,
      detailsRow: req_details,
      company_id: company_id,
      branch_id: branch_id,
      company_name: company_name,
      branch_name: branch_name,
      department: department,
      branchList: branches,
      payee: payee,
      attachments: attachments,
      attachments_cv: attachments_cv,
      check_number: check_number,
      bank_account: bank_number,
      company_logo: company_logo,
      multiple_data: req_bank_details_all,
    }));
    // }

    // getData('aam/getPayee', branches).then((res) => {
    //     setState(prev => ({ ...prev, payeeList: res,detailsRow:selectedRequest }))
    // })
  }, []);
  const onChangeCompany = (e) => {
    let company_name = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_name == company_name
    );
    let company_id = "";
    if (branches.length > 0) {
      company_id = branches[0].company_id;
    }
    setState({
      ...state,
      company_name: company_name,
      rule: [],
      emp_type: "",
      payroll_rule_group_id: "",
      branchList: branches,
      company_id: company_id,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.branch_id == branch_id
    );
    if (branches.length > 0) {
      setState((prev) => ({
        ...prev,
        branch_id: branch_id,
        branch_name: branches[0].branch_name,
      }));
    }
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const onClickRequest = (attachments) => {
    let imageDisplay = [];
    for (let index = 0; index < attachments.length; index++) {
      const element = attachments[index];
      let data_details = {
        url:
          "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
          element,
        title: element,
      };
      let ext = String(element).split(".");
      let ext2 = ext[ext.length - 1];
      if (ext2 == "pdf") {
        // pdfDisplay.push(data_details)
      } else {
        imageDisplay.push(data_details);
      }
    }
    setState((prev) => ({
      ...prev,
      openFile: true,
      imageDisplay: imageDisplay,
    }));
  };
  const onClickRequestCV = (attachments) => {
    let imageDisplay = [];
    for (let index = 0; index < attachments.length; index++) {
      const element = attachments[index];
      let data_details = {
        url:
          "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
          element,
        title: element,
      };
      let ext = String(element).split(".");
      let ext2 = ext[ext.length - 1];
      if (ext2 == "pdf") {
        // pdfDisplay.push(data_details)
      } else {
        imageDisplay.push(data_details);
      }
    }
    setState((prev) => ({
      ...prev,
      openFile: true,
      imageDisplayCV: imageDisplay,
    }));
  };
  const AddComment = (e) => {
    e.preventDefault();
    let request_comments = {
      app_id: "7",
      user_id: homeReducer.loginData.user_id,
      comment_type: "Text",
      comment: state.comment_value,
      req_id: selectedRequest.last_req_id,
      req_reference_no: selectedRequest.req_reference_no,
      req_user_id: selectedRequest.user_id,
      req_type: "Request for Payment",
    };

    let select_req = selectedRequest;
    let actionType = state.actionStatus;

    if (actionType === "Start Over") {
      actionType = "Return";
    }

    let request = {
      req_id: select_req.last_req_id,
      req_approval_id: select_req.req_approval_id,
      status: actionType,
      req_hierarchy_level: select_req.req_hierarchy_level,
      approver: "",
      comment: state.comment_value,
      app_id: "7",
      company_id: homeReducer.loginData.company_id,
      branch_id: homeReducer.loginData.branch_id,
      approver_id: homeReducer.loginData.user_id,
      cv_req_id: select_req.req_id,
      req_user_id: selectedRequest.user_id,
      multiple_data: state.multiple_data,
    };
    if (window.confirm("Are you sure you want to proceed this action?")) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      Promise.all([
        getData("HumanResource/LogRequestComment", request_comments),
        state.actionStatus === "Start Over"
          ? getData("aam/startOverRequestV2", request)
          : state.actionStatus === "Pending"
          ? undefined
          : getData("HumanResource/changeRequestStatus", request),
      ]).then((res) => {
        if (String(res[0]).includes("Success")) {
          if (actionType == "Return") {
            actionType = "Hold";
          }
          selectedRequest.req_approval_status = actionType;
          selectedRequest.status = state.actionStatus;
          selectedRequest.comments.push({
            user_lname: selectedRequest.user_lname,
            user_fname: selectedRequest.user_fname,
            comment: state.comment_value,
          });
          onRefresh();
          setState((prev) => ({
            ...prev,
            comment_modal: false,
            comment_value: "",
            refreshReqList: !state.refreshReqList,
          }));
          // homeReducer.socket.emit("sendNotification", {
          //     senderName: homeReducer.loginData.user_id,
          //     receiverName: state.selectedRequest.user_id,
          //     type: ''
          // })
        }
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
        handleCloseModal();
      });
    }
  };

  return (
    <div style={{ marginTop: 20, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        {/* <Grid item xs={12} md={1}></Grid> */}
        {/* <Grid item xs={12} md={1}></Grid> */}
        <Grid item xs={12} md={12}>
          <CustomizedTimeline req_id={selectedRequest.last_req_id} />
        </Grid>
        {/* <Grid item xs={12} md={1}></Grid> */}
        <Grid item xs={12} md={6}>
          <form onSubmit={onSubmit}>
            <Card elevation={4} style={{ background: "#fff" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} style={{ position: "relative" }}>
                    <img
                      style={{ width: 130, height: 130 }}
                      src={
                        "https://images.workflow.gzonetechph.com/company_logo/" +
                        state.company_logo
                      }
                    />
                    <div>
                      <div
                        style={{
                          position: "absolute",
                          top: 2,
                          right: 2,
                          color: "#f1c40f",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              comment_display_modal: true,
                            }));
                          }}
                          style={{ color: "#f1c40f" }}
                        >
                          <ChatIcon style={{ color: "#f1c40f" }} />
                        </IconButton>
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20 / 2,
                            backgroundColor: "#e74c3c",
                            position: "absolute",
                            left: 1,
                            bottom: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              fontSize: 10.5,
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                          >
                            {selectedRequest?.comments_rfp.length}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      Request For Payment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Requestor :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {selectedRequest.requestor_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          {" "}
                          Company :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.company_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Branch :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.branch_name}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Department :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {state.department}
                          </span>
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Typography variant="p">
                          Recurring :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {selectedRequest.recurring}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p">
                      Payee :{" "}
                      <span style={{ fontWeight: "bold" }}>{state.payee}</span>
                    </Typography>
                  </Grid>
                  {state.payee == "G-XCHANGE INC." ? (
                    <Grid item xs={12}>
                      <Typography variant="p">
                        Recipient :{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {selectedRequest?.req_recipient}
                        </span>
                      </Typography>
                    </Grid>
                  ) : undefined}

                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "20%" }}
                              >
                                Date
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Description
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.detailsRow.map((val, index) => {
                              return (
                                <RowInputs
                                  state={state}
                                  state_val={val}
                                  setState={setState}
                                  index={index}
                                />
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12} md={12}>
                    <Typography
                      variant={"p"}
                      style={{ fontWeight: "bold", fontSize: 20 }}
                    >
                      Total :{" "}
                      {formatNumber(
                        state.detailsRow.reduce((count, val) => {
                          let total = val.total;
                          if (val.total === "") {
                            total = 0;
                          }
                          return (count += parseFloat(total));
                        }, 0)
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.4,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Attachment(s)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.imageDisplay2.map((val, index) => {
                      if (index == 0)
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="p" style={{ fontSize: 15 }}>
                              {val}
                            </Typography>
                            <div
                              style={{
                                position: "relative",
                                width: 100,
                                height: 100,
                              }}
                            >
                              {index == 0 ? (
                                <div
                                  onClick={() => {
                                    onClickRequest(state.attachments);

                                    // window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                    background: "#00000047",
                                    position: "absolute",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{ color: "#ffff" }}
                                  >
                                    +{state.imageDisplay2.length - 1}
                                  </Typography>
                                </div>
                              ) : undefined}
                              <img
                                src={
                                  "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                                  val
                                }
                                style={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        );
                    })}
                    {state.pdfDisplay.map((val, index) => {
                      if (index == 0)
                        return (
                          <div
                            key={index}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="p" style={{ fontSize: 15 }}>
                              {val.title}
                            </Typography>
                            <div
                              style={{
                                position: "relative",
                                width: 100,
                                height: 100,
                              }}
                            >
                              {index == 0 ? (
                                <div
                                  onClick={() => {
                                    setState((prev) => ({
                                      ...prev,
                                      openFilePDF: true,
                                      indexPdf: 0,
                                    }));
                                    // window.open('https://images.workflow.gzonetechph.com/pockethr/attachments/' + val)
                                  }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 100,
                                    height: 100,
                                    cursor: "pointer",
                                    background: "#00000047",
                                    position: "absolute",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{ color: "#ffff" }}
                                  >
                                    +{state.pdfDisplay.length - 1}
                                  </Typography>
                                </div>
                              ) : undefined}

                              <img
                                src={PdfPic}
                                style={{
                                  width: 100,
                                  height: 100,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        );
                    })}

                    {/* <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>     */}
                  </Grid>
                  <Grid container justify="flex-end" item xs={12} md={12}>
                    {selectedRequest.req_approval_status === "Pending" ? (
                      <Button
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            comment_modal: true,
                          }));
                          //    onSubmitApproval('Denied')
                        }}
                        style={{
                          backgroundColor: "#e74c3c",
                          color: "#fff",
                          width: width < 600 ? "100%" : undefined,
                          marginRight: 10,
                        }}
                        variant="contained"
                      >
                        Add Comment
                      </Button>
                    ) : undefined}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{}} />
          <form onSubmit={onSubmit}>
            <Card elevation={4} style={{ background: "#fff" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} style={{ position: "relative" }}>
                    <img
                      style={{ width: 130, height: 130 }}
                      src={
                        "https://images.workflow.gzonetechph.com/company_logo/" +
                        state.company_logo
                      }
                    />
                    <div>
                      <div
                        style={{
                          position: "absolute",
                          top: 2,
                          right: 2,
                          color: "#f1c40f",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              comment_display_cv_modal: true,
                            }))
                          }
                          style={{ color: "#f1c40f" }}
                        >
                          <ChatIcon style={{ color: "#f1c40f" }} />
                        </IconButton>
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20 / 2,
                            backgroundColor: "#e74c3c",
                            position: "absolute",
                            left: 1,
                            bottom: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="p"
                            style={{
                              fontSize: 10.5,
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                          >
                            {selectedRequest?.comments.length}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      Check Voucher
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="p">
                        Payee :{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {state.payee}
                        </span>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="p">
                            CV No. :{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {selectedRequest.req_reference_no}
                            </span>
                          </Typography>
                          <Typography variant="p">
                            Recurring :{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {selectedRequest.recurring}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {state.payee == "G-XCHANGE INC." ? (
                    <Grid item xs={12}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="p">
                          Recipient :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {selectedRequest?.req_recipient}
                          </span>
                        </Typography>
                      </div>
                    </Grid>
                  ) : undefined}
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ padding: 10 }}>
                      <div style={{ padding: 10 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <Typography variant="p">
                                  Payment Method :{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    Check
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography variant="p">
                                  Payment Date :{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {moment().format("YYYY-MM-DD")}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Typography variant="p">
                                  Description :{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {selectedRequest.req_justification}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          {/* {state.multiple_data.map((val, index) => {
                                                        return <Grid item xs={12} md={6}> <Card variant='outlined'>
                                                            <CardContent>
                                                                <Grid key={index} container spacing={1}>
                                                                    <Grid item xs={12} md={12}>
                                                                        <Typography variant='p'>Bank Account : <span style={{ fontWeight: 'bold' }}>{val.bank_name}</span></Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={12}>
                                                                        <Typography variant='p'>Check Number :  <span style={{ fontWeight: 'bold' }}>{val.check_number}</span></Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={12}>
                                                                        <Typography variant='p'>Amount :  <span style={{ fontWeight: 'bold' }}>{val.amount}</span></Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                        </Grid>
                                                    })

                                                    } */}
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "70%" }}
                              >
                                Bank Name
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Cheque No.
                              </TableCell>
                              <TableCell
                                style={{
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cheque_details.map((val, index) => {
                              let backgroundColor = undefined;
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell
                                    style={{
                                      width: "20%",
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                      background: backgroundColor,
                                    }}
                                  >
                                    {val.bank_name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      width: "20%",
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                      background: backgroundColor,
                                    }}
                                  >
                                    {val.check_number}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{
                                      width: "20%",
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                      background: backgroundColor,
                                    }}
                                  >
                                    {val.amount}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginTop: -20 }}>
                    <Card variant="outlined" style={{ background: "#fafafc" }}>
                      <TableContainer>
                        <Table
                          border={1}
                          style={{
                            borderCollapse: "collapse",
                            borderColor: "#bdc3c7",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{ fontWeight: "bold", width: "70%" }}
                              >
                                Document Description
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Amount Paid
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {state.detailsRow.map((val, index) => {
                              return (
                                <RowInputsCV
                                  state={state}
                                  state_val={val}
                                  setState={setState}
                                  index={index}
                                />
                              );
                            })}
                            <StyledTableRow>
                              <StyledTableCell
                                style={{
                                  width: "20%",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                Total Payment
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  whiteSpace: "pre-line",
                                  wordWrap: "break-word",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                {formatNumber(
                                  state.detailsRow.reduce((count, val) => {
                                    let total = val.total;
                                    if (val.total === "") {
                                      total = 0;
                                    }
                                    return (count += parseFloat(total));
                                  }, 0)
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p" style={{ fontWeight: "bold" }}>
                      Attachment(s)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {state.attachments_cv.map((val, index) => {
                      return (
                        <div
                          key={index}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="p" style={{ fontSize: 15 }}>
                            {val}
                          </Typography>
                          <img
                            onClick={() => {
                              onClickRequest(state.attachments_cv);
                            }}
                            src={
                              "https://images.workflow.gzonetechph.com/pockethr/attachments/" +
                              val
                            }
                            style={{
                              width: 100,
                              height: 100,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      );
                    })}
                    {/* <img src={'https://images.workflow.gzonetechph.com/documents_wis/'+state.selectedReq.cert_file} style={{width:'50%',height:'50%'}}/>     */}
                  </Grid>
                  <Grid container item xs={12} md={12} justify="flex-end">
                    <Typography variant="p">
                      Received by : ________________________________
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#2c7f84",
                        height: 1.5,
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* <Typography variant="p" style={{ color: '#7f8c8d', fontWeight: 'bold', fontSize: 21, marginBottom: 10 }}>{'Request Hierarchy'}</Typography> */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: "#fff",
                              }}
                            >
                              {"Pending"}
                            </Typography>

                            <Typography
                              variant="p"
                              style={{ fontWeight: "bold" }}
                            >
                              {String(
                                selectedRequest.fullname
                              ).toLocaleUpperCase()}
                            </Typography>
                            <Typography
                              variant="p"
                              style={{ fontSize: 11, fontWeight: "bold" }}
                            >
                              PREPARED BY
                            </Typography>
                          </div>
                        </Grid>
                        {JSON.parse(
                          selectedRequest.req_hierarchy_structure
                        ).map((ary2, index2) => {
                          var colorss = "#2ecc71";
                          let valStatus = "Pending";
                          if (
                            parseInt(index2) <
                            parseInt(selectedRequest.req_hierarchy_level)
                          ) {
                            if (
                              selectedRequest.status === "Denied" &&
                              index2 === selectedRequest.req_hierarchy_level - 1
                            ) {
                              colorss = "#e74c3c";
                              valStatus = "Denied";
                            } else {
                              colorss = "#2ecc71";
                              valStatus = "Approved";
                            }
                          } else {
                            if (selectedRequest.status === "Cancelled") {
                              valStatus = "Cancelled";
                              colorss = "#e74c3c";
                            } else if (selectedRequest.status === "Denied") {
                              valStatus = "Denied";
                              colorss = "#e74c3c";
                            } else {
                              colorss = "#faab2d";
                            }
                          }
                          let name = "";
                          ary2.approver.forEach((approver) => {
                            if (name != "") {
                              name = " / ";
                            }
                            name += approver.name;
                          });
                          return (
                            <Grid item xs={12} sm={6} md={4}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="p"
                                  style={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: colorss,
                                  }}
                                >
                                  {valStatus}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {String(name).toLocaleUpperCase()}
                                </Typography>
                                <Typography
                                  variant="p"
                                  style={{ fontSize: 11, fontWeight: "bold" }}
                                >
                                  {ary2.title}
                                </Typography>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  </Grid>
                  <Grid container justify="flex-end" item xs={12} md={12}>
                    {selectedRequest.req_approval_status === "Pending" ? (
                      <>
                        <Button
                          onClick={() => {
                            addComment();
                          }}
                          style={{
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            width: width < 600 ? "100%" : undefined,
                            marginRight: 10,
                          }}
                          variant="contained"
                        >
                          Add Comment
                        </Button>

                        <Button
                          onClick={(e) => {
                            onSubmitApproval(e, "Approved");
                          }}
                          style={{
                            backgroundColor: "#2c6164",
                            color: "#fff",
                            width: width < 600 ? "100%" : undefined,
                          }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                      </>
                    ) : undefined}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} md={1}></Grid>

        {/* <Grid item xs={12} md={1}>

                </Grid> */}
      </Grid>

      <Dialog
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFile: false,
          }));
        }}
        open={state.openFile}
      >
        {/* <DialogContent> */}
        {/* <FilesDIsplay />
         */}
        <Lightbox
          images={state.imageDisplay}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              openFile: false,
            }));
          }}
        />
        {/* {state.imageDisplay.map((val,index)=>{
                    
                    if(index == 0 )
                    <iframe src={val.url} title="W3Schools Free Online Web Tutorials"></iframe>

                })

                } */}

        {/* </DialogContent> */}
      </Dialog>
      <Dialog
        fullScreen
        aria-labelledby="responsive-dialog-title"
        onClose={() => {
          setState((prev) => ({
            ...prev,
            openFilePDF: false,
          }));
        }}
        open={state.openFilePDF}
      >
        <FilesDIsplay
          state={state}
          onClosePDF={() => {
            setState((prev) => ({
              ...prev,
              openFilePDF: false,
            }));
          }}
          setState={setState}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.comment_modal}
        onClose={() => {
          setState((prev) => ({ ...prev, comment_modal: false }));
        }}
      >
        <DialogTitle>
          Request for Payment <Typography> Add Comment</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={AddComment}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  style={{ width: "100%" }}
                  multiline
                  rows={6}
                  onChange={onChangeText}
                  name="comment_value"
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#2c3e50",
                    color: "#fff",
                    marginRight: width < 600 ? undefined : 10,
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      actionStatus: "Pending",
                    }))
                  }
                >
                  Comment Only
                </Button>
                {selectedRequest.req_check_number !== null ? (
                  String(selectedRequest.req_check_number).split(",").length >
                    1 ||
                  selectedRequest.req_justification !== null ? undefined : (
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#f39c12",
                        color: "#fff",
                        marginRight: width < 600 ? undefined : 10,
                        width: width < 600 ? "100%" : undefined,
                      }}
                      variant="contained"
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          actionStatus: "Start Over",
                        }))
                      }
                    >
                      Start Over
                    </Button>
                  )
                ) : (
                  <Button
                    type="submit"
                    style={{
                      backgroundColor: "#f39c12",
                      color: "#fff",
                      marginRight: width < 600 ? undefined : 10,
                      width: width < 600 ? "100%" : undefined,
                    }}
                    variant="contained"
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        actionStatus: "Start Over",
                      }))
                    }
                  >
                    Start Over
                  </Button>
                )}

                {/* <Button onClick={() => setState(prev => ({
                                    ...prev, actionStatus: 'Denied'
                                }))} type='submit' style={{ backgroundColor: '#e74c3c', color: '#fff', marginRight: width < 600 ? undefined : 10, width: width < 600 ? '100%' : undefined }} variant="contained" >Denied</Button>
                                <Button onClick={() => setState(prev => ({
                                    ...prev, actionStatus: 'Approved'
                                }))} type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >Approve</Button> */}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.comment_display_modal}
        onClose={() => {
          setState((prev) => ({ ...prev, comment_display_modal: false }));
        }}
      >
        <div style={{ position: "relative" }}>
          <DialogTitle>
            Request for Payment <Typography> Comments</Typography>
          </DialogTitle>
          <IconButton
            onClick={() =>
              setState((prev) => ({ ...prev, comment_display_modal: false }))
            }
            style={{ position: "absolute", right: 3, top: 3 }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Card variant="outlined" style={{ background: "#fff" }}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid container justify="flex-start" item xs={12} md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ChatIcon />
                          <Typography variant="h6" style={{ color: "#2c7f84" }}>
                            Comments and Logs
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div style={{ height: 350, overflowY: "auto" }}>
                          {/* <Grid container spacing={1} style={{ height: 500, overflowY: 'auto' }}> */}
                          {selectedRequest?.comments_rfp.map((val, index) => {
                            let c_type = val.comment_type;
                            if (c_type === "Text") {
                              c_type = "Comment";
                            }
                            return (
                              <div style={{ marginBottom: 10 }}>
                                <div
                                  style={{
                                    paddingRight: 25,
                                    paddingLeft: 25,
                                    borderWidth: 1,
                                    borderColor: "#c5bfbf",
                                    background: "#f7f9fa",
                                    padding: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2f3640",
                                    }}
                                  >
                                    {c_type}
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {val.comment_type === "System" ? (
                                      <SettingsIcon
                                        style={{ color: "#2c3e50" }}
                                      />
                                    ) : (
                                      <AccountCircleIcon
                                        style={{ color: "#3498db" }}
                                      />
                                    )}

                                    <Typography
                                      variant="p"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        color: "#2980b9",
                                      }}
                                    >
                                      {`${val.user_lname} ${val.user_fname}`}
                                      <span
                                        style={{
                                          color: "#000",
                                          marginLeft: 10,
                                        }}
                                      >
                                        {val.comment_date_added}
                                      </span>
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                    }}
                                  >
                                    <Typography variant="p">{`${val.comment}`}</Typography>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={state.comment_display_cv_modal}
        onClose={() => {
          setState((prev) => ({ ...prev, comment_display_cv_modal: false }));
        }}
      >
        <div style={{ position: "relative" }}>
          <DialogTitle>
            Check Voucher <Typography> Comments</Typography>
          </DialogTitle>
          <IconButton
            onClick={() =>
              setState((prev) => ({ ...prev, comment_display_cv_modal: false }))
            }
            style={{ position: "absolute", right: 3, top: 3 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Card variant="outlined" style={{ background: "#fff" }}>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid container justify="flex-start" item xs={12} md={12}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ChatIcon />
                          <Typography variant="h6" style={{ color: "#2c7f84" }}>
                            Comments and Logs
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <div style={{ height: 350, overflowY: "auto" }}>
                          {/* <Grid container spacing={1} style={{ height: 500, overflowY: 'auto' }}> */}
                          {selectedRequest?.comments.map((val, index) => {
                            let c_type = val.comment_type;
                            if (c_type === "Text") {
                              c_type = "Comment";
                            }
                            return (
                              <div style={{ marginBottom: 10 }}>
                                <div
                                  style={{
                                    paddingRight: 25,
                                    paddingLeft: 25,
                                    borderWidth: 1,
                                    borderColor: "#c5bfbf",
                                    background: "#f7f9fa",
                                    padding: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    style={{
                                      fontWeight: "bold",
                                      color: "#2f3640",
                                    }}
                                  >
                                    {c_type}
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {val.comment_type === "System" ? (
                                      <SettingsIcon
                                        style={{ color: "#2c3e50" }}
                                      />
                                    ) : (
                                      <AccountCircleIcon
                                        style={{ color: "#3498db" }}
                                      />
                                    )}

                                    <Typography
                                      variant="p"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        color: "#2980b9",
                                      }}
                                    >
                                      {`${val.user_lname} ${val.user_fname}`}
                                      <span
                                        style={{
                                          color: "#000",
                                          marginLeft: 10,
                                        }}
                                      >
                                        {val.comment_date_added}
                                      </span>
                                    </Typography>
                                  </div>
                                  <div
                                    style={{
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                    }}
                                  >
                                    <Typography variant="p">{`${val.comment}`}</Typography>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default React.memo(RequestDetails, renderPropsAreEqual);
