const initialState = {
    addModal:false,
    client_name:'',
    contact_person:'',
    contact_number:'',
    client_branch_name:'',
    address:'',
    clientList:[],
    tin:'',
    actionType:'Add',
    client_id:'',
    selectedClient:[],
    company_id:'',

    alert:false,
    alertMessage:'',
    alertColor:'',

    tabIndex:0
    
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'onChangeClientReducer':
            return {
                ...state,
                ...action.data,
            }
        default:
            return state;
    }
}
export default dataReducer; 