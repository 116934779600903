// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
// import TableBankAccounts from './tableBankAccounts'
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import TableOfAccounts from './tableOfAccounts'
// import BankForm from "./bankForm";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { getData } from "../../api/api";
import PositionedSnackbar from '../../alert'
import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    HashRouter as Router,
    BrowserRouter,
    Route,
    useParams,
    Redirect,
    Link as NewLink,
    useHistory
} from "react-router-dom";
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}

let width = window.innerWidth;
const useStyles = makeStyles({
    root: {
        // display: 'flex',
        // flex: 1
    },
    container: {
        maxHeight: 440,
    },
    textFieldStyle: {
        width: '100%'
    }
});
function ChartOfAccounts() {
    const history = useHistory()
    const dispatch = useDispatch()
    const classes = useStyles()
    const chartoFAccountsReducer = useSelector((state) => state.ChartoFAccountsReducer);
    const homeReducer = useSelector(state => state.HomeReducer)
    const [state, setState] = React.useState({
        company_id: '',
        name: '',
        group: '',
        type: 'Debit',
        amount: '',
        groupList: [],
        actionType: 'Add',
        buttonType:''
    })
    const onChangeCompany = (e) => {
        let company_id = e.target.value

        setState({
            ...state,
            company_id: company_id
        })
    }
    const onChangeType = (e) => {
        let type = e.target.value

        setState({
            ...state,
            type: type
        })
    }
    const onChangeGroup = (e) => {
        let group = e.target.value

        setState({
            ...state,
            group: group
        })
    }
    const onChangeText = (e) => {
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
  
    const onSubmit = (e) => {
        e.preventDefault();
        let match = state.groupList.filter((val) => (val.val === state.group))
        let category_attribute = ''
        if (match.length > 0) {
            category_attribute = match[0].attribute
        }
        let data = {
            // company_id:chartoFAccountsReducer.selected_company_id,
            app_id: 16,
            parent_id: state.group,
            balance_sheet_group_name:String(state.name).toLocaleUpperCase(),
            user_id : localStorage.getItem('u'),
            data_type : 'Account',
            finance_type:state.type,
            amount:state.amount,
            group_type : chartoFAccountsReducer.selected_type,
            actionButton : state.buttonType

        }
        if(state.buttonType == 'Update'){
            data = {
                balance_sheet_group : chartoFAccountsReducer.selectedValue.balance_sheet_group,
                parent_id: state.group,
                balance_sheet_group_name:String(state.name).toLocaleUpperCase(),
                user_id: localStorage.getItem('u'),
                actionButton : state.buttonType
            }
        }
        else if(state.buttonType == 'Delete'){
            data = {
                balance_sheet_group : chartoFAccountsReducer.selectedValue.balance_sheet_group,
                delete_id: 1,
                user_id: localStorage.getItem('u'),
                actionButton : state.buttonType
            }
        }
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        getData('aam/insert_balance_sheet_group', data).then((res) => {
            if(res.res){
                if(state.buttonType == 'Update'){
                    history.push('/chartOfAccounts')
                }else if(state.buttonType == 'Delete'){
                    history.push('/chartOfAccounts')
                }else{
                    setState(prev => ({
                        ...prev,
                        alert: true,
                        alertMessage: "Success, Balance sheet group has been added .",
                        alertColor: '#2ecc71',
                        name:'',
                        group:'',
                        amount:'',
                    }))
                }
               
            }else{
                setState(prev => ({
                    ...prev,
                    alert: true,
                    alertMessage: "Warning, Something went wrong.",
                    alertColor: '#f39c12'
                }))
            }
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
    }
    React.useEffect(() => {
        if (chartoFAccountsReducer.actionStatus === 'Edit') {
            setState(prev => ({
                ...prev,
                name: chartoFAccountsReducer.selectedValue.balance_sheet_group_name,
                group: chartoFAccountsReducer.selectedValue.parent_id,
                actionType: chartoFAccountsReducer.actionStatus,
                type:chartoFAccountsReducer.selectedValue.finance_type,
                amount:chartoFAccountsReducer.selectedValue.amount
            }))
        }
    }, [])
    let groupList = chartoFAccountsReducer.selected_type === 'Balance Sheet'?chartoFAccountsReducer.groupList:chartoFAccountsReducer.groupList2
    return (
        <div>
 <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="#/chartOfAccounts">
                            Back
                        </Link>
                        <Link color="inherit">
                            Settings
                        </Link>
                        <Link color="inherit" href="#/chartOfAccounts">
                            Chart of Accounts
                        </Link>
                        <Typography color="textPrimary">{chartoFAccountsReducer.selected_type+' '+'Account'}</Typography>
                        {state.actionType === 'Edit' ?
                            <Typography style={{ display: state.actionType !== 'Edit' ? 'none' : undefined }} color="textPrimary">{state.name}</Typography>
                            : undefined
                        }
                        <Typography color="textPrimary">{state.actionType}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={12}>
                    <form onSubmit={onSubmit}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid container justify='flex-start' item xs={12} md={12}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="h6" style={{ color: '#2c7f84' }}>{chartoFAccountsReducer.selected_type+' '+'Account'}</Typography>
                                        </div>

                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={6}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Name</Typography>
                                        <TextField required value={state.name} onChange={onChangeText} name='name' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={6}>
                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={6}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Group</Typography>
                                        <FormControl variant={'outlined'} style={{ width: '100%', textAlign: 'left' }} size='small'>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={state.group}
                                                onChange={onChangeGroup}
                                            >
                                                {groupList.map((val, index) => {
                                                    return <MenuItem key={index} value={val.balance_sheet_group}>{val.balance_sheet_group_name}</MenuItem>
                                                })

                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={6}>

                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Starting Balance</Typography>
                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={2}>
                                        <FormControl variant={'outlined'} style={{ width: '100%', textAlign: 'left' }} size='small'>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={state.type}
                                                // onChange={handleChange}
                                                onChange={onChangeType}
                                            >
                                                {['Credit', 'Debit'].map((val, index) => {
                                                    return <MenuItem key={index} value={val}>{val}</MenuItem>
                                                })

                                                }

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container justify='flex-start' item xs={12} md={2}>
                                        <TextField type='number' onChange={onChangeText} name='amount' value={state.amount} variant='outlined' size='small'></TextField>
                                    </Grid>
                                    <Grid item xs={12} md={8}>

                                    </Grid>
                                    {chartoFAccountsReducer.actionStatus === 'Edit' ?
                                    <>
                                     <Grid container justify='flex-start' item xs={12} md={12}>
                                      <Button disabled ={homeReducer.loginData.user_id === chartoFAccountsReducer.selectedValue.user_id?false:true} onClick={()=>{
                                          setState(prev=>({
                                              ...prev,
                                              buttonType : 'Update'
                                          }))
                                      }} type='submit' style={{ marginTop: 20, backgroundColor: homeReducer.loginData.user_id === chartoFAccountsReducer.selectedValue.user_id?'#2c6164':'#7f8c8d',marginRight: width < 600 ?undefined:10, color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<UpdateIcon />}>Update</Button>
                                      <Button disabled ={homeReducer.loginData.user_id === chartoFAccountsReducer.selectedValue.user_id?false:true}  onClick={()=>{
                                          setState(prev=>({
                                              ...prev,
                                              buttonType : 'Delete'
                                          }))
                                      }} type='submit' style={{ marginTop: 20, backgroundColor: homeReducer.loginData.user_id === chartoFAccountsReducer.selectedValue.user_id?'#e74c3c':'#7f8c8d', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<DeleteIcon />}>Delete</Button>
                                  </Grid>
                                 
                                    </>
                                        :
                                        <Grid container justify='flex-start' item xs={12} md={12}>
                                            <Button type='submit' style={{ marginTop: 20, backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" startIcon={<AddIcon />}>Create</Button>
                                        </Grid>
                                    }
                                </Grid>

                                {/* <TableOfAccounts /> */}
                            </CardContent>
                        </Card>
                    </form>

                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(ChartOfAccounts, renderPropsAreEqual);
