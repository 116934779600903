import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import {
  TableRow,
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Tooltip,
  CardContent,
  Card,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Icon,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import BankAccounts from "./bankForm";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "../../../src/components/api/api";
import CallMadeIcon from "@material-ui/icons/CallMade";
import EditIcon from "@material-ui/icons/Edit";
// import ReactExport from "react-data-export";
import {
  HashRouter as Router,
  BrowserRouter,
  Route,
  useParams,
  Redirect,
  Link as NewLink,
  useHistory,
} from "react-router-dom";
// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let width = window.innerWidth;
const formatNumber = (num) => {
  if (num != "") {
    var amount = parseFloat(num).toFixed(2);
    var formattedString = amount
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString;
  } else {
    return 0;
  }
};
const columns = [
  {
    id: "company_name",
    label: "Company",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "bank_name",
    label: "Bank Name",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "bank_account_number",
    label: "Account No.",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  // { id: 'bank_balance', label: 'Balance', format: (value) => 'Php' + ' ' + formatNumber(value) },
  // { id: 'name', label: 'Name'},
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});

export default function StickyHeadTable() {
  const history = useHistory();
  const dispatch = useDispatch();
  const homeReducer = useSelector((state) => state.HomeReducer);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [state, setState] = React.useState({
    addBankModal: false,
    actionButton: "Create",
    refresh: false,
    bankList: [],
    bankListDisplay: [],
    editData: [],
    company_id: "All",
    selectedBank: "All",
    uniqueBankList: [],
    actualBalanceOrder: false,
    balanceOrder: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openCloseAddAccountModal = (status, type, details) => {
    setState((prev) => ({
      ...prev,
      addBankModal: status,
      actionButton: type,
      editData: details,
    }));
  };
  React.useEffect(() => {
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: true,
      },
    });
    let comapnies = homeReducer.companies.map((val) => val.company_id);
    let data = {
      companies: comapnies,
    };
    if (comapnies.length === 0) {
      alert("You do not have access in any companies.");
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: false,
        },
      });
    } else {
      getData("aam/getBankAccountDetails", data).then((res) => {
        let unique = res.data.filter(
          (item, i, ar) =>
            ar.findIndex(
              (item2) =>
                String(item2.bank_name).toLocaleUpperCase() ==
                String(item.bank_name).toLocaleUpperCase()
            ) === i
        );
        setState((prev) => ({
          ...prev,
          bankList: res.data,
          uniqueBankList: unique,
          bankListDisplay: res.data,
        }));
        dispatch({
          type: "onChangeHomeReducer",
          data: {
            loading: false,
          },
        });
      });
    }
  }, [state.refresh]);
  const onChangeText = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    if (name === "company_id") {
      let unique = state.bankList.filter(
        (item, i, ar) =>
          ar.findIndex(
            (item2) =>
              String(item2.bank_name).toLocaleUpperCase() ==
                String(item.bank_name).toLocaleUpperCase() &&
              (value === "All" ? i >= 0 : item2.company_id == value)
          ) === i
      );
      setState({
        ...state,
        [name]: value,
        uniqueBankList: unique,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };
  const onSubmitBankFilter = () => {
    console.log(state.selectedBank);
    console.log(state.selectedBank);

    let data = state.bankList.filter(
      (val, index) =>
        (state.company_id == "All"
          ? index >= 0
          : val.company_id == state.company_id) &&
        (state.selectedBank == "All"
          ? index >= 0
          : String(val.bank_name).toLocaleUpperCase() ==
            String(state.selectedBank).toLocaleUpperCase())
    );
    setState((prev) => ({
      ...prev,
      bankListDisplay: data,
    }));
  };
  const onClickSort = () => {
    let sort = state.bankListDisplay.sort(function (a, b) {
      if (!state.actualBalanceOrder) {
        return a["bank_actual_balance"] - b["bank_actual_balance"];
      } else {
        return b["bank_actual_balance"] - a["bank_actual_balance"];
      }
    });
    setState((prev) => ({
      ...prev,
      bankListDisplay: sort,
      actualBalanceOrder: !state.actualBalanceOrder,
    }));
  };
  const onClickSortBalance = () => {
    let sort = state.bankListDisplay.sort(function (a, b) {
      if (!state.balanceOrder) {
        return a["bank_balance"] - b["bank_balance"];
      } else {
        return b["bank_balance"] - a["bank_balance"];
      }
    });
    setState((prev) => ({
      ...prev,
      bankListDisplay: sort,
      balanceOrder: !state.balanceOrder,
    }));
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}></Grid>
        <Grid container justify="flex-start" item xs={12} md={3}>
          <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
            Company
          </Typography>
          <FormControl
            variant={"outlined"}
            style={{ width: "100%", background: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.company_id}
              // onChange={handleChange}
              name="company_id"
              onChange={onChangeText}
              style={{ textAlign: "left" }}
            >
              <MenuItem value={"All"}>{"All"}</MenuItem>
              {homeReducer.companies.map((val, index) => {
                return (
                  <MenuItem key={index} value={val.company_id}>
                    {String(val.company_name).toLocaleUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid container justify="flex-start" item xs={12} md={3}>
          <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
            Bank
          </Typography>
          <FormControl
            variant={"outlined"}
            style={{ width: "100%", background: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state.selectedBank}
              // onChange={handleChange}
              name="selectedBank"
              onChange={onChangeText}
              style={{ textAlign: "left" }}
            >
              <MenuItem value={"All"}>{"All"}</MenuItem>
              {state.uniqueBankList.map((val, index) => {
                return (
                  <MenuItem key={index} value={val.bank_name}>
                    {String(val.bank_name).toLocaleUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                onSubmitBankFilter();
              }}
              type="buttom"
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
                marginTop: width < 600 ? undefined : 17,
              }}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={1}>
                <Grid item container xs={12} md={3}>
                  <Card
                    variant="outlined"
                    style={{
                      width: "100%",
                      padding: 10,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      background: "#3498db",
                      color: "#fff",
                    }}
                  >
                    <Typography>Available Balance</Typography>
                    <Typography style={{ fontSize: 23, fontWeight: "bold" }}>
                      Php&nbsp;
                      {formatNumber(
                        state.bankList.reduce((count, val) => {
                          let value = parseFloat(val.bank_balance).toFixed(2);
                          count += parseFloat(value);
                          return count;
                        }, 0)
                      )}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item container xs={12} md={3}>
                  <Card
                    variant="outlined"
                    style={{
                      width: "100%",
                      padding: 10,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      background: "#1abc9c",
                      color: "#fff",
                    }}
                  >
                    <Typography>Current Balance</Typography>
                    <Typography style={{ fontSize: 23, fontWeight: "bold" }}>
                      Php&nbsp;
                      {formatNumber(
                        state.bankList.reduce((count, val) => {
                          let value = parseFloat(
                            val.bank_actual_balance
                          ).toFixed(2);
                          count += parseFloat(value);
                          return count;
                        }, 0)
                      )}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item container justify="flex-start" xs={12} md={12}>
          <Button
            onClick={() => {
              openCloseAddAccountModal(true, "Create", []);
            }}
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : undefined,
              marginRight: 5,
            }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Account
          </Button>
          <Button
            onClick={() => {
              window.open(
                "https://api.workflow.gzonetechph.com/test/exportBankdetails/" +
                  localStorage.getItem("u")
              );
            }}
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : undefined,
            }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper className={classes.root} variant="outlined">
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#ffff",
                        whiteSpace: "nowrap",
                      }}
                    >
                      #
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#ffff",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Available Balance
                      <Icon
                        onClick={onClickSortBalance}
                        style={{ cursor: "pointer" }}
                      >
                        swap_vert
                      </Icon>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#ffff",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Current Balance
                      <Icon onClick={onClickSort} style={{ cursor: "pointer" }}>
                        swap_vert
                      </Icon>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#ffff",
                        whiteSpace: "nowrap",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.bankListDisplay
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell>{index + 1}</TableCell>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format &&
                                typeof value === "bank_balance" ? (
                                  <span style={{ fontWeight: "bold" }}>
                                    {column.format(value)}
                                  </span>
                                ) : (
                                  column.format(value)
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            {"Php" + " " + formatNumber(row.bank_balance)}
                          </TableCell>
                          <TableCell>
                            {"Php" +
                              " " +
                              formatNumber(row.bank_actual_balance)}
                          </TableCell>
                          <TableCell style={{ fontWeight: "bold" }}>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Tooltip
                                style={{ marginRight: 10 }}
                                onClick={() => {
                                  dispatch({
                                    type: "onChangeBankReducer",
                                    data: {
                                      selectedBank: row,
                                    },
                                  });
                                  history.push("/bankDetails/");
                                }}
                                title="View"
                              >
                                <CallMadeIcon className={classes.iconSize} />
                              </Tooltip>
                              {homeReducer.loginData
                                ?.edit_bank_details_priviledge === "1" ? (
                                <Tooltip
                                  onClick={() => {
                                    openCloseAddAccountModal(true, "Edit", row);
                                  }}
                                  title="Edit"
                                >
                                  <EditIcon className={classes.iconSize} />
                                </Tooltip>
                              ) : undefined}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={state.bankListDisplay.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={state.addBankModal}
        onClose={() => {
          openCloseAddAccountModal(false);
        }}
      >
        <AppBar className={classes.appBar} elevation={1}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                openCloseAddAccountModal(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{state.actionButton}</Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flex: 1,
            background: "#f5f7f7",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <BankAccounts
                refresh={() =>
                  setState((prev) => ({ ...prev, refresh: !state.refresh }))
                }
                type={state.actionButton}
                editData={state.editData}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
