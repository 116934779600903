// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    FormControl,
    Select,
    InputLabel,
    Button,
    MenuItem
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
import TableBankAccounts from './tableBankAccounts'
import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { getData } from "../../../src/components/api/api"
import PositionedSnackbar from '../alert'

let width = window.innerWidth;

function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
const useStyles = makeStyles({
    root: {
        // display: 'flex',
        // flex: 1
    },
    container: {
        maxHeight: 440,
    },
    textFieldStyle: {
        width: '100%'
    }
});

function FormBankAccounts({ refresh, type, editData }) {
    const classes = useStyles()
    const dispatch = useDispatch();

    const homeReducer = useSelector(state => state.HomeReducer)
    const [state, setState] = React.useState({
        company_id: '',
        company_name: '',
        bank_name: '',
        account_number: '',
        initial_balance: '',
        bank_account_id: ''
    })
    const onChangeText = (e) => {
        console.log(e.target.value)
        setState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    const onSubmit = (e) => {
        e.preventDefault()
        let companyMatch = homeReducer.companies.filter((val) => (val.company_name === state.company_name))
        let company_id = ''
        if (companyMatch.length > 0) {
            company_id = companyMatch[0].company_id
        }
        let data = {
            company_id: company_id,
            company_name: state.company_name,
            bank_name: state.bank_name,
            bank_account_number: state.account_number,
            bank_balance: state.initial_balance,
            user_id: localStorage.getItem('u'),
            bank_actual_balance: state.initial_balance,

        }
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        getData('aam/insertBankDetails', { data: data, type: type, bank_account_id: state.bank_account_id }).then((res) => {

            if (res.res === 'exist') {
                setState(prev => ({
                    ...prev,
                    alert: true,
                    alertMessage: "Warning, Account number already exist .",
                    alertColor: '#f39c12'
                }))
            }
            if (res.res == true) {
                let alertMessage = "Success, Account has been added"
                if (type === 'Edit') {
                    alertMessage = "Success, Account has been updated"
                    setState(prev => ({
                        ...prev,
                        alert: true,
                        alertMessage: alertMessage,
                        alertColor: '#2ecc71'
                    }))
                } else {
                    setState(prev => ({
                        ...prev,
                        company_name: "",
                        company_id: "",
                        bank_name: "",
                        account_number: "",
                        initial_balance: "",
                        branch_name: "",
                        alert: true,
                        alertMessage: alertMessage,
                        alertColor: '#2ecc71'
                    }))
                }

                refresh()
            }
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })

        })
    }
    React.useEffect(() => {

        let companyMatch = homeReducer.companies.filter((val) => (val.company_id === editData.company_id))
        if (companyMatch.length > 0) {
            setState(prev => ({
                ...prev,
                company_name: companyMatch[0].company_name,
                bank_name: editData.bank_name,
                account_number: editData.bank_account_number,
                bank_account_id: editData.bank_account_id
            }))
        }
    }, [])

    return (
        <div style={{ marginTop: 90, padding: 10 }}>
            <PositionedSnackbar open={state.alert} vertical={'top'} horizontal={'center'} alertMessage={state.alertMessage} alertColor={state.alertColor} handleClose={() => {
                setState(prev => ({ ...prev, alert: false }))
            }} />
            <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card elevation={4}>
                        <CardContent>
                            <form onSubmit={onSubmit}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h4" style={{ color: '#2c7f84' }}>Bank Details</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Company</Typography>
                                        <FormControl variant={'outlined'} className={classes.textFieldStyle} size='small'>
                                            <InputLabel id="demo-simple-select-label"></InputLabel>
                                            <Select
                                                required
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='company_name'
                                                value={state.company_name}
                                                onChange={onChangeText}
                                            >
                                                {homeReducer.companies.map((val, index) => {
                                                    return <MenuItem key={index} value={val.company_name}>{val.company_name}</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Bank Name</Typography>
                                        <TextField required onChange={onChangeText} value={state.bank_name} name='bank_name' variant='outlined' size='small' className={classes.textFieldStyle} />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography style={{ fontWeight: 'bold' }} variant="p">Account Number</Typography>
                                        <TextField required onChange={onChangeText} value={state.account_number} name='account_number' variant='outlined' size='small' className={classes.textFieldStyle} />
                                    </Grid>
                                    {type === 'Create' ?
                                        <Grid item xs={12} md={6}>
                                            <Typography style={{ fontWeight: 'bold' }} variant="p">Initial Balance</Typography>
                                            <TextField required type='number' onChange={onChangeText} value={state.initial_balance} name='initial_balance' variant='outlined' size='small' className={classes.textFieldStyle} />
                                        </Grid>
                                        :
                                        undefined
                                    }

                                    <Grid container item xs={12} md={12} justify='flex-end'>
                                        <Button type='submit' style={{ backgroundColor: '#2c6164', color: '#fff', width: width < 600 ? '100%' : undefined }} variant="contained" >{type}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} md={3}>

                </Grid>

            </Grid>
        </div>



    );
}
export default React.memo(FormBankAccounts, renderPropsAreEqual);
