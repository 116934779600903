import {
  Grid,
  Breadcrumbs,
  Typography,
  Link,
  Divider,
  Button,
} from "@material-ui/core";
import React from "react";
import FilterCompany from "./filterCompany";
import PettyCashHooks from "../../hooks/pettyCashHooks/pettyCashHooks";
import CardStatus from "../reusesableComponents/cardStatus/cardStatus";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import FilterDate from "./filterDate";
import InputField from "../reusesableComponents/inputField/imputField";
import TableList from "./tableList";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "../reusesableComponents/dialog/dialog";
import CreatePettyCash from "./createPettyCash";
import AddSupplierForm from "../supplier/supplier_list/addSupplierForm";
let width = window.innerWidth;
const IndexPettyCash = () => {
  const { ...param } = PettyCashHooks();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit">Home </Link>
            <Typography color="textPrimary">Petty Cash</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid container item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Petty Cash
          </Typography>
        </Grid> */}
        <Grid container item xs={12} md={12}>
          <FilterCompany
            companies={param.companies}
            branches={param.branches}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FilterDate />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={2}>
          <CardStatus
            color={"#3498db"}
            icon={<ListAltIcon style={{ fontSize: 100, color: "#2e8ac7" }} />}
            count={12}
            type="Total PCF Requested"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CardStatus
            color={"#2ecc71"}
            icon={<DoneAllIcon style={{ fontSize: 100, color: "#29ba67" }} />}
            count={12}
            type="Approved PCF"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CardStatus
            color={"#f39c12"}
            icon={
              <PlaylistPlayIcon style={{ fontSize: 100, color: "#fcaf35" }} />
            }
            count={12}
            type="Pending PCF"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CardStatus
            color={"#e74c3c"}
            icon={<ThumbDownIcon style={{ fontSize: 100, color: "#de4333" }} />}
            count={12}
            type="Denied PCF"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputField inLabel="Search" type="text" />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            onClick={() => {
              // onSubmitBranchFilter();
            }}
            type="button"
            size="small"
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : "100%",
            }}
            variant="contained"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12} md={7}></Grid>
        <Grid item xs={12} md={2}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              param.handleOpenCreate();
            }}
            type="button"
            size="small"
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : "100%",
            }}
            variant="contained"
          >
            Add PCF
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <TableList />
        </Grid>
      </Grid>
      <Dialog
        handleClose={param.handleCloseCreate}
        open={param.open}
        title={"Create Petty Cash"}
        content={
          <>
            <CreatePettyCash
              branches={param.branches}
              onSelectBranch={param.onSelectBranch}
              onChangeText={param.onChangeText}
              onSubmit={param.onSubmit}
              accounts={param.accounts}
              onSelectAccountTitle={param.onSelectAccountTitle}
              stateForm={param.stateForm}
              onChangePic={param.onChangePic}
              handleOpenCreateSupplier={param.handleOpenCreateSupplier}
            />
          </>
        }
        size="lg"
      />
      <Dialog
        handleClose={param.handleCloseCreateSupplier}
        open={param.openAddSupplier}
        title={"Add Supplier"}
        content={
          <>
            <AddSupplierForm />
          </>
        }
        size="lg"
      />
    </div>
  );
};

export default IndexPettyCash;
