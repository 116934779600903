// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,

} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import Link from "@material-ui/core/Link";
import React from "react";
// import TableBankAccounts from './tableBankAccounts'
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import TableOfAccounts from './tableOfAccounts'
import AddIcon from '@material-ui/icons/Add';
import { getData } from "../../api/api";
// import BankForm from "./bankForm";
function renderPropsAreEqual(prevProps, nextProps) {
    if (prevProps === nextProps) {
        return true;
    } else {
        return false;
    }
}
let width = window.innerWidth;


function IndexChartOfAccounts() {
    const [state, setState] = React.useState({

    })
    const chartoFAccountsReducer = useSelector((state) => state.ChartoFAccountsReducer);
    const homeReducer = useSelector((state) => state.HomeReducer);
    const dispatch = useDispatch()
    const onChangeCompany = (e) => {
        let company_id = e.target.value
        console.log(company_id)
        dispatch({
            type:'onChangeChartOFAccountsReducer',
            data:{selected_company_id : company_id}
        })
    }
    
    return (
        <div>

            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">

                        <Link color="inherit">
                            Settings
                        </Link>
                        <Typography color="textPrimary">Chart of Accounts</Typography>
                    </Breadcrumbs>
                </Grid>
                {/* <Grid item xs={12} md={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid container justify='Flex-start' item xs={12} md={12}>
                                    <Typography variant="h6" style={{ color: '#2c7f84' }}>Select Company</Typography>
                                </Grid>
                                <Grid container justify='Flex-start' item xs={12} md={6}>
                                    <FormControl variant={'outlined'} style={{ width: '100%', textAlign: 'left' }} size='small'>
                                        <InputLabel id="demo-simple-select-label"></InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={chartoFAccountsReducer.selected_company_id}
                                            // onChange={handleChange}
                                            onChange={onChangeCompany}
                                        >
                                            {homeReducer.companies.map((val, index) => {
                                                return <MenuItem key={index} value={val.company_id}>{val.company_name}</MenuItem>
                                            })

                                            }

                                        </Select>
                                    </FormControl>
                                </Grid>
                               
                                
                            </Grid>

                        </CardContent>

                    </Card>
                </Grid> */}
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <TableOfAccounts type={'Balance Sheet'} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <TableOfAccounts type={'Profit and Loss Statement'}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
export default React.memo(IndexChartOfAccounts, renderPropsAreEqual);
