// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
  Icon,
  Button,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import React from "react";
import ServiceTable from "./servicesTable";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getData } from "../../api/api";
import CloseIcon from "@material-ui/icons/Close";
import ServiceForm from "./serviceForm";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  root: {
    width: "100%",
    // overflowX: "auto",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});

function IndexClient() {
  const history = useHistory();
  const classes = useStyles();
  const selectedClient = useSelector(
    (state) => state.ClientReducer.selectedClient
  );
  const actionType = useSelector((state) => state.ServiceReducer.actionType);
  const onBack = () => {
    history.push("/clientDetails");
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="#/clientMasterList">
            Client Master List
          </Link>
          <Typography color="textPrimary">Services and Contract</Typography>
          <Typography color="textPrimary">
            {selectedClient.client_name}
          </Typography>
          <Typography color="textPrimary">{actionType} Service</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container justify="flex-start" item xs={12} md={12}>
        <Button
          onClick={onBack}
          style={{ backgroundColor: "#2c6164", color: "#fff" }}
          startIcon={<Icon style={{ fontSize: 18 }}>arrow_back_ios</Icon>}
          variant="contained"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        <ServiceForm />
      </Grid>
      <Grid item xs={12} md={3}></Grid>
    </Grid>
  );
}
export default IndexClient;
