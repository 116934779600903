import { Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import SelectInput from "../../components/reusesableComponents/selectInput/selectInput";
let width = window.innerWidth;
const FilterCompany = (props) => {
  const { ...param } = props;
  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} md={5}>
        <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
          Petty Cash
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}></Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          dataList={param.companies}
          label={"Company"}
          valueData={(val) => val.company_id}
          valueDisplay={(val) => val.company_name}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <SelectInput
          dataList={param.branches}
          label={"Branch"}
          valueData={(val) => val.branch_id}
          valueDisplay={(val) => val.branch_name}
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <Button
          onClick={() => {
            // onSubmitBranchFilter();
          }}
          type="button"
          size="small"
          style={{
            backgroundColor: "#2c6164",
            color: "#fff",
            width: width < 600 ? "100%" : "100%",
            marginTop: width < 600 ? undefined : 17,
          }}
          variant="contained"
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterCompany;
