import { getData } from "../../api/api"
import React from 'react'
import { useSelector, useDispatch } from "react-redux";
export const BillingContext = React.createContext({});

export const BillingProvider=({children})=>{ 
    const branches = useSelector((state) => state.HomeReducer.branches);

    const dispatch = useDispatch()
    
    const getBillingData = (type,page,rowsPerPage,search) =>{
        let handleBranches = branches.map((val)=>(val.branch_id))
        let data = {
            handleBranches:handleBranches,
            page:page+1,
            limit:rowsPerPage,
            selectedType:type,
            search:search,
            journal:'Billing Invoices'
        }
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        getData('aam/getBillingRequest',data).then((res)=>{
            let account_name = ''
            let account_id = ''

            if(res.res.getJournal.length > 0){
                if(res.res.getJournal[0].default_account != null){
                    account_name = res.res.getJournal[0].default_account
                    account_id = res.res.getJournal[0].default_account
                }
            }
            dispatch({
                type:'onChangeBilling',
                data : {
                    cardStatus:res.res.count,   
                    billingList:res.res.data,
                    onCheckedBilling:[],
                    listCount:res.res.listCount,
                    selectedType:type,
                    journal:res.res.getJournal,
                    account_name:account_name,
                    account_id:account_id,
                }
            })
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
            
        })
    } 
   
    return (
        <BillingContext.Provider value={{
            getBillingData
        }}>
            {children}
        </BillingContext.Provider>
    )
}