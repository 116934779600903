// import MarkerClusterer from '@google/markerclustererplus';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Button,
  MenuItem,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import React from "react";
import TableBankAccounts from "./tableBankAccounts";
import Loading from "../../assets/loading2.gif";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getData } from "../../../src/components/api/api";
import PositionedSnackbar from "../alert";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { DropzoneDialog, DropzoneArea } from "material-ui-dropzone";
import axios from "axios";

let width = window.innerWidth;

function renderPropsAreEqual(prevProps, nextProps) {
  if (prevProps === nextProps) {
    return true;
  } else {
    return false;
  }
}
const useStyles = makeStyles({
  root: {
    // display: 'flex',
    // flex: 1
  },
  container: {
    maxHeight: 440,
  },
  textFieldStyle: {
    width: "100%",
  },
});

function FormBankDeposit({ refresh, transactionType }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const bankReducer = useSelector((state) => state.BankReducer);
  const [state, setState] = React.useState({
    amount: "",
    file: [],
  });
  const onChangeText = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    let finance_type = "Deposit";
    let finance_category = "Direct Deposit";

    if (transactionType === "Debit Memo") {
      finance_type = "Debit";
      finance_category = "Debit Memo";
    } else if (transactionType === "SYSTEM BORROW AMOUNT") {
      finance_type = "System Borrow Amount";
      finance_category = "System Borrow Amount";
    }

    let transactionDetails = {
      finance_type: finance_type,
      currency: "Php",
      amount: state.amount,
      branch_id: 0,
      app_id: 7,
      finance_details: "",
      finance_category: finance_category,
      bank_id: bankReducer.selectedBank.bank_account_id,
      company_id: bankReducer.selectedBank.company_id,
      finance_payment_method: "",
      finance_payment_reference_number: "",
      user_id: localStorage.getItem("u"),
    };
    if (state.file.length === 0 && transactionType != "SYSTEM BORROW AMOUNT") {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Warning, Please attach file.",
        alertColor: "#f39c12",
      }));
    } else {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      let data = {
        amount: state.amount,
        details: bankReducer.selectedBank,
        transactionDetails: transactionDetails,
      };
      const formData = new FormData();
      for (let i = 0; i < state.file.length; i++) {
        formData.append("file" + i, state.file[i]);
      }
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      formData.append("data", JSON.stringify(data));
      axios
        .post(
          "https://api.workflow.gzonetechph.com/aam/insertDeposit2",
          formData,
          config
        )
        .then((response) => {
          if (response.data.res === "exist") {
            setState((prev) => ({
              ...prev,
              alert: true,
              alertMessage:
                "Warning, Data already exist, try to reload the reload.",
              alertColor: "#f39c12",
            }));
            dispatch({
              type: "onChangeHomeReducer",
              data: {
                loading: false,
              },
            });
          } else if (response.data.res === "Insufficient Balance") {
            setState((prev) => ({
              ...prev,
              alert: true,
              alertMessage:
                "Warning, You have insufficient balance in this account.",
              alertColor: "#f39c12",
            }));
            dispatch({
              type: "onChangeHomeReducer",
              data: {
                loading: false,
              },
            });
          } else if (response.data.res) {
            setState((prev) => ({
              ...prev,
              amount: "",
              file: [],
              alert: true,
              alertMessage: "Success, Account has been added",
              alertColor: "#2ecc71",
            }));
          }
          dispatch({
            type: "updateBalance",
            data: response.data.data,
            actual: response.data.updated_actual_balance,
            borrowed: response.data.updated_borrowed_balance,
          });

          refresh();
        });
    }
  };
  const handleChangeFile = (files) => {
    setState((prev) => ({
      ...prev,
      file: files,
      clear: true,
    }));
  };

  return (
    <div style={{ marginTop: 90, padding: 10 }}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={4}>
            <CardContent>
              <form onSubmit={onSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h4" style={{ color: "#2c7f84" }}>
                      {transactionType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="p">
                      Amount
                    </Typography>
                    <TextField
                      required
                      type="number"
                      onChange={onChangeText}
                      value={state.amount}
                      name="amount"
                      variant="outlined"
                      size="small"
                      className={classes.textFieldStyle}
                    />
                  </Grid>
                  <Grid container item xs={12} md={12} justify="flex-start">
                    <Tooltip title="Attach Files">
                      <IconButton
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#fff",
                          marginRight: 10,
                          height: 40,
                          width: 40,
                        }}
                        variant="contained"
                        onClick={() => setOpen(true)}
                      >
                        <div style={{ position: "relative" }}>
                          <AttachFileIcon />
                          <div
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 10,
                              background: "#eb4d4b",
                              position: "absolute",
                              top: -10,
                              right: -15,
                              display: state.file.length == 0 ? "none" : "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="p"
                              style={{ color: "#fff", fontSize: 13 }}
                            >
                              {state.file.length}
                            </Typography>
                          </div>
                        </div>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid container item xs={12} md={12} justify="flex-end">
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#2c6164",
                        color: "#fff",
                        width: width < 600 ? "100%" : undefined,
                      }}
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      <Dialog
        fullWidth={"sm"}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload File"}</DialogTitle>
        <DialogContent>
          <DropzoneArea
            filesLimit={1}
            // className={classes.drop_zone_area}
            acceptedFiles={["image/*"]}
            onChange={handleChangeFile}
            showFileNames={true}
            maxFileSize={500800000}
            onDelete={(e) => {
              for (let index2 = 0; index2 < state.file.length; index2++) {
                const element = state.file[index2];
                if (element.name === e.name) {
                  state.file.splice(index2, 1);
                }
                setState((prev) => ({ ...prev }));
              }
            }}
            clearOnUnmount={false}
            initialFiles={state.file}
            showPreviewsInDropzone={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState((prev) => ({
                ...prev,
                file: [],
              }));
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Attach
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default React.memo(FormBankDeposit, renderPropsAreEqual);
