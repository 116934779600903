import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Dialog,
  Slide,
  AppBar,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  DialogTitle,
  DialogContent,
  TextField,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import RequestForm from "./requestForm";
import RequestDetails from "./requestDetails";

import { getData } from "../../../src/components/api/api";
import { useSelector, useDispatch } from "react-redux";
import CallMadeIcon from "@material-ui/icons/CallMade";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import CreateIcon from "@material-ui/icons/Create";
import PositionedSnackbar from "../alert";
import Group from "../../assets/group.png";

let width = window.innerWidth;
const columns = [
  { id: "req_reference_no", label: "Reference No." },
  {
    id: "req_date_added",
    label: "Date Request",
    format: (value) => moment(value).format("YYYY-MM-DD"),
  },
  {
    id: "req_payee",
    label: "Payee",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "company_name",
    label: "Company",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  {
    id: "recurring",
    label: "Recurring",
    format: (value) => String(value).toLocaleUpperCase(),
  },
  // {
  //     id: 'department',
  //     label: 'Department',
  //     format: (value) => String(value).toLocaleUpperCase(),
  // },
  // {
  //     id: 'status',
  //     label: 'Status',
  //     format: (value) => String(value).toLocaleUpperCase(),
  // },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    // overflowX: "auto",
  },
  container: {
    maxHeight: 440,
  },
  outlined: {
    borderWidth: 2,
  },
  cardFont: {
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
  },
  cardFontCount: {
    fontSize: 29,
    fontWeight: "bold",
    color: "#fff",
    position: "absolute",
  },
  cardColor: {
    backgroundColor: "#2ecc71",
  },
  cardColorPending: {
    backgroundColor: "#e67e22",
  },
  cardColorDenied: {
    backgroundColor: "#e74c3c",
  },
  tableHeight: {
    maxHeight: 300,
  },
  appBar: {
    backgroundColor: "#fff",
    color: "#000",
    position: "fixed",
    width: "100%",
  },
  iconSize: {
    height: 17,
    width: 17,
    cursor: "pointer",
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const homeReducer = useSelector((state) => state.HomeReducer);
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    requestList: [],
    requestListCount: 0,
    actionButton: "",
    selectedRequest: [],
    reqDetailsModal: false,
    refreshReqList: false,
    countStatus: [],
    status: "Total",
    edit: false,
    searchDriver: "",
    branchList: [],
    company_id: "All",
    branch_id: "All",
    apvListToCreateCV: [],
    description_cv: "",
    from: "",
    to: "",
    recipient: "",
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    getRequest(newPage, rowsPerPage, state.status, true, state.searchDriver);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    console.log("hehehe");
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    } else {
      getRequest(page, rowsPerPage, state.status, false, state.searchDriver);
    }
  }, [homeReducer.onRefresh]);
  React.useEffect(() => {
    getRequest(page, rowsPerPage, state.status, true, state.searchDriver);
    console.log("test");
  }, [state.refreshReqList]);

  const getRequest = (page, row, status, loadingStatus, searchDriver) => {
    let new_page = 1 + page;
    dispatch({
      type: "onChangeHomeReducer",
      data: {
        loading: loadingStatus,
      },
    });
    let branch_id = state.branch_id;
    let company_id = state.company_id;

    if (branch_id === "All") {
      branch_id = "";
    }
    if (company_id === "All") {
      company_id = "";
    }
    // if (typeof homeReducer.loginData.complete_name !== 'undefined') {
    let branches = homeReducer.branches.map((val) => val.branch_id);
    let data = {
      user_id: localStorage.getItem("u"),
      branches: branches,
      page: new_page,
      limit: row,
      status: status,
      req_type: "Request for Payment",
      branch_id: homeReducer.loginData.branch_id,
      searchDriver: searchDriver,
      branch_id_filter: branch_id,
      company_id_filter: company_id,
      from: state.from,
      to: state.to,
    };
    getData("aam/getAllRequestCRFV2", data).then((res) => {
      if (res === "Network Error") {
      } else {
        setState((prev) => ({
          ...prev,
          requestList: res.data,
          requestListCount: res.count,
          countStatus: res.countStatus,
          status: status,
        }));
        setPage(page);
      }

      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: false,
        },
      });
    });
  };
  const uppdateRequestList = (data) => {
    let requestList = state.requestList;
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      requestList.push(element);
    }
    setState((prev) => ({ ...prev, requestList: requestList }));
  };
  const handleNotification = (firstApprover) => {
    // homeReducer.socket.emit("sendNotification", {
    //     senderName: homeReducer.loginData.user_id,
    //     receiverName: firstApprover,
    //     type: ''
    // })
  };
  const formatNumber = (num) => {
    if (num != "") {
      var amount = parseFloat(num).toFixed(2);
      var formattedString = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedString;
    } else {
      return 0;
    }
  };
  const onSubmitCancelled = (request_status) => {
    let text = "Are you sure you want to " + request_status + " this request?";
    if (window.confirm(text) == true) {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      let select_req = state.selectedRequest;
      let match_apv_cv_access = homeReducer.branches.filter(
        (val) =>
          parseInt(state.selectedRequest.branch_id) === parseInt(val.branch_id)
      );
      let apv_cv_access = "";

      if (match_apv_cv_access.length > 0) {
        apv_cv_access = match_apv_cv_access[0].check_voucher_access;
      }
      let request = {
        req_id: select_req.req_id,
        req_approval_id: select_req.req_approval_id,
        status: request_status,
        req_hierarchy_level: select_req.req_hierarchy_level,
        req_type: select_req.req_type,
        approver: "",
        comment: state.comment_value,
        app_id: "7",
        company_id: homeReducer.loginData.company_id,
        branch_id: homeReducer.loginData.branch_id,
        approver_id: homeReducer.loginData.user_id,
        apv_cv_access: apv_cv_access,
      };
      getData("HumanResource/changeRequestStatus", request).then((res) => {
        if (String(res).includes("Success")) {
          state.selectedRequest.req_approval_status = request_status;
          state.selectedRequest.status = request_status;
          getRequest(page, rowsPerPage, state.status, true, state.searchDriver);

          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Success, Request has been " + request_status,
            alertColor: "#2ecc71",
            comment_value: "",
            comment_modal: false,
            refreshReqList: !state.refreshReqList,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Something went wrong",
            alertColor: "#f39c12",
          }));
        }
      });
    } else {
    }
  };
  const onChangeText = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onChangeCompany = (e) => {
    let company_id = e.target.value;
    const branches = homeReducer.branches.filter(
      (val) => val.company_id == company_id
    );

    setState({
      ...state,
      company_id: company_id,
      branchList: branches,
    });
  };
  const onChangeBranch = (e) => {
    let branch_id = e.target.value;
    setState((prev) => ({ ...prev, branch_id: branch_id }));
  };
  const onSubmitBranchFilter = () => {
    getRequest(0, rowsPerPage, state.status, true, state.searchDriver);
  };

  const onSelectAPVForCheckVoucher = (details) => {
    let match = false;
    state.apvListToCreateCV.forEach((val, index) => {
      if (val.req_id === details.req_id) {
        state.apvListToCreateCV.splice(index, 1);
        match = true;
      }
    });
    if (!match) {
      state.apvListToCreateCV.push(details);
    }
    setState((prev) => ({
      ...prev,
    }));
  };
  const openModalCreateVoucher = () => {
    setState((prev) => ({
      ...prev,
      openCreateCvModal: !state.openCreateCvModal,
    }));
  };
  const onSubmitCV = (e) => {
    e.preventDefault();

    if (state.apvListToCreateCV.length == 0) {
      setState((prev) => ({
        ...prev,
        alert: true,
        alertMessage: "Warning , Please select RFP to be group",
        alertColor: "#f39c12",
      }));
    } else {
      dispatch({
        type: "onChangeHomeReducer",
        data: {
          loading: true,
        },
      });
      let match_apv_cv_access = homeReducer.branches.filter(
        (val) =>
          parseInt(state.apvListToCreateCV[0].branch_id) ===
          parseInt(val.branch_id)
      );
      let apv_cv_access = "";

      if (match_apv_cv_access.length > 0) {
        apv_cv_access = match_apv_cv_access[0].account_voucher_access;
      }
      let data = {
        apv_cv_access: apv_cv_access,
        rfpList: state.apvListToCreateCV,
        description_cv: state.description_cv,
        user_id: homeReducer.loginData.user_id,
        recipient: state.recipient,
        fullname:
          homeReducer.loginData.user_lname +
          " " +
          homeReducer.loginData.user_fname,
        total: parseFloat(
          state.apvListToCreateCV.reduce(
            (count, val) => (count += parseFloat(val.req_quantity)),
            0
          )
        ).toFixed(2),
      };
      getData("aam/createCVForMultipleRFP", data).then((res) => {
        if (res) {
          setState((prev) => ({
            ...prev,
            alert: true,
            alertMessage: "Success , Requests for Payment has been grouped",
            alertColor: "#2ecc71",
            openCreateCvModal: false,
            apvListToCreateCV: [],
            refreshReqList: !state.refreshReqList,
          }));
        }
      });
    }
  };

  const onFilterDate = (e) => {
    getRequest(0, rowsPerPage, state.status, true, state.searchDriver);
  };

  return (
    <Grid container spacing={1}>
      <PositionedSnackbar
        open={state.alert}
        vertical={"top"}
        horizontal={"center"}
        alertMessage={state.alertMessage}
        alertColor={state.alertColor}
        handleClose={() => {
          setState((prev) => ({ ...prev, alert: false }));
        }}
      />
      <Grid container justify="flex-start" item xs={12} md={2}>
        <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
          Company
        </Typography>
        <FormControl
          variant={"outlined"}
          style={{ width: "100%", background: "#fff" }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.company_id}
            onChange={onChangeCompany}
            style={{ textAlign: "left" }}
          >
            <MenuItem value={"All"}>{"All"}</MenuItem>
            {homeReducer.companies.map((val, index) => {
              return (
                <MenuItem key={index} value={val.company_id}>
                  {val.company_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid container justify="flex-start" item xs={12} md={2}>
        <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
          Branch
        </Typography>
        <FormControl
          variant={"outlined"}
          style={{ width: "100%", background: "#fff" }}
          size="small"
        >
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={state.branch_id}
            style={{ textAlign: "left" }}
            onChange={onChangeBranch}
          >
            <MenuItem value={"All"}>{"All"}</MenuItem>
            {state.branchList.map((val, index) => {
              return (
                <MenuItem key={index} value={val.branch_id}>
                  {val.branch_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={1}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              onSubmitBranchFilter();
            }}
            type="button"
            style={{
              backgroundColor: "#2c6164",
              color: "#fff",
              width: width < 600 ? "100%" : undefined,
              marginTop: width < 600 ? undefined : 17,
            }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={7}></Grid>
      <Grid item xs={12} md={7}></Grid>
      <Grid></Grid>
      <Grid container justify="flex-end" item xs={12} md={2}>
        <Grid container justify="flex-start" item xs={12} md={12}>
          <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
            From
          </Typography>
        </Grid>
        <TextField
          onChange={onChangeText}
          required
          value={state.from}
          type="date"
          style={{ backgroundColor: "#fff", width: "100%" }}
          name="from"
          variant="outlined"
          size="small"
          className={classes.textFieldStyle}
        />
      </Grid>
      <Grid container justify="flex-end" item xs={12} md={2}>
        <Grid container justify="flex-start" item xs={12} md={12}>
          <Typography style={{ fontWeight: "bold", fontSize: 12 }} variant="p">
            To
          </Typography>
        </Grid>
        <TextField
          onChange={onChangeText}
          required
          type="date"
          value={state.to}
          style={{ backgroundColor: "#fff", width: "100%" }}
          name="to"
          variant="outlined"
          size="small"
          className={classes.textFieldStyle}
        />
      </Grid>
      <Grid container justify="flex-end" item xs={12} md={1}>
        <Button
          onClick={() => {
            onFilterDate();
          }}
          type="submit"
          style={{
            backgroundColor: "#2c6164",
            color: "#fff",
            width: width < 600 ? "100%" : undefined,
            marginTop: width < 600 ? undefined : 17,
          }}
          variant="contained"
        >
          Filter
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={1}>
              {state.countStatus.map((val, index) => {
                return (
                  <Grid key={index} item xs={12} md={2}>
                    <Card
                      style={{ background: val.color, cursor: "pointer" }}
                      variant="outlined"
                      onClick={() =>
                        getRequest(
                          0,
                          rowsPerPage,
                          val.type,
                          true,
                          state.searchDriver
                        )
                      }
                    >
                      <CardContent>
                        <div style={{ display: "flex" }}>
                          <Typography className={classes.cardFont}>
                            {val.type}
                          </Typography>
                        </div>
                        <Typography className={classes.cardFontCount}>
                          {val.count}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title="View">
                            <CallMadeIcon
                              className={classes.iconSize}
                              style={{
                                color: "#fff",
                                fontWeight: "bold",
                                width: 20,
                                height: 20,
                              }}
                            />
                          </Tooltip>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item xs={12} md={12}></Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={1}>
          <Grid container item xs={12} md={12} justify="flex-start">
            <Typography variant="p" style={{ fontWeight: "bold" }}>
              Selected : {state.status}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={4} justify="flex-start">
            <Card
              variant="outlined"
              style={{
                width: width > 600 ? 230 : "100%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                value={state.searchDriver}
                placeholder="Search"
                style={{ borderStyle: "none", outline: "none" }}
                name="searchDriver"
                onChange={onChangeText}
              />
              <SearchIcon
                style={{ cursor: "pointer", color: "#787f80" }}
                onClick={() =>
                  getRequest(
                    0,
                    rowsPerPage,
                    state.status,
                    true,
                    state.searchDriver
                  )
                }
              />
              <RefreshIcon
                style={{ cursor: "pointer", color: "#787f80" }}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    searchDriver: "",
                  }));
                  setTimeout(() => {
                    getRequest(
                      0,
                      rowsPerPage,
                      state.status,
                      true,
                      state.searchDriver
                    );
                  }, 200);
                }}
              />
            </Card>
          </Grid>
          <Grid container item xs={12} md={8} justify="flex-end">
            {/* <Button
              onClick={() => {
                setState((prev) => ({ ...prev, actionButton: "Create" }));
                setTimeout(() => {
                  handleClickOpen();
                }, 100);
              }}
              style={{
                backgroundColor: "#2c6164",
                color: "#fff",
                marginRight: width < 600 ? undefined : 5,
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
              startIcon={<AddIcon />}
            >
              Request
            </Button> */}
            <Button
              onClick={() => {
                if (state.apvListToCreateCV.length > 0) {
                  openModalCreateVoucher();
                }
              }}
              type="buttom"
              style={{
                backgroundColor:
                  state.apvListToCreateCV.length === 0 ? "#636e72" : "#2c6164",
                color: "#fff",
                width: width < 600 ? "100%" : undefined,
              }}
              variant="contained"
              startIcon={<CreateIcon />}
            >
              {" "}
              Check voucher
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper
              className={(classes.root, classes.outlined)}
              variant="outlined"
            >
              <TableContainer
                className={(classes.container, classes.tableHeight)}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      ></TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Est. Release
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#2c6164",
                            color: "#ffff",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Amount
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#2c6164",
                          color: "#ffff",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{ backgroundColor: "#2c6164" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.requestList.map((row) => {
                      let bgColor = "#3498db";
                      let status = row.status;
                      if (row.status === "Approved") {
                        bgColor = "#2ecc71";
                      }
                      if (row.status === "Pending") {
                        bgColor = "#faab2d";
                      }
                      if (row.status === "Denied") {
                        bgColor = "#bf7bdb";
                      }
                      if (row.status === "Cancelled") {
                        bgColor = "#f56b5d";
                      }
                      if (row.status === "Return") {
                        bgColor = "#2c3e50";
                      }
                      if (
                        row.status === "Approved" &&
                        row.autogenerateCV == 0 &&
                        row.cv_req_id == null &&
                        row.req_apv_created !== null
                      ) {
                        bgColor = "#7f8c8d";
                        status = "Ready for CV";
                      }
                      let details = JSON.parse(row.req_details);
                      let match = state.apvListToCreateCV.filter(
                        (val) => val.req_id === row.req_id
                      );
                      let match_val = false;
                      if (match.length > 0) {
                        match_val = true;
                      }
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell>
                            <img
                              src={Group}
                              style={{
                                width: 35,
                                height: 35,
                                display:
                                  row.autogenerateCV == 0 ? undefined : "none",
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.estimated_date_release}</TableCell>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.id === "status" ? (
                                  <span
                                    style={{
                                      paddingTop: 3,
                                      paddingBottom: 3,
                                      paddingRight: 5,
                                      paddingLeft: 5,
                                      background: bgColor,
                                      color: "#fff",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <Typography variant="p">
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </Typography>
                                  </span>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {details.map((valDetails, index2) => {
                                return (
                                  <Typography variant={"p"} key={index2}>
                                    {String(
                                      valDetails.description
                                    ).toLocaleUpperCase()}
                                  </Typography>
                                );
                              })}
                            </div>
                          </TableCell>
                          <TableCell>
                            <Typography variant={"p"}>
                              {formatNumber(row.req_quantity)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                paddingTop: 3,
                                paddingBottom: 3,
                                paddingRight: 5,
                                paddingLeft: 5,
                                background: bgColor,
                                color: "#fff",
                                borderRadius: 5,
                              }}
                            >
                              {status}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              onClick={() => {
                                setState((prev) => ({
                                  ...prev,
                                  actionButton: "Details",
                                  selectedRequest: row,
                                  reqDetailsModal: true,
                                }));
                              }}
                              title="View"
                            >
                              <CallMadeIcon className={classes.iconSize} />
                            </Tooltip>
                            {row.status === "Approved" &&
                            row.autogenerateCV == 0 &&
                            row.cv_req_id == null &&
                            row.req_apv_created !== null ? (
                              <Checkbox
                                checked={match_val}
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                style={{ transform: "scale(0.8)" }}
                                onClick={() => {
                                  onSelectAPVForCheckVoucher(row);
                                }}
                              />
                            ) : undefined}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={state.requestListCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* </CardContent>
                </Card> */}
      </Grid>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar} elevation={1}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{state.actionButton}</Typography>
          </Toolbar>
          {/* <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} /> */}
        </AppBar>
        <div style={{ marginTop: 60 }} />
        <div style={{ display: "flex", flex: 1, background: "#f5f7f7" }}>
          <RequestForm
            handleNotification={(firstApprover) =>
              handleNotification(firstApprover)
            }
            refresh={() => {
              setState((prev) => ({
                ...prev,
                refreshReqList: !state.refreshReqList,
              }));
            }}
            handleClose={() => handleClose()}
            action={state.actionButton}
            selectedRequest={state.selectedRequest}
          />
        </div>
      </Dialog>
      <Dialog
        fullScreen
        open={state.reqDetailsModal}
        onClose={() => {
          setState((prev) => ({ ...prev, reqDetailsModal: false }));
        }}
      >
        <AppBar className={classes.appBar} elevation={1}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setState((prev) => ({ ...prev, reqDetailsModal: false }));
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{state.actionButton}</Typography>
          </Toolbar>
          {/* <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} /> */}
        </AppBar>
        <div style={{ marginTop: 60 }} />
        <div style={{ display: "flex", flex: 1, background: "#f5f7f7" }}>
          <RequestDetails
            onSubmitCancelled={(status) => {
              onSubmitCancelled(status);
            }}
            refreshFunc={() => {
              setState((prev) => ({
                ...prev,
                refreshReqList: !state.refreshReqList,
              }));
            }}
            handleClose={() => handleClose()}
            action={state.actionButton}
            selectedRequest={state.selectedRequest}
            edit={state.edit}
          />
          <div style={{ marginTop: 70 }} />
        </div>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={state.openCreateCvModal}
        onClose={() => {
          setState((prev) => ({ ...prev, openCreateCvModal: false }));
        }}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <DialogTitle>Create Check Voucher</DialogTitle>

          <div style={{ position: "absolute", right: 30, bottom: -5 }}>
            <Typography variant="h6">
              Total :{" "}
              <span style={{ fontWeight: "bold" }}>
                {formatNumber(
                  state.apvListToCreateCV.reduce(
                    (count, val) => (count += parseFloat(val.req_quantity)),
                    0
                  )
                )}
              </span>{" "}
            </Typography>
          </div>
          <div style={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  openCreateCvModal: false,
                }));
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <form onSubmit={onSubmitCV}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="p" style={{ fontWeight: "bold" }}>
                  Description
                </Typography>
                <TextField
                  onChange={onChangeText}
                  name="description_cv"
                  multiple
                  rows={4}
                  required
                  placeholder="Type"
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="p" style={{ fontWeight: "bold" }}>
                  Recipient
                </Typography>
                <TextField
                  onChange={onChangeText}
                  name="recipient"
                  required
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardContent>
                    <TableContainer style={{ height: 300, overflowY: "auto" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#ffff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#ffff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Payee
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#ffff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Company
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#ffff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Description
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#ffff",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Total Amount
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#2c6164",
                                color: "#ffff",
                                whiteSpace: "nowrap",
                              }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.apvListToCreateCV.map((val, index) => {
                            let details = JSON.parse(val.req_details);
                            return (
                              <TableRow>
                                <TableCell style={{ whiteSpace: "nowrap" }}>
                                  {moment(val.req_date_added).format(
                                    "YYYY-MM-DD"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {String(val.req_payee).toLocaleUpperCase()}
                                </TableCell>
                                <TableCell>{val.company_name}</TableCell>
                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {details.map((valDetails, index2) => {
                                      return (
                                        <Typography variant="p" key={index2}>
                                          {String(
                                            valDetails.description
                                          ).toLocaleUpperCase()}
                                        </Typography>
                                      );
                                    })}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {formatNumber(val.req_quantity)}
                                </TableCell>
                                <TableCell>
                                  <CloseIcon
                                    onClick={() => {
                                      state.apvListToCreateCV.splice(index, 1);
                                      setState((prev) => ({
                                        ...prev,
                                      }));
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container justify="flex-end" item xs={12} md={12}>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: "#2c6164",
                    color: "#fff",
                    width: width < 600 ? "100%" : undefined,
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
