const initialState = {
    clientBranchAddress:'',
    clientBranchName:'',
    clientBranchList:[],
    actionType:'Add',
    selectedClientBranch:[],

    serviceList:[],
    selectedServiceId:'',
    contractList:[]
  
}
const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'onChangeClientBranchReducer':
            return {
                ...state,
                ...action.data,
            }
        default:
            return state;
    }
}
export default dataReducer; 