// import MarkerClusterer from '@google/markerclustererplus';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableRow,
    TableBody,
    Dialog,
    AppBar,
    Toolbar,
    IconButton
} from "@material-ui/core";
import { Breadcrumbs, Backdrop } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import React from "react";
// import Loading from '../../assets/loading2.gif'
import { useSelector, useDispatch } from "react-redux";
import Dashboard from "./dashboard";
import Filter from './filter'
import FilterBranch from './filter_branch'
import TransactionTable from './transactionTable'
import moment from 'moment'
import { getData } from "../../../src/components/api/api"
import CloseIcon from '@material-ui/icons/Close';
import RequestDetails from './requestDetails'
const useStyles = makeStyles({
    root: {
        width: '100%',
        // overflowX: "auto",
    },
    container: {
        maxHeight: 440,

    },
    outlined: {
        borderWidth: 2
    },
    cardFont: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff'
    },
    cardFontCount: {
        fontSize: 29,
        fontWeight: 'bold',
        color: '#fff',
        position: 'absolute'
    },
    cardColor: {
        backgroundColor: '#2ecc71'
    },
    cardColorPending: {
        backgroundColor: '#e67e22'
    },
    cardColorDenied: {
        backgroundColor: '#e74c3c'
    },
    tableHeight: {
        maxHeight: 300
    },
    appBar: {
        backgroundColor: '#fff', color: '#000',
        position: 'fixed',
        width: '100%'
    },
    iconSize: {
        height: 17,
        width: 17,
        cursor: 'pointer'
    }
});

function IndexTransaction() {
    const classes = useStyles();
    const branchesReducer = useSelector(state => state.HomeReducer.branches)
    const page = useSelector(state => state.RequestLogsDetails.page)
    const rowsPerPage = useSelector(state => state.RequestLogsDetails.rowsPerPage)
    const selection = useSelector(state => state.RequestLogsDetails.selection)
    const company_id = useSelector(state => state.RequestLogsDetails.company_id)
    const branch_id = useSelector(state => state.RequestLogsDetails.branch_id)


    
    const dispatch = useDispatch()

    const [state, setState] = React.useState(({
        selection: {
            startDate: new Date(moment(new Date()).format('YYYY-MM-01')),
            endDate: new Date(),
            key: 'selection'
        },
        company_id:'All',
        branch_id:'All',
        branchList:[],
        date_range_modal:false,
        refreshFilter:false,
        refreshDashboard:false,
        refreshTransactionTable:false,
        refreshFilterBranch:false,
        reqDetailsModal:false,
        selectedRequest:[]

    }))
    const openDateRange = () => {
        setState(prev => ({
            ...prev,
            date_range_modal: !state.date_range_modal,
            refreshFilter:!state.refreshFilter
        }))
    }
    const getDateTransaction = React.useCallback((newPage,limit,status = 'Total') =>{
        dispatch({
            type: 'onChangeHomeReducer',
            data: {
                loading: true,
            }
        })
        let branches  = branchesReducer.map((val) => (val.branch_id))
        let data = {
            company_id : state.company_id,
            branches:branches,
            branch_id : state.branch_id,
            from: moment(state.selection.startDate).format('YYYY-MM-DD'),
            to:moment(state.selection.endDate).format('YYYY-MM-DD'),
            req_type : 'Request for Payment',
            limit : limit,
            page : newPage+1,
            status :status
        }   
        getData('aam/getTransactionDetails',data).then((res)=>{
            dispatch({
                type:'onChnageRequestLogs',
                data:{
                    requestLogsDetails:res.details,
                    totalRow:res.row,
                    dashboard:res.dashboard
                }
            })
            setState(prev=>({
                ...prev,
                refreshTransactionTable:!state.refreshTransactionTable,
                refreshDashboard:!state.refreshDashboard
            }))
            dispatch({
                type: 'onChangeHomeReducer',
                data: {
                    loading: false,
                }
            })
        })
        })

        React.useEffect(()=>{
            dispatch({
                type:'onChnageRequestLogs',
                data:{
                    requestLogsDetails:[],
                    totalRow:0,
                    dashboard:[
                        {type:'Total',count:0,color:'#3498db'},
                        {type:'Approved',count:0,color:'#2ecc71'},
                        {type:'Pending',count:0,color:'#faab2d'},
                        {type:'Return',count:0,color:'#2c3e50'},
                        {type:'Cancelled',count:0,color:'#f56b5d'},
                        {type:'Denied',count:0,color:'#bf7bdb'},
                    ]
                }
            })
        },[])
        const handleClose = () =>{

        }
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="textPrimary">Request Logs</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Filter 
                    refreshFilter={state.refreshFilter} 
                    openDateRange = {()=>openDateRange()}
                    getDateTransaction = {getDateTransaction}
                    state={state}
                    setState={setState}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Dashboard refreshDashboard={state.refreshDashboard} getDateTransaction={getDateTransaction}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <FilterBranch 
                    setState={setState}
                    refreshFilterBranch={state.refreshFilterBranch}
                    state={state}
                    getDateTransaction = {getDateTransaction}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TransactionTable
                    getDateTransaction = {getDateTransaction}
                    refreshTransactionTable={state.refreshTransactionTable}
                    setState={setState}
                    />
                </Grid>
            </Grid>
            <Dialog fullScreen open={state.reqDetailsModal} onClose={() => {
                setState(prev => ({ ...prev, reqDetailsModal: false }))
            }} >
                <AppBar className={classes.appBar} elevation={1}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => {
                            setState(prev => ({ ...prev, reqDetailsModal: false }))
                        }} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" >
                            {state.actionButton}
                        </Typography>
                    </Toolbar>
                    {/* <div style={{ backgroundColor: '#2c7f84', height: 4, width: '100%' }} /> */}
                </AppBar>
                <div style={{ marginTop: 60 }} />
                <div style={{ display: 'flex', flex: 1, background: '#f5f7f7' }}>
                    <RequestDetails onSubmitCancelled={(status) => { }} refreshFunc={() => { setState(prev => ({ ...prev, refreshReqList: !state.refreshReqList })) }} handleClose={() => handleClose()} action={state.actionButton} selectedRequest={state.selectedRequest} edit={state.edit} />
                    <div style={{ marginTop: 70 }} />

                </div>
            </Dialog>
        </div>
    );
}
export default IndexTransaction;
