import moment from 'moment'
const initialState = {
   requestLogsDetails : [],
   dashboard:[],
   page:0,
   rowsPerPage:10,
   totalRow:0,
   company_id:'All',
   branch_id:'All',
   selection: {
    startDate: new Date(moment(new Date()).format('YYYY-MM-01')),
    endDate: new Date(),
    key: 'selection'
},
  }
  const dataReducer = (state = initialState, action) => {
      switch (action.type) {
          case 'onChnageRequestLogs':
              return {
                  ...state,
                  ...action.data,
              }
          default:
              return state;
      }
  }
  export default dataReducer; 