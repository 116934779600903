import { TextField, Typography } from "@material-ui/core";
import React from "react";
const renderEqualProps = (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};
const ImputField = (props) => {
  const { ...param } = props;
  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <Typography
          style={{ fontWeight: "bold", fontSize: 12, textAlign: "left" }}
          variant="p"
        >
          {param.label}
        </Typography>
      </div>
      <TextField
        variant="outlined"
        type={param.type}
        label={param.inLabel}
        fullWidth
        size={"small"}
        onChange={param.onChange}
        value={param.value}
        name={param.name}
        required={param.required}
      />
    </>
  );
};

export default React.memo(ImputField, renderEqualProps);
